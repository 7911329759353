import { isSameDate } from "../../../functions";
import beautyDate from "../../../tables/functions/beautyDate";
import flagAll from "../../../../images/icons/buttons/info.svg";
import flagEN from "../../../../images/flags/gb.svg";
import flagDE from "../../../../images/flags/de.svg";
import historyIcon from "../../../../images/icons/buttons/history.svg";

export default function Filters({
  setFiltered,
  data,
  activeFilter,
  setActiveFilter,
  stringFilter,
  setStringFilter,
  filterLangAction,
  filterStringAction,
  historyFilter,
  setHistoryFilter,
  filterHistory,
  dataActive,
}) {
  return (
    <div>
      <div className="order-monitor-list-filters">
        <div className="order-monitor-list-flags">
          <div>
            <img
              src={flagEN}
              className={activeFilter === "Ang" ? "active" : ""}
              onClick={() => filterLangAction("Ang")}
            />
          </div>
          <div>
            <img
              src={flagDE}
              className={activeFilter === "Niem" ? "active" : ""}
              onClick={() => filterLangAction("Niem")}
            />
          </div>
        </div>
        <div>
          <input
            placeholder="Filtruj wg. zlec./klienta"
            className="order-monitor-list-search"
            type="search"
            onChange={(e) => filterStringAction(e.target.value)}
            value={stringFilter}
          />
        </div>
        <div
          onClick={() => {
            if (activeFilter !== "history") {
              filterHistory();
            } else {
              setFiltered(dataActive);
              setActiveFilter(false);
              setStringFilter("");
            }
          }}
        >
          <img
            src={historyIcon}
            className={
              "imgHistory" + (activeFilter === "history" ? " active" : "")
            }
          />
        </div>
        <div
          className="img"
          style={{ padding: "0 7px", color: "rgb(70,70,70)" }}
          onClick={() => {
            setFiltered(dataActive);
            setActiveFilter(false);
            setStringFilter("");
          }}
        >
          ✖
        </div>
      </div>
      {activeFilter || stringFilter ? (
        <div className="order-monitor-filter-info">
          {activeFilter === "Ang" ? (
            <>Filtr: tylko angielskie</>
          ) : activeFilter === "Niem" ? (
            <>Filtr: tylko niemieckie</>
          ) : activeFilter === "history" ? (
            <>Łącznie z gotowymi</>
          ) : stringFilter ? (
            <>Filtr: {stringFilter}</>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
