import { useState } from "react";
import { useGeneric } from "../useGeneric";
import DailyReport from "./WorkTimeReports/DailyReport";

import PersonReport from "./WorkTimeReports/PersonReport";
import { minutesToTimeString } from "./WorkTimeView";
import Timesheet from "./TimesheetRev/Timesheet";
import DatePick from "./TimesheetRev/DatePick";
import WorkTimeStats from "./WorkTimeStats";
import Loading from "./Loading";

export default function WorkTimeReports({ user }) {
  const [selectedDate, setSelectedDate] = useState(() => {
    const d = new Date();
    d.setHours(12);
    d.setDate(d.getDate()-1);
    
    return d.toISOString().slice(0, 10);;
  });
  const [timescale, setTimescale] = useState("day");
  const [viewUser, setViewUser] = useState();
  const [extraMode, setExtraMode] = useState(false);

  const downloadEmployeeStatus = useGeneric({
    name: "employeeStatus",
    query: { date: selectedDate },
    update: JSON.stringify(selectedDate),
    reload: 45,
  });

  const downloadReports = useGeneric({
    name: "TSreports",
    query: { date: selectedDate, timescale: timescale },
    //update: selectedDate + timescale,
    update: JSON.stringify(selectedDate) + timescale,
    reload: 45,
  });

  const employeeStatus = !downloadEmployeeStatus.loading
    ? downloadEmployeeStatus.rows
    : undefined;
  const reports = !downloadReports.loading ? downloadReports.rows : undefined;

  const reportsByPerson = [];

  if (reports) {
    reports.forEach((item) => {
      const dataStructure = {
        ...item,
      };

      if (reportsByPerson[item.employeeID]) {
        reportsByPerson[item.employeeID].push(dataStructure);
      } else {
        reportsByPerson[item.employeeID] = [dataStructure];
      }
    });
  }

  console.log(reports, reportsByPerson);

  return (
    <div className="workReports">
      {viewUser ? (
        <div className="reportsWrapper full">
          <div className="timesheetAllHeading">
            <div>{viewUser.fullname}</div>
            <button
              className="regularButton"
              onClick={() => setViewUser(undefined)}
            >
              Powrót
            </button>
          </div>
          <div className="workTimeViewWrapper">
            <div className="timesheet workTime">
              <Timesheet user={viewUser} defaultDate={new Date(selectedDate)} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="reportsHeader">
            {!extraMode ? <>
            <div className="timescaleHeader">
              <button
                className={
                  "regularButton" + (timescale === "day" ? " active" : "")
                }
                onClick={() => setTimescale("day")}
              >
                Dzień
              </button>
              <button
                className={
                  "regularButton" + (timescale === "month" ? " active" : "")
                }
                onClick={() => setTimescale("month")}
              >
                Miesiąc
              </button>
            </div>
            <DatePick date={new Date(selectedDate)} set={setSelectedDate} />
            </> : <></>}
            <div>
              {!extraMode ?
              <button onClick={() => setExtraMode("stats")}>
                Statystyki wg zleceń
              </button>
              : 
              <button onClick={() => setExtraMode(false)}>
                Powrót do raportów
              </button>}
            </div>
          </div>

          {extraMode === "stats" ? (
            <WorkTimeStats/>
          ) : !reports ? (
            <Loading text="Pobieranie..."/>
          ) : reports && reports.length === 0 ? (
            <div className="message">Brak danych</div>
          ) : (
            <div className="reportsWrapper">
              <div>
                <div className="employeeStatus">
                  {employeeStatus && timescale === "day" ? (
                    employeeStatus.map((item) => {
                      const workTimes = JSON.parse("[" + item.work_time + "]");

                      const workStart = workTimes.find((i) =>
                        [0, 2].includes(i?.type)
                      )?.time;

                      const workLocation = workTimes.find((i) =>
                        [0, 2].includes(i?.type)
                      )?.type;

                      const workStartTime = workStart
                        ? new Date(workStart).toLocaleTimeString("pl-PL", {
                            hour: "numeric",
                            minute: "numeric",
                          })
                        : "";
                      const workEnd = workTimes.find((i) =>
                        [1, 3].includes(i?.type)
                      )?.time;
                      const workEndTime = workEnd
                        ? new Date(workEnd).toLocaleTimeString("pl-PL", {
                            hour: "numeric",
                            minute: "numeric",
                          })
                        : "";
                      return (
                        <div
                          className={
                            "employeeCard" +
                            (item.holiday_type ? " absent" : "") +
                            (workLocation === 2 ? " remote" : "")
                          }
                        >
                          <div className="employeeName">{item.cardname}</div>
                          {item.holiday_type_name ? (
                            <div className="holidayType">
                              {item.holiday_type_name}
                            </div>
                          ) : (
                            <></>
                          )}
                          {workStartTime ? (
                            <div className="employeeWorkTime">
                              {workEndTime ? (
                                <>
                                  {" "}
                                  {workStartTime} ➔ {workEndTime}
                                </>
                              ) : (
                                <>
                                  {workStartTime ? (
                                    <> od {workStartTime}</>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
                <DailyReport date={selectedDate} timescale={timescale} />
              </div>
              <div className="personReportWrapper">
                {!reportsByPerson ? (
                  <>Ładowanie</>
                ) : (
                  Array.from(reportsByPerson)
                    .filter((item) => item)
                    .map((report) => (
                      <PersonReport report={report} setViewUser={setViewUser} />
                    ))
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
