import { Field, setIn, useFormikContext } from "formik";
import { useGeneric } from "../../useGeneric";
import { useContext, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { TableContext } from "../TableUniversal";
import Bag from "./Bag";
import TextEditor from "./TextEditor";

export default function FieldGroup({
  item,
  pathID,
  createMap,
  bagContents,
  setBagContents,
  inits,
  setSetterActed,
  initialSet,
  preFill,
  setAllFormSourceData,
  allFormSourceData,
  all,
}) {
  const { selectionData } = useContext(TableContext);

  const init = item?.dataSource?.init;

  const [bagContentsUpdates, setBagContentsUpdates] = useState(0);
  const { setFieldValue, values, initialValues } = useFormikContext();

  const [initQueryString, setInitQueryString] = useState(() => {
    if (item?.dataSource?.initFrom) {
      const initSet = item?.dataSource?.initFrom;
      const value =
        all && all.length ? all[0]?.[initSet.prop] : values[initSet.prop];
      return {
        [initSet.in]: {
          ...initSet.query,
          value: value,
        },
      };
    }
  });
  const [dataView, setDataView] = useState();
  const [searchString, setSearchString] = useState(/*values[item.name]*/ "");
  const [firstInit, setFirstInit] = useState(true);
  const [updates, setUpdates] = useState(0);
  const [validSelection, setValidSelection] = useState(false);
  const [stallLoading, setStallLoading] = useState(true);
  const [selectedObject, setSelectedObject] = useState();
  const [selectedDataSource, setSelectedDataSource] = useState(
    item.dataSource && item.dataSource.name
      ? Array.isArray(item.dataSource.name)
        ? item.dataSource.name[0]
        : item.dataSource.name
      : undefined
  );

  function setter(prop) {
    setSelectedObject(prop.name);
    item.dataSource?.setters(prop.name, values).map((setter) => {
      //console.log(setter);
      setFieldValue(setter[0], setter[1]);
      setBagContentsUpdates((prev) => prev + 1);
      setValidSelection(true);
    });
    setSetterActed((prev) => prev + 1);
  }

  const initDataFetch = useGeneric({
    name:
      item.dataSource &&
      item.dataSource.init &&
      selectionData &&
      selectionData[init.name]
        ? item.dataSource.name
        : undefined,
    query: {
      limit: 1,
      offset: 0,
      orderBy: "id",
      search:
        init && selectionData && selectionData[init.name]
          ? init.query(selectionData[init.name])
          : {},
    },
  });
  const initData =
    initDataFetch && !initDataFetch.loading ? initDataFetch.rows : undefined;

  const useData = useGeneric({
    query: {
      ...item?.dataSource?.params,
      search: item?.dataSource?.query
        ? {
            ...item?.dataSource?.query(searchString, selectedObject, values),
            active: item?.dataSource?.active
              ? { value: 1, exact: true }
              : undefined,
            ...initQueryString,
          }
        : undefined,
    },
    update: updates,
    //stall: stallLoading,
    name:
      item.dataSource && Array.isArray(item.dataSource.name)
        ? selectedDataSource.db
        : selectedDataSource,
  });
  const dataAll = !useData.loading ? useData.rows : undefined;

  const dataUnique =
    dataAll && dataAll.length && item?.dataSource?.distinct
      ? [
          ...new Map(
            dataAll.map((i) => [i[item.dataSource.distinct], i])
          ).values(),
        ]
      : [];

  const data = item?.dataSource?.distinct ? dataUnique : dataAll;
  //  console.log(initQueryString);

  const affectProps = item.affectProps;

  useEffect(() => {
    const wait = setTimeout(() => {
      if (
        item &&
        item.dataSource &&
        !item.dataSource.setters &&
        searchString.length < 2
      ) {
      } else {
        setUpdates((prev) => prev + 1);
      }
    }, 500);

    if (updates > 0 || item?.dataSource?.initFrom) {
      setStallLoading(false);
    }
    return () => clearTimeout(wait);
  }, [searchString]);

  useEffect(() => {
    if (updates > 0) {
      setInitQueryString({});
    }
    //console.log(item);
    if (updates === 0) {
      if (item.label === "jednostka") {
        if (!useData.loading && data && data.length) {
          setFieldValue(
            item.name,
            item?.dataSource?.initFrom?.display(data[0])
          );
        }
      }
    }
  }, [
    useData.loading,
    updates,
    item?.dataSource?.initFrom,
    JSON.stringify(allFormSourceData),
  ]);

  useEffect(() => {
    const initFrom = item?.dataSource?.initFrom;
    if (!useData.loading && initFrom && data && data.length) {
      const value = values[initFrom.prop];
      if (item?.dataSource?.setters) {
        const setterData = {
          name: data[0],
          view: item.dataSource?.display(data[0]),
          text: item.dataSource?.text(data[0]),
        };
        setter(setterData);
      }
    }
  }, [useData.loading]);

  useEffect(() => {
    if (
      item?.element === "select" ||
      (inits &&
        createMap?.initialize?.to === item.name &&
        inits[createMap?.initialize?.from])
    ) {
      setStallLoading(false);
    }
  }, []);

  /*useEffect(() => {
    if (!initDataFetch.loading) {
      const value = init?.display(initData?.[0]);
      if (item?.dataSource?.setters) {
        const setterData = {
          name: initData?.[0],
          view: item.dataSource?.display(initData?.[0]),
          text: item.dataSource?.text(initData?.[0]),
        };
        setFieldValue(item.name, value);
        setSearchString(value);
        setter(setterData);
      }
    }
  }, [initDataFetch.loading]);*/

  useEffect(() => {
    if (preFill && item.dataSource?.setters) {
      setValidSelection(true);
    }
  }, [preFill, item.dataSource?.setters]);

  useEffect(() => {
    if (!useData.loading) {
      setAllFormSourceData((prev) => ({
        ...prev,
        [item.name]: selectedObject,
      }));
    }
    //  console.log("updated source data");
  }, [JSON.stringify(selectedObject)]);

  /*
  useEffect(
    () => {
      //  console.log("source data affect");
      if (item.affect) {
        setFieldValue(
          item.affect,
          item.affectAction(values, allFormSourceData?.[item.name])
        );
      }
    },
    affectProps
      ? [...affectProps.map((p) => values[p]), setFieldValue, JSON.stringify(values), updates]
      : []
  );*/

  useEffect(() => {
    if (
      !values[item.bagID] ||
      (values[item.bagID] &&
        bagContents &&
        bagContents[item.bagID] &&
        Array.isArray(bagContents[item.bagID]) &&
        bagContents[item.bagID].includes(values[item.bagID]))
    ) {
      return;
    }
    setBagContents((prev) => ({
      ...prev,
      [item.bagID]: prev[item.bagID]
        ? [...prev[item.bagID], values[item.bagID]]
        : [values[item.bagID]],
    }));
  }, [bagContentsUpdates]);
  /*useEffect(() => {
    setBagContents(initialSet);
  
    setFieldValue(item.name, initialSet.name)
  }, [initialValues, JSON.stringify(initialSet)]);*/

  return (
    <div
      style={item?.hiddenOnEmpty && !preFill ? { display: "none" } : {}}
      className="field-group"
    >
      <label htmlFor={item?.name}>
        <div>
          {item.label
            ? typeof item.label === "function"
              ? item.label(values)
              : item.label
            : ""}
        </div>{" "}
        <div
          onClick={() => {
            setSelectedDataSource((prev) => {
              const length = item.dataSource.name.length;
              const current = item.dataSource.name.indexOf(prev);
              console.log(prev, length, current, item.dataSource.name);
              return current < length - 1
                ? item.dataSource.name[current + 1]
                : item.dataSource.name[0];
            });
          }}
        >
          {item.dataSource &&
          item.dataSource.name &&
          Array.isArray(item.dataSource.name) ? (
            <div className="data-source-select">{selectedDataSource.title}</div>
          ) : (
            <></>
          )}
        </div>
        {validSelection ? <div className="label-tick">✓</div> : <></>}
      </label>
      <div style={{ position: "relative", display: "flex", ...item.wrapStyle }}>
        {item?.type === "bag" ? (
          <>
            <Field
              name={item?.name}
              hidden="true"
              required={item?.required}
              style={item.inputStyle}
            />
            <Bag
              createMap={createMap}
              currentName={item?.name}
              disabled={item?.disabled}
              data={all}
              name={item?.source}
              contents={bagContents}
              setContents={setBagContents}
              convert={item?.convert}
              setFieldValue={setFieldValue}
            />
          </>
        ) : item?.element === "text-editor" && all ? (
          <div>
          <TextEditor name={item.name}/>
          {/*<Field name={item.name} as="textarea" style={{fontSize:"9px"}} disabled={true}/>*/}
          </div>
        ) : (
          <Field
            name={item.name}
            style={item.inputStyle}
            maxLength={item.maxLength}
            disabled={item.disabled}
            required={item?.required}
            as={item.element ? item.element : null}
            step={item.step ? item.step : null}
            type={item.type ? item.type : null}
            autocomplete="off"
            onChange={(e) => {
              setFieldValue(item.name, e.target.value);
              setSearchString(e.target.value);
              setDataView(true);
              setValidSelection(false);
            }}
            onFocus={(e) => {
              e.preventDefault();
              setUpdates((prev) => prev + 1);
              setStallLoading(false);
              setDataView(true);
            }}
            onBlur={(e) => {
              //e.preventDefault();
              setTimeout(() => setDataView(false), 200);
            }}
          >
            {item.options ? (
              item.options.map((i) => <option value={i.value}>{i.text}</option>)
            ) : item.dataSource?.options && data ? (
              <>
                {/* <option value="">-</option>*/}
                {data.map((i) => (
                  <option index={i} value={item.dataSource.options(i).value}>
                    {item.dataSource.options(i).text}
                  </option>
                ))}
              </>
            ) : null}
          </Field>
        )}
        {dataView && item.dataSource?.display ? (
          <div
            className={
              "search-field" +
              (item.dataSource?.inactive ? " disabled-search-field" : "")
            }
          >
            {useData.loading ? (
              <div style={{ padding: "5px 20px", fontSize: "12px" }}>
                {item.dataSource?.searchMessage ? (
                  item.dataSource?.searchMessage
                ) : (
                  <>Wyszukiwanie...</>
                )}
              </div>
            ) : !useData.loading && data && data.length ? (
              data
                .map((prop) => ({
                  name: prop,
                  view: item.dataSource?.display(prop),
                  text: item.dataSource?.text(prop),
                }))
                .map((prop) => (
                  <div
                    onClick={() =>
                      item?.dataSource?.setters ? setter(prop) : null
                    }
                  >
                    {prop.view}
                  </div>
                ))
            ) : (
              <div
                style={{
                  color: "brown",
                  padding: "5px 20px",
                  fontSize: "12px",
                }}
              >
                {item.dataSource?.notFoundMessage ? (
                  item.dataSource.notFoundMessage
                ) : (
                  <>Brak wyników</>
                )}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {item.addToBag ? (
          <button
            style={{
              width: "40px",
              height: "32px",
              boxShadow: "none",
              borderRadius: "0",
              marginTop: "0",
            }}
            onClick={(e) => {
              e.preventDefault();
              setBagContentsUpdates((prev) => prev + 1);
            }}
          >
            +
          </button>
        ) : (
          <></>
        )}
        {item?.appendix}
      </div>

      {data && item?.dataSource?.info ? (
        <div className="field-info">{item.dataSource.info(selectedObject)}</div>
      ) : (
        <></>
      )}
      {data && item?.dataSource?.infoOptions ? (
        <div className="field-info">
          {item.dataSource.infoOptions(
            data.filter((d) => d.id == values[item.name])[0]
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
