import React, { useState, useEffect } from "react";
import GoogleDriveLink from "../orders/GoogleDriveLink";
import SpreadsheetRow from "./SpreadsheetRow";
import Loading from "../main/Loading";

function Spreadsheet(props) {
  const {
    mode,
    dataStructure,
    calculables,
    data,
    deleteRow,
    editData,
    submitData,
    acceptRow,
    footer,
    options,
    params,
    historyComponent,
    superHeader,
    print,
    driveLink,
    firstPage,
  } = props;
  const [selected, setSelected] = useState([]);

  const [checkedIndices, setCheckedIndices] = useState({});

  useEffect(() => {
    setCheckedIndices([]);
    setSelected([]);
  }, [params]);

  if (!data) return <Loading text="Pobieranie danych" />;

  return (
    <div className={"spreadsheetView" + (print ? " only-print" : "")}>
      {Array(Math.ceil(data.length / 5))
        .fill(1)
        .map((item, index, array) => (
          <div
            className="printPages"
            style={{ top: (21 - 2.3) * index + "cm" }}
          >
            Strona numer {firstPage + index + 1}
          </div>
        ))}

      <div className={"spreadsheet" + (mode ? " " + mode : "")}>
        <div className="viewer">
          <table className="dataTable">
            <thead>
              {superHeader ? (
                <tr className={superHeader.className}>
                  <th
                    colSpan={
                      dataStructure.filter((d) => !d.hiddenTable).length
                    } /*style={superHeader && {borderTop:"2px solid white", borderLeft: "2px solid white" }}*/
                  >
                    {superHeader.content}
                  </th>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <th className="selectRow">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setCheckedIndices((prev) => {
                        const result = {};
                        const indices = data.map((i) => i.id);
                        indices.forEach((i) => (result[i] = e.target.checked));
                        return result;
                      });
                      if (e.target.checked) setSelected(data);
                      else setSelected([]);
                    }}
                  />
                </th>
                {options?.numberColumn ? <th>Lp.</th> : <></>}
                {dataStructure.map((dataItem) => (
                  <React.Fragment key={dataItem.id}>
                    {!dataItem.hidden &&
                    !dataItem.hiddenTable &&
                    !dataItem.additional ? (
                      <th
                        className={dataItem.className}
                        title={dataItem.description}
                      >
                        <span className="only-screen">
                          {dataItem.shortText}
                        </span>
                        <span className="only-print">{dataItem.longText}</span>
                      </th>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                ))}
                <th className="rowOptions"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index, array) => (
                <SpreadsheetRow
                  mode={mode}
                  key={parseInt(item.id)}
                  dataStructure={dataStructure}
                  item={item}
                  index={index}
                  array={array}
                  deleteRow={deleteRow}
                  editData={editData}
                  selected={selected}
                  setSelected={setSelected}
                  calculables={calculables}
                  options={options}
                  acceptRow={acceptRow}
                  checkedIndices={checkedIndices}
                  setCheckedIndices={setCheckedIndices}
                  historyComponent={historyComponent}
                />
              ))}
            </tbody>
          </table>
          {data.length ? <></> : <div className="message">Brak wpisów</div>}
        </div>
      </div>
      {footer ? footer(selected) : <></>}
    </div>
  );
}

export default Spreadsheet;
