import React from "react";
import order from "../images/icons/icons8-document-50.png";
import brk from "../images/icons/icons8-cafe-50.png";
import other from "../images/icons/icons8-puzzle-50.png";
import out_duty from "../images/icons/icons8-briefcase-50.png";
import out_private from "../images/icons/icons8-freedom-50.png";

function Icon(props) {
  const { name } = props;
  let src = null;
  switch (name) {
    case "order":
      src = order;
      break;
    case "break":
      src = brk;
      break;
    case "other":
      src = other;
      break;
    case "out_duty":
      src = out_duty;
      break;
    case "out_private":
      src = out_private;
      break;
  }

  return <img className="icon" src={src} />;
}

export default Icon;
