import customersIcon from "../../../../images/icons/buttons/customers.svg";
import ordersIcon from "../../../../images/icons/buttons/orders.svg";
import invoicesIcon from "../../../../images/icons/buttons/invoice.svg";
import moneyIcon from "../../../../images/icons/buttons/money.svg";
import info2Icon from "../../../../images/icons/buttons/info2.svg";
const menuCustomers = {
  items: [
    {
      text: "Klienci",
      image: customersIcon,
      link: "../major-customers",
    },
    {
      text: "Zlecenia",
      image: ordersIcon,
      link: "../orders",
    },
    {
      text: "Faktury",
      image: invoicesIcon,
      link: "../invoices",
    },
    {
      text: "Paragony",
      image: moneyIcon,
      link: "../trade-documents",
    },
    {
      text: "Informacje",
      image: info2Icon,
      link: "../customer-details-table"
    }
  ],
};
export default menuCustomers;
