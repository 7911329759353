import { useState, useEffect } from "react";
import { prepOrder } from "./functions";
import { URL } from "./global";

/*
CREATE TABLE `timesheet` (
  `id` int(11) NOT NULL,
  `user` varchar(30) NOT NULL,
  `date` date DEFAULT NULL,
  `startTime` datetime DEFAULT NULL,
  `endTime` datetime DEFAULT NULL,
  `lastTime` datetime DEFAULT NULL,
  `type` int(11) DEFAULT NULL,
  `status` int(11) DEFAULT NULL,
  `orderID` int(11) DEFAULT NULL,
  `orderString` varchar(25) DEFAULT NULL,
  `documentID` int(11) DEFAULT NULL,
  `characters` int(11) NOT NULL,
  `template` varchar(50) NOT NULL,
  `flags` varchar(100) NOT NULL,
  `comments` varchar(1024) NOT NULL
) ENGINE=InnoDB DEFAULT CHARSET=utf8;
*/

const getTimesheetItems = () => {
  //return fetch(URL + "components/api.php/records/timesheet", {
  return fetch(URL + "components/db-timesheet.php", {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({queryType: 'fetch'})
  })
    .then((response) => response.json())
    .then((timesheetItems) => {
      timesheetItems.forEach((item) => {
        const now = new Date();
        const timezoneOffset = now.getTimezoneOffset();
        
        item.startTimeObject =
          item.startTime &&
          new Date(
            new Date(item.startTime).getTime() - timezoneOffset * 60 * 1000
          );
        item.endTimeObject =
          item.endTime &&
          new Date(
            new Date(item.endTime).getTime() - timezoneOffset * 60 * 1000
          );
        item.dateObject = item.date && new Date(item.date);
        
        item.order = {};
        for (const property in item) {
          // if the property_name starts with "orderdata_"
          if (property.search(/^orderdata_/) > -1) {
            
            item.order[property.replace(/^orderdata_/, "")] = item[property];
            delete item[property];
          }
        }

        item.order.valid_order = item.orderString;
        item.order = prepOrder(item.order);
      });
      return timesheetItems;
    });
};

function useTimesheet(updateCount) {
  const [timesheetItems, setTimesheetItems] = useState([]);

  useEffect(() => {
   getTimesheetItems().then((timesheetItems) => setTimesheetItems(timesheetItems));
  }, [updateCount]);

  return {
    all: timesheetItems,
    last: timesheetItems[timesheetItems.length-1]
  }
};

export default useTimesheet;