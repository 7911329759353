import React from "react";
import useOrderSearch from "./useOrderSearch";

function ShowQueriedOrders(props) {
  const { query, inputProps } = props;
  const queryTransform = query && query?.replace(/\./g, "/");
  const orders = useOrderSearch(queryTransform);

  return query ? (
    <div className="showQueriedOrders">
      <select {...inputProps}>
        
        <option value={0}>
          {orders?.length ? "Wybierz znalezione..." : "---"}
        </option>
        
        {orders.map((item) => (
          <option
            key={item.id}
            value={item.id}
            affected={JSON.stringify({
              order_number:
                String(item.number_order).replace(/\//g, ".") +
                "." +
                item.language_main.toLowerCase(),
              order_customer: item.customers_name,
              client: item.customers_name,
              source_language: item.language_from,
              document_name: "",
              //document_return: item.finish_date.slice(0, 10),
              pages: item.pages_to_translate_count,
              gross_per_page:
                Math.round(
                  (parseFloat(item.price_brutto) /
                    parseFloat(item.pages_to_translate_count)) *
                    10
                ) / 10,
            })}
          >
            {String(item.number_order).replace(/\//g, ".")}.
            {item.language_main.toLowerCase()}{" "}
            {item.customers_name.slice(0, 60)}
            {item.acceptance_methods_retrieved_date !== "0000-00-00 00:00:00" &&
              " --- zwrot: " +
                new Date(item.acceptance_methods_retrieved_date)
                  .toLocaleString("pl-PL")
                  .slice(0, -3)}
          </option>
        ))}
      </select>
    </div>
  ) : (
    <></>
  );
}

export default ShowQueriedOrders;
