import { useFormContext } from "react-hook-form";
import { FieldType } from "./interfaces";
import SearchBox from "../main/TimesheetRev/NewItem/SearchBox/SearchBox";
import HybridField from "./HybridField";
import { get } from "http";
import { useContext, useEffect, useState } from "react";
import { MyFormContext } from "./Form";
import FieldButtons from "./FieldButtons";
export default function Field({
  field,
  name,
  defaultValue,
}: {
  field: FieldType;
  name: string;
  defaultValue: any;
}) {
  const { fieldValueObjects, setFieldValueObjects, disabledFields, edit, calculate } =
    useContext(MyFormContext);
  const {
    label,
    formFields,
    element,
    searchBoxProps,
    defaultFrom,
    defaultFromDB,
    setValues,
    setValuesIfEmpty,
    searchBoxGetDefaultFrom,
    searchBoxGenerateDefaults,
    type,
    step,
    style,
    hidden,
    labelStyle,
    inputStyle,
    buttonsStyle,
    options,
    buttons,
    min,
    max,
    disabled,
    updateOnChangedField,
    required
  } = field;

  const { register, watch, setValue, getValues } = useFormContext();
  const watchThis = watch(name);
  const isDisabled = disabledFields && disabledFields.includes(name);
  console.log(name, watchThis);

  
  const [dynamicLabel, setDynamicLabel] = useState(
    typeof label === "function" ? 
    label(getValues()) 
    : label
  );

  return (
    <div
      className="uf-field"
      data-field={name}
      style={{ ...style, display: hidden ? "none" : undefined }}
    >
      <div>
        <label htmlFor={name} style={labelStyle}>
          {dynamicLabel}
        </label>
        {type !== "hybrid" ? (
          <FieldButtons
            style={buttonsStyle}
            item={{
              name: name,
              value: watchThis,
              setValue: setValue,
              values: watch(),
              setLabel: setDynamicLabel,
            }}
            buttons={buttons}
          />
        ) : (
          <></>
        )}
      </div>
      {!element || element === "input" ? (
        <input
          type={hidden ? "hidden" : type ? type : "text"}
          min={min ? min : undefined}
          max={max ? max : undefined}
          {...register(name)}
          className={!watchThis ? "empty" : ""}
          style={inputStyle}
          step={step ? step : undefined}
          readOnly={isDisabled || disabled ? true : false}
          required={required ? true : false}
        />
      ) : element === "select" ? (
        <select
          {...register(name)}
          className={!watchThis ? "empty" : ""}
          style={inputStyle}
          disabled={isDisabled || disabled ? true : false}
          required={required ? true : false}
        >
          {options ? (
            options.map((option: any) => (
              <option value={option.value}>{option.text}</option>
            ))
          ) : (
            <></>
          )}
        </select>
      ) : element === "hybrid" ? (
        <HybridField
          name={name}
          searchBoxProps={searchBoxProps}
          searchBoxGetDefaultFrom={searchBoxGetDefaultFrom}
          searchBoxGenerateDefaults={searchBoxGenerateDefaults}
          inputStyle={inputStyle}
          fieldValueObjects={fieldValueObjects}
          setFieldValueObjects={setFieldValueObjects}
          setValues={setValues}
          setValuesIfEmpty={setValuesIfEmpty}
          defaultValue={defaultValue}
          formFields={formFields ? formFields : { name: name, id: "id" }}
          edit={edit}
          defaultFrom={defaultFrom}
          defaultFromDB={defaultFromDB}
          updateOnChangedField={
            updateOnChangedField ? updateOnChangedField : ""
          }
          buttons={buttons}
          required={required}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
