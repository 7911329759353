import React, { useState, useEffect } from "react";
import { URL } from "../../global";
import TranslatorsViewer from "./TranslatorsViewer";

function TranslatorsEditor(props) {
  const {
    translators,
    setCloseField,
    setSub,
    sub,
    setModal,
    setUpdateTranslators,
    updateTranslators,
  } = props;

  useEffect(
    () =>
      sub.show === true
        ? setCloseField((prev) => false)
        : setCloseField((prev) => true),
    [sub.show]
  );

  function change(e) {
    setSub((prev) => ({
      ...prev,
      data: { ...prev.data, [e.target.name]: e.target.value },
    }));
  }

  function editUser(e) {
    e.preventDefault();
    //console.log(sub.data);
    fetch(URL + "components/db-user-edit.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "edit", query: sub.data }),
    }).then((response) => {
      setUpdateTranslators((prev) => prev + 1);
      setModal(prev => ( {...prev, show:false}));
      return response.json();
    });
  }

  function removeUser(e) {
    e.preventDefault();
    //console.log(sub.data);
    fetch(URL + "components/db-user-edit.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "remove", query: sub.data }),
    }).then((response) => {
      setUpdateTranslators((prev) => prev + 1);
      setModal(prev => ( {...prev, show:false}));
      return response.json();
    });
  }

  return sub.show ? (
    <div className="editTranslator spreadsheet-form">
      <div>
        <form>
          <div className="formItem">
            <label>Imię</label>
            <input
              type="text"
              name="First Name"
              value={sub.data["First Name"]}
              onChange={change}
            ></input>
          </div>
          <div className="formItem">
            <label>Nazwisko</label>
            <input
              type="text"
              name="Last Name"
              value={sub.data["Last Name"]}
              onChange={change}
            ></input>
          </div>
          <div className="formItem">
            <label>Stawka podstawowa</label>
            <input
              type="number"
              step=".01"
              name="Rate"
              value={sub.data["Rate"]}
              onChange={change}
            ></input>
          </div>
          <div className="formItem">
            <label>Liczba jednostek</label>
            <input
              type="number"
              name="RateUnit"
              value={sub.data["RateUnit"]}
              onChange={change}
            ></input>
          </div>
          <div className="formItem">
            <label>Jednostka</label>
            <input
              type="text"
              name="RateUnitName"
              value={sub.data["RateUnitName"]}
              onChange={change}
            ></input>
          </div>
          <div className="formItem">
            <label>Języki</label>
            <input
              type="text"
              name="Languages"
              value={sub.data["Languages"]}
              onChange={change}
            ></input>
          </div>
          <button
            onClick={(e) => {
              setSub((prev) => ({ ...prev, show: !prev.show }));
              editUser(e);
            }}
          >
            Edytuj
          </button>
          {sub.data.Type === 2 ? (
            <button
              style={{ background: "#c97d65" }}
              onClick={(e) => {
                if (
                  window.confirm(
                    "Czy na pewno chcesz wykreślić tłumacza z listy?"
                  )
                ) {
                  setSub((prev) => ({
                    ...prev,
                    show: !prev.show,
                    update: prev.update + 1,
                  }));
                  removeUser(e);
                }
              }}
            >
              Wykreśl z listy
            </button>
          ) : (
            <></>
          )}
        </form>
      </div>
      {/*  <button onClick={() => setSub({ show: false, data: null })}>
        Powrót
  </button>*/}
    </div>
  ) : (
    <TranslatorsViewer
      updateTranslators={updateTranslators}
      setUpdates={setUpdateTranslators}
      setSub={setSub}
      sub={sub}
      translators={translators}
      setModal={setModal}
    />
  );
}

export default TranslatorsEditor;
