import React from "react";
import { useContext } from "react";
import { defaultEmailAccount } from "../global";
import driveIcon from "../../images/drive.png";
import AppContext from "../contexts/app-context";

function GoogleDriveLink(props) {
  const { orders, customString, caption } = props;
  const { accountID } = useContext(AppContext);
  const defaultAccount =
  !accountID || accountID.id1 === false || accountID.id1 === "false"
      ? defaultEmailAccount
      : accountID.id1;

  let orderStrings = "";
 

  if (customString) {
    orderStrings = customString;
  } else if (orders?.length) {
    orderStrings = orders
      .map((item) => item.orderNoLang)
      .slice(0, 3)
      .join('" OR "'); //.replaceAll(".", "\\.")
    if (
      orders.length === 1 &&
      orders[0].orderNoLang !== orders[0].orderNoLangOrLetter
    ) {
      orderStrings =
        orders[0].orderNoLang + '" OR "' + orders[0].orderNoLangOrLetter;
    }
  }
  //https://drive.google.com/drive/search?q=title:230.04.23
  const href =
    `https://drive.google.com/drive/u/${defaultAccount}/search?q=title:` +
    (orders && orders[0] ? orders[0].orderNoLangOrLetter : customString)  +
    //orderStrings +
    '';
  
  return (
    <a
      className="googleDriveLink"
      title="Szukaj na Dysku"
      target="_new"
      href={href}
    >
      <div>
        <img alt="driveLink" src={driveIcon}></img>
        {caption ? <div style={{fontSize:"9px"}}>{caption}</div> : <></>}
      </div>
    </a>
  );
}

export default GoogleDriveLink;
/*      https://drive.google.com/drive/u/${defaultEmailAccount}/search?q="` */
