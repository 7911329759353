import React from "react";

function TimesheetItemView(props) {
  const { item } = props;
  const orderNum = item.orderString && item.orderString.split(".")[0];
  const orderRest = item.orderString && item.orderString.slice(
    item.orderString.indexOf("."),
    item.orderString.length
  );

  return (
    <div className="timesheetItem">
      {item.orderString ? (
        <>
          <div style={{ gridColumn: "1/2" }} className="orderName">
            <div>
              <span className="orderNum">{orderNum}</span>
              <span className="orderRest">{orderRest}</span>
            </div>
          </div>
          <div className="customer" style={{ gridColumn: "2/4" }}>{item.order.customers_name}</div>
        </>
      ) : (
        <></>
      )}

      <div style={{ gridColumn: "1/2" }}>
        <span>{item.order.language_from}</span>
        <span className="smaller">➤</span> <span>{item.order.language_to}</span>
      </div>
      <div style={{ gridColumn: "2/4" }}>{item.typeString}</div>
      <div>{item.dateObject.toLocaleDateString()}</div>
      <div>
        <div style={{ justifyContent: "flex-end", padding: "0 5px" }}>
          {item.startTimeObject.toLocaleTimeString().slice(0, -3)}
        </div>
        <span className="smaller">➤</span>
        <div style={{ justifyContent: "flex-start", padding: "0 5px" }}>
          {item.endTimeObject
            ? item.endTimeObject.toLocaleTimeString().slice(0, -3)
            : "---"}
        </div>
      </div>
      {item.comments ? (
        <div className="comments" style={{ gridColumn: "1/4" }}>
          {item.comments}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TimesheetItemView;

/*
characters: "0"
comments: ""
customers_name: null
date: "2021-12-31"
dateObject: Fri Dec 31 2021 01:00:00 GMT+0100 (czas środkowoeuropejski standardowy) {}
documentID: "0"
endTime: "2021-12-31 14:00:44"
endTimeObject: Fri Dec 31 2021 15:00:44 GMT+0100 (czas środkowoeuropejski standardowy) {}
flags: "0"
id: "150"
language_from: null
language_to: null
lastTime: "2021-12-31 13:57:08"
number_order: null
orderID: "0"
orderString: null
price: null
startTime: "2021-12-31 13:57:08"
startTimeObject: Fri Dec 31 2021 14:57:08 GMT+0100 (czas środkowoeuropejski standardowy) {}
status: "1"
template: "0"
type: "12"
typeString: "wyjście prywatne"
user: "tymczasowy"
*/
