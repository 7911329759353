import { useContext, useRef, useState } from "react";
import editIcon from "../../../../images/icons/buttons/edit.svg";
import acceptIcon from "../../../../images/icons/buttons/save.svg";
import rejectIcon from "../../../../images/icons/buttons/trash.svg";
import modify from "../../modify";
import AppContext from "../../../contexts/app-context";

export default function OrderViewCharsEditor({ i, setUpdates }) {
  const [editor, setEditor] = useState(false);
  const [chars, setChars] = useState(
    i.char_count ? i.char_count : i.last_worked && i.chars ? i.chars : 0
  );
  const input = useRef();

  const { setOrderParams } = useContext(AppContext);

  function setDB() {
    console.log(`Added ${chars} to document ${i.document_id}`);
    const translatorsString =
      (i.translator ? i.translator : "") +
      (i.external_translator ? " *" + i.external_translator : "");
    console.log(translatorsString);

    if (chars != undefined && i.document_id) {
      modify(
        "edit-doc-chars",
        { char_count: chars, translator_name: translatorsString },
        i.document_id
      );
    }
    setUpdates((prev) => prev + 1);
    setEditor(false);
    setTimeout(
      () => setOrderParams((prev) => ({ ...prev, update: prev.update + 1 })),
      400
    );
  }

  return editor ? (
    <div>
      <div>
        <input
          ref={input}
          onChange={(e) => setChars(e.target.value)}
          onKeyUp={(e) => (e.key === "Enter" ? setDB() : null)}
          style={{ width: "80px", fontSize: "15px", fontFamily: "monospace" }}
          type="number"
          value={chars}
        />
      </div>
      <div className="order-view-char-options">
        <img
          onClick={() => {
            setEditor(false);
            setChars(
              i.char_count
                ? i.char_count
                : i.last_worked && i.chars
                ? i.chars
                : 0
            );
          }}
          style={{ background: "rgba(255,215,215)" }}
          src={rejectIcon}
        />
        <img
          onClick={() => setDB()}
          style={{ background: "lightgreen" }}
          src={acceptIcon}
        />
      </div>
    </div>
  ) : (
    <div>
      <div>
        {i.last_worked && i.chars ? (
          <div
            style={
              i.char_count
                ? {
                    fontWeight: "bold",
                    color: "green",
                  }
                : {}
            }
          >
            <span
              title={
                "Zatwierdzone znaki: " +
                i.char_count +
                "\r\n" +
                "Znaki z timesheetu: " +
                i.chars
              }
              style={{
                fontSize: "15px",
              }}
            >
              {i.char_count ? i.char_count : i.chars}
            </span>{" "}
            zzs
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="order-view-char-options">
        <img
          onClick={() => {
            setEditor(true);
            setTimeout(() => input?.current?.focus(), 200);
          }}
          style={{ background: "transparent" }}
          src={editIcon}
        />
        {Number(i.chars) || Number(i.char_count) ? (
          <img
            onClick={() => setDB()}
            style={{ background: "lightgreen" }}
            src={acceptIcon}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
