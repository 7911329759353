import React from "react";
import backIcon from "../../images/icons8-back-arrow-64.png";

function EditOrder(props) {
  const { editMode, setEditMode } = props;
  const item = editMode.order;
  let names =
    item.documents_names || item.documents_types
      ? item.documents_names.split(",")
      : [];
  const types = item.documents_types ? item.documents_types.split(",") : [];
  names = names.map((i, index) => (i ? i : types[index]));

  return (
    <div className="cs-edit-order">
      <button className="back"
        onClick={() => setEditMode((prev) => ({ ...prev, active: false }))}
      >
        <img src={backIcon}/>
      </button>
      <div className="cs-edit-basic">
      <h1>
        {String(item.number_order).replace(/\//g, ".")}.
        {item.language_main.toLowerCase()}
      </h1>
      <h2>{item.customers_name}<button className="cs-edit-button">Edytuj</button></h2>
      </div>
      <p>Przyjęte: {new Date(item.reception_date).toLocaleTimeString("pl-PL",{ year: "numeric", month: "2-digit", day: "2-digit", hour:"2-digit", minute: "2-digit", weekday: "long"})}</p>
      <p>Termin realizacji: {new Date(item.finish_date).toLocaleTimeString("pl-PL",{ year: "numeric", month: "2-digit", day: "2-digit", hour:"2-digit", minute: "2-digit", weekday: "long"})}</p>
      
      <table>
        <thead>
          <tr>
            <td>Lp.</td>
            <td>Nazwa</td>
          </tr>
        </thead>
        <tbody>
          {names.map((doc, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{doc}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EditOrder;
