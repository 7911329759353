import { useContext } from "react";
import Cell from "./Cell";
import { TableContext } from "../TableUniversal";
import removeNumberKeys from "../functions/removeNumberKeys";
import { Link } from "react-router-dom";
import modify from "../modify";
import infoIcon from "../../../images/icons/buttons/info.svg";
import editIcon from "../../../images/icons/buttons/edit.svg";
import removeIcon from "../../../images/icons/buttons/trash.svg";

export default function Row({ data, raw, i }) {
  const {
    headings,
    selectable,
    disableSelect,
    setUpdate,
    options,
    setDetails,
    offset,
    limit,
    buttons,
    del,
    selected,
    setSelected,
    lastSelected,
    setLastSelected,
  } = useContext(TableContext);

  function findLastKey(obj) {
    return Object.keys(obj)
      .sort((a, b) => b - a)
      .at(-1);
  }

  const disableSelection =
    disableSelect &&
    Array.isArray(disableSelect) &&
    raw[disableSelect[0]] === disableSelect[1];

  const successiveNumber = i + 1 + offset * limit;

  return (
    <tr
      className={selected && selected[i] && !disableSelection ? "selected" : ""}
    >
      {selectable ? (
        <td className="checkbox">
          {disableSelection ? (
            <></>
          ) : (
            <input
              type="checkbox"
              checked={selected && selected[i] ? selected[i] : false}
              onChange={(e) => {
                //console.log(e.nativeEvent.shiftKey)
                if (e.nativeEvent.shiftKey && lastSelected !== undefined) {
                  const current = parseInt(i);
                  const space = Math.abs(lastSelected - current);
                  for (let it = 0; it <= space; it++) {
                    setSelected((prev) => ({
                      ...prev,
                      [lastSelected - current > 0
                        ? lastSelected - it
                        : lastSelected + it]: true,
                    }));
                  }
                } else {
                  setSelected((prev) => ({ ...prev, [i]: !prev[i] }));
                  setLastSelected(i);
                }
              }}
            />
          )}
        </td>
      ) : (
        <></>
      )}
      <td
        style={{
          width: "10px",
          padding: "0 6px",
          minWidth: "0",
          color: "rgba(200,200,200)",
          fontFamily: "monospace",
          fontSize: "11px",
          fontWeight: "100",
        }}
      >
        {successiveNumber}
      </td>
      {headings.map((cell, index) => (
        <Cell key={successiveNumber + "-" + cell} data={data[cell]} />
      ))}
      <td style={{ width: "30px" }}>
        <div className="row-buttons">
          <button onClick={() => setDetails(i)}>
            <img
              style={{
                filter:
                  "contrast(.63) brightness(1.1) sepia(12) hue-rotate(140deg) saturate(25) ",
              }}
              src={infoIcon}
            />
          </button>
          {options?.editable && raw.settled !== 1 ? (
            <>
              <Link to={`edit/${raw.id}`} state={{ raw: raw, offset: offset }}>
                <button>
                  <img src={editIcon} />
                </button>
              </Link>

              {del ? (
                <button
                  onClick={async () => {
                    const really = window.confirm("Czy chcesz usunąć wpis?");
                    if (really) {
                      await modify(del, { active: 0 }, raw.id);
                      setUpdate((prev) => prev + 1);
                    }
                  }}
                  style={{
                    filter:
                      "contrast(.63) brightness(1.3) sepia(15) hue-rotate(-60deg) saturate(7) ",
                  }}
                >
                  <img src={removeIcon} />
                </button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {buttons ? (
            buttons.map((button) => (
              <Link to={button.link(data)}>
                <button onClick={button.action}>{button.content(data)}</button>
              </Link>
            ))
          ) : (
            <></>
          )}
        </div>
      </td>
    </tr>
  );
}
