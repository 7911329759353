export function datesArray(date) {
    date.setHours(0,0,0,0);
    const month = date.getMonth();
    const year = date.getFullYear();

    const arr = [];
    let i = 1;
    let nextDate = new Date(date);
    while (nextDate.getMonth() === month) {
        nextDate.setDate(i); 
        arr.push(new Date(nextDate));
        i++;
    } 
    arr.pop();
    return arr;
}