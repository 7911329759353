import nope from "../../../../images/icons/ghost_no.svg";
import { useEffect, useState } from "react";
import OrderListItem from "./OrderListItem";
import Filters from "./Filters";

export default function OrderList({
  data,
  token,
  loading,
  selectedID,
  setSelectedID,
}) {
  const dataActive = data.filter((i) => i.status === "1");
  const [filtered, setFiltered] = useState(dataActive);
  const [activeFilter, setActiveFilter] = useState(false);
  const [stringFilter, setStringFilter] = useState("");

  function filterHistory() {
    setFiltered(data);
    setActiveFilter("history");
  }

  function filterLangAction(lang) {
    setFiltered(dataActive.filter((i) => i.language_main === lang));
    setActiveFilter(lang);
    setStringFilter("");
  }
  function filterStringAction(filterString) {
    setStringFilter(filterString);
    setActiveFilter(false);
    if (filterString) {
      const filterReg = new RegExp(`${filterString}`, "gi");
      const filterRegNum = new RegExp(
        `${filterString.replace(/\./g, "/")}`,
        "gi"
      );

      setFiltered(
        data.filter(
          (i) =>
            i.number_order.search(filterRegNum) !== -1 ||
            i.customers_name.search(filterReg) !== -1 ||
            i.customers_symbol.search(filterReg) !== -1
        )
      );
    } else {
      setFiltered(dataActive);
    }
  }

  
  useEffect(() => {
    if (!loading) {
      if ((activeFilter && activeFilter === "Niem") || activeFilter === "Ang") {
        filterLangAction(activeFilter);
      } else if (activeFilter && activeFilter === "history") {
        filterHistory();
      } else if (stringFilter) {
        filterStringAction(stringFilter);
      } else {
        setFiltered(dataActive);
      }
    }
  }, [token]);

  return (
    <div className="order-monitor-list-top">
      <Filters
        data={data}
        dataActive={dataActive}
        setFiltered={setFiltered}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        stringFilter={stringFilter}
        setStringFilter={setStringFilter}
        filterLangAction={filterLangAction}
        filterStringAction={filterStringAction}
        filterHistory={filterHistory}
      />

      <div
        className="order-monitor-list"
        key={filtered && filtered[0] ? filtered[0].id : 0}
      >
        {filtered && filtered.length ? (
          filtered.map((order, ind, all) => (
            <OrderListItem
              key={order.id}
              order={order}
              documents={JSON.parse(order.documents)}
              ind={ind}
              all={all}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
            />
          ))
        ) : (
          <div className="filter-not-found">
            <div>
              <img src={nope} />
              <div>Nie znaleziono zleceń</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
