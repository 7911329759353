import { CSSProperties, useContext, useEffect, useState } from "react";
import SearchBox from "../main/TimesheetRev/NewItem/SearchBox/SearchBox";
import { useFormContext } from "react-hook-form";
import OrderItem from "../main/TimesheetRev/NewItem/SearchBox/ResultTypes/OrderItem";
import FieldButtons from "./FieldButtons";
import { useGeneric } from "../useGeneric";
import { MyFormContext } from "./Form";

export default function HybridField({
  name,
  formFields,
  searchBoxProps,
  searchBoxGetDefaultFrom,
  searchBoxGenerateDefaults,
  inputStyle,
  fieldValueObjects,
  setFieldValueObjects,
  setValues,
  setValuesIfEmpty,
  defaultValue,
  edit,
  defaultFrom,
  defaultFromDB,
  updateOnChangedField,
  buttons,
  required,
}: {
  name: string;
  formFields: any;
  searchBoxProps: any;
  searchBoxGetDefaultFrom?: string;
  searchBoxGenerateDefaults?: any;
  inputStyle?: CSSProperties;
  fieldValueObjects: any;
  setFieldValueObjects: any;
  setValues?: Function;
  setValuesIfEmpty?: Function;
  defaultValue: any;
  edit: Boolean;
  defaultFrom?: any;
  defaultFromDB?: any;
  updateOnChangedField: string;
  buttons?: any;
  required?: Boolean;
}) {
  const { getValues, watch, register, setValue } = useFormContext();
  const { defaultValues, duplicate } = useContext(MyFormContext);

  const [updates, setUpdates] = useState(0);

  const [touched, setTouched] = useState(false);

  // prefill value object from DB
  const fetchRaw: any = useGeneric(
    defaultFromDB && defaultFromDB(defaultValues)
      ? { ...defaultFromDB(defaultValues) }
      : { name: undefined }
  );

  const foundItem =
    fetchRaw && !fetchRaw.loading && fetchRaw.rows
      ? fetchRaw.rows[0]
      : undefined;

  const [selectedItem, setSelectedItem] = useState<any>(() => {
    if ((edit || duplicate) && defaultFrom) {
      const item: any = {};
      const def = defaultFrom(getValues());
      Object.keys(def).forEach((k) => (item[k] = def[k]));
      return item;
    } else {
      return {};
    }
  });

  useEffect(() => {
    if (foundItem && (edit || duplicate)) {
      setSelectedItem(foundItem);
      setFieldValueObjects((prev: any) => ({ ...prev, [name]: foundItem }));
      if (formFields) {
        formFields.forEach((f: any) => {
          if (foundItem[f.property]) {
            setValue(f.name, foundItem[f.property]);
          }
        });
      }
    }
  }, [fetchRaw.loading]);

  
  function valueSetter(setterFunction: Function | undefined, force: Boolean) {
    const setters =
      setterFunction &&
      setterFunction(selectedItem) &&
      Object.keys(setterFunction(selectedItem));
    const setterObject =
      setterFunction && setterFunction(selectedItem)
        ? setterFunction(selectedItem)
        : {};
    if (setters && setterObject) {
      setters.forEach((key: any) => {
        console.log(getValues()[key]);
        if ((!force && !getValues()[key]) || force) {
          setValue(key, setterObject[key]);
        }
      });
    }
  }

  useEffect(() => {
    if (touched) {
      valueSetter(setValues, true);
      valueSetter(setValuesIfEmpty, false);

      // set hidden field value corresponding to hybrid field value
      formFields.forEach((formField: { name: string; property: string }) => {
        setValue(formField?.name, selectedItem?.[formField?.property]);
      });
      setValue(name, selectedItem);
      setFieldValueObjects((prev: any) => ({ ...prev, [name]: selectedItem }));
    }
  }, [JSON.stringify(selectedItem)]);

  useEffect(() => {
    if (
      searchBoxGetDefaultFrom &&
      fieldValueObjects?.[searchBoxGetDefaultFrom]
    ) {
      setUpdates((prev) => prev + 1);
    }
  }, [
    searchBoxGetDefaultFrom ? fieldValueObjects?.[searchBoxGetDefaultFrom] : [],
  ]);

  // IF THE RELATED FIELD CHANGES THEN UPDATE SELECTED ITEM TO EMPTY
  useEffect(() => { 
    setSelectedItem({});
  }, [watch(updateOnChangedField)]);

  

  return (
    <div
      onClick={() => setTouched(true)}
      style={edit && fetchRaw.loading ? { opacity: ".3" } : {}}
    >
      {formFields.map((formField: { name: string; property: string }) => (
        <input
          type="hidden"
          {...register(formField?.name)}
          required={required ? true : false}
        />
      ))}
      <FieldButtons item={selectedItem} buttons={buttons} />
      <SearchBox
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        inputStyle={inputStyle}
        updates={updates}
        {...searchBoxProps}
        queryDefaults={
          searchBoxGetDefaultFrom && searchBoxGenerateDefaults
            ? searchBoxGenerateDefaults(
                fieldValueObjects?.[searchBoxGetDefaultFrom]
              )
            : searchBoxProps?.queryDefaults
        }
        updateOnChanged={JSON.stringify(fieldValueObjects)}
      />
    </div>
  );
}
