import { useEffect, useState } from "react";
import NumberOrder from "./common/NumberOrder";
import ExecutiveDailyOrderTableLang from "./ExecutiveDailyOrderTableLang";

export default function ExecutiveDailyOrderTable({ data, selectedDate, setEnglishSum, setGermanSum }) {
  const [mode, setMode] = useState(0);
  const [onlyLeft, setOnlyLeft] = useState(true);
  
  const viewData =
    mode === 0
      ? [data.find((d) => d.language === "Ang")]
      : mode === 1
      ? [data.find((d) => d.language === "Niem")]
      : data;

  return (
    <div>
      <div className="detailed-order-report-bar">
        <div>Edytor</div>
        <div>
        <button
          onClick={() => setOnlyLeft((prev) => !prev)}
        >
          {onlyLeft ? <>Tylko w tłumaczeniu</> : <>Wszystkie</>}
        </button>
        </div>
        <div style={{ textAlign: "right" }}>
          {["angielski", "niemiecki"].map((item, index) => (
            <button
            style={{width:"90px"}}
              className={index === mode ? "active" : ""}
              key={index}
              onClick={() => setMode(index)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <div className="detailed-order-report-editor">
        <ExecutiveDailyOrderTableLang
          key={0}
          style={mode === 0 ? {} : { display: "none" }}
          viewData={[data.find((d) => d.language === "Ang")]}
          mode={mode}
          onlyLeft={onlyLeft}
          selectedDate={selectedDate}
          setSum={setEnglishSum}
        />
        <ExecutiveDailyOrderTableLang
          key={1}
          style={mode === 1 ? {} : { display: "none" }}
          viewData={[data.find((d) => d.language === "Niem")]}
          mode={mode}
          onlyLeft={onlyLeft}
          selectedDate={selectedDate}
          setSum={setGermanSum}
        />
      </div>
    </div>
  );
}
