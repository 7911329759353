import { useContext, useEffect, useRef, useState } from "react";
import { useGeneric } from "../../useGeneric";
import SearchWindow from "./SearchWindow";
import { TableContext } from "../TableUniversal";
import { useFormikContext } from "formik";
import { useSearchParams } from "react-router-dom";
import FilterClear from "../components/FilterClear";

export default function PreField({
  name,
  db,
  label,
  type,
  limit,
  style,
  searchColumn,
  targetColumn,
  affect,
  affected,
  affecting,
  setEffects,
  effects,
  target,
  standard,
  standardDB,
  orderBy,
  orderDesc,
  displayOptions,
  defaultSearch,
  searchLink,
  setSearchDisplay,
  ignore,
  clearPreFields,
  setClearPreFields,
  searchParams,
  setSearchParams,
  preFill,
  inputElement,
  exact,
  s,
}) {
  const {
    setSearch,
    search,
    urlMAP,
    initialize,
    update,
    setUpdate,
    loading,
    setInitialize,
  } = useContext(TableContext);

  // the value provided in the input box
  const [inputValue, setInputValue] = useState("");

  // the data associated with that value
  const [inputData, setInputData] = useState(
    defaultSearch ? defaultSearch : ""
  );
  const [viewList, setViewList] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [prevSearchString, setPrevSearchString] = useState("");
  const [updates, setUpdates] = useState(0);

  const effectSearch = effects && effects[name] ? effects[name] : {};

  const useFillOnEmpty = useGeneric({
    stall: false,
    name: search?.[preFill?.source]?.value ? preFill?.from.db : undefined,
    query: {
      limit: 1,
      offset: 0,
      search:
        preFill && search[preFill.source]
          ? {
              [preFill.from.searchColumn]: search[preFill.source].value
                ? {
                    value: search[preFill.source].value,
                    exact: true,
                  }
                : undefined,
            }
          : undefined,
    },
    //update: JSON.stringify(urlMAP)
  });

  const fillOnEmpty = !useFillOnEmpty.loading ? useFillOnEmpty.rows : undefined;
  //console.log(fillOnEmpty);

  useEffect(() => {
    console.log(fillOnEmpty);
    if (!useFillOnEmpty.loading) {
      setInputValue((prev) =>
        fillOnEmpty && preFill ? fillOnEmpty[0][preFill?.target] : prev
      );
    }
  }, [useFillOnEmpty.loading]);

  const useData = useGeneric({
    //update: updates,
    stall: false,
    name: db,
    query: {
      limit: limit ? limit : 200,
      offset: 0,
      orderBy: orderBy ? orderBy : undefined,
      orderDesc: orderDesc ? orderDesc : undefined,
      search: {
        ...effectSearch,
        [searchColumn]: searchString
          ? {
              value: searchString,
            }
          : undefined,
      },
    },
    update: updates,
  });
  const data = !useData.loading ? useData.rows : undefined;

  useEffect(() => {
    if (clearPreFields === true) {
      setInputValue("");
    }
  }, [clearPreFields]);

  useEffect(() => {
    const wait = setTimeout(() => {
      setSearchString(inputValue);

      if (inputValue !== "" && preFill && search[preFill.source]) {
        if (search[preFill.source].value) {
          setInitialize(true);
          setSearchParams({ [preFill.name]: search[preFill.source].value });
        } else if (search[preFill.source].value === "") {
          setInitialize(true);
          setSearchParams({});
        }
      } else {
        if (inputValue === "") {
          setSearch((prev) => ({
            ...prev,
            [target]: {
              ...prev[targetColumn],
              value: inputValue,
            },
          }));

          if (inputValue === "" && setEffects) {
            setEffects({});
          } else if (affect && affected && affecting) {
            console.log(affect, affected, affecting, inputData?.[affecting]);
            setEffects((prev) => ({
              ...prev,
              [affect]: {
                [affected]: {
                  value:
                    inputData && inputData[affecting]
                      ? inputData[affecting]
                      : "",
                },
              },
            }));
          }
        }
      }

      setUpdates((prev) => prev + 1);

      if (inputElement && document.activeElement === inputElement.current) {
        setViewList(true);
      }
    }, 600);
    return () => {
      clearTimeout(wait);
    };
  }, [inputValue]);

  let hideInterval = undefined;

  return (
    <div
      style={{ display: "flex", alignItems: "center", position: "relative" }}
      className="filter-single"
      onMouseLeave={() => {
        hideInterval = setTimeout(() => {
          setViewList(false);
        }, 660);
      }}
      onMouseEnter={() => {
        clearInterval(hideInterval);
      }}
    >
      <label>{label}</label>
      <div className="filter-value" style={{ position: "relative" }}>
        <input
          ref={inputElement ? inputElement : undefined}
          type={type ? type : preFill ? "" : "search"}
          style={style}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);

            //setSearchString(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Escape") {
              setInputValue("");
            }
          }}
          onClick={() => {
            setViewList(true);
           
          }}
          /*
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setSearchString("");
              setPrevSearchString(searchString);
              setUpdates((prev) => prev + 1);
              setViewList(true);
            }
          }}*/
          /*
          onClick={(e) => {
            setSearchString((prev) =>
              JSON.stringify(searchString) === JSON.stringify(prevSearchString)
                ? prevSearchString
                : ""
            );

            setPrevSearchString(searchString);
            setUpdates((prev) => prev + 1);
            setViewList(true);
          }}*/
        />
        {preFill && inputValue ? (
          <button
            onClick={() => {
              setSearchParams({});
              setInputValue("");
              setSearch((prev) => ({ ...prev, [preFill.source]: "" }));
              //setInitialize(true);
              setUpdate((prev) => prev + 1);
            }}
          >
            ✕
          </button>
        ) : (
          <></>
        )}
        {viewList ? (
          <SearchWindow
            data={data}
            visible={viewList}
            setVisible={setViewList}
            setInput={setInputValue}
            setInputData={setInputData}
            display={
              displayOptions ? displayOptions : (v) => <>{v?.[searchColumn]}</>
            }
            inputElement={inputElement}
            name={searchColumn}
            targetColumn={targetColumn}
            target={target}
            searchLink={searchLink}
            setSearchDisplay={setSearchDisplay ? setSearchDisplay : () => {}}
            ignore={ignore}
            setClearPreFields={setClearPreFields}
            standard={standard}
            standardDB={standardDB}
            s={s}
            exact={exact}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
