import { useEffect, useState } from "react";
import { useGeneric } from "../useGeneric";
import { minutesToTimeString } from "./WorkTimeView";
import { isToday } from "../functions";
import Loading from "./Loading";

export default function WorkTimeStats() {
  const [orderQuery, setOrderQuery] = useState("");
  const [clientQuery, setClientQuery] = useState("");
  const [translatorQuery, setTranslatorQuery] = useState("");
  const [updates, setUpdates] = useState(0);
  const dataFetch = useGeneric({
    name: "ts-stats",
    query: {
      limit: 50,
      offset: 0,
      orderBy: "last_started",
      orderDesc: "DESC",
      search: {
        order_string: {
          value: orderQuery.replaceAll(".", "/"),
          exactLeft: true,
        },
        customer_name: {
          value: clientQuery,
        },
        cardname: {
          value: translatorQuery,
        },
      },
    },
    update: updates,
    reload: 90,
  });
  const data = !dataFetch.loading ? dataFetch.rows : undefined;

  const sumCount =
    data &&
    data
      .filter((item) => item.action_type === 1)
      .map((item) => item.count_zzs)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0);
  const sumMinutes =
    data &&
    data
      .filter((item) => item.action_type === 1)
      .map((item) => item.minutes)
      .reduce((a, b) => (a ? parseInt(a) : 0) + (b ? parseInt(b) : 0), 0);
  useEffect(() => {
    const wait = setTimeout(() => setUpdates((prev) => prev + 1), 1200);
    return () => clearTimeout(wait);
  }, [orderQuery, clientQuery, translatorQuery]);

  return (
    <div className="worktime-stats">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: "20px",
          maxWidth: "600px",
          padding: "3px",
        }}
      >
        <input
          placeholder="nr zlecenia"
          value={orderQuery}
          onChange={(e) => setOrderQuery(e.target.value)}
        />
        <input
          placeholder="nazwa klienta"
          value={clientQuery}
          onChange={(e) => setClientQuery(e.target.value)}
        />
        <input
          placeholder="tłumacz"
          value={translatorQuery}
          onChange={(e) => setTranslatorQuery(e.target.value)}
        />
      </div>
      {data ? (
        <>
          <div
            className="sums"
            style={{
              display: "grid",
              padding: "10px",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridGap: "20px",
            }}
          >
            <div>(tłumaczenie)</div>
            <div>Czas: {minutesToTimeString(sumMinutes)}</div>
            <div>Znaki: {sumCount} zzs</div>
            <div>
              Średnia:{" "}
              {sumCount && sumMinutes
                ? Math.floor((sumCount / sumMinutes) * 60) + " zzs/h"
                : ""}
            </div>
          </div>
          <table className="dataTable">
            <thead style={{ top: "50px" }}>
              <th>Nr</th>
              <th>Klient</th>
              <th>Dokumenty</th>
              <th>Czynność</th>
              <th>Zzs</th>
              <th>Czas</th>
              <th>Średnia</th>
              <th>Osoba</th>
              <th>Początek pracy</th>
              <th>Koniec pracy</th>
              <th>
                Cena netto/
                <br />
                godz.pracy
              </th>
            </thead>
            <tbody>
              {data
                .filter((item) => item.order_id)
                .sort((a, b) => (b.ended ? 0 : 1) - (a.ended ? 0 : 1))
                .map((item, index, all) => {
                  // minutes used to calculate actual price
                  const calcMinutes =
                    item.action_type === 1
                      ? // add transcription to calcMinutes
                        parseInt(item.minutes) +
                        all
                          .filter(
                            (i) =>
                              i.order_id === item.order_id &&
                              i.action_type === 4
                          )
                          .map((i) => i.minutes)
                          .reduce(
                            (a, b) =>
                              (a ? parseInt(a) : 0) + (b ? parseInt(b) : 0),
                            0
                          )
                      : item.minutes;

                  const documentIDs = item.document_ids.split(",");
                  const allDocumentPricesGross =
                    item.all_document_prices_gross.split(","); // brutto
                  const documentPricesGross = allDocumentPricesGross.slice(
                    0,
                    documentIDs.length
                  );
                  const allDocumentPricesNet =
                    item.all_document_prices_net.split(","); // brutto
                  const documentPricesNet = allDocumentPricesNet.slice(
                    0,
                    documentIDs.length
                  );

                  const price_brutto = Math.round(
                    documentPricesGross.reduce(
                      (a, b) =>
                        (a ? parseFloat(a) : 0) + (b ? parseFloat(b) : 0),
                      0
                    )
                  );
                  const price = Math.round(
                    documentPricesNet.reduce(
                      (a, b) =>
                        (a ? parseFloat(a) : 0) + (b ? parseFloat(b) : 0),
                      0
                    )
                  );
                  console.log(item.order_string, price, price_brutto);
                  return (
                    <tr
                      style={
                        !item.ended && item.started /*&&
                        isToday(new Date(item.started))*/
                          ? { color: "brown" }
                          : {}
                      }
                    >
                      <td>
                        {item.order_string.replaceAll("/", ".")}.
                        {String(item.language_main).toLowerCase()}
                      </td>
                      <td style={{ maxWidth: "220px", textAlign: "left" }}>
                        <div style={{ padding: "5px" }}>
                          <div>{item.customer_name}</div>
                          {!item.ended && item.started ? (
                            <div
                              style={{
                                fontSize: "11px",
                                background: "white",
                                marginTop: "8px",
                                width: "max-content",
                                padding: "2px 5px",
                                borderRadius:"5px",
                                boxShadow: "1px 1px 2px rgba(0,0,0,.3)"
                              }}
                            >
                              ➔{" "}
                              {new Date(item.finish_date).toLocaleString(
                                "pl-PL",
                                isToday(new Date(item.finish_date))
                                  ? { hour: "numeric", minute: "numeric" }
                                  : {
                                      day: "numeric",
                                      month: "short",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td style={{ maxWidth: "140px" }}>
                        {item.document_names ? (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {item.document_names.split(";!").map((doc) => (
                              <div
                                style={{
                                  boxShadow: "2px 2px 20px -3px rgba(0,0,0,.1)",
                                  borderRadius: "5px",
                                  fontSize: "70%",
                                  color: "rgb(50,50,50)",
                                  padding: "3px",
                                  margin: "3px",
                                  textAlign: "left",
                                }}
                              >
                                {doc}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td title={item.action_type_short}>
                        {item.action_type_short}
                      </td>
                      <td>
                        {parseInt(item.count_zzs) ? (
                          <>{parseInt(item.count_zzs)}</>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td>{minutesToTimeString(item.minutes)}</td>
                      <td>
                        {parseInt(item.count_zzs) && item.minutes ? (
                          <>
                            {parseInt(
                              (parseInt(item.count_zzs) / item.minutes) * 60
                            )}{" "}
                            <span style={{ fontSize: "50%" }}>zzs/h</span>
                          </>
                        ) : (
                          <>-</>
                        )}
                      </td>
                      <td>{item.cardname}</td>
                      <td
                        title={
                          "Zaczęte: " +
                          item.started +
                          "\r\nOstatnio: " +
                          item.last_started
                        }
                      >
                        {item.started && isToday(new Date(item.started))
                          ? new Date(item.started).toLocaleTimeString("pl-PL", {
                              hour: "numeric",
                              minute: "2-digit",
                            })
                          : item.started
                          ? new Date(item.started).toLocaleString("pl-PL", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "-"}
                      </td>
                      <td>
                        {item.ended && isToday(new Date(item.ended))
                          ? new Date(item.ended).toLocaleTimeString("pl-PL", {
                              hour: "numeric",
                              minute: "2-digit",
                            })
                          : item.ended
                          ? new Date(item.ended).toLocaleString("pl-PL", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "-"}
                      </td>
                      <td
                        title={
                          "netto: " +
                          item.price +
                          " zł / brutto: " +
                          price_brutto +
                          " zł" +
                          "\r\n realny czas: " +
                          minutesToTimeString(calcMinutes) +
                          "\r\n brutto za h: " +
                          (item.minutes &&
                          item.action_type === 1 &&
                          price_brutto
                            ? Math.round(
                                (parseInt(price_brutto) /
                                  parseInt(calcMinutes)) *
                                  60
                              ) + " zł/h"
                            : "-")
                        }
                      >
                        {!item.cardname.search(/,/) > -1 &&
                        item.minutes &&
                        item.ended &&
                        item.action_type === 1 &&
                        price
                          ? Math.round(
                              (parseInt(price) / parseInt(calcMinutes)) * 60
                            ) + " zł/h"
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
      ) : (
        <Loading text="Pobieranie..."/>
      )}
    </div>
  );
}
