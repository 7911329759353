export default function processDictionary(dataRows, dictionary, ignores) {
  return dataRows && dictionary
    ? dataRows.map((row) => {
        const newRow = {};
        for (const property in dictionary) {
          if (property === ignores) {
            continue;
          }
          if (dictionary[property].name) {
            newRow[dictionary[property].name] = {};
            newRow[dictionary[property].name].name = property;
            newRow[dictionary[property].name].base = row[property];
            newRow[dictionary[property].name].jsx = row[property];
            if (dictionary[property].style) {
              newRow[dictionary[property].name].style =
                dictionary[property].style;
            }
            if (dictionary[property].title) {
              const titleTransform = dictionary[property].title;
              newRow[dictionary[property].name].title = titleTransform(
                row[property],
                row
              );
            }
            if (dictionary[property].transform) {
              const transform = dictionary[property].transform;
              newRow[dictionary[property].name].jsx = transform(
                row[property],
                row
              );
              newRow[dictionary[property].name].base = row[property];
            }
          } else {
            newRow[dictionary[property]] = {};
            //newRow[dictionary[property]].name = row[property];
            newRow[dictionary[property]].base = row[property];
            newRow[dictionary[property]].jsx = row[property];
          }
        }
        return newRow;
      })
    : dataRows;
}
