import React, { useState } from "react";
import ModalContent from "./ModalContent";
import Draggable from "react-draggable";

function Modal({
  show,
  type,
  updates,
  content,
  width,
  style,
  heading,
  className,
  setData,
  contentData,
}) {
  //const { show, type, content, width, style, heading, className } = props.data;
  //const setData = props.setData;
  const [closeField, setCloseField] = useState(true);

  const [sub, setSub] = useState({ show: false, data: null, update: 0 });

  let widthStyle = "";
  switch (width) {
    case "wide":
      widthStyle = "clamp(360px,500px,99vw)";
      break;
    case "wider":
      widthStyle = "clamp(300px, 560px,95vw)";
      break;
    case "wider-plus":
      widthStyle = "clamp(300px, 620px,95vw)";
      break;
    case "960":
      widthStyle = "clamp(760px, 560px, 95vw)";
      break;
    case "1100":
      widthStyle = "clamp(1100px, 620px, 95vw)";
      break;
    case "full":
      widthStyle = "clamp(300px, 900px,95vw)";
      break;
    case "superwide":
      widthStyle = "clamp(300px, 1200px,95vw)";
      break;
    case "screen":
      widthStyle = "95vw";
      break;
  }

  return show ? (
    <div className={"modalWrap" + (className ? " " + className : "")}>
      <div
        className="closeField"
        /*onDoubleClick={() =>
          closeField
            ? setData((prev) => ({ ...prev, show: !prev.show }))
            : setSub(false)
        }*/
        onContextMenu={(e) => {
          e.preventDefault();
          closeField
            ? setData((prev) => ({ ...prev, show: !prev.show }))
            : setSub(false);
        }}
      ></div>
      <Draggable handle=".heading h2">
        <div className="modal" style={{ width: widthStyle, ...style }}>
          <div className="heading">
            <h2>{type}</h2>
            {closeField ? (
              <div
                className="close"
                onClick={() =>
                  setData((prev) => ({ ...prev, show: !prev.show }))
                }
              >
                ✖
              </div>
            ) : (
              <div className="close" onClick={() => setSub(false)}>
                {"◀"}
              </div>
            )}
          </div>
          <ModalContent
            content={content}
            passProps={{
              setModal: setData,
              setData: setData,
              setCloseField: setCloseField,
              sub: sub,
              setSub: setSub,
              updates: updates,
              contentData: contentData,
            }}
          />
        </div>
      </Draggable>
    </div>
  ) : (
    <></>
  );
}

export default Modal;
