import React, { useContext, useState, useEffect } from "react";
import iconInPerson from "../../images/icons/icons8-female-user.svg";
import iconEmail from "../../images/icons/icons8-gmail.svg";
import iconPost from "../../images/icons/icons8-mailbox.svg";
import OrderContext from "../contexts/order-context";
import doneIcon from "../../images/icons/icons8-approved-checkmark-symbol-to-verify-the-result-48.png";
import miniCommentIcon from "../../images/icons/comment2.svg";
import restoreIcon from "../../images/icons/icons8-restore-24.png";
import commentIcon from "../../images/icons/comment.png";
import FullScreenToggle from "./FullScreenToggle";
import GoogleDriveLink from "./GoogleDriveLink";
import Documents from "../Documents";
import OrdersOtherLink from "./OrdersOtherLink";
import MailLink from "./MailLink";
import OrderAdditionalInfo from "./OrderAdditionalInfo";
import NewOrderCustom from "./NewOrderCustom";
import { URL } from "../global";
import PercentDone from "../main/OrderQueue/parts/PercentDone";
import AppContext from "../contexts/app-context";

function OrderCardBox(props) {
  const { data: item, view, details, setDetails, params, dayInfo } = props;
  // choose docPages, if present, over pagesNum

  const [done, setDone] = useState(item?.personal_done);
  const [doneChange, setDoneChange] = useState(false);
  const [commentColor, setCommentColor] = useState(item?.comment_color);

  const [statusInputView, setStatusInputView] = useState(false);
  const [comment, setComment] = useState(item?.comment);

  const commentColors = [
    "#4e7a7b",
    "violet",
    "orangered",
    "brown",
    "green",
    "rgb(168, 138, 16)",
    "rgb(168, 81, 234)",
  ];
  const context = useContext(OrderContext);
  const app = useContext(AppContext);

  async function updateCommentColor() {
    const response = await fetch(URL + "components/update-comment-color.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: item.comment_id,
        color: commentColor,
      }),
    });
    const result = await response.json();
    // await setUpdate((prev) => prev + 1);
    // await setShowCreate(false);
    // return result;
  }

  async function makeComment() {
    const response = await fetch(URL + "components/update-comments.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        comment: comment,
        orderID: item.id,
      }),
    });
    const result = await response.json();
    // await setUpdate((prev) => prev + 1);
    // await setShowCreate(false);
    // return result;
  }

  useEffect(() => {
    updateCommentColor();
  }, [commentColor]);

  useEffect(() => {
    if (!doneChange) return;
    let dones = localStorage.getItem("personal_done");
    dones = dones ? JSON.parse(dones) : Array();

    // CLEAN UP THE DONE FILTERS FROM BEFORE LAST SEVEN DAYS
    dones = dones.filter((doneItem) => {
      if (!doneItem.date) return false;
      const d = new Date(doneItem.date);
      const now = new Date();
      const SevenDaysAgo = new Date(now.setDate(now.getDate() - 7));
      console.log(d, SevenDaysAgo, d < SevenDaysAgo);
      if (d > SevenDaysAgo) return true;
    });
    if (done) {
      dones.push({
        date: new Date(item.finish_date),
        id: item.id,
      });
    } else {
      dones = dones.filter((doneItem) =>
        doneItem.id == item.id ? false : true
      );
    }
    localStorage.setItem("personal_done", JSON.stringify(dones));
  }, [doneChange]);

  let receiveMethod = item.acceptance_methods_name;
  const allDocsDone = item?.documents
    ?.map((d) => (d.char_count ? true : false))
    .reduce((a, b) => a && b, true);

  let receiveMethodIcon;
  if (receiveMethod === "osobiście") receiveMethodIcon = iconInPerson;
  else if (receiveMethod === "e-mailem") receiveMethodIcon = iconEmail;
  //else if (receiveMethod === "listem") receiveMethodIcon = iconPost;
  else if (receiveMethod === "wysyłka priorytetowa")
    receiveMethodIcon = iconPost;
  else if (receiveMethod === "wysyłka kurierska") receiveMethodIcon = iconPost;

  return (
    <div
      className={
        "orderCardWrap" +
        (view.fresh ? " fresh" : "") +
        (done ? " done" : "") +
        (allDocsDone ? " completed" : "")
      }
    >
      {allDocsDone ? <div className="completed-tick">✓</div> : <></>}
      {item.comment ? (
        <div
          onClick={() =>
            setCommentColor((prev) =>
              prev === commentColors?.length ? 0 : prev + 1
            )
          }
          className="order-comment"
          style={{ background: commentColors[commentColor] }}
          title={item.comment}
        >
          {item?.comment?.length > 28
            ? item.comment?.slice(0, 28) + "..."
            : item?.comment}
        </div>
      ) : (
        <></>
      )}
      {new Date(item.orderDateJS) - new Date() < 8 * 60 * 60 * 1000 ? (
        <div
          className={
            "incoming" +
            (new Date(item.orderDateJS) - new Date() < 0 ? " past" : "")
          }
        >
          {new Date(item.orderDateJS) - new Date() > 0 ? "za " : ""}{" "}
          {(Math.abs(new Date(item.orderDateJS) - new Date()) > 60 * 60 * 1000
            ? (Math.floor(
                Math.abs(new Date(item.orderDateJS) - new Date()) /
                  (60 * 60 * 1000)
              ) %
                60) +
              "h "
            : "") +
            Math.round(
              Math.abs(
                ((new Date(item.orderDateJS) - new Date()) / 60 / 1000) % 60
              )
            ) +
            " min"}{" "}
          {new Date(item.orderDateJS) - new Date() < 0 ? " temu" : ""}
        </div>
      ) : (
        <></>
      )}

      <div
        className={
          "orderCard" +
          (details ? " detailedView" : "") +
          (view.fresh ? " fresh" : "")
        }
        title={item.id}
        data-key={item.id}
        key={item.id}
        style={{
          borderLeft: "6px solid " + view.rgbPages,
          //filter: view.fresh ? "contrast(1.4)" : "",
        }}
      >
        <div className="orderTopBar">
          <div
            className="orderFullNumber"
            onClick={() => {
              setDetails((prev) => !prev);
            }}
          >
            <div className="fullNumberGroup">
              <span className="orderNumber" style={{ color: view.rgbAll }}>
                {item.orderNumber}
              </span>
              .<span className="orderMonth">{item.orderMonth}</span>.
              <span className="orderYear">{item.orderYear}</span>
              {!details &&
              (item.remarks_translators || item.remarks_general) ? (
                <img
                  title={
                    item.remarks_translators + "\r\n" + item.remarks_general
                  }
                  style={{
                    width: "18px",
                    position: "absolute",
                    top: "20%",
                    right: "-20px",
                    display: "block",
                  }}
                  src={commentIcon}
                ></img>
              ) : (
                ""
              )}
            </div>
            <span className="orderLanguage">
              {item.language_from_short} <span className="littleSign">➤</span>{" "}
              <span style={{ fontWeight: "bold" }}>
                {item.language_to_short}
              </span>
            </span>
          </div>
          <div className="orderClient" title={item.customers_name}>
            {view.customerShort}
          </div>
          <div className="orderReceive" title={item.acceptance_methods_name}>
            <img className="receiveMethodIcon" src={receiveMethodIcon}></img>
          </div>
        </div>

        <div className="otherOrderData">
          <div
            className="orderFinishDate"
            style={{ color: view.fresh ? "#517d85" : "black" }}
          >
            {"➤"} {dayInfo ? item.orderDate : ""}{" "}
            {/*item.orderDate === "Dziś " ? "" : item.orderDate + " | "*/}
            <span className="orderHour">{item.orderHour}</span>
          </div>
          {item.pages_to_translate_count ? (
            <div
              className="orderPages"
              style={{ color: view.rgbAll, fontWeight: view.bold }}
            >
              {view.pagesString}
            </div>
          ) : (
            <></>
          )}
          <div className="orderPrice">{item.grossPrice}</div>
        </div>

        <div className={"orderDetails"}>
          {!details ? (
            ""
          ) : (
            <div className="orderDetailsLayout">
              <Documents
                tag="Dokumenty"
                order={item}
                content={item.documents}
                className="oDocuments"
              />
              {(!item.remarks_translators && !item.remarks_general) ||
              (item.remarks_general === "Kary umowne za spóźnienia i błędy. " &&
                !item.remarks_translators) ? (
                <></>
              ) : (
                <div className="remarksFull" style={{ maxWidth: "unset" }}>
                  <div className="remarksHead">
                    <div>
                      <img src={commentIcon} />
                    </div>
                  </div>
                  <div className="remarksContent">
                    <div className="remarksTranslators">
                      {item.remarks_translators}
                    </div>
                    <div className="remarksGeneral">{item.remarks_general}</div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="optionsBar">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "36px",
            }}
          >
            <OrderAdditionalInfo
              item={item}
              view={view}
              details={details}
              params={params}
            />
            <div className="orderOptions">
              {statusInputView ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "200px",
                  }}
                >
                  <input
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    maxLength={100}
                    style={{ width: "150px", fontSize: "11px" }}
                  />
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      makeComment();
                      setStatusInputView(false);
                      app.setOrderParams((prev) => ({
                        ...prev,
                        update: prev.update + 1,
                      }));
                    }}
                  >
                    ✓
                  </button>
                </div>
              ) : (
                <>
                  <MailLink item={item} />
                  {item.custom ? (
                    <>
                      <div
                        onClick={() =>
                          context.setModal((prev) => ({
                            ...prev,
                            show: true,
                            content: (
                              <NewOrderCustom
                                setModal={context.setModal}
                                setUpdateCustom={context.setUpdateCustom}
                                editID={item.id}
                              />
                            ),
                            type: "Edycja zlecenia specjalnego",
                          }))
                        }
                      >
                        ✎
                      </div>
                      <div
                        onClick={async () => {
                          const response = await fetch(
                            URL + "components/db_remove_custom_order.php",
                            {
                              credentials: "include",
                              method: "POST",
                              body: JSON.stringify({
                                id: item.id,
                              }),
                            }
                          );
                          const result = await response.json();
                          context.setUpdateCustom((prev) => prev + 1);
                        }}
                        style={{ color: "orangered" }}
                      >
                        ✖
                      </div>
                    </>
                  ) : (
                    <>
                      <GoogleDriveLink orders={[item]}></GoogleDriveLink>
                      <OrdersOtherLink
                        item={item}
                        view={view}
                        context={context}
                      />
                      <div
                        className="orderDone"
                        title="Dodaj krótką uwagę"
                        onClick={() => {
                          setStatusInputView(true);
                          // setDone((prev) => !prev);
                          // setDoneChange(true);
                        }}
                      >
                        <img
                          src={done ? restoreIcon : miniCommentIcon}
                          alt=""
                        ></img>
                      </div>
                      <FullScreenToggle
                        setModal={context.setModal}
                        item={item}
                      ></FullScreenToggle>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <PercentDone
        documents={item.documents.map((d) => ({
          pages_count: d.pages_count_translator,
          chars: d.char_count,
          chars_ts: Math.round(Number(d.total_count)),
          type_of_translation: d.type_of_translation,
        }))}
        order={item}
      />
    </div>
  );
}

export default OrderCardBox;
