import { createContext, useEffect, useState } from "react";
import Body from "./components/Body";
import Head from "./components/Head";
import { useGeneric } from "../useGeneric";
import processDictionary from "./functions/processDictionary";
import removeNumberKeys from "./functions/removeNumberKeys";
import Details from "./Details";
import { useLocation, useSearchParams } from "react-router-dom";
import Create from "./Create";
import Remove from "./Remove";
import decodeSearch from "./instances/newExternals/decodeSearch";
import MultiControls from "./components/MultiControls";
import urlQueryToObject from "./urlToQuery";
import urlToQuery from "./urlToQuery";

// TABLE CONTEXT
export const TableContext = createContext({});

export default function TableUniversal({
  name,
  dataCount,
  icon,
  data,
  del,
  active,
  disableSelect,
  dictionary,
  classNames,
  options,
  defLimit,
  searchPattern,
  groupBy,
  orderBy,
  orderDesc,
  propMap,
  buttons,
  createMap,
  editable,
  customSearchComponent,
  customMenu,
  searchMap,
  multiControls,
  replacement,
  sumField,
}) {
  const locationData = useLocation();
  const create = locationData.pathname.split("/").at(-2) === "create";
  const edit = locationData.pathname.split("/").at(-2) === "edit";
  const remove = locationData.pathname.split("/").at(-2) === "delete";

  const page = Number(locationData.pathname.split("/").at(-1))
    ? Number(locationData.pathname.split("/").at(-1))
    : 0;
  //console.log(page);

  const selectionData = locationData.state;

  const [selected, setSelected] = useState({});
  const [lastSelected, setLastSelected] = useState();
  const [ignoreColumns, setIgnoreColumns] = useState();
  const linkData = useLocation().state;
  const [initialize, setInitialize] = useState(true);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(defLimit ? defLimit : 20);
  const [offset, setOffset] = useState(
    locationData.offset !== undefined ? locationData.offset : 0
  );

  const [orderProp, setOrderProp] = useState(orderBy);
  const [orderDir, setOrderDir] = useState(orderDesc);
  //const [headings, setHeadings] = useState([]);
  const [update, setUpdate] = useState(0);

  const [details, setDetails] = useState(undefined);

  //const urlSearch = useLocation().search;
  let [urlSearch, setURLSearch] = useSearchParams();

  const urlQuery = urlToQuery(urlSearch.toString());
  console.log(urlQuery);

  const urlMAP = {};
  if (searchPattern) {
    Object.keys(searchPattern).forEach((key) => {
      if (searchPattern[key].fromURL && urlQuery) {
        urlMAP[key] = {
          ...searchPattern[key],
          ...urlQuery[searchPattern[key].fromURL],
        };
      }
    });
  }

  const [search, setSearch] = useState(() =>
    searchPattern
      ? { ...patternToSearch(searchPattern), ...urlMAP }
      : { ...urlMAP }
  );
  const [prevSearch, setPrevSearch] = useState(() => search);
  const [replace, setReplace] = useState([false, {}]);

  console.log(Object.keys(search).map(k => search[k]).find(v => v.value));
  const fetchData = useGeneric({
    name: data,
    query: {
      limit: limit,
      offset: offset * limit,
      orderBy: orderProp ? orderProp : "",
      orderDesc: orderDir,
      search: active
        ? { ...search, active: { value: 1, exact: true } }
        : search,
      count: dataCount,
      empty: !Object.keys(search).map(k => search[k]).find(v => v.value) ? true : undefined,
    },
    
    reload: 45,
    update: update, //limit + offset + orderProp + orderDir,
  });

  const dataRows = fetchData && !fetchData.loading ? fetchData.rows : [];

  const countResult =
    fetchData && !fetchData.loading && fetchData.query_count_result
      ? JSON.parse(fetchData.query_count_result)
      : undefined;

  const dataRowsByDictionary = dictionary
    ? processDictionary(dataRows, dictionary, ignoreColumns)
    : dataRows;
  //console.log(dictionary);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      ...urlMAP,
    }));
    console.log("new URL", urlSearch, search);
    //console.log(search);
  }, [urlSearch]);

  useEffect(() => {
    if (fetchData && !fetchData.loading) {
      setCount(fetchData.count);
      //console.log(fetchData.count);
      setSelected({});
    }
  }, [fetchData.loading, fetchData.count]);

  useEffect(() => {
    if (search && !initialize && details === undefined) {
      const awaitBeforeUpdate = window.setTimeout(() => {
        setOffset(0);
        setUpdate((prev) => prev + 1);
      }, 90);
      return () => clearTimeout(awaitBeforeUpdate);
    }
  }, [JSON.stringify(search)]);

  const headings =
    dataRowsByDictionary && dataRowsByDictionary.length
      ? Object.keys(removeNumberKeys(dataRowsByDictionary[0]))
      : [];

  const originalProps =
    dataRows && dataRows.length
      ? Object.keys(removeNumberKeys(dataRows[0]))
      : [];

  const lastPage = Math.floor(count / limit) + 1;

  return (
    <TableContext.Provider
      value={{
        tableName: name,
        tableIcon: icon,
        loading: fetchData.loading,
        data: dataRowsByDictionary,
        del: del,
        dataRaw: dataRows,
        dataFetchName: data,
        originalProps: originalProps,
        dictionary: dictionary
          ? dictionary
          : { id: { name: "id", order: "id" } },
        headings: headings,
        options: options,
        disableSelect: disableSelect,
        selectable: options && options.selectable ? true : false,
        groupBy: groupBy,
        orderBy: orderBy ? orderBy : "id",
        lastPage: lastPage,
        setOffset: setOffset,
        limit: limit,
        setLimit: setLimit,
        offset: offset,
        orderProp: orderProp,
        setOrderProp: setOrderProp,
        orderDir: orderDir ? orderDir : "DESC",
        setOrderDir: setOrderDir,
        update: update,
        setUpdate: setUpdate,
        search: search,
        setSearch: setSearch,
        prevSearch: prevSearch,
        setPrevSearch: setPrevSearch,
        searchPattern: searchPattern ? searchPattern : search,
        urlMAP: urlMAP,
        customSearchComponent: customSearchComponent
          ? customSearchComponent
          : undefined,
        details: details,
        setDetails: setDetails,
        propMap: propMap,
        count: count,
        buttons: buttons,
        createMap: createMap,
        initialize: initialize,
        setInitialize: setInitialize,
        customMenu: customMenu,
        /*searchObj: searchObj,
        setSearchObj: setSearchObj,*/
        selected: selected,
        setSelected: setSelected,
        lastSelected: lastSelected,
        setLastSelected: setLastSelected,
        ignoreColumns: ignoreColumns,
        setIgnoreColumns: setIgnoreColumns,
        selectionData: selectionData,
        multiControls: multiControls,
        setReplace: setReplace,
        sumField: sumField,
        countResult: countResult,
      }}
    >
      {create || edit ? (
        <Create edit={true} />
      ) : details === undefined ? (
        remove ? (
          <Remove data={linkData} dictionary={dictionary} />
        ) : replace[0] ? (
          replacement(replace[1])
        ) : (
          <></>
        )
      ) : (
        <Details />
      )}
      <table
        style={
          create || edit || details != undefined ? { display: "none" } : {}
        }
        className={"universal-table" + (classNames ? " " + classNames : "")}
      >
        <Head />
        <Body />
      </table>
    </TableContext.Provider>
  );
}

export function patternToSearch(searchPattern) {
  return Object.assign(
    ...Object.keys(searchPattern).map((key) => {
      let searchItem = { [key]: { value: "" } };
      if (
        !searchPattern[key].optionsDB &&
        searchPattern[key].value === undefined
      ) {
        searchItem = { [key]: { value: searchPattern[key] } };
      } else if (!searchPattern[key].optionsDB) {
        searchItem = { [key]: searchPattern[key] };
      } else if (searchPattern[key].value) {
        searchItem = { [key]: searchPattern[key] };
      }
      return searchItem;
    })
  );
}
