import ShowQueriedOrders from "../../ShowQueriedOrders";

const externalsTable = (orders, translator, user) => [
  /*  {
      identifier: "id",
      shortText: "",
      longText: "ID",
      description: "",
      dataType: "integer",
      style: null,
      attributes: null,
      source: "",
    },*/
  {
    identifier: "translator_id",
    shortText: "Imię i nazwisko",
    longText: "Imię i nazwisko",
    hiddenTable: true,
    value: translator
      ? translator["First Name"] + " " + translator["Last Name"]
      : "",
    description: "",
    dataType: "string",
    attributes: null,
    source: "",
    formElement: "disabled",
    required: true,
  },
  {
    identifier: "accept_date",
    shortText: "Data przyjęcia",
    longText: "Data przyjęcia",
    description: "",
    dataType: "date",
    style: null,
    attributes: null,
    source: "",
    formElement: "input",
    formElementType: "date",
    default: new Date().toISOString().slice(0, 10),
    transform: (text) => new Date(text).toLocaleDateString("pl-PL", {day:"numeric", month: "long", year: "numeric"}),
    required: true,
  },
  {
    identifier: "order_id",
    toData: (props) => props["order_number"] + " " + props["order_customer"],
    shortText: "Nr zlec.",
    longText: "Nr zlec.",
    description: "",
    dataType: "string",
    style: null,
    attributes: null,
    hiddenTable: true,
    source: "",
    formElement: "select",
    formElementExtended: true,
    formElementExtendedView: (props) => <ShowQueriedOrders {...props}/>,
    formElementSwitch: "Bieżące",
    default: 0,
    affects: ["order_number", "order_customer"],
    //required: true,
    options: [...orders.data]
      ?.sort(
        (a, b) =>
          new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
      )
      .map((order) => {
        return {
          value: order.id,
          text: order.valid_order + " " + order.customers_name,
          affected: {
            order_number: order.valid_order,
            order_customer: order.customers_name,
          } /*+
            "- - - " +
            order.documents.length +
            " dok. / " +
            String(order.docPages).replace(".", ",") +
            " str.",*/,
        };
      }),
  },
  {
    identifier: "order_number",
    shortText: "Nr zlec.",
    longText: "Nr zlec.",
    description: "",
    dataType: "string",
    formType: 1,
    transform: (text) => {
      const arr = text.split(".");
      return arr.length > 1 ? <><span style={{fontWeight: "bold"}}>{arr[0]}</span>.<span>{arr[1]}</span>.<span>{arr[2]}</span>.<span style={{fontSize:"95%", opacity:".7"}}>{arr[3]}</span></> : <>-</>
    },
  },
  {
    identifier: "order_customer",
    shortText: "Klient",
    longText: "Klient",
    description: "",
    dataType: "string",
    formType: 1,
    //formElement: "disabled",
  },
 
  {
    identifier: "description",
    shortText: "Uwagi",
    longText: "Uwagi",
    description: "",
    dataType: "string",
    style: null,
    attributes: null,
    source: "",
    default: "",
    formElement: "textarea",
    formElementAttributes: {
      rows: 3,
      maxLength: 250,
    },
    required: false,
    width: "200px",
  },
  {
    identifier: "characters",
    shortText: "Ilość",
    longText: "Ilość",
    description: "",
    dataType: "integer",
    style: null,
    attributes: null,
    source: "",
    formElement: "input",
    formElementType: "number",
    formElementAttributes: {
      step: 1800,
      min: 0,
    },
    default: "",
    unit: translator ? translator["RateUnitName"] : "-",
    required: false,
  },
  {
    identifier: "accept",
    shortText: "Zatw.",
    longText: "Zatwierdzenie",
    description: "",
    dataType: "boolean",
    style: null,
    attributes: null,
    source: "",
    formType: 1,
    default: false,
    required: false,
    hidden: true,
  },
  {
    identifier: "rate",
    shortText: "Stawka",
    longText: "Stawka",
    description: "",
    dataType: "float",
    unit: "zł",
    style: null,
    attributes: null,
    formElement: "input",
    formElementType: "number",
    source: "",
    default: translator?.Rate,
    required: false,
  },
  {
    identifier: "amount",
    shortText: "Kwota",
    longText: "Kwota",
    description: "",
    dataType: "float",
    unit: "zł",
    style: null,
    attributes: null,
    formElement: "disabled",
    formElementType: "number",
    formElementAttributes: {
      step: 50,
      min: 0,
      max: 99999,
    },
    default: 0,
    required: false,
  },
  {
    identifier: "createdBy",
    shortText: "Dodał",
    longText: "Dodał",
    description: "",
    dataType: "string",
    formType: 1,
    default: user,
    hiddenTable: true
  },
  /*
  {
    identifieer: "settled",
    shortText: "",
    longText: "Rozl.",
    description: "",
    dataType: "string",
    formType: 1,
  },*/
];

export default externalsTable;
