import { useContext, useEffect, useState } from "react";
import { TableContext } from "../TableUniversal";
import menuIcon from "../../../images/content.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Filters from "./Filters";

export default function Controls() {
  const {
    data,
    dataRaw,
    tableName,
    tableIcon,
    lastPage,
    offset,
    setOffset,
    setUpdate,
    limit,
    setLimit,
    headings,
    selectable,
    dictionary,
    search,
    setSearch,
    searchObj,
    searchPattern,
    customSearchComponent,
    createMap,
    initialize,
    setInitialize,
    customMenu,
    selectionData,
    loading,
    update,
    sumField,
    countResult
  } = useContext(TableContext);

  //console.log(dataRaw);
  //const navigate = useNavigate();

  const locationLast = useLocation().pathname.split("/").at(-1);
  const pathID = !Number.isNaN(Number(locationLast))
    ? Number(locationLast)
    : false;
  const [pageInputActive, setPageInputActive] = useState(false);
  const [rollDownMenu, setRollDownMenu] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
  //  console.log(offset,lastPage)
    if (offset+1 > lastPage) {
      setOffset(lastPage-1);
      setUpdate((prev) => prev + 1);
    }
  },[offset])


//  console.log(search);

  return (
    <tr>
      <td
        colSpan={headings.length + (selectable === true ? 1 : 0) + 2}
        style={{ padding: 0 }}
      >
        <div className="universal-table-controls">
          <div
            className="table-name"
            onMouseLeave={() => setRollDownMenu(false)}
          >
            {customMenu ? (
              <div>
                <img
                  src={menuIcon}
                  className="table-image"
                  onClick={() => setRollDownMenu((prev) => !prev)}
                />
                {rollDownMenu ? (
                  <div className="menu-roll-down">
                    {customMenu.items.map((menuItem) =>
                      menuItem.link ? (
                        <Link to={menuItem.link}>
                          <div>
                            {menuItem.image ? (
                              <img src={menuItem.image} />
                            ) : (
                              <></>
                            )}
                            {menuItem.text}
                          </div>
                        </Link>
                      ) : (
                        <div>{menuItem.text}</div>
                      )
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div onClick={() => navigate(-1)}>
                <img src={menuIcon} className="table-image" />
              </div>
            )}
            {tableIcon ? (
              <img className="main-table-icon" src={tableIcon} />
            ) : (
              <></>
            )}
            <span className="main-table-name">{tableName}</span>

            {createMap && createMap.create !== false ? (
              <Link to="create/" state={selectionData}>
                <button className="new">
                  <span className="icon">+</span> NOWE
                </button>
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div>
            {dataRaw && dataRaw.length && sumField ? (
              <>{sumField(dataRaw[0], search, countResult)}</>
            ) : (
              <></>
            )}
          </div>
          <div
            /*style={{ visibility: loading ? "hidden" : "visible" }}*/
            className="pagination"
          >
            <button
              style={{ opacity: offset === 0 ? ".2" : "1" }}
              onClick={() => {
                if (!loading) {
                  setOffset(0);
                  setUpdate((prev) => prev + 1);
                }
              }}
            >
              |◀
            </button>
            <button
              style={{ opacity: offset === 0 ? ".2" : "1" }}
              onClick={() => {
                if (!loading) {
                  setOffset((prev) => (prev > 0 ? prev - 1 : prev));
                  setUpdate((prev) => prev + 1);
                }
              }}
            >
              ◀
            </button>
            <div style={{ opacity: loading ? ".08" : "1" }}>
              {!pageInputActive ? (
                <span onClick={() => setPageInputActive(true)}>
                  {offset + 1}
                </span>
              ) : (
                <input
                  type="number"
                  min={1}
                  max={lastPage}
                  className="tiny-input"
                  value={offset + 1}
                  onChange={(e) => {
                    if (!loading) {
                      setOffset(parseInt(e.target.value) - 1);
                      if (parseInt(e.target.value) > 0) {
                        setUpdate((prev) => prev + 1);
                      }
                    }
                  }}
                  onKeyUp={(e) => {
                    if (!loading) {
                      if (e.key === "Enter") {
                        setPageInputActive(false);
                      }
                    }
                  }}
                />
              )}{" "}
              <span> / {lastPage ? lastPage : <>-</>}</span>
            </div>
            <button
              style={{ opacity: offset === lastPage - 1 ? ".2" : "1" }}
              onClick={() => {
                if (!loading) {
                  setOffset((prev) => (prev < lastPage - 1 ? prev + 1 : prev));
                  setUpdate((prev) => prev + 1);
                }
              }}
            >
              ▶
            </button>
            <button
              style={{ opacity: offset === lastPage - 1 ? ".2" : "1" }}
              onClick={() => {
                if (!loading) {
                  setOffset(lastPage - 1);
                  setUpdate((prev) => prev + 1);
                }
              }}
            >
              ▶|
            </button>
            <select
              title="Liczba wpisów na stronę"
              style={{
                border: "none",
                marginLeft: "8px",
                padding: "6px 4px",
              }}
              value={limit}
              onChange={(e) => {
                setLimit(parseInt(e.target.value));
                setUpdate((prev) => prev + 1);
              }}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="filters">
            {customSearchComponent ? customSearchComponent : <></>}
            {search && Object.keys(search).length ? <Filters /> : <></>}
          </div>
        </div>
      </td>
    </tr>
  );
}
