import React, { useState, useEffect } from "react";
import { URL } from "../../global";

function getSworn(year, page) {
  return fetch(URL + "components/db-sworn.php", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({
      queryType: "fetch",
      query: {
        year: parseInt(year),
        page: page === "all" ? page : parseInt(page),
      },
    }),
  })
    .then((response) => response.json())
    .then((result) => ({ ...result, success: 1 }));
}

function searchSworn(order, customer) {
  return fetch(URL + "components/db-sworn.php", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({
      queryType: "search",
      query: { order: order, customer: customer },
    }),
  })
    .then((response) => response.json())
    .then((result) => result);
}

function swornHistory(number, year) {
  return fetch(URL + "components/db-sworn.php", {
    method: "post",
    credentials: "include",
    body: JSON.stringify({
      queryType: "showHistory",
      query: { number: number, year: year },
    }),
  })
    .then((response) => response.json())
    .then((result) => result);
}

function useSworn(year, page, updates) {
  const [sworn, setSworn] = useState([]);
  
  useEffect(() => {
    let unsubscribed = false;
    getSworn(year, page).then((data) => {
      if (!unsubscribed) {
      setSworn(data);
      }
    });
    const reload = window.setInterval(
      () => getSworn(year, page).then((data) => {
        if(!unsubscribed) {
          setSworn(data);
        }
      }),
      10000
    );
    return () => {
      window.clearInterval(reload);
      unsubscribed = true;
    }
  }, [updates, page, year]);

  return sworn;
}

export function useAll(year, run) {
  const [swornAll, setSwornAll] = useState([]);

  useEffect(() => {
    if (run) {
      getSworn(year, "all").then((data) => setSwornAll(data));
    }
  }, [run]);

  return swornAll;
}

export function useSwornHistory(number,year) {
  const [sworn, setSworn] = useState([]);
  useEffect(() => {
    if (!number) {
      setSworn([]);
      return;
    }

    swornHistory(number,year).then((data) => setSworn(data));
  }, [number]);

  return sworn;
}

export function useSwornSearch(order, customer, updates) {
  const [sworn, setSworn] = useState([]);

  useEffect(() => {
    if (!order && !customer) {
      setSworn([]);
      return;
    }

    searchSworn(order, customer).then((data) => setSworn(data));
  }, [order, customer, updates]);

  return sworn;
}

export default useSworn;
