import flags from "../../../../flags";
import { languagesList } from "../../../../global";
import languageDisplay from "../../displays/languageDisplay";

export const translatorsMap = {
  db: "components/set/set-externals-translators.php",
  className: "three-columns",
  fields: {
    first_name: {
      label: "Imię",
      required: true,
      dataSource: {
        name: "new-externals-list",
        active: 1,
        params: {
          orderBy: "last_name",
          orderDesc: "ASC",
        },
        searchMessage: "sprawdzanie podobnych...",
        notFoundMessage: "nie ma jeszcze w bazie",
        inactive: true,
        query: (value, o, formContent) =>
          value.length > 2
            ? {
                first_name: {
                  value: value,
                },
                last_name: formContent.last_name
                  ? {
                      value: formContent.last_name,
                    }
                  : undefined,
              }
            : "error",
        display: (row) => (
          <div style={{ fontSize: "12px", padding: "5px 8px" }}>
            {row.full_name}
          </div>
        ),
        text: (row) => row.full_name,
        setters: false,
      },
    },
    last_name: {
      label: "Nazwisko",
      required: true,
      dataSource: {
        name: "new-externals-list",
        active: 1,
        params: {
          orderBy: "first_name, last_name",
          orderDesc: "ASC, ASC",
        },
        searchMessage: "sprawdzanie podobnych...",
        notFoundMessage: "nie ma jeszcze w bazie",
        inactive: true,
        query: (value, o, formContent) =>
          value.length > 2
            ? {
                last_name: {
                  value: value,
                },
                first_name: formContent.first_name
                  ? {
                      value: formContent.first_name,
                    }
                  : undefined,
              }
            : "error",
        display: (row) => (
          <div style={{ fontSize: "12px", padding: "5px 8px" }}>
            {row.full_name}
          </div>
        ),
        text: (row) => row.full_name,
        setters: false,
      },
    },
    languages_adder: {
      label: "Języki",
      type: "search",
      addToBag: "languages_main",
      bagID: "languages_adder",
      dataSource: {
        name: "languages-searchable",
        params: {
          orderBy: "importance",
          orderDesc: "ASC",
        },
        query: (value) => ({
          name: {
            value: value,
          },
        }),
        value: "id",
        setters: (row) => [["languages_adder", row.full_name]],
        display: (row) => <>{languageDisplay(row)}</>,
        text: (row) => row.full_name,
      },
    },
    languages_main: {
      type: "bag",
      required: true,
      display: (v) => (
        <div>
          <img
            style={{
              width: "15px",
              marginRight: "5px",
              borderRadius: "5px",
              boxShadow: "0 0 2px rgba(0,0,0,.4)",
            }}
            src={flags[languagesList.find((i) => i.name === v)?.flagCode]}
          />

          <span>{v}</span>
        </div>
      ),
      convert: (contents) =>
        contents
          .map((item) => languagesList.find((f) => f.name === item)?.code)
          .join(","),
      revert: (contents) =>
        contents &&
        contents
          .split(",")
          .map((item) => languagesList.find((f) => f.code === item)?.name),
      source: "languages_adder",
    },
    type: {
      label: "Przysięgły?",
      element: "select",
      default: 0,
      options: [
        { value: 0, text: "nie" },
        { value: 1, text: "tak" },
      ],
    },
    written_or_oral: {
      label: "Rodzaj",
      default: 0,
      element: "select",
      options: [
        { value: 0, text: "pisemny" },
        { value: 1, text: "ustny" },
        { value: 2, text: "oba" },
      ],
    },
    email: {
      label: "E-mail",
      type: "email",
    },
    email2: {
      label: "E-mail 2",
      type: "email",
    },
    phone: {
      label: "Telefon",
      type: "tel",
    },
    phone2: {
      label: "Telefon 2",
      type: "tel",
    },
    other_contact: {
      label: "Inna forma kontaktu",
    },
    street: {
      label: "Ulica",
    },
    post_code: {
      label: "Kod pocztowy",
    },
    town: {
      label: "Miejscowość",
    },
    education: {
      //label: "Wykształcenie",
      element: "textarea",
    },
    experience: {
      //label: "Doświadczenie",
      element: "textarea",
    },
    comment: {
      element: "textarea",
      label: "",
    },
    user_group: {
      prefix: (
        <div
          style={{
            height: "5px",
            borderRadius: "10px",
            border: "2.5px solid rgba(100,100,100,.15)",
            margin: "35px 2px",
          }}
        ></div>
      ),
      label: "Grupa użytkowników",
      element: "select",
      options: [
        { value: 0, text: "obsługa" },
        { value: 1, text: "koordynator angielski" },
      ],
    },
  },
  sections: [
    {
      title: "Dane personalne",
      fields: [
        "first_name",
        "last_name",
        "languages_adder",
        "languages_main",
        ["type",
        "written_or_oral"],
      ],
    },
    {
      title: "Kontakt",
      fields: ["email", "email2", ["phone", "phone2"], "other_contact"],
    },
    {
      title: "Adres",
      fields: [
        "street",
        {
          style: { gridTemplateColumns: "1fr 2fr" },
          fields: ["post_code", "town"],
        },
        "user_group",
      ],
    },
    {
      title: "Wykształcenie",
      fields: ["education"],
      //columns: "1/2"
    },
    {
      title: "Doświadczenie",
      fields: ["experience"],
      //columns: "1/2"
    },
    {
      title: "Uwagi",
      fields: ["comment"],
      //columns: "2/4"
    },
  ],
};
