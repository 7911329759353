import flags from "../../../flags";
import { languagesList } from "../../../global";
import TableUniversal from "../../TableUniversal";
import { settlementPropMap } from "../examples/Dictionaries/SettlementPropMap";
import SettleControls from "./ExternalDashboard/SettleControls";
import { settlementMap } from "./maps/settlementMap";
import pagesIcon from "../../../../images/icons/buttons/sentpages.svg";
import translatorsIcon from "../../../../images/icons/buttons/translators.svg";
import statsIcon from "../../../../images/icons/buttons/stats.svg";
import moneyIcon from "../../../../images/icons/buttons/money.svg";
import customersIcon from "../../../../images/icons/buttons/customers.svg";
import { translator_name } from "./maps/common/translator_name";
import customMenuItems from "./customMenuItems";
import NumberOrder from "../../common/NumberOrder";
import NumberPurchaseInvoice from "../../common/NumberPurchaseInvoice";

export default function Settlements() {
  return (
    <div className="fullScreen settlements">
      <TableUniversal
        name="Wykaz rozliczeń"
        icon={moneyIcon}
        data="settlements"
        classNames="big"
        propMap={settlementPropMap}
        defLimit={10}
        //del={"del-externals-settlements"}
        options={{
          selectable: true,
          editable: true,
        }}
        createMap={settlementMap}
        orderBy={"id"}
        orderDesc="DESC"
        dictionary={dictionary}
        searchPattern={{
          translator_name: {
            label: "Tłumacz",
            value: "",
            className: "pick-translator",
            fillOnEmpty: {
              name: "translator",
              source: "translator_id",
              target: "full_name",
              from: {
                db: "new-externals-list",
                searchColumn: "id",
              },
            },
            optionsDB: {
              name: "translator_id",
              style: {
                width: "230px",
                fontWeight: "400",
                height: "32px",
                fontSize: "16px",
                //padding:"20px 8px",
                background: "white",
                color: "#46797e",
              },
              target: "translator_id",
              db: "new-externals-sent",
              searchColumn: "full_name",
              targetColumn: "translator_id",
              orderBy: "id",
              orderDesc: "DESC",
              displayOptions: (row) => {
                const languages = row?.languages_main?.split(",");
                const firstLanguage = languages?.[0];
                return (
                  <div
                    className="translator-picker"
                    title={row.languages_main.toLowerCase()}
                  >
                    <img
                      className="flag"
                      src={
                        flags[
                          languagesList.find((i) => i.code === firstLanguage)
                            ?.flagCode
                        ]
                      }
                    />

                    {row.full_name}
                  </div>
                );
              },
            },
          },
          translator_id: {
            value: "",
            hidden: true,
          },
          paid: {
            name: "Zapłacone",
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              tak: 1,
              nie: 0,
            },
          },
          document_type: {
            value: "",
            label: "Rodzaj dokumentu",
            element: "select",
            options: {
              wszystkie: "",
              "faktura VAT": 1,
              rachunek: 2,
              "umowa o dzieło": 3,
              "umowa zlecenie": 4,
              gotówka: 5,
            },
          },
          document_number: {
            value: "",
            label: "Numer dokumentu",
          },
          order_numbers: {
            value: "",
            label: "Zawiera zlecenie",
          },
        }}
        customMenu={customMenuItems}
      />
    </div>
  );
}

const dictionary = {
  settlement_number: {
    name: "Nr",
    title: (v, all) => all.id,
    style: {
      width: "64px",
    },
    transform: (v, all) => (
      <>
        {v}-{all.year}
      </>
    ),
  },

  create_date: {
    name: "Data utworzenia",
    order: "create_date",
    style: {
      width: "110px",
      fontSize: "11px",
    },
    transform: (v) => new Date(v).toLocaleString(),
  },
  translator_name: {
    name: "Tłumacz",
    order: "translator_name, id",
    style: {
      width: "200px",
    },

    textAlign: "center",
  },
  document_type_string: {
    name: "Dok.",
    order: "document_type_string",
    style: {
      width: "110px",

      textAlign: "center",
    },
  },
  document_number: {
    name: "Nr dokumentu",
    style: {
      width: "150px",
      textAlign: "center",
    },
    transform: (v, a) => (
      <NumberPurchaseInvoice
        content={v}
        id={undefined}
        search={{ source_doc_name: v }}
      />
    ),
  },

  order_numbers: {
    name: "wysłane zlecenia",
    style: {
      width: "250px",
    },
    transform: (v, all) => (
      <div className="settled-orders-list">
        <div className="settled-orders-sum">
          {v ? <>{v.split(",").length} zlec.</> : <></>}
        </div>
        <div className="settled-orders-details">
          {v ? (
            v.split(",").map((i, index) => (
              <NumberOrder
                content={
                  <div
                    title={all?.order_ids?.split(",")?.[index]}
                    style={{
                      padding: "3px 6px",
                      display: "grid",
                      gridTemplateColumns: "15px 15px 60px",
                      alignItems: "center",
                      fontSize: "9px",
                    }}
                  >
                    <span>{index + 1}</span>
                    <img
                      style={{ width: "15px", padding: "0 5px" }}
                      src={
                        flags[
                          languagesList.find(
                            (f) =>
                              f.code.toUpperCase() ===
                              i.split(".").at(-1).toUpperCase()
                          )?.flagCode
                        ]
                      }
                    />
                    <div>
                      {i.split(".").at(0)}.{i.split(".").at(1)}.
                      {i.split(".").at(2)}
                    </div>
                  </div>
                }
                id={all?.order_ids?.split(",")?.[index]}
                number_order={i}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    ),
  },
  total_net: {
    name: "suma netto",
    order: "total_net, id",
    style: {
      width: "150px",
      textAlign: "center",
    },
  },
  comment: {
    name: "Uwagi",
    style: {
      width: "100px",
      fontSize: "10px",
    },
  },
  paid: {
    name: "zapłacone",
    order: "paid, id",
    style: {
      width: "100px",
      textAlign: "center",
    },
    transform: (v) => (v === 1 ? <>✓</> : <>✖</>),
  },
};
