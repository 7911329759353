import { useEffect, useState } from "react";
import { useGeneric } from "../../useGeneric";
import Loading from "../Loading";
import OrderView from "./parts/OrderView";
import OrderList from "./parts/OrderList";

export default function OrderQueue() {
  document.title = "••ZLECENIA••";
  const [selectedID, setSelectedID] = useState();
  const [updates, setUpdates] = useState(0);
  const fetchData = useGeneric({
    name: "order-queue",
    query: {
      orderBy: "finish_date",
      orderDesc: "ASC",
    },
    reload: 20,
    update: updates,
  });

  const data = !fetchData.loading ? fetchData.rows : undefined;
  const token = !fetchData.loading ? fetchData.token : undefined;

  useEffect(() => {
    if (
      (updates === 0 && data && data.length) ||
      (data && data.length && !data.find((o) => o.id === selectedID))
    ) {
      setSelectedID(data[0].id);
      setUpdates(prev => prev+1);
    }
    
  }, [token]);

  return (
    <div className="order-monitor">
      {fetchData.loading ? (
        <div className="order-monitor-loading">
          <Loading text="Pobieranie zleceń" />
        </div>
      ) : (
        <div className="order-monitor-screen">
          <OrderList
            data={data}
            token={token}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
            loading={fetchData.loading}
          />
          <OrderView data={data} selectedID={selectedID} />
        </div>
      )}
    </div>
  );
}
