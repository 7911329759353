import { Link } from "react-router-dom";
import processDictionary from "./functions/processDictionary";
import removeNumberKeys from "./functions/removeNumberKeys";
import Box from "./components/Box";

export default function Remove({ data, dictionary }) {
  const d = removeNumberKeys(data);
  console.log(processDictionary([d], dictionary));
  return (
    <div className="remove">
      <div className="title">Szczegóły wpisu</div>
      {d && d.id ? (
        <>
          <Box
            key={d.id}
            data={processDictionary([d], dictionary)[0]}
            raw={[d]}
            i={1}
          />
        </>
      ) : (
        <>
          <div>Łącze wygasło.</div>
          <Link to=".">Powrót</Link>
        </>
      )}
      <div className="remove-button">
        <button>Potwierdź usunięcie</button>
      </div>
    </div>
  );
}
