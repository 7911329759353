import { useGeneric } from "../useGeneric";

export function minutesToTimeString(min) {
  //let days = Math.floor(min / 60 / 24);
  let days = 0;
  let hours = Math.floor(min / 60) - (days * 24);
  let minutes = Math.round(min - hours * 60) - (days * 24 * 60);
  
  
  if (minutes === 60) {
    minutes = 0;
    hours = hours + 1;
  }
  return /*(days ? days + " dni " : "") +*/ (hours ? hours + " h " : "") + (minutes ? minutes + " min" : "");
}

function minutesToTimeOfDayString(min) {
  const hours = Math.floor(min / 60);
  let minutes = Math.round(min - hours * 60);
  minutes = String(minutes).length < 2 ? "0" + minutes : minutes;
  return (hours ? hours + ":" : "") + (minutes ? minutes : "00");
}

export default function WorkTimeView({ user, stage }) {
  
  const dataRaw = useGeneric({
    name: "totalAttendance",
    query: {
      employeeID: user.employeeID,
    },
    reload: 300,
    update: stage
  });
  const data = !dataRaw.loading ? dataRaw.rows : undefined;

  return data ? (
    <div className="workTimeView">
      {data.map((item) => {
        const d = new Date();
        d.setMonth(item.month - 1);
        const details = JSON.parse(
          "[" + item.details.replaceAll("\\", "") + "]"
        );

        const starts = details.map(
          (item) =>
            new Date(item.t1).getHours() * 60 + new Date(item.t1).getMinutes()
        );
        const ends = details.map(
          (item) =>
            new Date(item.t2).getHours() * 60 + new Date(item.t2).getMinutes()
        );
        starts.sort((a, b) => b - a);
        ends.sort((a, b) => b - a);
        if (starts.length > 8) {
          starts.pop();
          starts.pop();
          starts.shift();
          starts.shift();
        }
        if (starts.length > 15) {
          starts.pop();
          starts.pop();
          starts.pop();
          starts.shift();
          starts.shift();
          starts.shift();
        }
        if (ends.length > 8) {
          ends.pop();
          ends.pop();
          ends.shift();
          ends.shift();
        }
        if (ends.length > 15) {
          ends.pop();
          ends.pop();
          ends.pop();
          ends.shift();
          ends.shift();
          ends.shift();
        }
        const meanStart = minutesToTimeOfDayString(
          starts.reduce((a, b) => a + b) / starts.length
        );
        const meanEnd = minutesToTimeOfDayString(
          ends.reduce((a, b) => a + b) / ends.length
        );
        const remoteLength = details.filter((item) => item.tp1 === 2).length;

        return (
          <div className="hoursMonth" key={item.month + "." + item.year}>
            <div className="hoursMonthHeading">
              {d.toLocaleDateString("pl-PL", { month: "long" })} {item.year}
            </div>
            <div style={{ fontSize: "12px", marginBottom: "5px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Liczba dni w pracy: {details.length}</div>

                <div style={{ paddingLeft: "7px" }}>
                  Biuro: {details.length - remoteLength}
                </div>
                <div style={{ paddingLeft: "7px" }}>
                  Zdalnie: {remoteLength}
                </div>
              </div>
              <div>
                Pracujesz przeciętnie: {meanStart}-{meanEnd}
              </div>
            </div>
            <table className="hoursTable">
              {details.reverse().map((day) => {
                const diff = new Date(day.t2) - new Date(day.t1);
                const diffString = minutesToTimeString(diff / 60 / 1000);
                return (
                  <tr key={day.t2}>
                    <td
                      style={
                        day.tp1 === 0
                          ? { background: "#b8ff5a" }
                          : { background: "lightblue" }
                      }
                    >
                      {new Date(day.t1).toLocaleDateString("pl-PL", {
                        day: "numeric",
                      })}
                    </td>
                    <td>
                      {new Date(day.t1).toLocaleDateString("pl-PL", {
                        weekday: "long",
                      })}
                    </td>
                    <td>
                      {new Date(day.t1).toLocaleTimeString("pl-PL", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </td>
                    <td>
                      {new Date(day.t2).toLocaleTimeString("pl-PL", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </td>
                    <td
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        paddingLeft: "9px",
                      }}
                    >
                      {diffString}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="module" style={{ textAlign: "center", fontSize: "13px" }}>
      Wczytywanie...
    </div>
  );
}
