import React, { useEffect, useState } from "react";
import getInvoices from "./getInvoices";

export const useInvoices = (params) => {
  const [invoices, setInvoices] = useState({ data: [], loading: true });

  useEffect(() => {
    getInvoices(params).then((invoices) =>
      setInvoices({
        ...invoices,
        loading: false,
      })
    );
    const interval = setInterval(() => {
      getInvoices(params).then((invoices) =>
        setInvoices({
          ...invoices,
          loading: false,
        })
      );
    }, 180000); // run every three minutes
    return () => {
      clearInterval(interval);
      setInvoices({ data: [], dataJPK: {}, loading: true });
    };
  }, [params?.type, params?.year, params?.month]); //params?

  return invoices;
};

export default useInvoices;
