import React from "react";
import OrderCard from "./OrderCard";
import { compareDay, shortenLanguage } from "../functions";
import { isSameDay } from "../main/WorkTime";

function ordersView(props) {
  const {
    orders,
    date,
    customOrders,
    details,
    setDetails,
    view,
    params,
    heading,
  } = props;

  let classNm = "";
  switch (view) {
    case "cards":
      classNm = "orderCollection";
      break;
    case "table":
      classNm = "orderTable";
      break;
  }
  let customOrdersProcessed;
  if (customOrders) {
    customOrdersProcessed = customOrders
      .map((item) => {
        return {
          ...item,
          custom: true,
          orderDateJS: new Date(item.finish_date),
          orderHour: new Date(item.finish_date).toLocaleTimeString("pl-PL", {
            hour: "numeric",
            minute: "numeric",
          }),
          orderNumber: "X" + item.number_order,
          valid_order: "X" + item.number_order,
          language_from_short: shortenLanguage(item.language_from),
          language_to_short: shortenLanguage(item.language_to),
          docPages: 0,
          docPagesNormal: 0,
          docPagesSworn: 0,
          docPagesSwornRounded: 0,
          remarks_translators: item.comments,
        };
      })
      .filter((item) => isSameDay(item.orderDateJS, date));
  }

  const ordersCombined =
    customOrdersProcessed && orders
      ? [...orders, ...customOrdersProcessed].sort((a, b) =>
          a.orderHour.localeCompare(b.orderHour)
        )
      : orders;

  return (
    <div className={classNm}>
      {ordersCombined &&
        ordersCombined.map((item) => (
          <OrderCard
            key={item.id}
            item={item}
            params={params}
            details={details}
            setDetails={setDetails}
            view={props.view}
            dayInfo={heading ? true : false}
          />
        ))}
    </div>
  );
}

export default ordersView;
