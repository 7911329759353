import React, { useEffect, useState } from "react";
import addToTimesheet from "./addToTimesheet";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";

function Break(props) {
  const [breakActive, setBreakActive] = useState(0);
  let navigate = useNavigate();

  useEffect(() => {
    const now = new Date();
    if (breakActive === "begin") {
      addToTimesheet({
        queryType: "add",
        date: now,
        startTime: now,
        lastTime: now,
        type: 10,
        status: 0,
        comments: "",
      });
      props.setUpdateCount((prev) => prev + 1);
    } else if (breakActive === "end") {
      addToTimesheet({
        queryType: "endItem",
        user: "tymczasowy",
        endTime: now,
        status: 1,
      });
      navigate(`../activity`);
      props.setUpdateCount((prev) => prev + 1);
    }
  }, [breakActive]);

  const now = new Date();
  const nowPlus15 = new Date();
  nowPlus15.setTime(nowPlus15.getTime() + 15 * 60 * 1000);
  return (
    <div className="specificForm">
      <BackButton link="../activity"/>
      <div >
        {!breakActive ? (
          <>
            <button className="main" onClick={() => setBreakActive("begin")}>
              Rozpocznij przerwę
            </button>
           
          </>
        ) : (
          <>
            <h2>Trwa przerwa...</h2>
            <hr></hr>
            <div>
              do{" "}
              <span className="bigger">
                {nowPlus15.toLocaleTimeString().slice(0, -3)}
              </span>
            </div>
            <hr></hr>
            <button className="main" onClick={() => setBreakActive("end")}>
              Zakończ
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Break;
