import flags from "../../../../../flags";
import { languagesList } from "../../../../../global";
import { roundingMethod } from "../sentOrdersMap";

export default function rate(gross, params = {}) {
  const rateTransform = (v) => Number(v.rate) * (1 + Number(v.tax) / 100);
  return {
    label: "stawka",
    type: "number",
    required: true,
    //type: "search",
    affect: "rate",
    affectAction: (row) => {
      //console.log(row.tax);
      return (
        Math.round(
          Number(row.rate_all) *
            (row.is_gross === "1" || row.is_gross === 1
              ? 1 / (Number(row.tax) > 0 ? 1 + Number(row.tax) / 100 : 1)
              : 1) *
            100
        ) / 100
      );
    },
    affectProps: ["rate", "rate_all", "tax"],
    dataSource: {
      /// check last rates
      name: "sent-orders-rates",
      active: 1,
      params: {
        orderBy: "id",
        orderDesc: "DESC",
      },
      query: (value, all, formValues) => ({
        rate: {
          value: value,
        },
        translator_id: {
          value: formValues.translator_id,
          exact: true
        },
      }),
      setters: (row, formData) => {
            //console.log(row.is_gross, row.rate, allFormData.rate_all);
        return [
          [
            "rate_all",
            row.is_gross 
              ? Number(row.rate_gross)
              : Number(row.rate)
          ],
          ["is_gross", row.is_gross],
          ["rate_distinctor", row ? row.rate_distinctor : ""],
          ["unit", row ? row.unit : ""],
          [
            "unitName",
            row
              ? row.unit_joint_name +
                " (" +
                row.unit_number +
                " " +
                row.unit_name +
                ")"
              : "",
          ],

          ["unitCounter", row ? row.unit_number : 1],
          ["unitIndividualName", row ? row.unit_name : ""],
          ["rounding_method", row ? row.rounding_method : ""],
          ["tax", Number(row.tax) ]
        ];
      },
      display: (v) =>
        v.rate ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "84px 1fr",
              padding: "4px 10px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{}}>
              <div style={{ fontSize: "15px" }}>
                {String(v.is_gross ? v.rate_gross : v.rate).replace(".", ",")}{" "}
                zł
              </div>
              <div style={{ fontSize: "8.5px", color: "grey" }}>
                za {v.unit_joint_name_short}{" "}
                {v.unit_number !== 1 ? (
                  <>
                    ({v.unit_number} {v.unit_name})
                  </>
                ) : (
                  <></>
                )}{" "}
                {v.is_gross ? (
                  <span
                    style={{
                      color: "whitesmoke",
                      fontWeight: "bold",
                      padding: "1px 3px",
                      borderRadius: "4px",
                      background: "#8ea8d1",
                      fontSize: "7px",
                      textTransform: "uppercase",
                    }}
                  >
                    brutto
                  </span>
                ) : (
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      padding: "1px 3px",
                      borderRadius: "4px",
                      background: "#cccccb",
                      fontSize: "7px",
                      textTransform: "uppercase",
                    }}
                  >
                    netto
                  </span>
                )}
              </div>
              <div
                style={{
                  fontSize: "10px",
                  textTransform: "uppercase",
                  fontWeight: "700",
                }}
              >
                {v.rate_distinctor}
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "42px 1fr 70px 21px 52px",
                alignItems: "center",
                marginLeft: "8px",
                fontSize: "10px",
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  style={{ width: "13px" }}
                  src={
                    flags[
                      languagesList.find((i) => i.code === v.language_from_name)
                        ?.flagCode
                    ]
                  }
                />
                {"▸"}
                <img
                  style={{ width: "13px" }}
                  src={
                    flags[
                      languagesList.find((i) => i.code === v.language_to_name)
                        ?.flagCode
                    ]
                  }
                />
              </div>
              <div style={{ fontSize: "11px" }}>{v.translator_name}</div>
              <div style={{ color: "grey", fontSize: "90%" }}>
                {v.order_customer === "Polska Agencja Prasowa S.A."
                  ? "PAP"
                  : v.order_customer}
              </div>

              <div>
                {v.rounding_method === 0
                  ? "•"
                  : v.rounding_method === 1
                  ? "↑1.0"
                  : v.rounding_method === 2
                  ? "↑0.5"
                  : v.rounding_method === 3
                  ? "↓1.0"
                  : v.rounding_method === 4
                  ? "↓0.5"
                  : ""}
              </div>
              <div style={{ marginLeft: "8px" }}>
                {" "}
                {">"}{" "}
                {new Date(v.time_added).toLocaleDateString("pl-pl", {
                  month: "short",
                  year: "2-digit",
                })}
              </div>
            </div>
          </div>
        ) : (
          ""
        ),
      text: (v) => Number(v.rate),
    },
    ...params,
  };
}
