export default function genericCaseMap(name) {
  let address;
  switch (name) {
    case "requests":
      address = "get_foreign/get-attendance-requests.php";
      break;
    case "holidayCount":
      address = "get_foreign/get-holiday-count.php";
      break;
    case "totalAttendance":
      address = "get_foreign/get-total-attendance.php";
      break;
    case "orders":
      address = "db-simple-orders-ts.php";
      break;
    case "timesheet":
      address = "TS_get.php";
      break;
    case "searchOrders":
      address = "db-search-orders.php";
      break;
    case "attendanceByDay":
      address = "db-attendance-by-day.php";
      break;
    case "searchCustomers":
      address = "db-search-customers.php";
      break;
    case "findDocuments":
      address = "db-find-documents.php";
      break;
    case "TSreports":
      address = "TS_reports.php";
      break;
    case "TSreportsDay":
      address = "TS_reports_day.php";
      break;
    case "customOrders":
      address = "db_query_custom_orders.php";
      break;
    case "removeCustomOrder":
      address = "db_query_custom_order_remove.php";
      break;
    case "employeeStatus":
      address = "db-employee-status.php";
      break;
    case "employeeActiveDays":
      address = "db-employee-active-days.php";
      break;
    case "customDocuments":
      address = "db-get-custom-documents.php";
      break;
    case "languages":
      address = "db-languages.php";
      break;
    case "external-translators":
      address = "db-external-translators.php";
      break;
    case "order-table":
      address = "db-order-table.php";
      break;
    case "order-search-procedure":
      address = "db-search-orders-procedural.php";
      break;
    case "order-table-oral":
      address = "db-order-table-oral.php";
      break;
    case "order-list":
      address = "db-order-list-full.php";
      break;
    case "attendance-all":
      address = "db-attendance-table.php";
      break;
    case "invoices-table":
      address = "db-invoices-table.php";
      break;
    case "timesheet-table":
      address = "db-timesheet-table.php";
      break;
    case "customers-table":
      address = "db-customers-table.php";
      break;
    case "sworn-registry-table":
      address = "db-sworn-registry-table.php";
      break;
    case "employees-table":
      address = "db-employees-table.php";
      break;
    case "new-externals":
      address = "db-new-externals.php";
      break;
    case "new-externals-list":
      address = "db-new-externals-list.php";
      break;
    case "new-externals-sent":
      address = "db-new-externals-sent.php";
      break;
    case "sent-orders-table":
      address = "db-sent-orders-table.php";
      break;
    case "sent-orders-rates":
      address = "db-sent-orders-rates.php";
      break;
    case "sent-orders-sending-costs":
      address = "db-sent-orders-sending-costs.php";
      break;
    case "sent-orders-stats":
      address = "db-sent-orders-stats.php";
      break;
    case "translation-units":
      address = "db-translation-units.php";
      break;
    case "languages-searchable":
      address = "db-language-table.php";
      break;
    case "ts-stats":
      address = "db-ts-stats.php";
      break;
    case "settlements":
      address = "db-settlement-table.php";
      break;
    case "db-now":
      address = "db-now.php";
      break;
    case "trade-documents":
      address = "enova/trade-documents-fetch.php";
      break;
    case "lsi_test":
      address = "lsi/test.php";
      break;
    case "customers-list":
      address = "db-customers-list.php";
      break;
    case "customers-sent-list":
      address = "db-customers-sent-list.php";
      break;
    case "current-orders-notify":
      address = "db-current-orders-notify.php";
      break;
    case "externals-rates":
      address = "db-external-rates.php";
      break;
    case "sent-orders-rate-distinctors":
      address = "db-sent-orders-rate-distinctors.php";
      break;
    case "order-stats":
      address = "db-order-stats.php";
      break;
    case "orders-translators-day":
      address = "db-orders-translators-day.php";
      break;
    case "executive-daily-report":
      address = "db-executive-report.php";
      break;
    case "executive-daily-report-chunk-2":
      address = "db-executive-report-chunk-2.php";
      break;
    case "customer-details":
      address = "db-customer-details.php";
      break;
    case "purchase-invoices":
      address = "db-invoices-purchase-table.php";
      break;
    case "order-view":
      address = "db-order-view.php";
      break;
    case "invoice-view":
      address = "db-invoice-view.php";
      break;
    case "purchase-invoice-view":
      address = "db-invoice-purchase-view.php";
      break;
    case "customer-details-table":
      address = "db-customer-details-table.php";
      break;
    case "order-queue":
      address = "db-order-list-queue.php";
      break;
    case "gus-nip":
      address = "gusAPI/query.php";
      break;
    case "TSactionTypes":
      address = "db-action-types.php";
      break;
    case "translatorsByDocumentID":
      address = "db-get-translators-by-document-ids.php";
      break;
    case "externalsByOrderID":
      address = "db-get-externals-by-order-id.php";
      break;
    case "getDocsByOrder":
      address = "db-get-docs-by-order.php";
      break;
    case "fixProcessTime":
      address = "set/kill-problem-process.php";
      break;
    case "remoteDays":
      address = "db-remote-days.php";
      break;
    case "daysOffYear":
      address = "db-days-off-year.php";
      break;
  }
  return address;
}
