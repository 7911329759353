import { Link } from "react-router-dom";
import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import menuCustomers from "../newExternals/menuCustomers";
import { propMap } from "./Dictionaries/CustomersPropMap";
import flags from "../../../flags";
import { languagesList } from "../../../global";

export default function BigCustomersTable() {
  const dictionary = {
    /*id: {
      name: "id",
      order: "id",
      style: {
        width: "20px",
        textAlign: "center",
        padding: 0,
        fontSize: "9px",
        color: "grey",
      },
    },*/
    name: {
      name: "Nazwa",
      order: "name",
      style: {
        width: "44%",
        maxWidth: "500px",
        padding: "3px 12px",
      },
      transform: (v, a) => (
        <Link to={`../orders?customer=${a.id}`}>
          <div className="big-client-name">
            <div>
              <div>{v}</div>
              <div
                style={{
                  textTransform: "uppercase",
                  color: "rgb(150,150,150)",
                  fontSize: "9px",
                  marginTop: "2px",
                }}
              >
                {a.town}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                color: "rgb(150,150,150)",
                fontSize: "8px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>{a.languages.split(",").length} jęz.</div>
            </div>
          </div>
        </Link>
      ),
    },
    orders: {
      name: "Liczba zleceń",
      style: {
        width: "100px",
        textAlign: "center",
      },
      order: "orders, id",
    },
    gross_price: {
      name: "Suma brutto",
      transform: (v) =>
        prettyNumber(v, { className: "number-fancy", postfix: " zł" }),
      style: {
        textAlign: "center",
      },
      order: "gross_price, orders, id",
    },
    last_order_date: {
      name: "Ostatnie zlecenie",
      transform: (v, a) => (
        <div>
          <div>{prettyDate(v)}</div>
          <div style={{color:"grey"}}>{a.last_order_number}</div>
        </div>
      ),
      style: {
        textAlign: "center",
        fontSize: "11px",
      },
      order: "last_order_date, id",
    },
    email: {
      name: "Kontakt",
      style: {
        width: "170px",
        fontSize: "11px",
      },
      transform: (v, a) => (
        <div>
          <div>
            <a href={"mailto:" + v}>{v}</a>
          </div>

          <div>{a.phone}</div>
        </div>
      ),
    },

    private_firm_name: {
      name: "Typ",
      transform: (v) =>
        v === "instytucja" ? (
          <div className="customer-type-institution">{v}</div>
        ) : v === "firma" ? (
          <div className="customer-type-company">{v}</div>
        ) : (
          <div className="customer-type-person">{v}</div>
        ),
    },
  };
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Panel klientów"
        data="customers-list"
        dataCount={true}
        classNames="big"
        searchPattern={{
          name: {
            value: "",
            style: {
              
            },
            inputStyle: {
              width: "190px",
              padding: "10px",
              height: "25px",
            },
          },
          private_firm_name: {
            value: "",
            element: "select",
            exact: true,
            options: {
              wszystkie: "",
              "osoba pryw.": "osoba prywatna",
              firma: "firma",
              instytucja: "instytucja",
            },
          },
          orders: {
            label: "Min. liczba zleceń",
            value: 5,
            inputStyle: {
              width: "100px",
            },
            type:"number",
            name: "orders",
            rangeFrom: true,
            integer: true,
          },
          gross_price: {
            label: "Min. suma brutto",
            type: "number",
            inputStyle: {
              width: "100px",
            },
            name: "gross_price",
            step: 10000,
            rangeFrom: true,
            integer: true,
            value: 0,
          },
          recent: {
            label: "bieżące",
            element: "select",
            value: 1,
            exact: true,
            inputStyle: {
              width: "130px",
            },
            options: {
              wszystkie: "",
              "tylko bieżące": 1,
              "tylko starsze": 0,
            },
          },
        }}
        dictionary={dictionary}
        orderBy="gross_price, last_order_id, id"
        orderDesc="DESC, DESC, DESC"
        propMap={propMap}
        customMenu={menuCustomers}
      />
    </div>
  );
}
