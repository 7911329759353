import { useContext } from "react";
import { TableContext } from "../TableUniversal";
import modify from "../modify";

export default function MultiControls() {
  const { headings, selectable, multiControls } = useContext(TableContext);

  return multiControls ? (
    <tr>
      <th
        className="multi-options"
        colSpan={headings.length + (selectable === true ? 1 : 0) + 2}
      >
        {multiControls}
      </th>
    </tr>
  ) : (
    <></>
  );
}
