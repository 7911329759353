import { useState } from "react";

export default function CheckButton({
  stateTexts,
  icon,
  actions,
  activeControl,
}) {
  const [active, setActive] = useState(activeControl);
  const [openMenu, setOpenMenu] = useState(false);
  function clicker() {
    setActive((prev) => !prev);
    if (active) {
      actions[1]();
    } else {
      actions[0]();
    }
  }
  return (
    <div
      onClick={() => setOpenMenu((prev) => !prev)}
      onMouseLeave={() => setOpenMenu(false)}
      className={"checkButtonWrap" + (active ? " active" : "")}
    >
      <button className="checkButton">
        <img src={icon} />
      </button>
      <div className="checkLabel">{active ? stateTexts[1] : stateTexts[0]}</div>
      {openMenu ? (
        <div className="hoverShowWrapper">
          <div
            style={{ color: "black" }}
            onClick={() => clicker()}
            className="hoverShow"
          >
            Zmień
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
