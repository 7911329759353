import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import languageDisplay from "../displays/languageDisplay";
import customMenuItems from "./customMenuItems";
import { translator_name } from "./maps/common/translator_name";
import { rateMap } from "./maps/rateMap";

export default function Rates() {
  return (
    <div className="rates fullScreen">
      <TableUniversal
        name="Stawki tłumaczy"
        //data="externals-rates"
        defLimit={10}
        data="sent-orders-rates"
        active="1"
        orderBy="time_added, translator_name, id"
        orderDesc="DESC, ASC, DESC"
       // createMap={rateMap}
        dictionary={dictionary}
        classNames="big"
        searchPattern={{
          translator_name: {
            label: "Tłumacz",
            value: ""
          },
          order_customer: {
            label: "Ostatni klient",
            value: "",
          },
          language_from_full_name: {
            label: "Z języka",
            value: ""
          },
          language_to_full_name: {
            label: "Na język",
            value:""
          },
          rate_distinctor: {
            label: "Wyróżnik",
            value:""
          },
        }}
        customMenu={customMenuItems}
      />
    </div>
  );
}

const dictionary = {
  translator_name: {
    name: "Tłumacz",
    order: "translator_name, time_added, id",
  },
  language_full_name: {
    name: "Język",
    style: {
      display: "none",
    },
  },
  language_from_full_name: {
    name: "z",
    order: "from, id",
    style: {
      fontSize: "11px",
    },
    transform: (v, a) => languageDisplay({ name: a.language_from_name }, v, {}, true),
  },
  language_to_full_name: {
    name: "na",
    order: "to, id",
    style: {
      fontSize: "11px",
    },
    transform: (v, a) => languageDisplay({ name: a.language_to_name }, v),
  },
  rate: {
    name: "stawka netto",
    order: "rate, id",
    style: {
        textAlign: "center"
    },
    transform: (v) => <>{prettyNumber(v)} zł</>
  },
  rate_distinctor: {
    name: "wyróżnik",
    order: "rate_distinctor, rate, id",
    style: {
        fontSize:"11px"
    }
  },
  unit_name: {
    name: "jednostka",
    order: "id",
    transform: (v, a) => (
      <div>{a.unit_joint_name + " " + a.unit_number + " " + v}</div>
    ),
  },
  order_customer: {
    name: "ostatni klient",
    style:{
        fontSize:"11px",
        color:"grey"
    }
  },
  time_added: {
    name: "ostatnio zastosowana",
    transform: (v) => prettyDate(v),
    style: {
        textAlign:"center"
    }
  },
};
