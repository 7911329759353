import React from "react";
import ListingTableRow from "./ListingTableRow";
import NumberOrder from "../tables/common/NumberOrder";

function ListingTable({
  data,
  checked,
  setChecked,
  displayPrevious,
  setDisplayPrevious,
  sumPrice,
  sumWords,
  sumPages,
  sumPriceGross,
  isToday,
  params,
}) {
  return (
    <table className="listingTable">
      <thead>
        <tr>
          <th></th>
          <th>Lp.</th>
          <th>Data</th>
          <th>Nr zlec.</th>
          <th>Nazwa</th>
          <th>Wyrazy</th>
          <th>Strony</th>
          <th>Stawka</th>
          <th>Cena netto</th>
          <th>Cena brutto</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={11}>
            <div
              style={{
                cursor: "pointer",
                padding: "10px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setDisplayPrevious((prev) => !prev)}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "22px",
                  marginRight: "8px",
                }}
              >
                {displayPrevious ? "-" : "+"}
              </span>{" "}
              {displayPrevious ? "Ukryj" : "Pokaż"} poprzednie
            </div>
          </td>
        </tr>
        {data
          .map((i) =>
            checked[i.id] ? { ...i, checked: true } : { ...i, checked: false }
          )
          .map((item, index, arr) => {
            const number = item.number_order.split("/");
            item.previous = false;
            if (new Date(item.create_date).getMonth() + 1 !== params.month) {
              item.previous = true;
            }
            console.log(
              new Date(item.create_date).getMonth() + 1,
              params.month
            );
            const checkToday = isToday(new Date(item.finish_date));
            const numberJSX = (
              <>
                <span>{number[0]}</span>.
                <span style={{ color: "grey" }}>
                  {number[1]}.{number[2]}.{item.language_main.toLowerCase()}
                </span>
              </>
            );
            const nextDocInOrderCellStyle = {
              borderTop:
                arr?.[index - 1]?.number_order === item.number_order
                  ? "1.2px solid transparent"
                  : null,
              opacity:
                arr?.[index - 1]?.number_order === item.number_order
                  ? "0"
                  : "1",
            };
            return (
              <ListingTableRow
                item={item}
                index={index}
                displayPrevious={displayPrevious}
                numberJSX={
                  <NumberOrder id={item.id} number_order={item.number_order} withLang={true} lang={item.language_main} />
                }
                nextDocInOrderCellStyle={nextDocInOrderCellStyle}
                checkToday={checkToday}
                checked={checked}
                setChecked={setChecked}
              />
            );
          })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="5">Suma</td>

          <td>{sumWords} wyr.</td>
          <td>{String(sumPages).replace(".", ",")} str.</td>
          <td></td>
          <td>
            {String(sumPrice)
              .replace(".", ",")
              .replace(/(,[0-9])$/, "$10")}{" "}
            zł
          </td>
          <td>{String(sumPriceGross).replace(".", ",")} zł</td>
        </tr>
      </tfoot>
    </table>
  );
}

export default ListingTable;
