import loading from "../../images/loading.svg";

export default function Loading({ text, little }) {
  return (
    <div className="loading">
      <div>
        <img src={loading} style={little ? { boxShadow:"0 0 5px orange", borderRadius:"50%", width:"18px"} : {}} />
        <div>{text}</div>
      </div>
    </div>
  );
}
