import React, { useState, useEffect } from "react";
import { URL } from "../global";


function getHolidays(date) {
  return fetch(URL + "components/db-holidays.php", {
    credentials: "include",
    method: "POST",
    body: JSON.stringify({queryType: 'fetch', year: date.getFullYear()})
  })
    .then((response) => response.json())
    .then((holidayItems) => holidayItems);
}

function useHolidays(change, date) {
    const [ holidayItems, setHolidayItems ] = useState([]);
    
    useEffect(() => {
        getHolidays(date).then((items) => setHolidayItems(items));
    }, [change]);

    useEffect(() => {
       const timer = setInterval(() => getHolidays(date).then((items)=> setHolidayItems(items)), 10000 )
       return () => clearInterval(timer);
    }, [date.getFullYear()])

    return [holidayItems, setHolidayItems];
}

export default useHolidays;
