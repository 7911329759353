export const document_number = {
  label: "Numer dokumentu rozliczenia",
  dataSource: {
    name: "purchase-invoices",

    params: {
      orderBy: "id",
      orderDesc: "DESC",
      limit: 20,
    },
    query: (value, all) => ({
      doc_search: {
        value: value,
      },
      id_factures_categories: {
        value: 9,
        exact: true,
      },
      /*seller: {
          value: all.translator_name
        }*/
    }),
    value: "source_doc_name",
    setters: (row) => [["document_number", row.source_doc_name]],
    display: (row) => (
      <div
        style={{
          fontSize: "11px",
          boxShadow: "0px -3px 10px rgba(0,0,0,.05) inset",
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{row.seller_name}</div> <div>{row.source_doc_name}</div>
      </div>
    ),
    text: (row) => row.source_doc_name,
  },
};
