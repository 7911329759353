import { URL } from "./global";
import { countryCode } from "./functions";

const getInvoices = (params) => {
  const dateStart = new Date();
  dateStart.setFullYear(params.year, params.month - 1, 1);
  const dateEnd = new Date();
  dateEnd.setFullYear(params.year, params.month, 0);
  return fetch(URL + "components/db-invoices.php", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((invoices) => {
      let now = new Date();
      invoices = invoices[0];
      return {
        data: invoices.map((item) => {
          return item;
        }),
        jpk: {
          "JPK_V7M (2)": {
            variables: {
              "tns:DataWytworzeniaJPK": now.toISOString().slice(0, -5),
              "tns:Rok": params.year,
              "tns:Miesiac": params.month,
            },
            SprzedazWiersze: invoices
              .map((item) => ({
                KodKrajuNadaniaTIN: countryCode(item.customers_countries_name),
                NrKontrahenta: String(item["customers_nip"]).replace(/[A-Za-z]*/, ""),
                NazwaKontrahenta: item["customers_name"],
                DowodSprzedazy: item["number_facture"],
                DataWystawienia: item["date_issue"],
                DataSprzedazy: item["date_sell"],
                K_19: item["price"],
                K_20: (
                  Number(item["price_brutto"]).toFixed(2) -
                  Number(item["price"]).toFixed(2)
                ).toFixed(2),
              }))
              .filter((item) => item !== null),
          },
          "JPK_FA (4)": {
            variables: {
              "tns:DataWytworzeniaJPK": now.toISOString().slice(0, -5),
              "tns:DataOd": dateStart.toISOString().slice(0, 10),
              "tns:DataDo": dateEnd.toISOString().slice(0, 10),
            },
            Faktura: invoices.map((item) => ({
              KodWaluty: "PLN",
              P_1: item["date_issue"], // Data wystawienia
              P_2A: item["number_facture"], // Kolejny numer faktury, nadany w ramach jednej lub więcej serii, który w sposób jednoznaczny indentyfikuje fakturę
              P_3A: item["customers_name"], // Imię i nazwisko lub nazwa nabywcy towarów lub usług. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 3 ustawy
              P_3B:
                item["customers_street"] +
                " " +
                item["customers_nr_home"] +
                "/" +
                item["customers_nr_place"] +
                ", " +
                item["customers_post_code"] +
                " " +
                item["customers_town"], // Adres nabywcy. Pole opcjonalne dla przypadków określonych w art. 106e ust. 5 pkt 3 ustawy oraz w § 3 pkt 2 i 4 rozporządzenia Ministra Finansów z dnia 3 grudnia 2013 r. w sprawie wystawiania faktur (Dz. U. z 2013 r., poz. 1485), a także § 3 pkt 2 i 4  rozporządzenia Ministra Finansów z dnia 29 października 2021 r. w sprawie wystawiania faktur (Dz. U. z 2021 r., poz. 1979)
              P_3C: item["seller_name"], // Imię i nazwisko lub nazwa sprzedawcy towarów lub usług
              P_3D:
                item["seller_street"] +
                " " +
                item["seller_nr_home"] +
                "/" +
                item["seller_nr_place"] +
                ", " +
                item["seller_post_code"] +
                " " +
                item["seller_town"], // Adres sprzedawcy
              //P_4A: item[""], // Kod (prefiks) podatnika VAT UE dla przypadków określonych w art. 97 ust. 10 pkt 2 i 3 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 3 ustawy
              //P_4B: item[""], // Numer, za pomocą którego podatnik jest zidentyfikowany na potrzeby podatku, z zastrzeżeniem pkt 24 lit. a ustawy. Pole opcjonalne dla przypadku określonego w art. 106e ust. 4 pkt 2 ustawy. W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2 ustawy, numer, o którym mowa w art. 136 ust. 1 pkt 3 ustawy
              //P_5A: item[""], // Kod (prefiks) nabywcy VAT UE, o którym mowa w art. 106e ust. 1 pkt 24 ustawy oraz w przypadku, o którym mowa w art. 136 ust. 1 pkt 4 ustawy
              //P_5B: item[""], // Numer, za pomocą którego nabywca towarów lub usług jest identyfikowany dla podatku lub podatku od wartości dodanej, pod którym otrzymał on towary lub usługi, z zastrzeżeniem art. 106e ust. 1 pkt 24 lit. b ustawy. Pole opcjonalne dla przypadku określonego w art. 106e ust. 5 pkt 2 ustawy. W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2 ustawy, numer, o którym mowa w art. 136 ust. 1 pkt 4 ustawy
              P_6:
                item["payment_methods_payed_date"] != "0000-00-00 00:00:00"
                  ? item["payment_methods_payed_date"].slice(0, 10)
                  : "", // Data dokonania lub zakończenia dostawy towarów lub wykonania usługi lub data otrzymania zapłaty, o której mowa w art. 106b ust. 1 pkt 4, o ile taka data jest określona i różni się od daty wystawienia faktury
              P_13_1: item["price"], // Suma wartości sprzedaży netto ze stawką podstawową - aktualnie 23% albo 22%. W przypadku faktur zaliczkowych, kwota zaliczki netto. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              P_14_1: (
                Number(item["price_brutto"]).toFixed(2) -
                Number(item["price"]).toFixed(2)
              ).toFixed(2), // Kwota podatku od sumy wartości sprzedaży netto ze stawką podstawową - aktualnie 23% albo 22%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_1W: item[""], // W przypadku gdy faktura jest wystawiona w walucie obcej, kwota podatku od sumy wartości sprzedaży netto ze stawką podstawową, przeliczona zgodnie z art. 31a w związku z art. 106e ust. 11 ustawy - aktualnie 23% albo 22%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_13_2: item[""], // Suma wartości sprzedaży netto ze stawką obniżoną pierwszą - aktualnie 8 % albo 7%. W przypadku faktur zaliczkowych, kwota zaliczki netto. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_2: item[""], // Kwota podatku od sumy wartości sprzedaży netto ze stawką obniżoną pierwszą - aktualnie 8% albo 7%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_2W: item[""], // W przypadku gdy faktura jest wystawiona w walucie obcej, kwota podatku od sumy wartości sprzedaży netto ze stawką obniżoną, przeliczona zgodnie z art. 31a w związku z art. 106e ust. 11 ustawy - aktualnie 8% albo 7%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_13_3: item[""], // Suma wartości sprzedaży netto ze stawką obniżoną drugą - aktualnie 5%. W przypadku faktur zaliczkowych, kwota zaliczki netto. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_3: item[""], // Kwota podatku od sumy wartości sprzedaży netto ze stawką obniżoną drugą - aktualnie 5%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_3W: item[""], // W przypadku gdy faktura jest wystawiona w walucie obcej, kwota podatku od sumy wartości sprzedaży netto ze stawką obniżoną drugą, przeliczona zgodnie z art. 31a w związku z art. 106e ust. 11 ustawy - aktualnie 5%. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_13_4: item[""], // Suma wartości sprzedaży netto ze stawką obniżoną trzecią w walucie, w której faktura została wystawiona - procedura odwrotnego obciążenia, dla której podatnikiem jest nabywca zgodnie z art. 17 ust. 1 pkt 7 i 8 ustawy oraz dla stawki podatku w przypadku ryczałtu dla taksówek osobowych. W przypadku faktur zaliczkowych, kwota zaliczki netto. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_4: item[""], // Kwota "0" dla procedury odwrotnego obciążenia, dla której podatnikiem jest nabywca zgodnie z art. 17 ust. 1 pkt 7 i 8 ustawy oraz kwota podatku od sumy wartości sprzedaży netto dla stawki podatku w walucie, w której faktura została wystawiona w przypadku ryczałtu dla taksówek osobowych. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_4W: item[""], // W przypadku gdy faktura jest wystawiona w walucie obcej, kwota "0" dla procedury odwrotnego obciążenia, dla której podatnikiem jest nabywca zgodnie z art. 17 ust. 1 pkt 7 i 8 ustawy oraz kwota podatku od sumy wartości sprzedaży netto w walucie obcej dla stawki podatku w przypadku ryczałtu dla taksówek osobowych, przeliczona zgodnie z art. 31a w związku z art. 106e ust. 11 ustawy. W przypadku faktur zaliczkowych, kwota podatku wyliczona według wzoru, o którym mowa w art. 106f ust. 1 pkt 3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_13_5: item[""], // Suma wartości sprzedaży netto dla transakcji dostawy towarów oraz świadczenia usług poza terytorium kraju. W przypadku faktur zaliczkowych, kwota zaliczki netto. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_14_5: item[""], // Kwota podatku od wartości dodanej w przypadku procedur szczególnych, o których mowa w dziale XII w rozdziałach 6a, 7 i 9 ustawy
              //P_13_6: item[""], // Suma wartości sprzedaży netto ze stawką 0%. W przypadku faktur zaliczkowych, kwota zaliczki netto. Pole opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3, a także ust. 4 pkt 3 i ust. 5 pkt 1-3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              //P_13_7: item[""], // Suma wartości sprzedaży zwolnionej. W przypadku faktur zaliczkowych, kwota zaliczki netto. Pola opcjonalne dla przypadków określonych w art. 106e ust. 2 i 3, a także ust. 4 pkt 3 i ust. 5 pkt 1-3 ustawy. W przypadku faktur korygujących, kwota różnicy, o której mowa w art. 106j ust. 2 pkt 5 ustawy
              P_15: item["price_brutto"], // Kwota należności ogółem. W przypadku faktur zaliczkowych kwota zapłaty dokumentowana fakturą. W przypadku faktur, o których mowa w art. 106f ust. 3 ustawy kwota pozostała do zapłaty. W przypadku faktur korygujących korekta kwoty wynikającej z faktury korygowanej. W przypadku, o którym mowa w art. 106j ust. 3 ustawy korekta kwot wynikających z faktur korygowanych
              P_16: "false", // W przypadku dostawy towarów lub świadczenia usług, w odniesieniu do których obowiązek podatkowy powstaje zgodnie z art. 19a ust. 5 pkt 1 lub art. 21 ust. 1 - wyrazy "metoda kasowa", należy podać wartość "true"; w przeciwnym przypadku - wartość "false"
              P_17: "false", // W przypadku faktur, o których mowa w art. 106d ust. 1 - wyraz "samofakturowanie", należy podać wartość "true"; w przeciwnym przypadku - wartość "false"
              P_18: "false", // W przypadku dostawy towarów lub wykonania usługi, dla których obowiązanym do rozliczenia podatku od wartości dodanej lub podatku o podobnym charakterze jest nabywca towaru lub usługi - wyrazy "odwrotne obciążenie", należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              P_18A: "false", // W przypadku faktur, w których kwota należności ogółem przekracza kwotę 15 000 zł lub jej równowartość wyrażoną w walucie obcej, obejmujących           dokonaną na rzecz podatnika dostawę towarów lub świadczenie usług, o których mowa w załączniku nr 15 do ustawy - wyrazy "mechanizm...
              P_19: "false", // W przypadku dostawy towarów lub świadczenia usług zwolnionych od podatku na podstawie art. 43 ust. 1, art. 113 ust. 1 i 9 albo przepisów wydanych na podstawie art. 82 ust. 3 należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              //P_19A: item[""], // Jeśli pole P_19 równa się "true" - należy wskazać przepis ustawy albo aktu wydanego na podstawie ustawy, na podstawie którego podatnik stosuje zwolnienie od podatku
              //P_19B: item[""], // Jeśli pole P_19 równa się "true" - należy wskazać przepis dyrektywy 2006/112/WE, który zwalnia od podatku taką dostawę towarów lub takie świadczenie usług
              //P_19C: item[""], // Jeśli pole P_19 równa się "true" - należy wskazać inną podstawę prawną wskazującą na to, że dostawa towarów lub świadczenie usług korzysta ze zwolnienia
              P_20: "false", // W przypadku, o którym mowa w art. 106c ustawy należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              //P_20A: item[""], // Jeśli pole P_20 równa się "true" - należy podać nazwę organu egzekucyjnego lub imię i nazwisko komornika sądowego
              //P_20B: item[""], // Jeśli pole P_20 równa się "true" - należy podać adres organu egzekucyjnego lub komornika sądowego
              P_21: "false", // W przypadku faktur wystawianych w imieniu i na rzecz podatnika przez jego przedstawiciela podatkowego należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              //P_21A: item[""], // Jeśli pole P_21 równa się "true" - należy podać nazwę lub imię i nazwisko przedstawiciela podatkowego
              //P_21B: item[""], // Jeśli pole P_21 równa się "true" - należy podać adres przedstawiciela podatkowego
              //P_21C: item[""], // Jeśli pole P_21 równa się "true" - należy podać numer przedstawiciela podatkowego, za pomocą którego jest on zidentyfikowany na potrzeby podatku
              P_22: "false", // W przypadku gdy przedmiotem wewnątrzwspólnotowej dostawy są nowe środki transportu należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              //P_22A: item[""], // Jeśli pole P_22 równa się "true" - należy podać datę dopuszczenia nowego środka transportu do użytku
              //P_22B: item[""], // Jeśli pole P_22 równa się "true" a dostawa dotyczy pojazdów lądowych, o których mowa w art. 2 pkt 10 lit. a ustawy - należy podać przebieg pojazdu
              //P_22C: item[""], // Jeśli pole P_22 równa się "true" a dostawa dotyczy jednostek pływających, o których mowa w art. 2 pkt 10 lit. b ustawy, oraz statków powietrznych, o których mowa w art. 2 pkt 10 lit. c ustawy, należy podać liczbę godzin roboczych używania nowego środka transportu
              P_23: "false", // W przypadku faktur wystawianych w procedurze uproszczonej przez drugiego w kolejności podatnika, o którym mowa w art. 135 ust. 1 pkt 4 lit. b i c oraz ust. 2, zawierającej adnotację, o której mowa w art. 136 ust. 1 pkt 1 i stwierdzenie, o którym mowa w art. 136 ust. 1 pkt 2 ustawy należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              P_106E_2: "false", // W przypadku świadczenia usług turystyki, dla których podstawę opodatkowania stanowi zgodnie z art. 119 ust. 1 kwota marży, faktura - w zakresie danych określonych w ust. 1 pkt 1-17 - powinna zawierać wyłącznie dane określone w ust. 1 pkt 1-8 i 15-17, a także wyrazy "procedura marży dla biur podróży", należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              P_106E_3: "false", // W przypadku dostawy towarów używanych, dzieł sztuki, przedmiotów kolekcjonerskich i antyków, dla których podstawę opodatkowania stanowi zgodnie z art. 120 ust. 4 i 5 marża, należy podać wartość "true", w przeciwnym przypadku - wartość "false"
              //P_106E_3A, item[""] // Jeżeli pole P_106E_3 równa się "true", należy podać wyrazy: "procedura marży - towary używane" lub "procedura marży - dzieła sztuki" lub "procedura marży - przedmioty kolekcjonerskie i antyki"
              RodzajFaktury: item.advanced == "0" ? "VAT" : "ZAL", // "VAT", "KOREKTA", "ZAL"
              /*PrzyczynaKorekty: null,
                NrFaKorygowanej: null,
                OkresFaKorygowanej: null,
                NrFaZaliczkowej: null,*/
            })),
            FakturaWiersz: invoices.map((item) => ({
              P_2B: item["number_facture"],
              //P_7: $item[""],
              // P_8A:
              //P_8B:
              //P_9A:
              //P_10:
              P_11: item["price"],
              //P_12:
            })),
            Zamowienie: invoices.map((item) => ({
              P_2AZ: item["number_facture"],
              WartoscZamowienia: item["price_brutto"],
              ZamowienieWiersz: {
                P_7Z: "tłumaczenie", // ? nazwa/rodzaj towaru lub usługi
                P_8AZ: "str", // Miara zamówionego towaru lub zakres usługi
                P_8BZ: "1", //  Ilość zamówionego towaru lub zakres usługi
                P_9AZ: 10, // Cena jednostkowa netto
                P_11NettoZ: item["price"], // Wartość zamówionego towaru lub usługi bez kwoty podatku
                P_11VatZ: (
                  Number(item["price_brutto"]).toFixed(2) -
                  Number(item["price"]).toFixed(2)
                ).toFixed(2), // Kwota podatku od zamówionego towaru lub usługi
                //P_12Z:
                //P_12Z_XII:
              },
            })),
          },
          "JPK_VAT (3)": {
            variables: {
              "tns:DataWytworzeniaJPK": now.toISOString().slice(0, -5),
              "tns:DataOd": dateStart.toISOString().slice(0, 10),
              "tns:DataDo": dateEnd.toISOString().slice(0, 10),
            },
            SprzedazWiersze: invoices
              .map((item) => ({
                NrKontrahenta: String(item["customers_nip"]).replace(/[A-Za-z]*/, ""),
                NazwaKontrahenta: item["customers_name"],
                AdresKontrahenta:
                  item["customers_street"] +
                  " " +
                  (item["customers_nr_place"]
                    ? item["customers_nr_place"] +
                      "/" +
                      item["customers_nr_home"]
                    : item["customers_nr_home"]
                  ).replaceAll(" ", "") +
                  ", " +
                  item["customers_post_code"] +
                  " " +
                  item["customers_town"],
                DowodSprzedazy: item["number_facture"],
                DataWystawienia: item["date_issue"],
                DataSprzedazy: item["date_sell"],
                K_19: item["price"],
                K_20: (
                  Number(item["price_brutto"]).toFixed(2) -
                  Number(item["price"]).toFixed(2)
                ).toFixed(2),
              }))
              .filter((item) => item !== null),
          },
        },
        jpkPL: {
          DataWytworzeniaJPK: now.toISOString().slice(0, -5),
          Rok: params.year,
          Miesiac: params.month,
          SprzedazWiersze: invoices
            .map((item) =>
              item.customers_countries_name === "Polska"
                ? {
                    NrKontrahenta: String(item["customers_nip"]).replace(
                      /[A-Za-z]*/,
                      ""
                    ),
                    NazwaKontrahenta: item["customers_name"],
                    /*  AdresKontrahenta:
                        item["customers_street"] +
                        " " +
                        (item["customers_nr_place"] ? (item["customers_nr_place"] + "/" + item["customers_nr_home"]) : item["customers_nr_home"]).replaceAll(" ","") +
                        ", " +
                        item["customers_post_code"] +
                        " " +
                        item["customers_town"],*/
                    DowodSprzedazy: item["number_facture"],
                    DataWystawienia: item["date_issue"],
                    DataSprzedazy: item["date_sell"],
                    K_19: item["price"],
                    K_20: (
                      Number(item["price_brutto"]).toFixed(2) -
                      Number(item["price"]).toFixed(2)
                    ).toFixed(2),
                  }
                : null
            )
            .filter((item) => item !== null),
        },
        jpkForeign: {
          DataWytworzeniaJPK: now.toISOString().slice(0, -5),
          Rok: params.year,
          Miesiac: params.month,
          SprzedazWiersze: invoices
            .map((item) =>
              item.customers_countries_name !== "Polska"
                ? {
                    KodKrajuNadaniaTIN: countryCode(
                      item.customers_countries_name
                    ),
                    NrKontrahenta: String(item["customers_nip"]).replace(
                      /[A-Za-z]*/,
                      ""
                    ),
                    NazwaKontrahenta: item["customers_name"],
                    DowodSprzedazy: item["number_facture"],
                    DataWystawienia: item["date_issue"],
                    DataSprzedazy: item["date_sell"],
                    K_19: item["price"],
                    K_20: (
                      Number(item["price_brutto"]).toFixed(2) -
                      Number(item["price"]).toFixed(2)
                    ).toFixed(2),
                  }
                : null
            )
            .filter((item) => item !== null),
        },
      };
    });
};

export default getInvoices;
