import gb from "../images/flags/gb.svg";
import de from "../images/flags/de.svg";
import chchnya from "../images/flags/chchnya.svg";
import cn from "../images/flags/cn.svg";
import fi from "../images/flags/fi.svg";
import fr from "../images/flags/fr.svg";
import it from "../images/flags/it.svg";
import is from "../images/flags/is.svg";
import pt from "../images/flags/pt.svg";
import ua from "../images/flags/ua.svg";
import by from "../images/flags/by.svg";
import ru from "../images/flags/ru.svg";
import pl from "../images/flags/pl.svg";
import es from "../images/flags/es.svg";
import nl from "../images/flags/nl.svg";
import se from "../images/flags/se.svg";
import ro from "../images/flags/ro.svg";
import bg from "../images/flags/bg.svg";
import no from "../images/flags/no.svg";
import lt from "../images/flags/lt.svg";
import lv from "../images/flags/lv.svg";
import md from "../images/flags/md.svg";
import al from "../images/flags/al.svg";
import cz from "../images/flags/cz.svg";
import si from "../images/flags/si.svg";
import sk from "../images/flags/sk.svg";
import hr from "../images/flags/hr.svg";
import dk from "../images/flags/dk.svg";
import jp from "../images/flags/jp.svg";
import ee from "../images/flags/ee.svg";
import srb from "../images/flags/srb.svg";
import ir from "../images/flags/ir.svg";
import bih from "../images/flags/bih.svg";
import gr from "../images/flags/gr.svg";
import ge from "../images/flags/ge.svg";
import hu from "../images/flags/hu.svg";
import tr from "../images/flags/tr.svg";
import mk from "../images/flags/mk.svg";
import sa from "../images/flags/sa.svg";
import isr from "../images/flags/isr.svg";
import yd from "../images/flags/yd.svg";
import mngla from "../images/flags/mgnla.svg";
import question from "../images/flags/question.svg";
import indonesian from "../images/flags/indonesian.svg";
import hindi from "../images/flags/hindi.svg";
export default {
  gb: gb,
  ir: ir,
  hu: hu,
  gr: gr,
  mk: mk,
  yd:yd,
  question: question,
  sa: sa,
  ge: ge,
  bih: bih,
  isr:isr,
  tr: tr,
  de: de,
  ee: ee,
  fi: fi,
  srb: srb,
  cn: cn,
  lv: lv,
  fr: fr,
  it: it,
  is: is,
  chchnya: chchnya,
  pt: pt,
  ua: ua,
  by: by,
  ru: ru,
  pl: pl,
  es: es,
  nl: nl,
  se: se,
  ro: ro,
  bg: bg,
  no: no,
  lt: lt,
  md: md,
  al: al,
  cz: cz,
  si: si,
  sk: sk,
  hr: hr,
  dk: dk,
  jp: jp,
  mngla: mngla,
  hindi: hindi,
  indonesian: indonesian
};

