import React, { useState, useEffect } from "react";
import { URL } from "./global";


export const useTranslators = (update) => {
  const [active, setActive] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [editor, setEditor] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTranslators = () => {
    return fetch(URL + "components/db-translators.php", {
      credentials: "include",
      method: "POST",
    })
      .then((response) => response.json())
      .then((translators) => {
        translators = translators.map((item) => ({
          ...item,
          id: parseInt(item.id),
          count: parseInt(item.count),
          Type: parseInt(item.Type),
          Status: parseInt(item.Status),
        }));
  
        translators.sort((a, b) => {
          if (a["First Name"] < b["First Name"]) return -1;
          if (a["First Name"] > b["First Name"]) return 1;
          return 0;
        });
  
        const activeTranslators = translators.filter((item) => item.Status);
  
        const inactiveTranslators = translators.filter((item) => !item.Status);
  
        const externalTranslators = activeTranslators.filter(
          (t) => parseInt(t.Type) === 2 || parseInt(t.Type) === 0
        );
  
        const inactives = inactiveTranslators.filter(
          (t) => parseInt(t.Type) === 2 || parseInt(t.Type) === 0
        );
  
        return {
          active: activeTranslators,
          inactive: inactiveTranslators,
          editor: [
            ...externalTranslators.filter((item) => item.Type == 2),
            ...externalTranslators.filter((item) => item.Type != 2),
            ...inactives,
          ],
        };
      })
      .catch((error) => {
        console.log("Błąd wczytywania tłumaczy: " + error);
      });
  };
  

  useEffect(() => {
    getTranslators().then((translators) => {
      setActive(translators.active);
      setInactive(translators.inactive);
      setEditor(translators.editor);
      setLoading(false);
    });
  }, [update]);

  return { active: active, inactive: inactive, editor: editor, update: update, loading: loading };
};
