import { useEffect, useState } from "react";
import exportToExcel from "../main/exportToExcel";
import { useGeneric } from "../useGeneric";


export default function DataToExcel({
  name,
  rowsMap,
  pushRows,
  colStyles,
  orderBy,
  orderDir,
  button,
  search,
  workBook,
  filename,
}) {

  
  const [clicked, setClicked] = useState(0);
  const [loading, setLoading] = useState(false);

  async function doExport(data, rows, filename, colStyles) {
    if (!data || !rows) return;
    const date = new Date().toLocaleDateString();
    await exportToExcel({
      fileName: filename,
      workBookName: workBook ? workBook : data?.[0]?.translator_name,
      rows: rows,
      colStyles: colStyles,
      /*colFormatData: {
        columns: [4, 5],
        string: "0.00 zł",
      },*/
    });
    setLoading(false);
  }

  const data = useGeneric({
    name: clicked ? name : undefined,
    query: {
      limit: 10000,
      offset: 0,
      orderBy: orderBy,
      orderDesc: orderDir,
      search: search,
    },
    update: clicked,
  });


  useEffect(() => {
    if (!data.loading) {
      console.log("export");
      doExport(currentData, rows, filename, colStyles);
    }
  }, [data.loading]);

  const currentData = !data.loading ? data.rows : undefined;

  let rows = currentData
    ? currentData.slice().map(rowsMap)
    : undefined;

  if (rows) {
    rows.push(pushRows(rows));
  }

  return (
    <div
      onClick={() => {
        setClicked((prev) => prev + 1);
        setLoading(true);
      }}
    >
      {loading ? <>...</> : button}
    </div>
  );
}
