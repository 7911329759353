import { useEffect, useState } from "react";
import { URL } from "../global";
import useAttendance from "../useAttendance";
import useAttendanceSingle from "../useAttendanceSingle";
import { useGeneric } from "../useGeneric";
import Timesheet from "./TimesheetRev/Timesheet";
import useOfficeCheck from "./useOfficeCheck";
import WorkTimeView from "./WorkTimeView";
import { useLocation } from "react-router-dom";
import Loading from "./Loading";

export function isSameDay(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  d1.setHours(12);
  d2.setHours(12);
  return d1?.getDate() && d2?.getDate()
    ? d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear()
    : false;
}

export default function WorkTime({ user }) {
  const now = new Date();
  const location = useLocation();
  const locationState = location.state;
  console.log(locationState);
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  // stage of attendance wizard
  const [stage, setStage] = useState(0);

  // mail data
  const [customSubject, setCustomSubject] = useState("");
  const [mailContent, setMailContent] = useState("");
  const [customSubjectEnd, setCustomSubjectEnd] = useState("");
  const [mailContentEnd, setMailContentEnd] = useState("");

  // show work time history
  const [viewWorkTime, setViewWorkTime] = useState(false);

  // show timesheet
  const [viewTimesheet, setViewTimesheet] = useState(true);

  // end work now
  const [timeEnd, setTimeEnd] = useState(() => {
    const n = new Date();
    n.setSeconds(0);
    return n.toLocaleTimeString("pl-PL", {
      hour: "numeric",
      minute: "numeric",
    });
  });

  // current time
  const [time, setTime] = useState(now);

  // load all attendance data for the current user
  const dataRaw = useAttendanceSingle({
    query: { location: "biuro", employeeID: user.employeeID },
    offset: 0,
    // update the data when the user moves to another stge
    update: stage + user.employeeID,
  });

  // the actual rows of attendance data, if loaded
  const data = dataRaw.loading ? undefined : dataRaw.rows;

  // is the user at work right now?
  const atWork = data?.[0] ? [0, 2].includes(data[0].typeshort) : false;

  // has the user started work in the office
  const inOfficeStart = data && data[0] ? data[0].typeshort === 0 : false;

  // the time the user last started work
  const workStart =
    data && data.find((item) => [0, 2].includes(item.typeshort));

  // the time the user last ended work
  const workEnd = data && data.find((item) => [1, 3].includes(item.typeshort));

  // converts the above to dates
  const workStartDateTime = workStart && new Date(workStart.timestring);
  const workEndDateTime = workEnd && new Date(workEnd.timestring);

  // has it happened today?
  const workStarted = isSameDay(new Date(), workStartDateTime);
  const workEnded = isSameDay(new Date(), workEndDateTime);

  // current clock display
  const nowTime = time.toLocaleTimeString("pl-PL", {
    minute: "numeric",
    hour: "numeric",
    second: "numeric",
  });

  // is the user in office right now?
  const inOfficeCheck = useOfficeCheck();
  const inOffice = !inOfficeCheck.loading ? inOfficeCheck.value : undefined;

  // smaller
  const [hiddenAttendance, setHiddenAttendance] = useState(false);


  // if the user is at work move to stage 2
  useEffect(() => {
    if (stage === 0 && atWork) {
      setStage(2);
    }
  }, [atWork, stage]);

  // update the clock every second
  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  // add work start or end to db
  async function addEvent(eventType, mail) {
    const typeshort =
      eventType === "remote-in"
        ? 2
        : eventType === "remote-out"
        ? 3
        : eventType === "in"
        ? 0
        : eventType === "out"
        ? 1
        : undefined;
    const response = await fetch(
      `${URL}components/get_foreign/set-attendance-remote.php`,
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          typeshort: typeshort,
          employeeID: user.employeeID,
          mail: {
            subject: mail.subject,
            content: mail.content,
          },
        }),
      }
    );
    const result = await response.json();
    //console.log(result);
  }
  //console.log(workEndDateTime)
  return data ? (
    <div
      className={
        "timesheet workTimeWrapper" +
        (hiddenAttendance ? " hideAttendance" : "")
      }
    >
      <div className="workTimeCentralWrapper">
        <div style={{ textAlign: "center" }} className="workTime startEnd">
          {hiddenAttendance ? (
            <div className="reShow" onClick={() => setHiddenAttendance(false)}>
              <h2 className="clock">{nowTime}</h2>
              {atWork ? (
                <div className="little-module">
                  <div>
                    {inOfficeStart === true ? <>biuro </> : <>zdalnie </>}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <button className="regularButton">{atWork ? <>Zakończ</> : <>Zacznij</>}</button>
            </div>
          ) : (
            <>
              <div style={{ fontSize: "17px", fontWeight: "100" }}>
                {user.fullname}
              </div>
              <div
                className="hideAttendanceButton"
                onClick={() => setHiddenAttendance(true)}
              >
                ▴
              </div>
              {workEnded && stage === 0 ? (
                <div
                  style={{
                    fontSize: "11px",
                    background: "whitesmoke",
                    padding: "3px 10px",
                    marginTop: "4px",
                    marginBottom: "8px",
                  }}
                >
                  Ostatnie wyjście:{" "}
                  {workEndDateTime && isSameDay(workEndDateTime, now) ? (
                    <strong>DZISIAJ</strong>
                  ) : workEndDateTime &&
                    isSameDay(workEndDateTime, yesterday) ? (
                    <strong>WCZORAJ</strong>
                  ) : (
                    workEndDateTime.toLocaleDateString("pl-PL", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  )}{" "}
                  o godz.{" "}
                  {workEndDateTime.toLocaleTimeString("pl-PL", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                  ,
                </div>
              ) : (
                <></>
              )}
              <h2 className="clock">{nowTime}</h2>
              {stage === 0 ? (
                <div className="stage" key={0}>
                  <button className="superButton" onClick={() => setStage(1)}>
                    <div>
                      Zaczynam pracę{" "}
                      {inOffice === false
                        ? "zdalną"
                        : inOffice === true
                        ? "w biurze"
                        : ""}
                    </div>
                  </button>
                </div>
              ) : stage === 1 ? (
                <div className="stage2" key={1}>
                  {inOffice !== true ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          Temat: jestem {customSubject ? "-" : ""}&nbsp;
                        </div>
                        <div>
                          <input
                            placeholder="dopisek do tematu"
                            value={customSubject}
                            onChange={(e) => setCustomSubject(e.target.value)}
                            maxLength={32}
                          ></input>
                        </div>
                      </div>
                      <form>
                        <textarea
                          placeholder="Opcjonalnie napisz treść e-maila"
                          value={mailContent}
                          onChange={(e) => setMailContent(e.target.value)}
                          className="start-mail"
                          maxLength={400}
                        />
                      </form>
                    </>
                  ) : (
                    <div className="module" style={{ fontSize: "14px" }}>
                      Twój adres sieciowy wskazuje, że jesteś dziś w biurze.
                      <br />
                      Nie masz breloka albo wolisz skorzystać z systemu?
                      <br />
                      Odnotuj początek pracy klikając <i>ZACZNIJ TERAZ</i>.
                    </div>
                  )}
                  <div style={{ display: "flex" }}>
                    <button
                      className="regularButton"
                      onClick={() => setStage(0)}
                    >
                      ◂ POWRÓT
                    </button>
                    <button
                      className="regularButton bigger"
                      onClick={() => {
                        setStage(2);
                        addEvent("remote-in", {
                          subject: customSubject
                            ? "jestem - " + customSubject
                            : "jestem",
                          content: mailContent,
                        });
                        //setWorkStarted(new Date());
                      }}
                    >
                      ZACZNIJ TERAZ *
                    </button>
                  </div>
                  <div className="notes">
                    * Kliknięcie ZACZNIJ TERAZ powoduje automatyczne zapisanie
                    godziny rozpoczęcia pracy w bazie{" "}
                    {inOffice === false ? (
                      <>
                        i wysyła do biura wiadomość e-mail o podanej wyżej
                        treści
                      </>
                    ) : inOffice === true ? (
                      <>(praca w biurze)</>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : stage === 2 ? (
                <div className="stage" key={2}>
                  <div className="module">
                    {inOfficeStart === true ? (
                      <>
                        Jesteś w{" "}
                        <span style={{ fontWeight: "400" }}>biurze</span>{" "}
                      </>
                    ) : (
                      <>Pracujesz zdalnie </>
                    )}
                    od{" "}
                    {workStarted &&
                      workStartDateTime.toLocaleTimeString("pl-PL", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                  </div>

                  <div style={{ display: "flex" }}>
                    {/*<button
                className="regularButton"
                onClick={() => {
                  setStage(4);
                  //setWorkEnded(new Date());
                }}
              >
                SKOŃCZYŁEM/AM WCZEŚNIEJ
              </button>*/}
                    <button
                      className="regularButton"
                      onClick={() => {
                        setStage(5);

                        //setWorkEnded(new Date());
                      }}
                    >
                      ZAKOŃCZ PRACĘ
                    </button>
                  </div>
                </div>
              ) : stage === 3 ? (
                <div className="stage" key={3}>
                  <div className="module">
                    <p>Dzięki i do zobaczenia!</p>
                    {workEnded > workStarted ? (
                      <>
                        <div>Twoja dzisiejsza praca:</div>
                        <div>
                          {workStarted &&
                            workStarted.toLocaleTimeString("pl-PL", {
                              hour: "numeric",
                              minute: "numeric",
                            })}{" "}
                          -{" "}
                          {workEnded &&
                            workEnded.toLocaleTimeString("pl-PL", {
                              hour: "numeric",
                              minute: "numeric",
                            })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {workEnded && workStarted && workEnded > workStarted ? (
                      <div>
                        {(workEnded - workStarted) / 1000 / 60 / 60 >= 1
                          ? Math.floor(
                              (workEnded - workStarted) / 1000 / 60 / 60
                            ) + " h"
                          : ""}{" "}
                        {Math.floor((workEnded - workStarted) / 1000 / 60) -
                          Math.floor(
                            (workEnded - workStarted) / 1000 / 60 / 60
                          ) *
                            60 +
                          " min"}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="regularButton"
                      onClick={() => setStage(0)}
                    >
                      ◂ POWRÓT
                    </button>
                  </div>
                </div>
              ) : stage === 4 ? (
                <div className="stage" key={4}>
                  <div className="module">
                    O której godzinie skończyłeś/aś pracę?
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <input
                      type="time"
                      step={60}
                      value={timeEnd}
                      onChange={(e) => setTimeEnd(e.target.value)}
                    ></input>
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="regularButton"
                      onClick={() => setStage(2)}
                    >
                      ◂ POWRÓT
                    </button>
                    <button
                      className="regularButton bigger"
                      onClick={() => {
                        const end = new Date();
                        end.setHours(timeEnd.split(":")[0]);
                        end.setMinutes(timeEnd.split(":")[1]);
                        setStage(3);
                      }}
                    >
                      ZAPISZ
                    </button>
                  </div>
                </div>
              ) : stage === 5 ? (
                <div className="stage" key={5}>
                  <div>
                    {inOfficeStart !== true ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ fontSize: "14px" }}>
                            Temat: koniec {customSubjectEnd ? "-" : ""}&nbsp;
                          </div>
                          <div>
                            <input
                              placeholder="dopisek do tematu"
                              value={customSubjectEnd}
                              onChange={(e) =>
                                setCustomSubjectEnd(e.target.value)
                              }
                              maxLength={32}
                            ></input>
                          </div>
                        </div>
                        <form>
                          <textarea
                            placeholder="Opcjonalnie napisz treść e-maila"
                            value={mailContentEnd}
                            onChange={(e) => setMailContentEnd(e.target.value)}
                            className="start-mail"
                            maxLength={400}
                          />
                        </form>
                      </>
                    ) : (
                      <div
                        className="module"
                        style={{ fontSize: "13px", lineHeight: "150%" }}
                      >
                        Gdy klikniesz{" "}
                        <i style={{ fontWeight: "400" }}>ZAKOŃCZ TERAZ</i>,
                        odnotujesz wyjście z pracy
                        <br /> i nie musisz już zbliżać breloka do rejestratora.
                      </div>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <button
                      className="regularButton"
                      onClick={() => setStage(2)}
                    >
                      ◂ POWRÓT
                    </button>
                    <button
                      className="regularButton bigger"
                      onClick={() => {
                        if (workStart.typeshort === 0) {
                          addEvent("out", {
                            subject: customSubjectEnd
                              ? "koniec - " + customSubjectEnd
                              : "koniec",
                            content: mailContentEnd,
                          });
                        } else {
                          addEvent("remote-out", {
                            subject: customSubjectEnd
                              ? "koniec - " + customSubjectEnd
                              : "koniec",
                            content: mailContentEnd,
                          });
                        }
                        setStage(3);
                      }}
                    >
                      ZAKOŃCZ TERAZ *
                    </button>
                  </div>
                  <div className="notes">
                    * Kliknięcie ZAKOŃCZ TERAZ powoduje automatyczne zapisanie
                    godziny zakończenia pracy w bazie{" "}
                    {inOfficeStart === false ? (
                      <>i wysyła do biura wiadomość e-mail o podanej treści</>
                    ) : inOfficeStart === true ? (
                      <>(praca w biurze)</>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {hiddenAttendance ? (
          <></>
        ) : (
          <div className="workTime tab">
            {/*<div>
              <button
                className="regularButton"
                onClick={() => {
                  //if (viewWorkTime === false) setViewTimesheet(false);
                  setViewWorkTime((prev) => !prev);
                }}
              >
                {viewWorkTime ? (
                  <span style={{ fontSize: "18px" }}>×</span>
                ) : (
                  <>🔍︎ Historia czasu pracy</>
                )}
              </button>
              {viewWorkTime ? (
                <span style={{ paddingLeft: "10px" }}>
                  Historia czasu pracy
                </span>
              ) : (
                <></>
              )}
              </div>*/}
            {viewWorkTime ? <WorkTimeView user={user} stage={stage} /> : <></>}
          </div>
        )}
      </div>
      <div className={"workTimeViewWrapper" + (viewWorkTime ? " enabled" : "")}>
        <div className="workTime tab timesheet">
          {/*<div>
            <button
              className="regularButton"
              onClick={() => {
                //if (viewTimesheet === false) setViewWorkTime(false);
                setViewTimesheet((prev) => !prev);
              }}
            >
              {viewTimesheet ? (
                <span style={{ fontSize: "18px" }}>×</span>
              ) : (
                <>🔍︎ Timesheet (Jeszcze nie korzystamy - można testować)</>
              )}
              </button> 
            {viewTimesheet ? (
              <span style={{ paddingLeft: "10px" }}>Timesheet</span>
            ) : (
              <></>
            )}
          </div>*/}
          {viewTimesheet ? <Timesheet user={user} locationState={locationState}/> : <></>}
        </div>
      </div>
    </div>
  ) : (
    <Loading text="Pobieranie..."/>
  );
}
