import { useContext, useEffect, useRef, useState } from "react";
import { TableContext } from "../TableUniversal";
import { useGeneric } from "../../useGeneric";
import PreField from "../forms/PreField";
import languageDisplay from "../instances/displays/languageDisplay";
import { useLocation, useSearchParams } from "react-router-dom";

import { useFormikContext } from "formik";
import FilterClear from "./FilterClear";

export default function FilterItem({
  search,
  s,
  setInitialize,
  clearPreFields,
  setClearPreFields,
}) {
  const {
    dictionary,
    urlMAP,
    searchPattern,
    setSearch,
    updates,
    update,
    initialize,
    setOffset,
    setUpdate,
  } = useContext(TableContext);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [searchValue, setSearchValue] = useState(
    urlMAP && urlMAP[s] ? urlMAP[s].value : searchPattern[s]?.value
  );

  const itemElement = useRef();
  const inputElement = useRef();

  const urlValue = urlMAP?.[s]?.value;

  const useData = useGeneric({
    name: search[s]?.get?.db,
    query: {
      limit: 1,
      offset: 0,
      search: search[s]?.get?.query,
    },
  });

  const data = !useData.loading ? useData.rows?.[0] : undefined;

  const optionsDB =
    searchPattern[s] && searchPattern[s].optionsDB
      ? searchPattern[s].optionsDB
      : undefined;

  const preFill =
    searchPattern[s] && searchPattern[s].fillOnEmpty
      ? searchPattern[s].fillOnEmpty
      : undefined;

  useEffect(() => {
    if (searchValue !== undefined) {
      const wait = setTimeout(() => {
        setInitialize(false);
        setSearch((prev) => ({
          ...prev,
          [s]: { ...prev[s], value: searchValue },
        }));
      }, 600);
      return () => clearTimeout(wait);
    }
  }, [searchValue]);

  return optionsDB && optionsDB.name ? (
    <div
      className={
        "filter-single" +
        (searchPattern[s]?.className ? " " + searchPattern[s]?.className : "")
      }
      ref={itemElement}
      style={{ ...searchPattern[s]?.style, zIndex: 2 }}
    >
      <div className="filter-name">
        {searchPattern[s]?.label
          ? searchPattern[s].label
          : search[s]?.get?.name
          ? search[s].get.name
          : dictionary[s]?.name}
      </div>
      <PreField
        name={optionsDB.name} //"language"
        style={optionsDB?.style}
        db={optionsDB.db}
        limit={optionsDB.limit}
        displayOptions={optionsDB.displayOptions}
        searchColumn={optionsDB.searchColumn}
        targetColumn={optionsDB.targetColumn}
        orderBy={optionsDB.orderBy}
        orderDesc={optionsDB.orderDesc}
        target={optionsDB.target}
        standard={optionsDB.standard}
        standardDB={optionsDB.standardDB}
        preFill={preFill}
        clearPreFields={clearPreFields}
        setClearPreFields={setClearPreFields}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        inputElement={inputElement}
        exact={optionsDB?.exact}
        s={s}
      />
    </div>
  ) : (
    <div
      className={
        "filter-single" +
        (search[s]?.get || (search[s] && search[s].length) ? " active" : "") +
        (search[s]?.get ? " big" : "")
      }
      style={searchPattern[s]?.style}
    >
      <div className="filter-name">
        {searchPattern[s]?.label
          ? searchPattern[s].label
          : search[s]?.get?.name
          ? search[s].get.name
          : dictionary[s]?.name}
      </div>
      {
        <div className="filter-value">
          {searchPattern[s]?.readOnly && data ? (
            <div>
              {search[s]?.get?.jsx ? search[s].get.jsx(data) : search[s].value}
            </div>
          ) : searchPattern[s]?.element === "select" &&
            searchPattern[s].options ? (
            <select
              value={searchValue}
              style={searchPattern[s]?.inputStyle}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            >
              {Object.keys(searchPattern[s].options).map((o) => (
                <option value={searchPattern[s].options[o]}>{o}</option>
              ))}
            </select>
          ) : (
            <input
              type={searchPattern[s]?.type ? searchPattern[s].type : "search"}
              min={searchPattern[s]?.min ? searchPattern[s].min : undefined}
              max={searchPattern[s]?.max ? searchPattern[s].max : undefined}
              maxLength={
                searchPattern[s]?.maxLength
                  ? searchPattern[s].maxLength
                  : undefined
              }
              value={searchValue}
              style={searchPattern[s]?.inputStyle}
              step={searchPattern[s]?.step}
              onChange={(e) => {
                setSearchValue(
                  searchPattern[s]?.integer
                    ? Number(e.target.value)
                    : e.target.value
                );
              }}
            />
          )}
        </div>
      }
      {/* <FilterClear s={s} setSearch={setSearch} />*/}
    </div>
  );
}
