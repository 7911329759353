import { useState } from "react";
import Loading from "../main/Loading";
import { useGeneric } from "../useGeneric";
import useExecutiveReport from "./useExecutiveReport";
import prettyNumber from "./functions/prettyNumber";
import prettyDate from "./functions/prettyDate";
import ExecutivePageListing from "./ExecutivePageListing";
import ExecutivePageListingStatus from "./ExecutivePageListingStatus";
import ExecutiveDailyText from "./ExecutiveDailyText";
import ExecutiveDailyTable from "./ExecutiveDailyTable";
import ExecutiveDailyOrderTable from "./ExecutiveDailyOrderTable";

function stringSplit(string, splitter = ",") {
  if (string) {
    return String(string).split(splitter);
  }
}

function OrderListing({ data }) {
  return (
    <div className="orders-listing">
      {stringSplit(data) &&
        stringSplit(data).map((i) => {
          const s = stringSplit(i, "**");
          return (
            <div
              className="orders-listing-single"
              title={s[3] ? prettyDate(s[3]) : ""}
            >
              <div>{s[0].replace(/\//g, ".").toLowerCase()}</div>
              <div style={{ fontStyle: "italic" }}>{s[1]}</div>
              <div style={{ textAlign: "right" }}>{prettyNumber(s[2])}</div>
            </div>
          );
        })}
    </div>
  );
}

export default function ExecutiveDailyReport() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    if (d.getDay() === 0) {
      d.setDate(d.getDate() - 2);
    } else if (d.getDay() === 6) {
      d.setDate(d.getDate() - 1);
    }
    return d.toISOString().slice(0, 10);
  });
  const [mode, setMode] = useState("ugly");

  const [englishSum, setEnglishSum] = useState();
  const [germanSum, setGermanSum] = useState();

  const { loading, data, translators, orderless } = useExecutiveReport(selectedDate);
  console.log(data);

  const english = data?.filter((i) => i.language === "Ang");
  const german = data?.filter((i) => i.language === "Niem");
  const other = data?.filter(
    (i) => i.language !== "Ang" && i.language !== "Niem"
  );

  const fullData = {
    english: {
      translatedPages: prettyNumber(
        english?.[0]?.translated_pages_day_no_adjust
      ),
      receivedOrders: english?.[0].received_orders,
      receivedPages: english?.[0].received_pages, // - english?.[0].sent_pages,
      pagesToTranslate: english?.[0].pages_to_translate,
      pagesToTranslateEditor: englishSum,
      givenOrders: english?.[0].completed_orders_received_by_customers,
    },
    german: {
      translatedPages: prettyNumber(
        german?.[0]?.translated_pages_day_no_adjust
      ),
      receivedOrders: german?.[0].received_orders,
      receivedPages: german?.[0].received_pages - Number(german?.[0].sent_pages_since_yesterday),
      pagesToTranslate: german?.[0].pages_to_translate,
      pagesToTranslateEditor: germanSum,
      givenOrders: german?.[0].completed_orders_received_by_customers,
    },
    other: {
      receivedOrders: other
        ?.map((i) => i.received_orders)
        ?.reduce((a, b) => a + b, 0),
      receivedPages: other
        ?.map((i) => i.received_pages)
        ?.reduce((a, b) => a + b, 0),
      givenOrders: other
        ?.map((i) => i.completed_orders_received_by_customers)
        ?.reduce((a, b) => a + b, 0),
    },
    all: {
      receivedOrders: data
        ?.map((i) => i.received_orders)
        ?.reduce((a, b) => a + b, 0),
      receivedPages: data
        ?.map((i) => i.received_pages)
        ?.reduce((a, b) => a + b, 0),
      //-data?.map((i) => i.sent_pages)?.reduce((a, b) => a + b, 0),
      givenOrders: data
        ?.map((i) => i.completed_orders_received_by_customers)
        ?.reduce((a, b) => a + b, 0),
      customerTypes: {
        institutions: data
          ?.map((i) => i.received_orders_institutions)
          ?.reduce((a, b) => a + b, 0),
        mail: data
          ?.map((i) => i.received_orders_email)
          ?.reduce((a, b) => a + b, 0),
        person: data
          ?.map((i) => i.received_orders_person)
          ?.reduce((a, b) => a + b, 0),
      },
      orderless: {
        chars: orderless?.[0].chars,
        pages: Math.round(orderless?.[0].pages),
        documents: orderless?.[0].documents,
      }
    },
  };

  console.log("received_pages", english?.receivedPages)

  return (
    <div className="fullScreen executive-report-wrap">
      <div className="top-bar" style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "max-content",
          }}
        >
          <button
            onClick={() =>
              setSelectedDate((prev) => {
                const date = new Date(prev);
                date.setDate(date.getDate() - 1);
                if (date.getDay() === 6) {
                  date.setDate(date.getDate() - 1);
                }
                if (date.getDay() === 0) {
                  date.setDate(date.getDate() - 2);
                }
                return date.toISOString().slice(0, 10);
              })
            }
            style={{ width: "30px", padding: "5px", marginRight: "5px" }}
          >
            ◂
          </button>
          <input
            type="date"
            value={selectedDate}
            min="2023-07-03"
            max={new Date().toISOString().slice(0, 10)}
            onChange={(e) => {
              if (new Date(e.target)) {
                setSelectedDate(e.target.value);
              }
            }}
          />
          <button
            onClick={() =>
              setSelectedDate((prev) => {
                const date = new Date(prev);
                date.setDate(date.getDate() + 1);
                if (date.getDay() === 6) {
                  date.setDate(date.getDate() + 2);
                }
                if (date.getDay() === 0) {
                  date.setDate(date.getDate() + 1);
                }
                return date.toISOString().slice(0, 10);
              })
            }
            style={{ width: "30px", padding: "5px", marginLeft: "5px" }}
          >
            ▸
          </button>
        </div>

        <div style={{ fontSize: "15px" }}>
          {loading ? (
            <div>Generowanie danych...</div>
          ) : (
            <div className="executive-day-heading">
              {new Date(selectedDate)
                ?.toLocaleDateString("pl-PL", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })
                ?.toUpperCase()}
            </div>
          )}
        </div>
        <div>
          <button
            className="button"
            style={{ padding: "7px 24px" }}
            onClick={() =>
              setMode((prev) => (prev === "ugly" ? "beauty" : "ugly"))
            }
          >
            Zmień sposób prezentacji danych
          </button>
        </div>
      </div>
      {!loading ? (
        <div className="exec-layout">
          {mode === "ugly" ? (
            <ExecutiveDailyText data={fullData} translators={translators} />
          ) : (
            <ExecutiveDailyTable data={fullData} translators={translators} />
          )}
          <ExecutiveDailyOrderTable
            data={data}
            selectedDate={selectedDate}
            setEnglishSum={setEnglishSum}
            setGermanSum={setGermanSum}
          />
          {/* <ExecutivePageListingStatus data={data} selectedDate={selectedDate} />*/}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
