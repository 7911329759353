import { useContext } from "react";
import { SearchBoxContext } from "./SearchBoxContext";
import OrderItem from "./ResultTypes/OrderItem";
import editIcon from "../../../../../images/icons/icons8-edit-26.png";
import eraserIcon from "../../../../../images/icons/buttons/eraser.svg";

export default function FilledInput({ setActive, index }) {
  const context = useContext(SearchBoxContext);

  return (
    <div className="search-input filled-input" style={context.inputStyle}>
      {context.selectedItem?.id ? (
        <div className="filled-input-box">
          {context.selectedComponent({
            item: context.selectedItem,
            index: context.selectedItem?.index,
            detail: false,
            setActive: setActive,
          })}
          <button
            style={{ borderRadius: "10px" }}
            className="erase-button"
            onClick={() => {
              context.setSelectedItem(undefined);
            }}
          >
            <img src={eraserIcon} width="12" />
          </button>
        </div>
      ) : (
        <div className="filled-input-placeholder">
          <div
            className="filled-input-activate"
            onClick={() => setActive(true)}
          >
            Wybierz lub szukaj
          </div>
        </div>
      )}
    </div>
  );
}
