import React, { useContext } from "react";
import OrderCardTableRow from "./OrderCardTableRow";
import OrderCardBox from "./OrderCardBox";
import { orderViewParameters } from "../functions";

function OrderCard(props) {
  const { details, setDetails, params } = props;

  const item = props.item;

  const view = orderViewParameters(item);

  if (props.view === "table") {
    return (
      <OrderCardTableRow
        data={item}
        view={view}
        archive={false}
      ></OrderCardTableRow>
    );
  } else {
    return (
      <OrderCardBox
        data={item}
        view={view}
        details={details}
        params={params}
        setDetails={setDetails}
        dayInfo={props.dayInfo}
      />
    );
  }
}

export default OrderCard;
