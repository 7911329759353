import React, { useRef, useContext } from "react";
import DayButton from "../holidays/DayButton";
import { URL } from "../global";
import HolidaysContext from "../contexts/holidays-context.js";
import DatesGroup from "./DatesGroup.js";
import { processDates } from "../holidays/holidayFunctions";

function PersonDates(props) {
  const context = useContext(HolidaysContext);
  const {
    date,
    setChange,
    activeDates,
    setActiveDates,
    dayOffTypes,
    setContextParams,
    contextParams,
    setHoliday,
  } = context;
  const { heading, person, canControl } = props;

  const processedDates = processDates(date, activeDates, person);
  const datesPrepped =processedDates.datesPrepped;
  const aggregateGroups = processedDates.aggregateGroups;
  

  //console.log(groups, offGroupIndices, aggregateGroups, deleteIndices);

  return heading
    ? datesPrepped.map((item, index) => (
        <div
          className={
            item.dayClass +
            (item.publicHoliday || item.weekend === 0 ? " publicHoliday" : "")
          }
          key={index}
          title={"(" + item.dayName + ") " + item.holidayDescript}
        >
          <span className="dayNumber">{item.dayNumber}</span>
          <span className="dayNameShort">{item.dayNameShort}</span>
        </div>
      ))
    : aggregateGroups.map((group, groupIndex, groupArray) => {
        if (!group) return;
        
        return (
          <DatesGroup
            group={{ group, groupIndex, groupArray }}
            person={person}
            canControl={canControl}
          ></DatesGroup>
        );
      });
}

export default PersonDates;
