import { useContext } from "react";
import { TableContext } from "./TableUniversal";
import removeNumberKeys from "./functions/removeNumberKeys";
import Controls from "./components/Controls";

export default function Details() {
  const { setDetails, details, propMap, dataRaw } = useContext(TableContext);
  return (
    <div>
      <table style={{ width: "100%" }}>
        <Controls />
      </table>
      <div className="table-details">
        <div className="details-heading">
          <button onClick={() => setDetails(undefined)}>Powrót</button>
          <div>Szczegóły</div>

          <div className="details-browse">
            <button
              onClick={() => setDetails((prev) => (prev > 0 ? prev - 1 : prev))}
            >
              ◂
            </button>
            <button
              onClick={() =>
                setDetails((prev) =>
                  dataRaw && dataRaw[prev + 1] ? prev + 1 : prev
                )
              }
            >
              ▸
            </button>
          </div>
        </div>
        {dataRaw[details] !== undefined && details !== undefined ? (
          Object.keys(removeNumberKeys(dataRaw[details])).map((key) =>
            propMap && propMap[key] !== null ? (
              <div className="details-prop-group">
                <div className="details-prop-name" title={key}>
                  {propMap ? propMap[key] : key}
                </div>
                <div className="details-prop-value">
                  {dataRaw[details][key]}
                </div>
              </div>
            ) : (
              <div className="details-prop-group">
                <div className="details-prop-name" title={key}>
                  {propMap ? propMap[key] : key}
                </div>
                <div className="details-prop-value">
                  {dataRaw[details][key]}
                </div>
              </div>
            )
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
