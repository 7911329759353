import React, { useState, useEffect } from "react";
import { URL } from "./global";
import genericCaseMap from "./genericCaseMap";

export const useGeneric = (params) => {
  const [fetched, setFetched] = useState({
    loading: true,
    timePassed: 0,
  });

  const getFetched = async (params, signal) => {
    // name: db name to access
    const address = genericCaseMap(params.name);

    if (address && !params.stall) {
      // try to fetch from DB
      console.time("time");
      try {
        const response = await fetch(URL + "components/" + address, {
          credentials: "include",
          signal: AbortSignal.timeout(25000),
          method: "POST",
          body: JSON.stringify(params.query),
          // signal,
        });
        const result = await response.json();
        return result;
      } catch (error) {
        console.log("Błąd pobierania: " + error);
        return false;
      } finally {
        console.timeEnd("time");
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (fetched?.loading === true) {
      const i = window.setInterval(
        () =>
          setFetched((prev) => ({
            ...prev,
            timePassed: prev?.timePassed + 1,
          })),
        1000
      );
      return () => clearInterval(i);
    }
  }, [fetched?.loading]);

  async function update(params, signal) {
    if (params.disable === true) {
      return;
    }
    const data = await getFetched(params, signal);
    if (data) {
      setFetched((prev) => ({
        ...data,
        loading: false,
      }));
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    let reloader;

    if (params.reload) {
      reloader = setInterval(() => {
        update(params, signal);
      }, params.reload * 1000);
    }

    if (!params.noRefresh && !params.updateAll) {
      setFetched((prev) => ({
        ...prev,
        loading: true,
      }));
    }

    update(params, signal);
    return () => {
      controller.abort();
      if (params.reload) {
        clearInterval(reloader);
      }
    };
  }, [params.update, params.reload, params.updateAll, params.name]);

  //useEffect(() => update(params), [params.updateAll]);

  return fetched;
};
