import { CSSProperties } from "react";

export default function FieldButtons({
  item,
  buttons,
  style
}: {
  item: any;
  buttons: any;
  style?:CSSProperties;
}) {
  return buttons ? (
    <div style={style} className="field-buttons">
      {buttons(item).map((button: any) => (
        <div className="field-button">{button}</div>
      ))}
    </div>
  ) : (
    <></>
  );
}
