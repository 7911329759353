import { Link, useLocation } from "react-router-dom";
import { useGeneric } from "../useGeneric";
import { useEffect, useState } from "react";
import Document from "./documentEditor/Document";

export default function DocumentEditor() {
  const l = useLocation();
  const id = l.pathname.split("/").at(-1);
  const [edit, setEdit] = useState(false);
  const [hasBlank, setHasBlank] = useState(false);
  const [updates, setUpdates] = useState(0);
  const loadOrder = useGeneric({
    name: "orders",
    query: { id: id },
    reload: 60,
    update: id + updates,
  });
  const order = !loadOrder.loading ? loadOrder.rows?.at(-1) : undefined;

  const loadCustomDocuments = useGeneric({
    name: "customDocuments",
    query: { order: id },
    reload: 15,
    update: id + updates,
  });

  let customDocuments = !loadCustomDocuments.loading
    ? loadCustomDocuments.rows
    : undefined;

  customDocuments = customDocuments
    ? customDocuments.map((item) => ({ ...item, custom: true }))
    : undefined;

  let systemDocuments = order
    ? JSON.parse("[" + order.documents + "]")
    : undefined;

  const documents =
    customDocuments && systemDocuments
      ? [...systemDocuments, ...customDocuments]
      : systemDocuments
      ? systemDocuments
      : [];

  const number = order ? order.number.split("/") : undefined;

  return (
    <div className="documentEditor">
      <div className="orderData">
        <div className="id">
          <Link to={"./" + (Number(id) - 1)}>◂</Link>
          {order ? order.id : id}
          <Link to={"./" + (Number(id) + 1)}>▸</Link>
        </div>
        {order ? (
          <>
            <div>
              <div className="number">
                {number[0]}.{number[1]}.{number[2]}.
                {order.mainLang.toLowerCase()}
              </div>
            </div>
            <div className="client">{order.client}</div>
            <div className="lang">
              <div className="langFrom">{order.from}</div>
              <div>{" > "}</div>
              <div className="langTo">{order.to}</div>
              <div className="pages">{order.pages} str</div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {order ? (
        <div className="documentData">
          <table className="dataTable docEdit">
            <thead>
              <th className="controls"></th>
              <th>Lp</th>
              <th style={{ width: "60%" }}>Nazwa</th>
              <th>Rodzaj</th>
              <th>Liczba stron</th>
              <th>Tłumacze</th>
            </thead>
            <tbody>
              {documents
                .filter((doc) => doc.id)
                .map((doc, index) => (
                  <Document
                    doc={doc}
                    order={order}
                    key={doc.id}
                    index={index}
                    edit={edit === doc.id}
                    setEdit={setEdit}
                    hasBlank={hasBlank === doc.id}
                    setHasBlank={setHasBlank}
                    setUpdates={setUpdates}
                  />
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
