import { useEffect, useState } from "react";
import { URL } from "../global";

async function getOfficeCheck() {
  const response = await fetch(
    URL + "components/get_foreign/in_office_check.php",
    { credentials: "include", method: "GET" }
  );
  const result = await response.json();
  return result;
}

export default function useOfficeCheck() {
  const [inOffice, setInOffice] = useState({ loading: true });

  useEffect(() => {
    getOfficeCheck().then((r) =>
      setInOffice((prev) => ({ ...prev, ...r, loading: false }))
    );
  }, []);

  //console.log(inOffice);
  return inOffice;
}
