import { useContext } from "react";
import { TableContext } from "../TableUniversal";

export default function Cell({ data }) {
  const { dictionary, setSearch, setUpdate } = useContext(TableContext);

  return (
    <td style={data?.style} title={data?.title ? data.title.jsx : undefined}>
      {data?.title?.present ? (
        <div className="cell-inner">
          {data.jsx ? data.jsx : <>-</>}
          {data?.title?.present ? <span className="more-info">?</span> : <></>}
        </div>
      ) : (
        <>{data.jsx ? data.jsx : <>-</>}</>
      )}
    </td>
  );
}
