import React from "react";
import { shortenLanguage, convertTimestampToDuration } from "./functions";
import TimesheetItemView from "./TimesheetItemView";
/*
Lp.	Data	Od	Do	Czynność	P	Nr zlecenia / opis	Klient	Rodzaj	Z	NA	Typ szablonu	Znaki ze spacjami		Czas	Zzs/h T	Zzs/h S		Suma minut
*/

function TimesheetTable(props) {
  const currentTimesheet = props.tsData;
  const [modal, setModal] = props.handleModal;
  return (
    <div
      className="current"
      style={{
        fontSize: "14px",
        display: "block",
        overflowY: "auto",
      }}
    >
      {currentTimesheet && (
        <table className="timesheetTable">
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Data</th>
              <th>Od</th>
              <th>Do</th>
              <th>Czynność</th>
              <th>P</th>
              <th>Nr zlec.</th>
              <th>Klient</th>
              <th>Rodzaj</th>
              <th>Z</th>
              <th>Na</th>
              <th>Szablon</th>
              <th title="ze spacjami">Zzs</th>
              <th>Czas</th>
              <th>Zn./h</th>
            </tr>
          </thead>
          <tbody>
            {currentTimesheet &&
              currentTimesheet
                .map((item, index) => {
                  let startTimeObj = new Date(item.startTimeObject);
                  let endTimeObj = item.endTimeObject
                    ? new Date(item.endTimeObject)
                    : null;
                  let timeString = "-";
                  let time = 0;
                  let charsPerHour = "";
                  if (startTimeObj && endTimeObj) {
                    time = endTimeObj.getTime() - startTimeObj.getTime();

                    charsPerHour =
                      item.characters && time > 0
                        ? Math.round(
                            parseInt(item.characters) / (time / 1000 / 60 / 60)
                          )
                        : "";
                    timeString = convertTimestampToDuration(
                      endTimeObj.getTime() - startTimeObj.getTime()
                    );
                  }

                  return (
                    <tr
                      key={index + 1}
                      onClick={() =>
                        setModal({
                          show: true,
                          type: "Podgląd wpisu",
                          content: (
                            <TimesheetItemView item={item}></TimesheetItemView>
                          ),
                        })
                      }
                    >
                      <td title={"user=" + item.user + " id=" + item.id}>
                        {index + 1}
                      </td>
                      <td>
                        {item.date && item.dateObject.toLocaleDateString()}
                      </td>
                      <td title={item.startTimeObject}>
                        {item.startTime &&
                          item.startTimeObject
                            .toLocaleTimeString()
                            .slice(0, -3)}
                      </td>
                      <td title={item.endTimeObject}>
                        {item.endTime &&
                          item.endTimeObject.toLocaleTimeString().slice(0, -3)}
                      </td>
                      <td title={item.comments}>{item.typeString}</td>
                      {item.type === "10" ||
                      item.type === "4" ||
                      item.type === "11" ||
                      item.type === "12" ? (
                        <>
                          <td colSpan="10" className="commentsCell">
                            {item.comments}
                          </td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td style={{ fontSize: "120%" }}>
                            {item.orderString}
                          </td>
                          <td className="customer" title={item.customers_name}>
                            {item.order.customers_name}
                          </td>
                          <td></td>
                          <td className="langfromTD">
                            {shortenLanguage(item.order.language_from)}
                          </td>
                          <td className="langtoTD">
                            {shortenLanguage(item.order.language_to)}
                          </td>
                          <td></td>
                          <td>{item.characters}</td>
                          <td title={time / 1000 / 60 / 60}>{timeString}</td>
                          <td>{charsPerHour}</td>
                        </>
                      )}
                    </tr>
                  );
                })
                .reverse()}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TimesheetTable;
