import React, { useState, useEffect } from "react";
import useExternals from "../../useExternals";
import Spreadsheet from "../Spreadsheet";
import SpreadsheetForm from "../SpreadsheetForm";
import { URL } from "../../global";
import externalsTable from "./data";
import { readableNumberPL } from "../../functions";
import peopleIcon from "../../../images/icons/icons8-head-profile-64.png";
import settingsIcon from "../../../images/icons/icons8-settings-80.png";
import TranslatorsEditor from "./TranslatorsEditor";
import exportToExcel from "../../main/exportToExcel";
import xlsIcon from "../../../images/icons/xls.svg";

function Externals(props) {
  const { translators, orders, user, setUpdateTranslators, updateTranslators } =
    props;
  const [updates, setUpdates] = useState(0);
  const externalTranslators = translators.active.filter(
    (t) => parseInt(t.Type) === 2 || parseInt(t.Type) === 0
  );
  const [modal, setModal] = props.handleModal;
  const now = new Date();
  const years = Array.from(
    { length: 5 },
    (v, k) => k + ([now.getFullYear()] - 5) + 1
  );
  console.log(
    window.localStorage.getItem("externals_year"),
    window.localStorage.getItem("externals_translator_id")
  );
  const [selectedTranslator, setSelectedTranslator] = useState(null);
  const [params, setParams] = useState(() => {
    const year = window.localStorage.getItem("externals_year");
    return {
      year: year ? year : now.getFullYear(),
      month: now.getMonth(),
      translator_id: false,
    };
  });
  const [attributes, setAttributes] = useState({
    ascending: false,
  });

  const tableSchema = externalsTable(orders, selectedTranslator, user.name);

  const calculables = {
    amount: (obj) =>
      Math.round(
        (parseInt(obj.characters) / selectedTranslator["RateUnit"]) *
          parseFloat(obj.rate) *
          100
      ) / 100,
    //order_customer: (obj) => obj.order_number.split(" ").slice(1).join(" ")
  };

  useEffect(() => {
    if (params.translator_id) {
      window.localStorage.setItem(
        "externals_translator_id",
        params.translator_id
      );
    }
    window.localStorage.setItem("externals_year", params.year);
  }, [params.translator_id, params.year]);

  useEffect(() => {
    setSelectedTranslator(
      externalTranslators.filter((tr) => tr.id == params.translator_id)[0]
    );
  }, [params.translator_id]);

  useEffect(() => {
    const trans_id = window.localStorage.getItem("externals_translator_id");
    if (externalTranslators && externalTranslators.length) {
      setParams((prev) => ({
        ...prev,
        translator_id: trans_id
          ? externalTranslators.filter((tr) => tr.id == trans_id)[0]?.id
          : externalTranslators?.[0].id,
      }));
      //setSelectedTranslator(externalTranslators?.[0]);

      setUpdates((prev) => prev + 1);
    }
  }, [externalTranslators.length]);

  useEffect(() => {
    const interval = setInterval(() => setUpdates((prev) => prev + 1), 60000);
    return () => clearInterval(interval);
  }, [updates]);

  const currentData = useExternals(params, updates, attributes);

  function submitData(e, formData) {
    e.preventDefault();
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "add", query: formData }),
    }).then((response) => response.json());
    setUpdates((prev) => prev + 1);
  }
  function editData(e, formData) {
    e.preventDefault();
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "edit", query: formData }),
    }).then((response) => response.json());
    setUpdates((prev) => prev + 1);
  }

  function deleteRow(e, id) {
    e.preventDefault();
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "remove", query: { id: id } }),
    }).then((response) => response.json());
    setUpdates((prev) => prev + 1);
  }

  function acceptRow(e, data) {
    e.preventDefault();
    if (data.accept === "1") data.accept = 0;
    else if (data.accept === "0") data.accept = 1;
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "accept", query: data }),
    }).then((response) => response.json());
    setUpdates((prev) => prev + 1);
  }

  function settleRows(e, data) {
    e.preventDefault();
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        queryType: "settleRows",
        query: {
          ids: data.map((i) => ({ id: parseInt(i.id) })),
          settled: 1,
        },
      }),
    }).then((response) => response.json());
    setUpdates((prev) => prev + 1);
  }

  function editCell(e, data) {
    e.preventDefault();
    fetch(URL + "components/db-externals.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ queryType: "edit", query: data }),
    }).then((response) => {
      setUpdates((prev) => prev + 1);
      return response.json();
    });

    //console.log(item);
  }

  return (
    <div className="externals spreadsheetWrapper">
      <div className="top-bar">
        <div className="selectMy">
          <button
            className="standardButton newEntry"
            onClick={() =>
              setModal({
                show: true,
                type: "Nowy wpis",
                content: (
                  <SpreadsheetForm
                    dataStructure={tableSchema}
                    calculables={calculables}
                    params={params}
                    submitData={submitData}
                  />
                ),
              })
            }
          >
            +
          </button>
          <button
            className="standardButton fine"
            onClick={() => {
              setParams((prev) => {
                const currentIndex = externalTranslators.findIndex(
                  (tr) => tr.id === prev.translator_id
                );
                return {
                  ...prev,
                  translator_id:
                    currentIndex - 1 > -1
                      ? externalTranslators[currentIndex - 1].id
                      : externalTranslators[externalTranslators.length - 1].id,
                };
              });
            }}
          >
            {"◀"}
          </button>
          <select
            value={params.translator_id}
            onChange={(e) => {
              setParams((params) => ({
                ...params,
                translator_id: parseInt(e.target.value),
              }));
            }}
          >
            {externalTranslators?.map((translator, index) => (
              <option key={index} value={translator.id}>
                {translator["First Name"]} {translator["Last Name"]}{" "}
                {translator.count ? "(" + translator.count + ")" : ""}
              </option>
            ))}
          </select>
          <button
            className="standardButton fine"
            onClick={() => {
              setParams((prev) => {
                const currentIndex = externalTranslators.findIndex(
                  (tr) => tr.id === prev.translator_id
                );
                return {
                  ...prev,
                  translator_id:
                    currentIndex + 1 < externalTranslators.length
                      ? externalTranslators[currentIndex + 1].id
                      : externalTranslators[0].id,
                };
              });
            }}
          >
            {"▶"}
          </button>
          {/* <select
            value={params.month}
            onChange={(e) =>
              setParams((params) => ({ ...params, month: e.target.value }))
            }
          >
            {[
              { id: 0, name: "styczeń" },
              { id: 1, name: "luty" },
              { id: 2, name: "marzec" },
              { id: 3, name: "kwiecień" },
              { id: 4, name: "maj" },
              { id: 5, name: "czerwiec" },
              { id: 6, name: "lipiec" },
              { id: 7, name: "sierpień" },
              { id: 8, name: "wrzesień" },
              { id: 9, name: "październik" },
              { id: 10, name: "listopad" },
              { id: 11, name: "grudzień" },
            ].map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
            </select>*/}
          {/*
          <input type="date" name="start_date"/>
          <input type="date" name="end_date"/>
            <div>Filtruj</div>*/}
          <button
            className="standardButton fine"
            onClick={() =>
              setParams((prev) => ({
                ...prev,
                year:
                  prev.year - 1 < years[0]
                    ? years[years.length - 1]
                    : prev.year - 1,
              }))
            }
          >
            {"◀"}
          </button>
          <select
            value={params.year}
            onChange={(e) =>
              setParams((params) => ({ ...params, year: e.target.value }))
            }
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <button
            className="standardButton fine"
            onClick={() =>
              setParams((prev) => ({
                ...prev,
                year:
                  prev.year + 1 > years[years.length - 1]
                    ? years[0]
                    : prev.year + 1,
              }))
            }
          >
            {"▶"}
          </button>
          <button
            className="standardButton editButton"
            style={{ width: "50px" }}
            onClick={() =>
              setAttributes((prev) => ({ ...prev, ascending: !prev.ascending }))
            }
          >
            <span>
              {attributes.ascending ? (
                <>
                  <span style={{ fontSize: "130%" }}>↑</span>
                </>
              ) : (
                <>
                  <span style={{ fontSize: "130%" }}>↓</span>
                </>
              )}
            </span>
          </button>
          <button
            className="standardButton editButton"
            onClick={() => {
              let rows = currentData.slice().map((i) => ({
                "Data przyjęcia": { v: i.accept_date, t: 'd' },
                "Nr zlecenia": { v: i.order_number, t: 's' },
                Klient: { v: i.order_customer, t: 's'},
                "Liczba znaków": {v: i.characters, t: 'n'},
                Kwota: {v: i.amount, t: 'n'},
                Stawka: {v: i.rate, t: 'n'},
                "Data dodania wpisu": {v: i.added, t: 'd'},
              }));
              rows = attributes.ascending ? rows : rows.reverse();
              rows.push({
                Kwota: {
                  t: "n",
                  f: "=SUM(E2:E" + (rows.length + 1) + ")",
                },
                "Liczba znaków": {
                  t: "n",
                  f: "=SUM(D2:D" + (rows.length + 1) + ")",
                },
              });
              
              exportToExcel({
                fileName:
                  selectedTranslator["Last Name"] + ", " + selectedTranslator["First Name"] + " (rozliczenie) " + params.year,
                workBookName: params.year + " - " + selectedTranslator["First Name"] + " " + selectedTranslator["Last Name"],
                rows: rows,
                colStyles: [
                  { width: 12 },
                  { width: 15 },
                  { width: 40 },
                  { width: 12 },
                  { width: 12 },
                  { width: 12 },
                  { width: 15 },
                ],
                colFormatData: {
                  columns: [4, 5],
                  string: "0.00 zł",
                },
              })
            }}
          >
            <img src={xlsIcon}/>
            <span>Eksport</span>
          </button>
          <button
            className="standardButton editButton"
            onClick={() =>
              setModal({
                show: true,
                type: "Katalog tłumaczy i stawek bazowych",
                content: (
                  <TranslatorsEditor
                    updateTranslators={updateTranslators}
                    translators={translators}
                    setUpdateTranslators={setUpdateTranslators}
                  ></TranslatorsEditor>
                ),
              })
            }
          >
            <img src={peopleIcon} />
            <span>Tłumacze</span>
          </button>
          
          <button
            className="standardButton editButton"
            onClick={() =>
              setModal({
                show: true,
                type: "Ustawienia",
                content: (
                  <div style={{ padding: "10px 10px", fontSize: "13px" }}>
                    <p>Brak dostępnych ustawień. </p>
                    <p>Mogą pojawić się po aktualizacji.</p>
                  </div>
                ),
              })
            }
          >
            <img src={settingsIcon} />
            <span>Ustawienia</span>
          </button>
        </div>

        <div className="sum">
          {currentData && currentData.length ? (
            readableNumberPL(
              currentData
                //.filter(item => !parseInt(item.settled))
                .map((item) => item.characters)
                .reduce((a, b) => parseInt(a) + parseInt(b))
            ) +
            " " +
            selectedTranslator["RateUnitName"] +
            " / " +
            readableNumberPL(
              Math.round(
                currentData
                  //.filter(item => !parseInt(item.settled))
                  .map((item) => item.amount)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b)) * 100
              ) / 100
            ) +
            " zł"
          ) : (
            <></>
          )}
        </div>
      </div>
      <Spreadsheet
        dataStructure={tableSchema}
        calculables={calculables}
        data={currentData}
        deleteRow={deleteRow}
        editData={editData}
        acceptRow={acceptRow}
        options={{
          numberColumn: false,
          deleteRows: true,
          acceptRows: true,
        }}
        params={params}
        footer={(selected) => {
          const selectedFiltered = selected.filter(
            (item) => item.settled === "0"
          );
          const sumChars =
            selected && selected.length
              ? selected
                  .map((item) => parseInt(item.characters))
                  .reduce((a, b) => a + b)
              : 0;
          const sumAmount =
            selected && selected.length
              ? Math.round(
                  selected
                    .map((item) => parseFloat(item.amount))
                    .reduce((a, b) => a + b) * 100
                ) / 100
              : 0;

          return (
            <div className="spreadsheetFooter">
              <div className="sum">
                {selected && selected.length ? (
                  selected.length +
                  " zlec. / " +
                  readableNumberPL(sumChars) +
                  " " +
                  selectedTranslator["RateUnitName"] +
                  " / " +
                  readableNumberPL(sumAmount) +
                  " zł"
                ) : (
                  <></>
                )}
              </div>
              {selected &&
              selected.length &&
              !selected.some((i) => i.settled == "1") ? (
                <div>
                  <button
                    className="calculate"
                    onClick={() => {
                      setModal({
                        show: true,
                        type: "Rozlicz",
                        width: "full",
                        content: (
                          <div>
                            <div className="extOrderList">
                              {selected.map((item, index) => (
                                <div key={item.id}>
                                  <span style={{ color: "grey" }}>
                                    {index + 1}.
                                  </span>
                                  <span>{item.order_number}</span>
                                  <span>{item.order_customer}</span>
                                  <span>
                                    {readableNumberPL(item.characters)}{" "}
                                    {selectedTranslator["RateUnitName"]}
                                  </span>
                                  <span>
                                    {readableNumberPL(item.rate)} zł/
                                    {selectedTranslator["RateUnit"]}{" "}
                                    {selectedTranslator["RateUnitName"]}
                                  </span>
                                  <span>
                                    {String(item.amount).replace(".", ",")} zł
                                  </span>
                                </div>
                              ))}
                            </div>

                            <div className="extSummary">
                              <div>
                                <label>Liczba zleceń</label>
                                <div>{selected.length}</div>
                              </div>
                              <div>
                                <label>Ilość:</label>
                                <div>
                                  {readableNumberPL(sumChars)}{" "}
                                  {selectedTranslator["RateUnitName"]}
                                </div>
                              </div>
                              <div>
                                <label>Suma do zapłaty:</label>
                                <div>{readableNumberPL(sumAmount)} zł</div>
                              </div>
                            </div>
                            <button
                              className="extButton"
                              onClick={(e) => settleRows(e, selected)}
                            >
                              Ustaw jako rozliczone
                            </button>
                          </div>
                        ),
                      });
                    }}
                  >
                    Rozlicz
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}

export default Externals;
