import { useState } from "react";

export default function ActionType({ v }) {
  return (
    <div
      className="TSactionType"
      style={{ background: v.color }}
      //onClick={() => set({ id: 100, short: "x", color: "red" })}
    >
      {v.id ? (
        <div>{v.short}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
