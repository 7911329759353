export default function DatePick({date,set,update = undefined}) {
   
  return (
    <div className="datePicker">
      <button
        className="regularButton increment"
        onClick={() => {
          set((prev) => {
            const d = new Date(prev);
            d.setDate(d.getDate() - 1);
            return d;
          });
          if(update) { update((prev) => prev + 1); }
        }
        }
      >
        ◂
      </button>
      <input
        className="datePick"
        style={{ marginRight: "12px" }}
        type="date"
        value={date.toISOString().slice(0, 10)}
        onChange={(e) => {
          //console.log(new Date(e.target.value));
          set(() => new Date(e.target.value));
          if(update) { update((prev) => prev + 1); }
        }}
      />
      <button
        className="regularButton increment"
        onClick={() => {
          set((prev) => {
            const d = new Date(prev);
            d.setDate(d.getDate() + 1);
            return d;
          });
          if(update) { update((prev) => prev + 1); }
        }
        }
      >
        ▸
      </button>
    </div>
  );
}
