import { useEffect, useState } from "react";
import { URL } from "./global";

async function getAttendance(params) {
    const response = await fetch(URL + "components/get_foreign/get-attendance-latest.php", { method: "POST", credentials:"include", body: JSON.stringify(params) });
    const result = await response.json();
    return result;
}

/*
export async function updateDevice() {
    const response = await fetch(URL + "components/get_foreign/update-devices.php", { method: "POST", credentials:"include" });
    const result = await response.json();
    return result;
}*/

export default function useAttendance(params) {
    const [attendance,setAttendance] = useState({ loading:true });

    useEffect(() => {
        //updateDevice();
        getAttendance(params).then((result) => {
            setAttendance({...result, loading: false})
        });
        
        const interval = setInterval(() => getAttendance(params).then((result) => {
           // updateDevice() 
            setAttendance({...result, loading: false});
        }), 30000);
        return () => clearInterval(interval);
    }, [])
    return attendance;
}