import { useContext } from "react";
import AppContext from "../../contexts/app-context";
import InvoiceView from "../instances/newExternals/InvoiceView";

export default function NumberInvoice({
  id,
  number,
  content,
  style,
  clickable = true,
}) {
  const modal = useContext(AppContext);

  return (
    <div
      onClick={() => {
        if (clickable) {
          modal.setModal({
            show: true,
            type: "Faktura",
            width: "superwide",
            content: <InvoiceView inModal={true} id={id} number={number} />,
          });
        }
      }}
      className="clickable-details"
      style={{ cursor: "pointer", ...style }}
      title={id}
    >
      {content ? content : <div>{number}</div>}
    </div>
  );

  /*return (
      <Link to={`../order-view?id=${data.id}`}>
        <div title={data.id}>{data.number_order.replaceAll("/", ".")}</div>
      </Link>
    );*/
}
