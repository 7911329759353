import React, { useContext } from "react";
import { isToday, shortenLanguage } from "../functions";
import docIcon from "../../images/icons/icons8-document-24.png";
import GoogleDriveLink from "./GoogleDriveLink";
import MailLink from "./MailLink";
import OrdersOtherLink from "./OrdersOtherLink";
import OrderContext from "../contexts/order-context";
import FullScreenToggle from "./FullScreenToggle";

function OrderCardTableRow(props) {
  const { data: item, view, archive } = props;

  const pages = item.docPages ? item.docPages : item.pagesNum;
  const pagesString = String(pages).replace(".", ",") + " str.";

  const context = useContext(OrderContext);

  return (
    <div
      className={"orderCardTableRow" + (archive ? " " + archive : "")}
      title={item.id}
      data-key={item.id}
      key={item.id}
    >
      {archive ? (
        <div>
          <span className="orderHour">
            {item.orderDateJS.toLocaleDateString()}
          </span>
        </div>
      ) : (
        <div className="tableOrderHourBox">
          <span className="orderHour">{item.orderHour.split(":")[0]}</span>
          <span className="orderHourMinutes">
            {item.orderHour.split(":")[1]}
          </span>
        </div>
      )}
      <div>
        <div className="fullNumberGroup">
          <span className="orderNumber">{item.orderNumber}</span>.
          <span className="orderMonth">{item.orderMonth}</span>.
          <span className="orderYear">{item.orderYear}</span>
          <div className="orderLanguage">
            <span className="littleSign">➤</span>{" "}
            {shortenLanguage(item.language_to)}
          </div>
        </div>
      </div>

      {archive !== "customer" ? (
        <>
          <div>{item.customers_name}</div>
        </>
      ) : (
        <></>
      )}
      <div className="orderTableRowDocuments">
        <div>
          {Array.from([
            ...new Set(item.documents.map((doc) => doc.type_of_translation)),
          ]).join(", ")}
        </div>
        <div title={item.documents.length + " dok."}>
          {item.documents.map((doc, index) => (
            <div
              className="orderTableRowDocumentCards"
              title={doc.name ? doc.name : doc.document_name}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                  marginLeft: "-2px",
                  paddingRight: "0",
                  fontSize: "80%",
                  boxShadow: "2px 2px 3px -2px grey",
                }}
              >
                
                <img
                  style={{ width: "12px", padding: "2px" }}
                  src={docIcon}
                ></img>
              </div>
              {doc.name ? doc.name : doc.document_name}
            </div>
          ))}
        </div>
      </div>
      {archive !== "customer" && (
        <div className="orderPrice">{item.grossPrice}</div>
      )}
      <div>{pagesString}</div>
      {archive !== "customer" ? (
        <div className="orderOptions">
          <GoogleDriveLink orders={[item]}></GoogleDriveLink>
          <MailLink item={item}></MailLink>
          <OrdersOtherLink
            item={item}
            view={view}
            context={context}
          ></OrdersOtherLink>
          <FullScreenToggle
            setModal={context.setModal}
            item={item}
          ></FullScreenToggle>
        </div>
      ) : (
        <div className="orderOptions">
          <GoogleDriveLink orders={[item]}></GoogleDriveLink>
        </div>
      )}
    </div>
  );
}

export default OrderCardTableRow;
