import React, { useState } from "react";
import { getWeekendOffsets } from "../functions";
import { filterOrdersByDateOffset } from "../functions";

function SwornPages(props) {
  const orders = props.data;
  const [daysFromToday, setDaysFromToday] = useState(0);

  const filtered = filterOrdersByDateOffset(orders, daysFromToday, "past").orders;
  console.log(filtered);

  function handleChange(newRange) {
    setDaysFromToday(newRange);
  }

  return (
    <div style={{ padding: "15px", textAlign: "center" }}>
      <select
        value={daysFromToday}
        onChange={(event) => handleChange(event.target.value)}
      >
        <option value={0}>Dziś do końca dnia</option>
        <option value={1}>Na dziś i jeszcze jeden dzień</option>
        <option value={2}>Na dziś i jeszcze 2 dni</option>
        <option value={3}>Na dziś i jeszcze 3 dni</option>
        <option value={4}>Na dziś i jeszcze 4 dni</option>
      </select>
      <h1>
        {filtered && filtered.length ? (
          Math.ceil(
            parseFloat(
              (filtered
                .map((item) => item.docPagesSworn)
                .reduce((a, b) => a + b, 0) *
                1.2) /
                2
            )
          )
        ) : (
          <></>
        )}
      </h1>
      (w tym +20% zapasu)
    </div>
  );
}

export default SwornPages;
