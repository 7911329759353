import TableUniversal from "../../TableUniversal";
import HTMLReactParser from "html-react-parser";
import { customerDetailsMap } from "./maps/customerDetailsMap";
import { useContext } from "react";
import AppContext from "../../../contexts/app-context";
import menuCustomers from "./menuCustomers";

export default function CustomerDetailsTable() {
  const { modal, setModal } = useContext(AppContext);
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Informacje o klientach"
        classNames="big"
        data="customer-details-table"
        dictionary={dictionary(setModal)}
        createMap={customerDetailsMap}
        options={{
          editable: true,
        }}
        orderBy="name"
        orderDesc="ASC"
        customMenu={menuCustomers}
      />
    </div>
  );
}

const parse = HTMLReactParser;

const dictionary = (setModal) => ({
  name: {
    name: "Nazwa",
    style: {
      width: "50%"
    },
    transform: (v, a) => (
      <div title={a.customer_id}>
        <div>{v}</div>
        <div style={{ color: "grey", fontSize: "11px" }}>{a.symbol}</div>
      </div>
    ),
  },

  /*rates: {
    name: "Stawki",
    transform: (v) => v ? <div style={{padding:"5px", maxHeight:"50px", overflowY:"auto"}}>{parse(v)}</div> : <></>,
  },
  description: {
    name: "Opis",
    transform: (v) => v ? parse(v) : <></>,
  },
  invoice: {
    name: "Faktura",
    transform: (v) => v ? parse(v) : <></>,
  },*/
  description: {
    name: "Opis",
    style: {
      width: "180px",
      padding: 0,
    },
    transform: (v, a) =>
      v || a.rates || a.invoice ? (
        <button
          style={{ borderRadius: "5px", height: "25px" }}
          onClick={() => {
            setModal({
              show: true,
              type: a.name,
              content: (
                <div className="customer-details-in-editor">
                  {a.rates && <div>{parse(a.rates)}</div>}
                  {a.description && <div>{parse(a.description)}</div>}
                  {a.invoice && <div>{parse(a.invoice)}</div>}
                </div>
              ),
            });
          }}
        >
          Czytaj...
        </button>
      ) : (
        <div style={{textAlign:"center"}}>Brak</div>
      ),
  },
  last_update: {
    name: "Zm.",
    style: {
      width: "180px",
      textAlign: "center",
    },
  },
});
