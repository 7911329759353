import { useContext } from "react";
import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import AppContext from "../../../contexts/app-context";
import menuCustomers from "../newExternals/menuCustomers";
import customMenuItems from "../newExternals/customMenuItems";
import NumberPurchaseInvoice from "../../common/NumberPurchaseInvoice";

function years() {
  const year = new Date().getFullYear(); // 2023
  const startYear = 2012;
  const diff = year - startYear;
  const options = [""];
  for (let i = 0; i <= diff; i++) {
    options.push(year - i);
  }

  return options;
}

export default function PurchaseInvoicesTable() {
  const { userType } = useContext(AppContext);

  const onlyTranslators = userType !== 3; /// 3 - god mode admin

  return (
    <div className="fullScreen">
      <TableUniversal
        name="Faktury zakupowe"
        data="purchase-invoices"
        classNames="big"
        customMenu={customMenuItems}
        dictionary={dictionary}
        orderBy="id"
        orderDesc="DESC"
        searchPattern={{
          id_factures_categories: onlyTranslators
            ? { value: 9, style: { display: "none" } }
            : {
                label: "kategoria",
                default: "",
                element: "select",
                exact: true,
                value: "",
                options: {
                  wszystkie: "",
                  tłumacze: 9,
                  media: 6,
                  "prawno-podatkowe": 7,
                  "art.biurowe": 1,
                  "art. czystości": 2,
                  "auto - paliwo": 4,
                  "inf - komp": 5,
                  reklama: 8,
                  inne: 10,
                  kompensata: 11,
                  "hotel-bar": 12,
                  "hotel-kuchnia": 13,
                  "hotel-media": 14,
                  "hotel-ogólne": 15,
                  "hotel-spa": 16,
                  "hotel-nie licz": 17,
                },
              },
          seller: {
            label: onlyTranslators ? "Tłumacz" : "Sprzedający",
            value: "",
          },
          month: {
            label: "miesiąc",
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              styczeń: 1,
              luty: 2,
              marzec: 3,
              kwiecień: 4,
              maj: 5,
              czerwiec: 6,
              lipiec: 7,
              sierpień: 8,
              wrzesień: 9,
              październik: 10,
              listopad: 11,
              grudzień: 12,
            },
          },
          year: {
            label: "rok",
            value: "",
            element: "select",
            options: years()
              .reverse()
              .reduce((a, v) => ({ ...a, [v]: v }), {}),
            /*{
               
            }*/
          },
        }}
      />
    </div>
  );
}

const dictionary = {
  id: {
    name: "id",
  },
  factures_types_kind: {
    name: "typ",
    style: {
      fontSize: "10px",
    },
  },
  name: {
    name: "numer",
    transform: (v, a) => <NumberPurchaseInvoice id={a.id} number={v}/>
  },

  approve_date: {
    name: "data",
    style: {
      fontSize: "10px",
    },
    transform: (v) => prettyDate(v),
  },

  seller_name: {
    name: "Sprzedający",
    style: {
      width: "270px",
      padding: "0 12px",
      fontSize: "12px",
      fontWeight: "700",
    },
  },

  source_doc_name: {
    name: "nr dok. źr.",
    style: {
      fontSize: "10px",
    },
  },
  factures_categories_name: {
    name: "kat.",
    transform: (v, a) => (
      <div>
        <div>{v}</div>
        <div>{a.factures_subcategories_name}</div>
      </div>
    ),
  },

  net_value: {
    name: "netto",
  },
  gross_value: {
    name: "brutto",
  },
  currencies_symbol: {
    name: "wal.",
    style: {
      fontSize: "9px",
    },
  },

  status: {
    name: "status",
    style: {
      fontSize: "10px",
    },
  },
  approve_person: {
    name: "użytk.",
    transform: (v) => (
      <div title={v}>
        {String(v)?.split(" ")?.[0]?.[0]}
        {String(v)?.split(" ")?.[1]?.[0]}
      </div>
    ),
  },
  is_payed: {
    name: "zapł.",
    transform: (v) =>
      v === 1 ? (
        <div
          style={{
            fontSize: "11px",
            borderRadius: "50%",
            background: "#40c140",
            color: "white",
            fontWeight: "bold",
            width: "15px",
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          ✓
        </div>
      ) : (
        <>-</>
      ),
  },
};
