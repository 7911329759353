import { useEffect, useRef, useState } from "react";
import { shortenLanguage } from "../../functions";
import { URL } from "../../global";
import { minutesToTimeString } from "../WorkTimeView";
import NewItem from "./NewItem";
import ActionType from "./RowItems/ActionType";
import TextType from "./RowItems/TextType";
import NumberOrder from "../../tables/common/NumberOrder";

export function durationToString(duration) {
  if (!duration) return "";
  const t = duration.split(":");
  const hours = parseInt(t[0]);
  const minutes = parseInt(t[1]);
  return (
    <>
      {hours ? <>{hours} h</> : <></>}
      {minutes ? <> {minutes} min</> : <></>}
    </>
  );
}

export function durationToMinutes(duration) {
  const t = duration.split(":");
  const hours = parseInt(t[0]);
  const minutes = parseInt(t[1]);
  return minutes + hours * 60;
}

export function datestringToInputTime(dateString) {
  return dateString.split(" ")[1].slice(0, 5);
}

export default function Row({
  item,
  index,
  similarity,
  timeAcrossBreaks,
  updateData,
  user,
  showEdit,
  editProps,
}) {
  //editProps.showEdit = showEdit;
  const [updates, setUpdates] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [count, setCount] = useState(() =>
    item.count && item.count === "0.0"
      ? undefined
      : item.count.match(".0")
      ? parseInt(item.count)
      : item.count
  );
  const [unit, setUnit] = useState(item.unit);
  const [timeEdit, setTimeEdit] = useState(false);
  const [timeValue, setTimeValue] = useState(
    () => (item.start ? datestringToInputTime(item.start) : null)
    //item.start ? item.start.split(" ")[1].slice(0, 5) : null
  );
  const actionType = {
    id: item.action_type,
    name: item.action_type_string,
    short: item.action_type_short,
    color: item.action_type_color,
  };

  const timeEditor = useRef();

  async function updateEntry(params) {
    const response = await fetch(URL + "components/TS_update.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: item.id,
        ...params,
      }),
    });
    const result = await response.json();
    updateData((prev) => prev + 1);
    setUpdating(false);
  }

  useEffect(() => {
    if (updates === 0) return;
    setUpdating(true);
    const delay = setTimeout(() => {
      updateEntry({ count: count, unit: unit });
    }, 1500);
    return () => {
      clearTimeout(delay);
    };
  }, [count, unit]);

  useEffect(() => {
    if (updates === 0) return;
    setUpdating(true);
    updateEntry({
      startTime:
        new Date(item.start).toISOString().split("T")[0] + " " + timeValue,
    });
  }, [updates]);

  useEffect(() => {
    if (timeEdit) {
      timeEditor.current.focus();
    }
  }, [timeEdit]);

  /*useEffect(() => {
    if (updates === 0) return;
    setUpdating(true);
    const delay = setTimeout(
      () =>
        updateEntry({
          startTime: new Date().toISOString().split("T")[0] + " " + timeValue,
        }),
      1500
    );
    return () => clearTimeout(delay);
  }, [timeValue]);*/

  useEffect(() => {
    if (updates === 0) return;
    setUpdating(true);
    const delay = setTimeout(
      () =>
        updateEntry({
          actionType: actionType,
        }),
      1500
    );
    return () => clearTimeout(delay);
  }, [actionType]);

  async function removeEntry(id, makeEmpty) {
    const response = await fetch(URL + "components/TS_remove.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: id,
        makeEmpty: makeEmpty,
      }),
    });
    const result = await response.json();
    updateData((prev) => prev + 1);
    return result;
  }

  async function duplicateEntry() {
    const response = await fetch(URL + "components/TS_smart_duplicate.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        employeeID: user.employeeID,
        originalID: item.id,
        originalStart: item.start,
      }),
    });
    const result = response.json();
    updateData((prev) => prev + 1);
    return result;
  }

  async function addFromRow() {
    const now = new Date();
    //now.setMinutes(now.getMinutes() - 1);
    const response = await fetch(URL + "components/TS_insert.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        modify: false,
        params: {
          start:
            now.toISOString().split("T")[0] +
            " " +
            now.toTimeString().split(" ")[0],
          employeeID: user.employeeID,
          order_id: "",
          order_string: "",
          action_type: "",
          text_type: "",
          sworn: 0,
          document_id: "",
          document_name: "",
          customer_name: "",
          language_from: "",
          language_to: "",
          language_main: "",
          comment: "",
          unit: "zzs",
        },
      }),
    });
    const result = await response.json();
    await updateData((prev) => prev + 1);
    return result;
  }

  const itemOrderSplit = item.order_string
    ? item.order_string.split("/")
    : undefined;
  const itemOrderJSX = item.order_string ? (
    <>
      <span className="TSorderNumber">{itemOrderSplit[0]}.</span>
      <span className="TSorderMonth">{itemOrderSplit[1]}</span>.
      <span className="TSorderYear">{itemOrderSplit[2]}</span>
    </>
  ) : (
    <></>
  );
  //, showEdit: showEdit};

  return (
    <>
      {/* <span className="addRow"></span>*/}
      <div
        className={
          "TSrow" +
          (!item.customer_name ? " no-order" : "") +
          (!item.customer_name && item.action_type < 6 ? " no-order-chars" : "") +
          (similarity.value === item.first_id ? " similars" : "")
        }
        data-first={item.first_id}
        style={
          showEdit.id !== false && showEdit.id !== item.id
            ? { opacity: ".2" }
            : showEdit.id === item.id
            ? {
                zIndex: "3",
                background: "white",
                boxShadow: "0 0 10px rgba(0,0,0,.15)",
              }
            : {
                animationDelay: index / 25 + "s",
              }
        }
        key={item.id}
        onMouseOver={() => similarity.set(item.first_id)}
        onMouseOut={() => similarity.set()}
      >
        <div className="TSrowEdit"></div>
        <div className="TSrowTime" title={item.id}>
          {item.start ? (
            <span
              style={
                timeEdit ? { display: "none" } : {} /* updating
                  ? { opacity: ".3" }
                  : {}*/
              }
              onClick={() => setTimeEdit((prev) => !prev)}
            >
              {new Date(item.start).toLocaleTimeString("pl-PL", {
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
          ) : (
            <></>
          )}
          <div
            style={
              timeEdit
                ? {}
                : {
                    display: "none",
                  }
            }
            className="changeTime"
          >
            <input
              className="timeEdit"
              ref={timeEditor}
              type="time"
              value={timeValue}
              onChange={(e) => {
                setTimeValue(e.target.value);
              }}
              onClick={(e) => {
                setTimeValue(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  //setTimeValue(e.target.value);
                  setTimeEdit((prev) => !prev);
                  setUpdates((prev) => prev + 1);
                }
              }}
            />
            <div className="accept-or-reject">
              <button
                onClick={() => {
                  setTimeEdit((prev) => !prev);
                  setUpdates((prev) => prev + 1);
                }}
              >
                ✓
              </button>
              <button onClick={() => setTimeEdit((prev) => !prev)}>✖</button>
            </div>
          </div>
          {item.order_id ? (
            <NumberOrder
              id={item.order_id}
              number_order={item.order_string}
              withLang={true}
              content={<div className="smallInfo">zlec.</div>}
            />
          ) : (
            <></>
          )}
        </div>
        <div
          className="TSrowOrder"
          onClick={() => {
            showEdit.set((prev) => (prev === item.id ? false : item.id));
          }}
        >
          <div className="TSrowOrderGeneral">

          {!item.customer_name && item.action_type < 6 ? (
              <div className="rowComment">{item.comment}</div>
            ) : (
              <></>
            )}

            <div className="TSrowOrderData">{itemOrderJSX}</div>

            <div className="TSrowCustomerData">{item.customer_name}</div>

            <div style={{ fontSize: "11px" }}>
              {item.language_from ? (
                <>
                  {" "}
                  <span style={{ opacity: ".4" }}>
                    {shortenLanguage(item.language_from)} {"▸"}{" "}
                  </span>
                  <span style={{ fontWeight: "400" }}>
                    {shortenLanguage(item.language_to)}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <ActionType v={actionType} />
            <TextType v={item.text_type_string} set={null} />
            {!item.document_name && item.action_type > 5 ? (
              <div className="rowComment">{item.comment}</div>
            ) : (
              <></>
            )}
          </div>
          <div
            className="TSrowDocumentData"
            title={item.document_name ? item.document_name : ""}
          >
            {item.document_name ? (
              <>
                <div
                  className="sworn"
                  style={
                    item.sworn ? { color: "#1a8e89", fontWeight: "700" } : {}
                  }
                >
                  {item.sworn === 0 ? "zw." : "uwierz."}
                </div>
                <div className="documentName">{item.document_name}</div>{" "}
                <div className="rowComment">{item.comment}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="TSrowStats">
          {item.text_type === 8 ? (
            <></>
          ) : (
            <div className="TScount">
              {(item.comment && item.action_type < 6) ||
              item.document_name ||
              item.customer_name ? (
                <>
                  {updating ? <span className="count-loading">.</span> : <></>}
                  <input
                    className={item.next_count ? "pass" : ""}
                    type="text"
                    value={count}
                    placeholder={item.next_count ? "▸" : "???"}
                    //step="1000"
                    onChange={(e) => {
                      const numbers = /^[0-9]{1,6}([\.,][0-9]{0,1})?$/;
                      if (
                        e.target.value === "" ||
                        numbers.test(e.target.value)
                      ) {
                        setUpdates((prev) => prev + 1);
                        setCount(e.target.value.replace(",", "."));
                      }
                    }}
                  />
                  <select
                    value={unit}
                    onChange={(e) => {
                      setUpdates((prev) => prev + 1);
                      setUnit(e.target.value);
                    }}
                  >
                    <option value="zzs">zzs</option>
                    <option value="min">min</option>
                    <option value="wyr.">wyr.</option>
                    <option value="str.f">str. fiz.</option>
                  </select>
                </>
              ) : (
                <></>
              )}
            </div>
          )}

          <div
            className="TSduration"
            title={"ŁĄCZNIE: " + minutesToTimeString(timeAcrossBreaks)}
          >
            {item.duration ? (
              durationToString(item.duration)
            ) : new Date() - new Date(item.start) > 60 * 1000 &&
              item.action_type !== 0 ? (
              <div style={{ fontWeight: "bold" }} onClick={() => addFromRow()}>
                ZAKOŃCZ
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="TSavg">
            <>
              {item.duration &&
              durationToMinutes(item.duration) > 2 &&
              count &&
              item.last_count === "0.0" ? (
                <div style={{ fontWeight: "bold" }}>
                  {Math.round((count / timeAcrossBreaks) * 60)}{" "}
                  <span style={{ fontSize: "10px", color: "grey" }}>
                    {unit}
                    {"/h"}
                  </span>
                </div>
              ) : item.duration &&
                durationToMinutes(item.duration) > 2 &&
                count ? (
                <>
                  {Math.round(count / (durationToMinutes(item.duration) / 60))}{" "}
                  <span style={{ fontSize: "10px", color: "grey" }}>
                    {unit}
                    {"/h"}
                  </span>
                </>
              ) : count &&
                item.duration &&
                durationToMinutes(item.duration) < 3 ? (
                <span style={{ fontSize: "9px", color: "lightgrey" }}>
                  za krótko
                </span>
              ) : item.next_count ? (
                <div className="pass">▼</div>
              ) : (
                <></>
              )}
            </>
          </div>
        </div>
        <div className="TScontrols">
          <div
            className="TSduplicate"
            title="Powiel wpis na koniec listy"
            onClick={() => {
              const really = window.confirm(
                "Czy na pewno chcesz powielić wpis?"
              );
              if (really === true) {
                duplicateEntry(item.id);
              }
            }}
          >
            ☇
          </div>
          <div
            className="TSremove"
            title="Czyści wpis"
            onClick={() => {
              const really = window.confirm("Czy chcesz wyczyścić wpis?");
              if (really === true) {
                removeEntry(item.id, true);
              }
            }}
          >
            -
          </div>
          <div
            className="TSremove"
            title="Usuwa wpis"
            onClick={() => {
              const really = window.confirm("Czy na pewno chcesz usunąć wpis?");
              if (really === true) {
                removeEntry(item.id, false);
              }
            }}
          >
            ✕
          </div>
        </div>
      </div>
      {showEdit.id === item.id ? (
        <div className="TSforms">
          {/*<NewItem {...newItemProps} />*/}
          <NewItem
            key={item.employeeID + "." + item.id}
            user={user}
            workStarted={editProps.workStarted}
            ts={editProps.ts}
            setUpdate={updateData}
            setShowCreate={editProps.setShowCreate}
            lastEmpty={editProps.lastEmpty}
            selectedDate={editProps.selectedDate}
            defaults={editProps.defaults}
            showEdit={showEdit}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
