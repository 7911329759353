export const propMap = {
  id: "Nr ident.",
  time_added: "Czas dodania",
  time_approved: "Czas zatwierdzenia",
  translator_id: "ID tłumacza",
  language: "ID języka",
  order_number: "Nr zlecenia",
  order_customer: "Nazwa klienta",
  order_id: "ID zlecenia",
  accept_date: "Data zatwierdzenia",
  finished_date: "Data ukończenia",
  description: "Opis",
  comment: "Komentarz",
  quantity: "Ilość",
  rounding_method: "Metoda zaokrąglenia (id)",
  unit: "Jednostka",
  accept: "Akceptacja",
  settled: "Rozliczone",
  settled_id: "ID rozliczenia",
  rate: "Stawka (netto)",
  rate_distinctor: "Wyróżnik stawki",
  client_paid_rate: "Stawka klienta (netto)",
  tax: "Podatek",
  sending_cost: "Koszt wysyłki lub inny stały",
  amount: "Kwota łącznie",
  //createdBy: "ID użytkownika",
  created_by_string: "Utworzony przez",
  last_edited_by_string: "Zmodyfikowany przez",
  active: "Aktywne",
  translator_name: "Imię i nazwisko tłumacza",
  languageName: "Język (krótko)",
  language_full_name: "Język (pełny)",
  settlement_id: "ID rozliczenia",
  settlement_year: "Rok rozliczenia",
  total_net: "Suma netto rozliczenia",
  entries: "ID wpisów w rozliczeniu",
  unit_joint_name: "Jednostka zbiorcza",
  unit_joint_name_short: "Jednostka zbiorcza (skr.)",
  unit_name: "Jednostka",
  unit_number: "Liczba w jednostce",
  count: "Wszystkie",
};
