export default function dateToSQL(date) {
   return date.toISOString().split("T")[0];
}

export function dateTimeToSQL(date) {
   return date.toISOString().split("T")[0] + " " + date.toTimeString().split(" ")[0];
}

export function dateToSQLTime(date) {
   date.setSeconds(0);
   return date.toTimeString().split(" ")[0];
}