import React from 'react'

function OrderRow(props) {
    return (
        <div className={"orderRow" + (props.className ? " " + props.className : "")}>
                <div>{props.tag}</div>
                <div className={props.contentClass}>{props.content}</div>
        </div>
    )
}

export default OrderRow