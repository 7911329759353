export const settlementPropMap = {
  id: "Nr ident.",
  settlement_number: "Nr rozliczenia",
  year: "Rok",
  translator_id: "ID tłumacza",
  document_system_id: "ID dokumentu w systemie",
  document_number: "Nr dokumentu",
  document_type: "ID rodzaju dokumentu",
  entries: "ID zleceń",
  create_date: "Data utworzenia",
  total_net: "Suma netto",
  paid: "Zapłacone",
  active: "Aktywne",
  order_numbers: "Numery zleceń",
  translator_name: "Imię i nazwisko",
  document_type_string: "Dokument rozliczeniowy",
  count: "Wszystkie",
};
