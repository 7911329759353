import React, { useState, useEffect, useReducer } from "react";
import { Routes, Route, Link } from "react-router-dom";
import functions, { ordersPrepared } from "../functions";
import Begin from "../Begin";
import Activity from "../Activity";
import Break from "../Break";
import TranslationForm from "../TranslationForm";
import useTimesheet from "../useTimesheet";
import TimesheetTable from "../TimesheetTable";
import GenericTimesheetItem from "../GenericTimesheetItem";
import TimesheetItemView from "../TimesheetItemView";
import ActiveTimesheetItem from "../ActiveTimesheetItem";
import FullOrderItem from "../orders/FullOrderItem";
import ripple from "../../images/Ripple-1s-424px.svg";
import dragon from "../../images/icons8-european-dragon-100.png";
import Loading from "./Loading";

/*function setTimeSheetNew(state, action) {
  switch (action.type) {
    case "add":
      return { currentID: action.id };
      break;
  }
}*/

function Timesheet(props) {
  /*  const [timesheetNew, dispatchTimesheet] = useReducer(setTimeSheetNew, {
    currentID: null,
  });*/
  const orders = props.orders;
  const [updateCount, setUpdateCount] = useState(0);
  const timesheetObject = useTimesheet(updateCount);
  const currentTimesheet = timesheetObject.all;
  const lastTimesheetItem = timesheetObject.last;
  //console.log(timesheetObject);
  const [timesheetNow, setTimesheetNow] = useState({
    order: "",
    timeStart: "",
    active: false,
  });
  const [currentOrderData, setCurrentOrderData] = useState();

  useEffect(() => {
    if (currentTimesheet && lastTimesheetItem) {
      let currentOrder = lastTimesheetItem.order;

      if (currentOrder) setCurrentOrderData(currentOrder);
      setTimesheetNow((prev) => ({
        ...prev,
        timeStart: lastTimesheetItem.startTimeObject,
      }));
      console.log(currentOrder, lastTimesheetItem, currentOrderData);
    }
  }, [lastTimesheetItem]);

  function ActiveView(props) {
    return !orders.loading ? (
      <>
        {lastTimesheetItem &&
        !parseInt(lastTimesheetItem.status) &&
        currentOrderData ? (
          <div className="beginWrap">
            <ActiveTimesheetItem
              timesheetNow={timesheetNow}
              setTimesheetNow={setTimesheetNow}
              orderData={<FullOrderItem data={currentOrderData} />}
              rawData={currentOrderData}
              setUpdateCount={setUpdateCount}
              newItem={false}
            />
          </div>
        ) : (
          props.el
        )}
      </>
    ) : (
      <>
        <Loading text="Pobieranie danych" />
      </>
    );
  }

  return (
    <>
      <div className={"timesheet interfaceElement add"}>
        <div className="timesheetMain">
          <Routes>
            <Route path="" element={<ActiveView el={<Begin />} />}></Route>
            <Route path="activity" element={<Activity />}></Route>

            <Route
              path="order"
              element={
                <TranslationForm
                  orders={orders}
                  updateCount={updateCount}
                  status={[timesheetNow, setTimesheetNow]}
                  setUpdateCount={setUpdateCount}
                />
              }
            ></Route>
            <Route
              path="break"
              element={<Break setUpdateCount={setUpdateCount} />}
            ></Route>
            <Route
              path="other"
              element={
                <GenericTimesheetItem
                  setUpdateCount={setUpdateCount}
                  type="4"
                  heading="Inne"
                />
              }
            ></Route>
            <Route
              path="out_duty"
              element={
                <GenericTimesheetItem
                  setUpdateCount={setUpdateCount}
                  type="11"
                  heading="Wyjście służbowe"
                />
              }
            ></Route>
            <Route
              path="out_private"
              element={
                <GenericTimesheetItem
                  setUpdateCount={setUpdateCount}
                  type="12"
                  heading="Wyjście prywatne"
                />
              }
            ></Route>
          </Routes>
          <TimesheetTable
            tsData={currentTimesheet}
            handleModal={props.handleModal}
          />
        </div>
      </div>
    </>
  );
}

export default Timesheet;
