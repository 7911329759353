import React from "react";
import papIcon from "../images/clients/pap-60x35.png";

/// This function checks if a date is today or a day offset from today (fromThis)
export function compareDay(date, fromThis) {
  let compared = new Date();

  compared.setDate(compared.getDate() + fromThis);
  return (
    date.getDate() === compared.getDate() &&
    date.getMonth() === compared.getMonth() &&
    date.getFullYear() === compared.getFullYear()
  );
}

export function ordersPrepared(orders) {
  const prepared =
    orders &&
    orders
      /*  .filter((item) => {
        if (item.language_main !== language) return false;
        else return item;
      })*/
      /// SORT BY DATE
      .sort((a, b) => {
        let date1 = new Date(a.finish_date);
        let date2 = new Date(b.finish_date);
        return date1 - date2;
      })
      /// ADD KEY DAYA
      .map((item) => {
        return prepOrder(item);
      });
  console.log(prepared);
  return prepared;
}

export function prepOrder(item) {
  if (item.number_order) {
    item["valid_order"] =
      String(item["number_order"]).replace(/\//gi, ".") +
      "." +
      item["language_main"].toLowerCase();
    const splitOrder = item.valid_order.split(".");
    item.hidden = true;
    item.orderNumber = splitOrder[0];
    item.orderMonth = splitOrder[1];
    item.orderYear = splitOrder[2];
    item.orderNoLang =
      item.orderNumber + "." + item.orderMonth + "." + item.orderYear;
    item.orderNoLangOrLetter = String(item.orderNoLang).replace(
      /[a-zA-Z]/g,
      ""
    );
    item.grossPrice = new String(item.price_brutto);
    item.grossPrice = String(item.grossPrice).replace(".", ",") + " zł";
    item.pages =
      String(item.pages_to_translate_count)
        .replace(".00", "")
        .replace(".", ",") + " str.";
    item.rate = new String(item.rate);
    item.pagesNum = parseInt(item.pages_to_translate_count);
    item.orderDate = item.finish_date.slice(0, -3);
    item.orderDateJS = new Date(item.finish_date);
    item.language_to_short = shortenLanguage(item.language_to);
    item.language_from_short = shortenLanguage(item.language_from);
    item.language_main_short = shortenLanguage(item.language_main);
    let splitOrderDate = item.orderDate.split("-");
    const now = new Date();
    const createDate = new Date(item.create_date);

    // GET THE OVERALL NUMBER OF PAGES FROM DOCS, NOT ORDER
    let docPagesArr =
      item.documents && item.documents.length
        ? item.documents.map((doc) => {
            return {
              total: parseFloat(doc.pages_count_translator),
              sworn:
                doc.type_of_translation === "przysięgłe"
                  ? parseFloat(doc.pages_count_translator)
                  : 0,
              normal:
                doc.type_of_translation === "zwykłe"
                  ? parseFloat(doc.pages_count_translator)
                  : 0,
            };
          })
        : null;

    item.docPages =
      docPagesArr &&
      docPagesArr
        .map((i) => i.total)
        .reduce((prev, curr) => {
          return prev + curr;
        }, 0);

    item.docPagesSworn =
      docPagesArr &&
      docPagesArr
        .map((i) => i.sworn)
        .reduce((prev, curr) => {
          return prev + curr;
        }, 0);

    item.docPagesSwornRounded =
      docPagesArr &&
      docPagesArr
        .map((i) => i.sworn)
        .reduce((prev, curr) => {
          if (
            (curr % 2 !== 0 && Math.floor(curr) % 2 === 0) ||
            (Number.isInteger(curr) && curr % 2 !== 0)
          )
            curr = curr + 1;
          return Math.ceil(prev) + Math.ceil(curr);
        }, 0);

    item.docPagesNormal =
      docPagesArr &&
      docPagesArr
        .map((i) => i.normal)
        .reduce((prev, curr) => {
          return prev + curr;
        }, 0);

    const recentMinutes = 120;
    const timeAgo = now.getTime() - createDate.getTime();
    item.recent = timeAgo < 1000 * 60 * recentMinutes ? true : false;
    item.minutesAgo = Math.ceil(timeAgo / 1000 / 60);

    if (compareDay(item.orderDateJS, 0)) {
      item.orderDate = "Dziś ";
      item.orderHour = splitOrderDate[2].split(" ")[1];
    } else {
      let date = item.orderDate.split(" ")[0].split("-");
      let hour = splitOrderDate[2].split(" ")[1];
      item.orderDate =
        item.orderDateJS.toLocaleDateString("pl-PL", { weekday: "long" }) +
        ", " +
        date[2] +
        "." +
        date[1];
      item.orderHour = hour;
    }
  }
  return item;
}

export function orderViewParameters(item) {
  const view = {};

  view.pages = item.docPages ? item.docPages : item.pagesNum;
  view.pagesString = String(view.pages).replace(".", ",") + " str.";
  // colouring by number of pages
  view.rgbPages = `rgb(255,${255 - view.pages * 3},${255 - view.pages * 3})`;
  view.rgbAll = `rgb(${view.pages * 3},0,0)`;
  view.bold = "";
  if (view.pages > 10) view.bold = "bold";

  view.fresh = false;

  const now = new Date();

  const createDate = new Date(item.create_date);

  view.customerShort = item.customers_name.split(" ");
  view.customerShort.length = 6;
  view.customerShort = view.customerShort.join(" ");
  view.customerShort =
    item.customers_name === "Polska Agencja Prasowa S.A." ||
    item.customers_name === "Polska Agencja Prasowa S.A. w likwidacji" ? (
      <div className="pap">
        <img src={papIcon} />
        {view.customerShort}
      </div>
    ) : (
      view.customerShort
    );

  if (now - createDate < 2 * 60 * 60 * 1000) view.fresh = true;
  return view;
}

export function getWeekendOffsets(fromDate = new Date()) {
  // [today, tomorrow, day after tomorrow]
  let weekendOffsets = [0, 0, 0];
  if (fromDate.getDay() === 4) weekendOffsets = [0, 0, 2, 2, 2];
  if (fromDate.getDay() === 5) weekendOffsets = [0, 2, 2, 2, 2];
  if (fromDate.getDay() === 6) weekendOffsets = [0, 1, 1, 1, 1];
  if (fromDate.getDay() === 0) weekendOffsets = [0, 0, 0, 0, 0];
  return weekendOffsets;
}

export function filterOrdersByDate(orders, offset) {
  return orders?.data?.filter((item) => {
    let date = new Date(item.finish_date);
    let now = new Date();
    /// FILTER ORDERS BY DATE OFFSET FROM TODAY. 0 = TODAY, 1 = TOMORROW & SO ON
    if (offset < 0) {
      if (compareDay(date, "before")) return false;
    } else if (offset < 7) {
      if (compareDay(date, offset)) return true;
    }
    /// FILTER TO OBTAIN ONLY THOSE ORDERS WHICH ARE DUE FROM THREE DAYS FROM NOW ONWARDS
    else {
      if (compareDay(date, 0 + getWeekendOffsets()[0])) return false;
      if (compareDay(date, 1 + getWeekendOffsets()[1])) return false;
      if (compareDay(date, 2 + getWeekendOffsets()[2])) return false;
      else return true;
    }
  });
}

// NEW
export function filterOrdersByDateOffset(
  orders,
  offset,
  modifier = null,
  limit = null
) {
  const filterDate = offsetByWeekdays(offset);
  const oneDay = 1000 * 60 * 60 * 24;
  const now = new Date();
  let day = filterDate
    ? filterDate.toLocaleDateString("pl-PL", { weekday: "long" })
    : "";
  now.setHours(0, 0, 0, 0);
  if (!modifier && filterDate) {
    switch (filterDate.getTime() - now) {
      /*   case -oneDay * 2:
        day = "Przedwczoraj";
        break;*/
      case -oneDay:
        day = "Wczoraj";
        break;
      case 0:
        day = "Dziś";
        break;
      case oneDay:
        day = "Jutro";
        break;
      /*case oneDay * 2:
        day = "Pojutrze";
        break;*/
    }
    day = (
      <>
        {day}
        <span
          style={{
            marginLeft: "10px",
            paddingTop: "4px",
            fontWeight: "normal",
            fontSize: "14px",
            opacity: ".75",
          }}
        >
          {filterDate.toLocaleDateString("pl-PL", {
            day: "numeric",
            month: "long",
          })}
        </span>
      </>
    );
  }

  const filteredOrders = orders?.data?.filter((item) => {
    let date = new Date(item.finish_date);
    if (!filterDate) return false;
    date.setHours(0, 0, 0, 0);
    if (!modifier) {
      if (date.getTime() === filterDate.getTime()) {
        return true;
      }
    } else if (modifier === "future") {
      if (date >= filterDate) {
        return true;
      }
    } else if (modifier === "past") {
      if (date <= filterDate) {
        return true;
      }
    }
  });
  return {
    orders: filteredOrders,
    day: day,
    date: filterDate,
  };
}

// NEW DATE HANDLING FUNCTIONS

// NEW

function weekDayCalendar(offset, firstDate = new Date()) {
  firstDate.setHours(0, 0, 0, 0);
  const secondDate = new Date(firstDate.getTime());
  secondDate.setDate(firstDate.getDate() + offset);
  const startDate = secondDate > firstDate ? firstDate : secondDate;
  const endDate = secondDate > firstDate ? secondDate : firstDate;
  let weekDays = [];

  let date = new Date(startDate.getTime());

  for (let i = 0; i <= Math.abs(Math.round(offset)); i++) {
    let newDate = new Date(date.getTime());
    if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
      weekDays.push(newDate);
    }
    date.setDate(date.getDate() + 1);
  }
  return weekDays;
}

function offsetByWeekdays(offset, firstDate = new Date()) {
  const offsetCalendar = Math.abs(offset) > 3 ? offset * 1.5 : offset * 5;
  let result = weekDayCalendar(offsetCalendar * 1.6, firstDate);
  if (offset < 0) result.reverse();
  if (firstDate.getDay() === 0 || firstDate.getDay() === 6) {
    offset = offset > 0 ? offset - 1 : offset + 1;
  }
  let resultDate = result[Math.abs(offset)];

  return resultDate;
}

// END NEW

export function filterOrders(orders, queryObject) {
  const {
    filterBy: queryType,
    value: queryString,
    method: queryMethod,
  } = queryObject;
  let regeExModifier = "";
  if (queryMethod === "start") regeExModifier = "^";
  let query = "";
  try {
    query = new RegExp(regeExModifier + queryString, "gim");
  } catch {
    return;
  }
  if (!queryType) queryType = "customers_name";
  if (!queryString || queryString.length < 2) return orders;
  return orders.filter((item) => {
    //console.log(query, queryString, item.customers_name.search(query))
    if (item[queryType] && item[queryType].search(query) > -1) {
      return true;
    }
  });
}

export function plDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString();
}

export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export function shortenLanguage(lang) {
  switch (lang) {
    case "Ang":
      return "EN";
    case "Pol":
      return "PL";
    case "Niem":
      return "DE";
  }
}

export function convertTimestampToDuration(timestamp) {
  const hours = Math.floor(timestamp / 1000 / 60 / 60);
  const minutesExact = Math.floor((timestamp / 1000 / 60) % 60);
  const minutes = Math.round((timestamp / 1000 / 60) % 60);
  let seconds = timestamp / 1000 - minutesExact * 60;
  const hoursString = hours ? hours + " h" : "";
  const minutesString = minutes ? minutes + " min." : "<1 min.";

  return `${hoursString} ${minutesString}`;
}

export function countryCode(fullName) {
  const countryCodes = {
    Austria: "AT",
    Belgia: "BE",
    Bułgaria: "BG",
    Chorwacja: "HR",
    Cypr: "CY",
    Czechy: "CZ",
    Dania: "DK",
    Estonia: "EE",
    Finlandia: "FI",
    Francja: "FR",
    Grecja: "GR",
    Hiszpania: "ES",
    Irlandia: "IE",
    Litwa: "LT",
    Luksemburg: "LU",
    Łotwa: "LV",
    Malta: "MT",
    Holandia: "NL",
    Niemcy: "DE",
    Polska: "PL",
    Portugalia: "PT",
    Rumunia: "RO",
    Słowacja: "SK",
    Słowenia: "SI",
    Szwecja: "SE",
    Węgry: "HU",
    Włochy: "IT",
    "Irlandia Północna": "XI",
  };

  return countryCodes[fullName];
}

export function readableNumberPL(number, digits) {
  if (digits === 1) number = Math.round(number * 10) / 10;
  if (digits === 2) number = Math.round(number * 100) / 100;
  if (digits === 3) number = Math.round(number * 1000) / 1000;
  return String(number)
    .replace(/(\d)()(?=(\d{3})+(?!\d))/g, "$1\u00a0$2")
    .replace(/\./, ",");
}

export function isSameDate(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return (
    d1.getDate() == d2.getDate() &&
    d1.getMonth() == d2.getMonth() &&
    d1.getFullYear() == d2.getFullYear()
  );
}

export function inflect(number, forms) {
  // [0 dni, 1 dzień, 2/3 dni, 6 dni]
  let inflected = forms[0];
  const lastNum = Number(String(number).at(-1));
  if (number === 0) {
    inflected = forms[0];
  } else if (number === 1) {
    inflected = forms[1];
  } else if (
    (Number(String(number).slice(-2)) < 10 && lastNum > 1 && lastNum < 5) ||
    (Number(String(number).slice(-2)) > 19 && lastNum > 1 && lastNum < 5)
  ) {
    inflected = forms[2];
  } else {
    inflected = forms[3];
  }
  return number + " " + inflected;
}

export function isJSONParsable(input) {
  if (input) {
    try {
      JSON.parse(input);
    } catch (e) {
      return false;
    }
    if (JSON.parse(input)) {
      return true;
    } else {
      return false;
    }
  }
}

export function objectOrJSONparse(input) {
  if (typeof input === "string" && isJSONParsable(input)) {
    return JSON.parse(input);
  } else {
    return input;
  }
}

export function howMuchHolidayLeft(count, employeeID) {
  const hCount =
    count && !count.loading && count.rows && count.rows.length
      ? count.rows.filter((h) => h.id === employeeID)[0]
      : undefined;
  const hCountOff = hCount ? hCount.remaining_holiday : 0;
  const hCountSugg = hCount ? hCount.suggested_count : 0;
  const checkMax = hCountOff - hCountSugg;
  const hCountOffSugg = hCount ? hCount.remaining_holiday_with_suggestions : 0;
  return {
    hCount,
    hCountOff,
    hCountSugg,
    hCountOffSugg,
    checkMax,
  };
}
