import { useEffect, useState } from "react";
import { useGeneric } from "../../../useGeneric";
import OrderDocument from "./OrderDocument";

export default function OrderDocuments({
  documents,
  selectedOrder,
  setSelectedOrder,
  types,
}) {
  const fetchDocTranslators = useGeneric({
    name: documents ? "translatorsByDocumentID" : undefined,
    query: {
      search: {
        document_id: {
          value: documents.map((d) => d.id).join(","),
          find_in_set: true,
          standard: true,
        },
      },
    },
    update: selectedOrder.documents,
  });

  const fetchExternals = useGeneric({
    name: selectedOrder.id ? "externalsByOrderID" : undefined,
    query: {
      search: {
        order_id: {
          value: selectedOrder.id,
          exact: true,
          standard: true,
        },
      },
    },
    update: selectedOrder.documents,
  });

  const externals =
    fetchExternals && !fetchExternals.loading ? fetchExternals.rows : undefined;

  const docTranslators =
    fetchDocTranslators && !fetchDocTranslators.loading
      ? fetchDocTranslators.rows
      : undefined;

  return (
    <div className="documents" key={selectedOrder.id}>
      {externals && externals.length ? (
        <div className="externals-info">
          <div>WYSŁANE DO:</div>
          <div>
            {externals?.map((e) => (
              <div>
                {e.first_name} {e.last_name}{" "}
                <span className="externals-info-date">
                  {new Date(e.accept_date).toLocaleDateString("pl-PL", {
                    weekday: "long",
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : <></>}
      {documents.map((doc, index) => (
        <OrderDocument
          doc={doc}
          index={index}
          key={doc.id}
          types={types}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          translatorData={
            docTranslators &&
            docTranslators.filter((d) => d.document_id === doc.id)
          }
        />
      ))}
    </div>
  );
}
