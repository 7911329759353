import DataToExcel from "../../DataToExcel";
import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import languageDisplay from "../displays/languageDisplay";
import customMenuItems from "./customMenuItems";
import xlsIcon from "../../../../images/icons/xls.svg";
import { Link } from "react-router-dom";

const dictionary = {
  id: {
    name: "id",
  },
  create_date: {
    name: "Data przyjęcia",
    transform: (v) => prettyDate(v),
  },
  finish_date: {
    name: "Termin realizacji",
    transform: (v) => prettyDate(v),
  },
  language_main: {
    name: "Język",
  },
  number_order: {
    name: "Nr zlecenia",
  },
  customers_name: {
    name: "Klient",
  },
  type_of_translation: {
    name: "przys.",
    style: {
        fontSize:"11px"
    },
    transform: (v) => v === "przysięgłe" ? "tak" : "-"
   },
  price_brutto: {
    name: "Cena brutto",
    transform: (v) => prettyNumber(v) + " zł",
  },
  pages: {
    name: "Liczba str.",
    transform: (v) => prettyNumber(v),
  },
  translated_chars: {
    name: "Tł. zzs",
    transform: (v) => prettyNumber(Math.round(v * 10) / 10),
  },
  translated_pages: {
    name: "Tł. str.",
    transform: (v) => prettyNumber(Math.round(v * 10) / 10),
  },
  percentage: {
    name: "Procent",
    transform: (v, a) => {
     
      const percentage = Math.round((a.translated_pages / a.pages) * 100);

      return (
        <div
          style={{
            color: `rgb(${200-percentage},${190-percentage*2}, ${200-percentage*2})`,
          }}
        >
          {(a.translated_pages <= a.pages ? <>{percentage}%</> : <>100% (+{percentage-100}%)</>)}
          
        </div>
      );
    },
  },
  translator: {
    name: "Tłumacze",
    style: {
        fontSize:"11px"
    }
  },
};

export default function OrdersTranslatorsDay() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Postępy dzienne"
        classNames="big"
        defLimit={50}
        data="orders-translators-day"
        orderBy="id"
        orderDesc="DESC"
        searchPattern={{
          create_date: {
            value: "",
            type:"date",
            modifier: "date"
          },
          language_main: {
            value:""
          },
          translator: {
            value: ""
          },
          customers_name: {
            value: ""
          }
          
        }}
        dictionary={dictionary}
      />
    </div>
  );
}
