import { useContext, useEffect, useState } from "react";
import HolidaysContext from "../contexts/holidays-context";
import { URL } from "../global";

export default function HolidayRequestView({
  selectedItem,
  personName,
  setUpdate,
  setSelectedRange,
  currentRequestStatus,
  currentRequestID,
  myRequests,
}) {
  const title =
    selectedItem.dates[0].typeshort +
    " " +
    personName +
    " " +
    selectedItem.rangeString;

  const context = useContext(HolidaysContext);
  const [comment, setComment] = useState(
    myRequests?.find((r) => r.id === currentRequestID)?.comment
  );

  useEffect(() => {
    const req = myRequests
      ? myRequests.find((r) => r.id === currentRequestID)
      : "";
    setComment(req ? req.comment : "");
  }, [currentRequestID, selectedItem.id]);

  function sendHolidayRequest(mode = undefined, id = undefined) {
    const address = URL + "components/mail/send-holiday-request.php";
    const testAddress =
      "https://system2.tlumacz.tv/components/mail/send-holiday-request.php";
    fetch(testAddress, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        db: {
          dateset: selectedItem.dateStrings.join(","),
          person: personName,
          employeeID: context.user.employeeID,
          holidayType: selectedItem.dates[0].type,
          comment: comment,
        },
        mode: mode,
        title: title,
        id: id,
        content:
          selectedItem.dates[0].typestring +
          "<br/>" +
          (comment ? comment + "<br/>" : "") +
          selectedItem.rangeString +
          "<br/>" +
          new Date(selectedItem.dates[0].date).toLocaleDateString("pl-PL", {
            weekday: "long",
          }) +
            (selectedItem.dates[0] !==
          selectedItem.dates.at(-1)
            ? " - " +
              new Date(selectedItem.dates.at(-1).date).toLocaleDateString("pl-PL", {
                weekday: "long",
              })
            : ""),
      }),
    });
  }

  return !myRequests ? (
    <></>
  ) : (
    <div className="holidayRequestView">
      <h4>{title}</h4>
      <p style={{ marginTop: "21px", color: "grey", fontSize: "11px" }}>
        Wybrane daty:
      </p>
      <div style={{ marginBottom: "30px" }}>
        {selectedItem.dates.map((d) => {
          const date = new Date(d.date);
          return (
            <div>
              {date.toLocaleDateString("pl-PL", {
                day: "numeric",
                year: "numeric",
                month: "long",
              })}
              {" - "}
              {date.toLocaleDateString("pl-PL", { weekday: "long" })}
            </div>
          );
        })}
      </div>
      <p style={{ marginTop: "21px", color: "grey", fontSize: "11px" }}>
        Dodatkowy opis:
      </p>
      <div>
        <textarea
          onInput={(e) => setComment(e.target.value)}
          style={{
            height: "60px",
            resize: "none",
            width: "100%",
            marginTop: "9px",
          }}
          disabled={
            currentRequestStatus === "sent" ||
            currentRequestStatus === "approved"
          }
          value={comment}
          maxLength={200}
        ></textarea>
      </div>
      <div>
        {currentRequestStatus === "rejected" ? (
          <></>
        ) : (
          <button
            style={{ padding: "18px", width: "200px", marginTop: "30px" }}
            onClick={() => {
              if (
                currentRequestStatus === "blank" ||
                currentRequestStatus === "cancelled"
              ) {
                sendHolidayRequest();
                window.setTimeout(() => setUpdate((prev) => prev + 1), 800);
                setSelectedRange(false);
              }
              if (
                currentRequestStatus === "sent" ||
                currentRequestStatus === "approved"
              ) {
                sendHolidayRequest("cancel", currentRequestID);
                window.setTimeout(() => setUpdate((prev) => prev + 1), 800);
                setSelectedRange(false);
              }
            }}
          >
            {currentRequestStatus !== "cancelled" &&
            (currentRequestStatus === "sent" ||
              currentRequestStatus === "approved") ? (
              <>Zrezygnuj</>
            ) : (
              <>
                Wyślij wniosek{" "}
                {currentRequestStatus === "cancelled" ? <>ponownie</> : <></>}
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
