import { useEffect, useState } from "react";
import { useGeneric } from "../useGeneric";

export default function useExecutiveReport(selectedDate) {
  const useData = useGeneric({
    name: "executive-daily-report",

    query: {
      selectedDate: selectedDate,
    },

    update: selectedDate,
  });

  const data = !useData.loading ? useData.rows : undefined;

  const attendance =
    data && data.attendance_translators_by_day
      ? data.attendance_translators_by_day
      : undefined;

  const all =
    data && data.daily_report_full ? data.daily_report_full : undefined;

  const orderless =
    data && data.daily_report_orderless
      ? data.daily_report_orderless
      : undefined;

  return {
    loading: useData.loading,
    data: all,
    orderless: orderless,
    translators: {
      all: attendance && attendance.map((i) => 1).reduce((a, b) => a + b, 0),
      english:
        attendance &&
        attendance
          .filter((i) => i.group === 10 || i.group2 === 10)
          .filter((i) => i.languages.includes("Ang"))
          .map((i) => 1)
          .reduce((a, b) => a + b, 0),
      german:
        attendance &&
        attendance
          .filter((i) => i.group === 11 || i.group2 === 11)
          .filter((i) => i.languages.includes("Niem"))
          .map((i) => 1)
          .reduce((a, b) => a + b, 0),
    },
  };
}
