import React, { useState } from "react";
import addToTimesheet from "./addToTimesheet";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";
import CommentArea from "./CommentArea";

function GenericTimesheetItem(props) {
  const { type, heading } = props;
  const [comment, setComment] = useState("");
  const [active, setActive] = useState(false);
  let navigate = useNavigate();

  

  function beginItem() {
    const now = new Date();
    addToTimesheet({
      queryType: "add",
      date: now,
      startTime: now,
      lastTime: now,
      type: type,
      status: 0,
      comments: comment,
    });
    setActive(true);
    props.setUpdateCount((prev) => prev + 1);
  }

  function endItem() {
    const now = new Date();
    addToTimesheet({
      queryType: "endItem",
      endTime: now,
      status: 1,
      comments: comment,
    });
    navigate("../activity");
    setActive(false);
    props.setUpdateCount((prev) => prev + 1);
  }

  return (
    <div className="tsItem specificForm">
      <BackButton link="../activity" />
      {!active ? (
        <>
          <h2>{heading}</h2>

          <button className="main" onClick={beginItem}>
            Rozpocznij
          </button>
        </>
      ) : (
        <>
          <CommentArea comment={[comment,setComment]}/>
          <button className="main" onClick={endItem}>
            Zakończ
          </button>
        </>
      )}
    </div>
  );
}

export default GenericTimesheetItem;
