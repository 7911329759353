import { useContext, useState } from "react";
import { TableContext } from "../../../TableUniversal";
import modify from "../../../modify";
import { useGeneric } from "../../../../useGeneric";
import SettlementList from "./SettlementList";
import SettlementLists from "./SettlementLists";
import Create from "../../../Create";
import { settlementMap } from "../maps/settlementMap";
import { document_number } from "../maps/common/document_number";

export default function SettleControls({ currentData }) {
  const {
    replaceTable,
    selected,
    setSelected,
    dataRaw,
    headings,
    selectable,
    update,
    multiControls,
  } = useContext(TableContext);
  const [createOpen, setCreateOpen] = useState(false);
  const [currentInput, setCurrentInput] = useState([]);
  const selectedData = Object.keys(selected)
    .filter((key) => selected[key])
    .map((key) => dataRaw[key]);

  const editor = currentData?.editor ? true : false;

  const [settlement, setSettlement] = useState(
    currentData?.settlement ? currentData.settlement : []
  );

  const [settlementDone, setSettlementDone] = useState([]);

  const selectedFullFetch = useGeneric({
    name: "sent-orders-table",
    disable: settlement.length === 0,
    query: {
      limit: 1000,
      offset: 0,
      orderBy: "id",
      orderDesc: "DESC",

      search: {
        id: {
          value: settlement.length ? settlement.join(",") : undefined,
          find_in_set: true,
        },
      },
    },
    update: JSON.stringify(settlement),
  });

  const selectedFull = !selectedFullFetch.loading
    ? selectedFullFetch.rows
    : undefined;

  const selectedFullByTranslator = {};

  if (selectedFull && selectedFull.length) {
    selectedFull.forEach((item) => {
      if (!selectedFullByTranslator[item.translator_name]) {
        selectedFullByTranslator[item.translator_name] = [];
      }
      selectedFullByTranslator[item.translator_name].push(item);
    });
  }

  const translators = Object.keys(selectedFullByTranslator);

  //useEffect(() => {}, [currentInput.length]);
  //console.log(selectedFullByTranslator);
  return editor ? (
    <div
      className={
        "settlement-control full-settlement-editor" +
        (currentInput && currentInput.length ? " divide" : "")
      }
    >
      <SettlementLists
        translators={translators}
        settlement={settlement}
        settlementDone={settlementDone}
        setSettlement={setSettlement}
        setCurrentInput={setCurrentInput}
        selectedFullByTranslator={selectedFullByTranslator}
        createOpen={createOpen}
        setCreateOpen={setCreateOpen}
        editor={editor}
      />
      {currentInput && currentInput.length ? (
        <Create
          customBack={
            <button
              onClick={() => {
                setCurrentInput([]);
                setCreateOpen(false);
              }}
              className="return"
            >
              ←
            </button>
          }
          customButtonAction={() => {
            /* setSettlement((prev) => {
              console.log(currentInput.map((c) => c.id));
              return currentInput.map((c) => c.id).filter((f) => !prev.includes(f));
            });*/
            setSettlementDone(
              (prev) =>
                currentInput &&
                currentInput.length && [
                  ...prev,
                  ...currentInput.map((c) => c.id),
                ]
            );
            setCurrentInput([]);
            console.log(settlement, currentInput);
            //console.log(currentInput);
          }}
          customMap={{
            ...settlementMap,
            fields: {
              ...settlementMap.fields,
              settlement_number: {
                label: "Dodać do poprzedniego?",
                type: "search",

                dataSource: {
                  name: "settlements",
                  active: 1,
                  params: {
                    orderBy: "id",
                    orderDesc: "DESC",
                  },
                  query: (value, all, formValues) => ({
                    settlement_number: {
                      value: value,
                    },
                    translator_id: {
                      value: formValues?.translator_id,
                      exact: true,
                    },
                  }),
                  value: "settlement_number",
                  setters: (row, formValues) => [
                    ["settlement_id", row?.id],
                    ["settlement_number", row?.settlement_number],
                    ["entries", formValues.entries + "," + row?.entries],
                    [
                      "total_net",
                      Number(formValues.total_net) + Number(row.total_net),
                    ],
                    [
                      "document_amount",
                      Number(formValues.document_amount) + Number(row.document_amount),
                    ],
                  ],
                  display: (row) => (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                              boxShadow: "0 0 5px rgba(0,0,0,.3)",
                            }}
                          >
                            {row?.settlement_number}
                          </div>
                        </div>
                        <div style={{ fontSize: "11px", color: "grey" }}>
                          {row?.translator_name}
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "11px",
                              margin: "1px 0px",
                            }}
                          >
                            {row?.order_numbers?.split(",").length} zlec.
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          fontSize: "9px",
                          paddingTop: "2px",
                          marginTop: "3px",
                        }}
                      >
                        {row?.order_numbers.split(",").map((i) => (
                          <div
                            style={{
                              borderRadius: "7px",
                              boxShadow: "1px 1px 3px rgba(0,0,0,.1)",
                              margin: "1px 2px",
                              padding: "1px 2px",
                            }}
                          >
                            {i}
                          </div>
                        ))}
                      </div>
                    </div>
                  ),
                  text: (row) => row?.settlement_number,
                },
              },
              settlement_id: {
                disabled: true,
                // hide:true
              },
              translator_name: {
                label: "Tłumacz",
                disabled: true,
              },
              sent_order_all: {
                hide: true,
              },
              document_number: { label: "numer dokumentu" }, //document_number,
              total_net: {
                label: "Suma",
                disabled: true,
                display: (v) => v.replace(".", ",") + " zł",
              },
              document_amount: {label:"kwota dokumentu" },
              comment: {
                ...settlementMap.fields.comment,
                label: "Uwagi",
              },
            },
            sections: [
              {
                title: "Dodaj rozliczenie",

                fields: [
                  "translator_name",
                  "settlement_number",
                  //"settlement_id",

                  "sent_order_all",
                  "entries",
                  "document_type",
                  "document_number",
                  "document_amount",
                  "total_net",
                  "comment",
                ],
              },
            ],
          }}
          initialSet={{
            entries: currentInput.map((i) => i.id).join(","),
            sent_order_all: currentInput,
            translator_id: currentInput[0].translator_id,
            translator_name: currentInput[0].translator_name,
            total_net:
              Math.round(
                currentInput
                  .map((i) => parseFloat(i.amount))
                  .reduce((a, b) => a + b, 0) * 100
              ) / 100,
            document_amount:
              Math.round(
                currentInput
                  .map((i) => parseFloat(i.amount))
                  .reduce((a, b) => a + b, 0) * 100
              ) / 100,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ fontSize: "12px", padding: "0 9px" }}>
        Wybrane:{" "}
        {selected && Object.keys(selected).filter((i) => selected[i]).length}
      </div>
      <ul style={{ display: "flex" }}>
        {settlement && settlement.length ? (
          <li
            className="settlement-control"
            onClick={
              () => console.log(selectedFull)
              /*window.alert(
              selectedFull
                .map((i) => i.order_number + " " + i.order_customer)
                .join(",\r\n")
            )*/
            }
          >
            Rozliczenie{" "}
            {settlement && settlement.length ? (
              <>({settlement.length})</>
            ) : (
              <></>
            )}
            <SettlementLists
              translators={translators}
              settlement={settlement}
              setSettlement={setSettlement}
              setCurrentInput={setCurrentInput}
              currentInput={currentInput}
              selectedFullByTranslator={selectedFullByTranslator}
            />
          </li>
        ) : (
          <></>
        )}
        <li
          style={
            Object.keys(selected)
              .map((key) => selected[key])
              .reduce((a, b) => a || b, false)
              ? { visibility: "visible" }
              : { visibility: "hidden" }
          }
          onClick={async () => {
            /*await modify("mod-settlement", {
              entries: selectedData.map((i) => i.id).join(","),
            });*/
            await setSettlement((prev) => [
              ...new Set([
                ...prev,
                ...selectedData
                  .filter((f) => f?.settled !== 1)
                  .map((i) => i?.id),
              ]),
            ]);
            setSelected({});
            //window.alert(settlement);
            //window.alert(selectedData.map((i) => i.id).join("\r\n"));
          }}
        >
          <span style={{ color: "green", fontSize: "14px" }}>+</span> Dodaj do
          rozliczenia
        </li>
        {settlement && settlement.length ? (
          <li onClick={() => setSettlement([])}>
            <span style={{ color: "darkred", fontSize: "11px" }}>✖</span>{" "}
            Wyczyść rozliczenie
          </li>
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}
