import TableUniversal from "../../TableUniversal";

const dictionary = {
  DocNo: {
    name: "Nr",
  },
  pos: {
    name: "POS",
    style: {
      width: "120px",
    },
  },
  nazwisko: {
    name:"Użytkownik",
    transform: (v,a) => <>{a.imie} {a.nazwisko}</>,
    style: {
        width: "100px",
      },
  },
  SourceDate: {
    name: "data",
    order: "SourceDate",
    transform: (v) => new Date(v).toLocaleString(),
    style: {
      width: "100px",
    },
  },
 
  ko_firma: {
    name: "Firma",
  },
  ko_miasto: {
    name: "Miasto",
  },
  ko_adres: {
    name: "Adres",
  },
  Uwagi: {
    name:"uwagi",
    style: {fontSize:"9px"}
  },
  ValueNWal: {
    name: "netto",
    transform: (t) => Math.round(Number(t) * 100) / 100,
  },
  ValueBWal: {
    name: "brutto",
    transform: (t) => Math.round(Number(t) * 100) / 100,
  },
  _KwRozliczenia: {
    name: "rozl.",
    transform: (t) => Math.round(Number(t) * 100) / 100,
    order: "_KwRozliczenia",
  },
};

export default function Lsi() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="LSI"
       
        data="lsi_test"
        /*  groupBy={[
          "month",
          (m, props) =>
            new Date(props.year, m - 1, 1).toLocaleDateString("pl-PL", {
              month: "long",
              year: "numeric",
            }),
        ]}*/
        /*orderBy="SourceDate"
        orderDesc="DESC"*/
        options={{ selectable: true }}
        dictionary={dictionary}
      />
    </div>
  );
}
