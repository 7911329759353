import { useContext, useEffect } from "react";
import ResultItem from "./ResultItem";
import { SearchBoxContext } from "./SearchBoxContext";

export default function ResultsBox({ queryResults, setActive }) {
  const {resultWindowStyle} = useContext(SearchBoxContext);

  return (
    <div className="search-box-results" style={resultWindowStyle}>
      {!queryResults ? (
        <div className="search-box-result-info">Wyszukiwanie...</div>
      ) : queryResults.length === 0 ? (
        <div className="search-box-result-info">Brak wyników</div>
      ) : (
        queryResults.map((resultItem, index) => (
          <ResultItem item={resultItem} index={index} setActive={setActive} />
        ))
      )}
    </div>
  );
}
