import { useRef, useState } from "react";
import prettyNumber from "../../../tables/functions/prettyNumber";
import { toPages } from "./DocumentsTable";
import { percentToFactor } from "./OrderViewInner";

export default function DocumentTableRow({
  order,
  d,
  ind,
  pagesEdited,
  setPagesEdited,
  netValues,
  grossValues,
}) {
  const translators = JSON.parse(d.translators);
  const [editablePages, setEditablePages] = useState(false);
  const editRef = useRef();
  const pagesTranslatedCount = toPages(
    d.chars || d.chars_ts,
    d.type_of_translation === "przysięgłe" ? true : false
  );

  //const [pagesEdited, setPagesEdited] = useState(pagesTranslatedCount);

  return (
    <tr>
      <td style={{ width: "24px" }}>{ind + 1}</td>
      <td style={{ width: "30%" }}>{d.name}</td>
      <td style={{ width: "60px" }}>
        {d.type_of_translation === "zwykłe" ? (
          <div className="monitor-zwykle">zw.</div>
        ) : d.type_of_translation === "przysięgłe" ? (
          <div className="monitor-uwierzytelnione">przys.</div>
        ) : (
          d.type_of_translation
        )}
      </td>
      <td style={{ width: "120px" }}>
        {d.translators ? (
          translators?.map((t) => (
            <div
              className={
                "order-monitor-doc-translator" +
                (t.action_type === 3 ? " non-translation" : " translation")
              }
            >
              {t.translator}{" "}
              {t.action_type === 3 ? (
                <span style={{ fontSize: "12px", color: "grey" }}>SPR.</span>
              ) : (
                <></>
              )}
            </div>
          ))
        ) : order.ext_translators ? (
          <>
            {order.ext_translators?.split(",")?.map((e) => (
              <div className="order-monitor-doc-translator external">{e}</div>
            ))}
          </>
        ) : (
          <></>
        )}
      </td>

      <td
        style={{
          width: "77px",
        }}
      >
        {d.chars ? (
          <div className="monitor-chars">{d.chars}</div>
        ) : d.chars_ts ? (
          <div style={{ color: "rgb(64,64,64)" }}>{d.chars_ts}</div>
        ) : (
          <div>-</div>
        )}
      </td>
      <td style={{ width: "77px" }}>
        <div>{d.pages_count}</div>

        {order.remarks_top_secret && order.customers_symbol === "pap" ? (
          <div className="pap-words">{order.remarks_top_secret} wyr.</div>
        ) : (
          <></>
        )}
      </td>
      <td
        style={{ width: "57px", padding: "0px" }}
        onClick={() => {
          setEditablePages(true);
          setTimeout(() => editRef?.current?.focus(), 100);
        }}
      >
        {d.chars || d.chars_ts ? (
          <div
            style={
              editablePages
                ? { color: "green", fontSize: "12px" }
                : d.chars
                ? { color: "green" }
                : { color: "rgb(60,60,60)" }
            }
          >
            {pagesTranslatedCount}
          </div>
        ) : (
          <>-</>
        )}
        {editablePages ? (
          <input
            ref={editRef}
            type="number"
            className="inputPages"
            onChange={(e) =>
              setPagesEdited((prev) => ({ ...prev, [d.id]: e.target.value }))
            }
            value={pagesEdited?.[d.id]}
          />
        ) : (
          <></>
        )}
      </td>
      <td>{d.discount ? d.discount : <>-</>}</td>
      <td>
        {d.special ? <div>{d.special}</div> : <></>}
        {d.expert ? <div>{d.expert}</div> : <></>}
      </td>
      <td>
        ~
        {prettyNumber(
          Math.round(d.rate * percentToFactor(order.tax_rate) * 100) / 100
        )}
      </td>
      <td>
        <div
          className={
            grossValues && grossValues[d.id] !== undefined ? "smaller" : ""
          }
        >
          {d.price_gross}
        </div>
        {grossValues && grossValues[d.id] !== undefined ? (
          <div>
            {prettyNumber(grossValues[d.id], {
              style: grossValues[d.id] ? { color: "orange" } : {},
            })}
          </div>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}
