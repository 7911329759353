import OrderViewInner from "./OrderViewInner";

export default function OrderView({ data, selectedID }) {
  return (
    <div className="order-monitor-details">
      {data && data.length ? (
        data
          .filter((o) => o.id === selectedID)
          .map((order) => <OrderViewInner key={order.id} order={order} />)
      ) : (
        <div>Nie wybrano zlecenia.</div>
      )}
    </div>
  );
}
