import { URL } from "../global";

export default async function modify(name, values, id) {
  let path = "components/set/";
  switch (name) {
    /*   case "mod-externals":
      path += "set-externals.php";
      break;*/
    case "mod-settlement":
      path += "set-settlement.php";
      break;
    case "mod-settlement-simple":
      path += "mod-settlement.php";
      break;
    case "del-externals":
      path += "remove-externals.php";
      break;
    case "del-externals-translators":
      path += "remove-externals-translators.php";
      break;
    case "del-externals-settlements":
      path += "remove-externals-settlements.php";
      break;
    case "del-externals-settlements-individual":
      path += "remove-externals-settlements-individual.php";
      break;
    case "edit-doc-chars":
      path += "set-char-count.php";
      break;
  }
  const response = await fetch(URL + path, {
    method: "POST",
    body: JSON.stringify({
      params: values,
      editID: id ? id : undefined,
    }),
    credentials: "include",
  });
  const result = await response.json();
  return result;
}
