import React, { useEffect, useState, useRef } from "react";
import { useOrderDataFull } from "../useOrderDataFull";
import { useTranslators } from "../useTranslators";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MainButton from "./MainButton";
import Timesheet from "./Timesheet";
import CurrentOrders from "./CurrentOrders";
import Listing from "./Listing";
import Modal from "../Modal";
/*import icon1 from "../../images/noun_clock_102092.svg";
import icon2 from "../../images/noun_documents_1185970.svg";
import AccountsIcon from "../../images/icons/accounting.svg";
import holidayIcon from "../../images/icons/noun-calendar-4580739.svg";*/
import ordersIcon from "../../images/icons/m/system_icon-01.svg";
import ordersIcon2 from "../../images/icons/buttons/orders.svg";
import reportIcon from "../../images/icons/buttons/report.svg";
import holidayIcon from "../../images/icons/m/system_icon-02.svg";
import timesheetIcon from "../../images/icons/m/system_icon-03.svg";
import registerIcon from "../../images/icons/m/system_icon-04.svg";
import invoicesIcon from "../../images/icons/m/system_icon-05.svg";
import AccountsIcon from "../../images/icons/m/system_icon-06.svg";
import listingIcon from "../../images/icons/m/icon_listing.svg";
import historyIcon from "../../images/icons/m/icon_history-08.svg";
import alarmIcon from "../../images/icons/icons8-alarm-16.png";
import ripple from "../../images/Ripple-1s-424px.svg";
import gift from "../../images/icons/gift.png";
import peopleIcon from "../../images/icons/buttons/customers.svg";
import translatorsIcon from "../../images/icons/buttons/translators.svg";
import Holidays from "./Holidays";
import Externals from "../spreadsheets/externals/Externals";
import Invoices from "./Invoices";
import Settings from "./Settings";
import AppContext from "../contexts/app-context";
import SwornRegistry from "../spreadsheets/swornRegistry/SwornRegistry";
import CustomerService from "./CustomerService";
import Attendance from "./Attendance";
import WorkTime from "./WorkTime";
import WorkTimeReports from "./WorkTimeReports";
import { useGeneric } from "../useGeneric";
import DocumentEditor from "../orders/DocumentEditor";
import NewExternals from "../tables/instances/newExternals/NewExternals";
import Translators from "../tables/instances/newExternals/Translators";
import OrdersTable from "../tables/instances/newExternals/OrdersTable";
import AttendanceTable from "../tables/instances/examples/AttendanceTable";
import InvoicesTable from "../tables/instances/examples/InvoicesTable";
import Tables from "../tables/Tables";
import Now from "./Now";
import OrderQueue from "./OrderQueue/OrderQueue";
import HolidayYear from "./HolidayYear";

function MainEditor(props) {
  const { loggedIn, user, logOut } = props;
  const [clicked, setClicked] = useState(null);

  const [googleAccountID, setGoogleAccountID] = useState(() => ({
    id1: localStorage.getItem("googleAccountID")
      ? localStorage.getItem("googleAccountID")
      : false,
    id2: localStorage.getItem("googleAccountID2")
      ? localStorage.getItem("googleAccountID2")
      : false,
    mail2: localStorage.getItem("googleAccountMail2")
      ? localStorage.getItem("googleAccountMail2")
      : false,
  }));
  const [background, setBackground] = useState(() =>
    localStorage.getItem("background")
      ? parseInt(localStorage.getItem("background"))
      : 0
  );

  const [notificationsActive, setNotificationsActive] = useState(() =>
    localStorage.getItem("orderNotificationsEnable")
      ? localStorage.getItem("orderNotificationsEnable") === "true"
        ? true
        : false
      : false
  );

  //console.log(`url(${backgrounds[background]}), linear-gradient(60deg, rgba(200, 200, 200, 1) 40%,rgba(200, 200, 200, 0.03) 100%), url(${pattern})`);
  const location = useLocation();
  const navigate = useNavigate();
  const currentSection = location.pathname;
  console.log(currentSection);
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkmode") === "true"
      ? localStorage.getItem("darkmode")
      : false;
  });
  const [modal, setModal] = useState({
    show: false,
    type: false,
    content: <></>,
    updates: 0,
  });

  const [modal2, setModal2] = useState({
    show: false,
    type: false,
    content: <></>,
    updates: 0,
  });

  const mainButtons = useRef(null);

  const [orderParams, setOrderParams] = useState({
    count: 300,
    type: "",
    language: window.localStorage.getItem("ordersLanguage")
      ? window.localStorage.getItem("ordersLanguage")
      : "Ang",
    repeat: true,
    client: false,
    update: 0,
  });
  /*
  const fetchLatestTimesheet = useGeneric({
    name: "current-orders-notify",
    query: { limit: 10, offset: 0, orderBy: "id", orderDesc: "DESC" },
    reload: 30,
  });

  const latestTimeSheet = !fetchLatestTimesheet.loading
    ? fetchLatestTimesheet.rows
    : undefined;
*/
  const [updateTranslators, setUpdateTranslators] = useState(0);

  const translators = useTranslators(updateTranslators);

  const orders = useOrderDataFull(orderParams); //orders combined with documents in one super-query

  const ordersNumber = orders && orders.data ? orders.data.length : 0;
  //console.log(orders);
  const recentOrders = orders
    ? orders.data
        //.filter((item) => item.recent)
        .filter((item) => item.orderDateJS > new Date().setHours(0, 0, 0, 0))
        .sort(
          (a, b) =>
            new Date(b.create_date).getTime() -
            new Date(a.create_date).getTime()
        )
    : undefined;

  const mainTitle = "SYS/2";

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Powiadomienia nie są obsługiwane przez tę przeglądarkę.");
    } else {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (mainButtons.current && mainButtons.current.dataset) {
      if (location.pathname !== "/") {
        mainButtons.current.dataset.clicked = true;
      } else {
        mainButtons.current.dataset.clicked = false;
      }
    }
  }, [mainButtons.current]);

  useEffect(() => {
    document.title = titles[location.pathname] ?? mainTitle;
  }, [location]);

  function orderNotification(minutesAgo, random) {
    const lastOrder = random
      ? orders.data[Math.floor(Math.random() * (orders.data.length + 1))]
      : recentOrders?.at(0);
    const newOrder = lastOrder?.minutesAgo < minutesAgo ? lastOrder : null;

    //console.log(lastOrder, newOrder);
    const options = {
      body: `${newOrder?.orderDate}, godz. ${newOrder?.orderHour}\n${
        newOrder?.documents?.[0].name
          ? newOrder.documents[0].name
          : newOrder?.documents[0]?.document_name
      }\n${
        newOrder?.customers_name === "Polska Agencja Prasowa S.A." ||
        newOrder?.customers_name === "Polska Agencja Prasowa S.A. w likwidacji"
          ? newOrder?.remarks_top_secret + " wyr."
          : newOrder?.docPages + " str."
      } / ${newOrder?.users_name}`,
      icon: gift,
      dir: "ltr",
    };

    const notification = newOrder
      ? new Notification(
          newOrder?.valid_order + " " + newOrder?.customers_name,
          options
        )
      : null;
  }

  function currentJobNotification(jobs) {
    if (!jobs || !jobs.length) return;
    const employee = jobs[0]?.employee_name;
    const customer = jobs[0]?.customer_name;
    const order =
      jobs[0]?.order_string.replace("/", ".") +
      jobs[0]?.language_main.toLowerCase();
    const start = jobs[0]?.start.split(" ").at(-1);
    const text = `robi ${order}\n${customer}\n od ${start}`;
    //console.log(lastOrder, newOrder);
    const options = {
      body: text,
      icon: peopleIcon,
      dir: "ltr",
    };

    const notification = new Notification(employee, options);
  }

  useEffect(() => {
    if (notificationsActive) orderNotification(3);
    //orderNotification(1.01);
  }, [ordersNumber]);

  /*
  useEffect(() => {
    if (notificationsActive && user.id === 1 && latestTimeSheet) {
      currentJobNotification(latestTimeSheet);
    }
  }, [fetchLatestTimesheet.loading]);*/

  const links = [
    {
      to: "orders",
      availability: [0, 1, 2, 3, 4, 5],
      title: "Bieżące zlecenia",
      props: {
        alt: "list",
        icon: ordersIcon,
        iconPadding: 2,
        text: "Zlecenia",
        styles: { borderColor: "#b3efe7" },
        setClicked: setClicked,
      },
    },
    {
      to: "holidays",
      availability: [0, 1, 2, 3, 4, 5],
      title: "Wolne",
      props: {
        alt: "holidays",
        icon: holidayIcon,
        iconPadding: 2,
        text: "Urlopy",
        styles: { borderColor: "rgb(255 217 109)" },
        setClicked: setClicked,
      },
    },
    /*{
      to: "timesheet",
      availability: [0, 3],
      title: "Timesheet",
      props: {
        alt: "add",
        icon: timesheetIcon,
        iconPadding: 2,
        text: "Timesheet",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },*/

    {
      to: "repertorium",
      availability: [0, 1, 2, 3, 5],
      title: "Repertorium",
      props: {
        alt: "repertorium",
        icon: registerIcon,
        iconPadding: 2,
        text: "Repertorium",
        styles: { borderColor: "rgb(178 141 195)" },
        setClicked: setClicked,
      },
    },
    {
      to: "work-time",
      availability: [0, 1, 2, 3, 4, 5],
      title: "Czas pracy",
      props: {
        alt: "add",
        icon: timesheetIcon,
        iconPadding: 2,
        text: "Czas pracy",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
    {
      to: "externals",
      availability: [6], //[0, 2, 3, 5],
      title: "Zdalni",
      props: {
        alt: "Rozliczenia",
        icon: AccountsIcon,
        iconPadding: 2.5,
        text: "Rozliczenia",
        styles: { borderColor: "rgb(178 191 145)" },
        setClicked: setClicked,
      },
    },
    {
      to: "work-time-reports",
      availability: [2, 3, 4, 5],
      title: "Raporty",
      props: {
        alt: "Raporty czasu pracy",
        icon: timesheetIcon,
        iconPadding: 2.5,
        text: "Raporty",
        styles: { borderColor: "rgb(178 191 145)" },
        setClicked: setClicked,
      },
    },
    {
      to: "zestawienia",
      availability: [2, 3, 4, 5],
      title: "Zestawienia",
      props: {
        alt: "---",
        icon: listingIcon,
        iconPadding: 2,
        text: "Zestawienia",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },

    /* {
      to: "obsluga",
      availability: [3],
      title: "Historia",
      props: {
        alt: "---",
        icon: historyIcon,
        iconPadding: 2,
        text: "Historia",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
*/ /*
    {
      to: "invoices",
      availability: [0, 3, 5],
      title: "Faktury",
      props: {
        alt: "invoices",
        icon: invoicesIcon,
        iconPadding: 3,
        text: "Faktury",
        styles: { borderColor: "rgb(138 141 195)" },
        setClicked: setClicked,
      },
    },*/

    {
      to: "tables/sent",
      availability: [2, 3, 4, 5],
      title: "Rozliczenia",
      props: {
        alt: "---",
        icon: translatorsIcon,
        iconPadding: 2,
        text: "Rozliczenia",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
    {
      to: "tables/major-customers",
      availability: [2, 3, 4, 5],
      title: "Klienci",
      props: {
        alt: "---",
        icon: ordersIcon2,
        iconPadding: 2,
        text: "Panel klientów",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
    {
      to: "tables/daily-report",
      availability: [2, 3, 4],
      title: "Raport dzienny",
      props: {
        alt: "---",
        icon: reportIcon,
        iconPadding: 2,
        text: "Raport dzienny",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
    {
      to: "tables",
      availability: [3],
      title: "devs",
      props: {
        alt: "---",
        icon: historyIcon,
        iconPadding: 2,
        text: "devs",
        styles: { borderColor: "#6dd76d" },
        setClicked: setClicked,
      },
    },
  ];

  const titles = {};
  links.forEach(
    (link) => (titles["/" + link.to] = link.title + " | " + mainTitle)
  );
  console.log(titles);

  return (
    <AppContext.Provider
      value={{
        accountID: googleAccountID,
        setAccountID: setGoogleAccountID,
        background: background,
        setBackground: setBackground,
        darkMode: darkMode,
        modal: modal,
        modal2: modal2,
        setModal: setModal,
        setModal2: setModal2,
        userType: user?.type,
        employeeID: user?.employeeID,
        setOrderParams: setOrderParams,
      }}
    >
      <div
        className={
          "mainEditor" +
          (darkMode ? " darkMode" : "") +
          (" background" + background)
        }
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            //e.preventDefault();
            setModal((prev) => ({ ...prev, show: false }));
          }
        }}
      >
        <div className="infobar">
          <div className="title">
            <div className="titleName">
              <Link to="./">System2</Link>{" "}
            </div>
            <div className="links">
              {links.map((item, index) =>
                item.availability.some((i) => i === parseInt(user.type)) ? (
                  <Link
                    key={index}
                    to={item.to}
                    className={"/" + item.to === currentSection ? "active" : ""}
                  >
                    {item.props.icon ? (
                      <img
                        style={{
                          boxSizing: "border-box",
                          padding: item.props.iconPadding + "px",
                        }}
                        src={item.props.icon}
                      ></img>
                    ) : (
                      <div style={{ height: "30px", width: "30px" }}>#</div>
                    )}
                    <span className="text">
                      {item.props.text.toUpperCase()}
                    </span>
                  </Link>
                ) : (
                  <></>
                )
              )}
            </div>
          </div>

          <div className="infos">
            <div
              className="notifications"
              title={
                notificationsActive
                  ? "Wyłącz powiadomienia"
                  : "Włącz powiadomienia"
              }
              onClick={() => {
                localStorage.setItem(
                  "orderNotificationsEnable",
                  !notificationsActive
                );
                setNotificationsActive((prev) => !prev);
              }}
            >
              <img
                style={{
                  width: "18px",
                  cursor: "pointer",
                  filter: notificationsActive
                    ? "saturate(1.5) brightness(.92)"
                    : "saturate(0)",
                }}
                src={alarmIcon}
              />
            </div>
            <div
              className="userName"
              onClick={() =>
                new Notification(`Jesteś zalogowany jako ${user.name}`)
              }
            >
              {user.name}{" "}
            </div>
            <div
              className="smallButton"
              onClick={() => {
                setDarkMode((prev) => !prev);
                localStorage.setItem("darkmode", String(!darkMode));
              }}
            >
              <span className="text">motyw:</span>{" "}
              {darkMode ? "ciemny" : "jasny"}
            </div>
            <div
              className="smallButton"
              onClick={() =>
                setModal((prev) => ({
                  show: true,
                  content: <Settings />,
                  width: "wide",
                }))
              }
            >
              Ustawienia
            </div>

            <div
              className="smallButton"
              onClick={() => {
                logOut();
              }}
            >
              Wyloguj
            </div>
          </div>
        </div>
        <Routes>
          <Route
            path="timesheet/*"
            key={1}
            element={
              <Timesheet orders={orders} handleModal={[modal, setModal]} />
            }
          />
          <Route path="work-time/*" element={<WorkTime user={user} />} />
          <Route
            path="work-time-reports/*"
            element={<WorkTimeReports user={user} />}
          />
          <Route path="orders/edit-documents/*" element={<DocumentEditor />} />
          <Route
            path="tables/*"
            element={
              <Tables />
              //<Translators/>
              //<InvoicesTable/>
              // <AttendanceTable/>
              // <OrdersTable/>
            }
          />
          <Route
            path="orders/*"
            element={
              <CurrentOrders
                orders={orders}
                recentOrders={recentOrders}
                orderParams={orderParams}
                setOrderParams={setOrderParams}
                translators={translators.active}
                handleModal={[modal, setModal]}
                loggedIn={loggedIn}
                user={user}
                darkMode={darkMode}
                orderNotification={orderNotification}
                notificationsActive={notificationsActive}
              />
            }
          />
          <Route
            path="holidays/*"
            element={
              <Holidays
                loggedIn={loggedIn}
                user={user}
                translators={translators.active}
              />
            }
          />
          <Route
            path="holiday-year-view"
            element={
              <HolidayYear
                loggedIn={loggedIn}
                user={user}
                // translators={translators.active}
              />
            }
          />
          <Route
            path="repertorium/*"
            element={
              <SwornRegistry orders={orders} handleModal={[modal, setModal]} />
            }
          />
          <Route path="zestawienia/*" element={<Listing />} />
          <Route path="obsluga/*" element={<CustomerService />} />

          <Route
            path="externals/*"
            element={
              <Externals
                orders={orders}
                translators={translators}
                updateTranslators={updateTranslators}
                setUpdateTranslators={setUpdateTranslators}
                handleModal={[modal, setModal]}
                user={user}
              />
            }
          />
          <Route path="invoices/*" element={<Invoices />} />
          <Route path="attendance/*" element={<Attendance />} />
          <Route path="now/*" element={<Now />} />
          <Route
            path="/"
            element={
              <div className="mainButtons" ref={mainButtons}>
                {links.map((item, index) =>
                  item.availability.some((i) => i === parseInt(user.type)) ? (
                    <Link key={index} to={item.to}>
                      <MainButton {...item.props} />
                    </Link>
                  ) : (
                    <></>
                  )
                )}
              </div>
            }
          />
        </Routes>

        <Modal {...modal} setData={setModal}></Modal>
        <Modal {...modal2} setData={setModal2}></Modal>
      </div>
    </AppContext.Provider>
  );
  {
    /*) : (
    <div className="loading">
      <img src={ripple} alt="Pobieranie danych"></img>
    </div>
  );*/
  }
}

export default MainEditor;
