import { useContext } from "react";
import { TableContext } from "../TableUniversal";
import Row from "./Row";
import Loading from "../../main/Loading";

export default function Body() {
  const { data, dataRaw, groupBy, loading, headings, selectable, setUpdate, search } =
    useContext(TableContext);

  // console.log(data, dataRaw)
  return data && data.length && !loading ? (
    <tbody>
      {data.map((row, index, rows) => {
        const groupProp = Array.isArray(groupBy) ? groupBy[0] : groupBy;
        const groupTransform =
          Array.isArray(groupBy) && groupBy[1] ? groupBy[1] : (d, a) => d;
        //prep for comparison (third argument)
        const prep =
          Array.isArray(groupBy) && groupBy[2] ? groupBy[2] : (d, a) => d;

        const thisProps = dataRaw[index];
        const prevProps = dataRaw[index - 1];

        return (
          <>
            {(thisProps[groupProp] !== undefined &&
              prevProps &&
              prep(prevProps[groupProp]) !== prep(thisProps[groupProp])) ||
            (thisProps[groupProp] !== undefined && index === 0) ? (
              <tr className="group-heading" key={thisProps?.id}>
                <td
                  colSpan={headings.length + 1 + (selectable === true ? 1 : 0)}
                >
                  {groupTransform(thisProps[groupProp], thisProps, setUpdate, search)}
                </td>
              </tr>
            ) : (
              <></>
            )}
            <Row
              key={thisProps?.id}
              data={row}
              raw={dataRaw[index]}
              i={index}
            />
          </>
        );
      })}
    </tbody>
  ) : loading ? (
    <tr>
      <td>
        <Loading text="Pobieranie..." />
      </td>
    </tr>
  ) : (
    <tr>
      <td colSpan={2}>
        <div className="loading-table">
          <div
            style={{
              background: "lightgrey",
              color: "orangered",
              padding: "5px",
              width: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              fontSize: "25px",
            }}
          >
            ✖
          </div>
          <div>Nie znaleziono wpisów</div>
        </div>
      </td>
    </tr>
  );
}
