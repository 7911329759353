import React, { useState, useEffect } from 'react'
import { URL } from "../../global";
import paperPlaneIcon from "../../../images/icons/paper-plane.svg";
import editIcon from "../../../images/icons/icons8-edit-26.png";
import approveIcon from "../../../images/icons/icons8-approve-32.png";

function TranslatorItem(props) {
    const { item, setUpdates, setSub, setModal } = props;
  
    function restoreUser(id) {
        //console.log(sub.data);
        fetch(URL + "components/db-user-edit.php", {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ queryType: "restore", query: { id: id } }),
        }).then((response) => {
          setUpdates((prev) => prev + 1);
          setModal(prev => ( {...prev, show:false, content: <div>Przywrócono użytkownika.</div>}));
          return response.json();
        });
    }

    return (
        <div style={{ opacity: item.Status === 0 ? ".35" : "1" }}>
          <span>
            {item.Type == 2 ? (
              <img
                style={{ width: "13px", opacity: ".4" }}
                src={paperPlaneIcon}
              />
            ) : (
              <></>
            )}{" "}
            {item["First Name"]} {item["Last Name"]}
          </span>
          {item["Rate"] != 0 ? (
            <span>
              {item["Rate"]} zł/{item["RateUnit"]} {item["RateUnitName"]}
            </span>
          ) : (
            <span>-</span>
          )}
          {item.Status === 1 ? (
            <img
              src={editIcon}
              onClick={() =>
                setSub((prev) => ({ ...prev, show: !prev.show, data: item }))
              }
            />
          ) : (
            <img
              src={approveIcon}
              onClick={(e) => {
                e.preventDefault();
                if (window.confirm("Czy chcesz przywrócić?")) {
                restoreUser(item.id);
                }
              }}
            />
          )}
        </div>
    )
}

export default TranslatorItem;