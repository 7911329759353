import { useState } from "react";
import DocumentTableRow from "./DocumentTableRow";
import prettyNumber from "../../../tables/functions/prettyNumber";

export function toPages(chars, sworn) {
  let page = 1600;
  if (sworn) {
    page = 1125;
  }
  let pages = chars / page;
  return pages < 1
    ? 1
    : sworn && chars < 1600
    ? 1
    : Math.round(pages * 100) / 100;
}

export default function DocumentsTable({ order, docEditor }) {
  const documents = JSON.parse(order.documents);

  return (
    <div className="order-monitor-single-documents">
      <table className="order-monitor-doc-table">
        <thead>
          <tr>
            <th>Lp.</th>
            <th>Dokument</th>
            <th>Typ</th>
            <th>Tłumacz</th>
            <th>Znaki</th>
            <th>Str. obl.</th>
            <th>z tł.</th>
            <th>Rabat</th>
            <th>Specj.</th>
            <th>Stawka brutto</th>
            <th>Wartość brutto</th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((d, ind) => (
            <DocumentTableRow
              key={d.id}
              order={order}
              d={d}
              ind={ind}
              pagesEdited={docEditor.pagesEdited}
              setPagesEdited={docEditor.setPagesEdited}
              netValues={docEditor.netValues}
              grossValues={docEditor.grossValues}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}></td>

            <th className={documents?.find((d) => !d.chars) ? "draft" : ""}>
              {documents
                ?.map((d) => d.chars || d.chars_ts)
                ?.reduce((a, b) => a + b, 0)}
            </th>
            <th>
              {documents?.map((d) => d.pages_count)?.reduce((a, b) => a + b, 0)}
            </th>
            <th style={{ position: "relative" }}>
              <div
                style={
                  docEditor.sumPagesEdited
                    ? {
                        fontSize: "12px",
                        color: "green",
                        position: "absolute",
                        left: 0,
                        top: 0,
                      }
                    : {}
                }
              >
                {documents && documents.find((d) => d.chars | d.chars_ts) ? (
                  Math.round(
                    documents
                      ?.map((d) =>
                        toPages(
                          d.chars || d.chars_ts,
                          d.type_of_translation === "przysięgłe" ? true : false
                        )
                      )
                      ?.reduce((a, b) => a + b, 0) * 100
                  ) / 100
                ) : (
                  <></>
                )}
              </div>
              {docEditor.sumPagesEdited ? (
                <div style={{ color: "orange" }}>
                  {docEditor.sumPagesEdited}
                </div>
              ) : (
                <></>
              )}
            </th>

            <td colSpan={3}></td>
            <th>
              {docEditor.grossValueSum ? (
                <div>
                  {prettyNumber(docEditor.grossValueSum, {
                    style: docEditor.grossValueSum ? { color: "orange" } : {},
                  })}
                </div>
              ) : (
                Math.round(
                  documents
                    ?.map((d) => d.price_gross)
                    ?.reduce((a, b) => a + b, 0) * 100
                ) / 100
              )}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
