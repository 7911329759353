import React, { useState } from "react";

export const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    handleChange: (event) => {
      setValue(prev => ({
          ...prev,
          [event.target.name]: event.target.value
      }));
    },
  };
};
