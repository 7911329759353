export default function TextType({v, set}) {
    return (
      <div
        className="TStextType"
        onClick={() => set}
      >
        {v ? <span>{v}</span> : <></>}
      </div>
    );
  }
  