import { useRef, useState } from "react";
import { dateToSQLTime } from "../dateToSQL";

export default function TimeEdit({ ts, lastEmpty, workStarted, defaults, startTime, setStartTime }) {
  const timeEditor = useRef();
  const [timeEditView, setTimeEditView] = useState(false);
  const now = new Date();  

  return (
    <>
      <div>
        <span style={{ fontSize: "10px" }}>od</span>
        <div
          className="setTime"
          style={{
            fontSize: "24px",
            display: "flex",
            paddingBottom: "5px",
            alignItems: "center",
          }}
        >
          <>
            {timeEditView ? (
              <input
                type="time"
                value={startTime}
                ref={timeEditor}
                onChange={(e) => setStartTime(e.target.value)}
                max={
                  ts ? ts.at(-1)?.endWork?.split(" ")[1]?.slice(0, -3) : null
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setStartTime(e.target.value);
                    setTimeEditView(false);
                  }
                }}
                step={60}
              />
            ) : (
              <span
                onClick={() => {
                  setTimeEditView(true);
                }}
              >
                {startTime.length === 8 ? startTime.slice(0, -3) : startTime}
              </span>
            )}
            {timeEditView ? (
              <>
                <span
                  className="acceptIcon"
                  onClick={() => setTimeEditView((prev) => !prev)}
                >
                  ✓
                </span>
              </>
            ) : (
              <span
                className="editIcon"
                onClick={() => setTimeEditView((prev) => !prev)}
              >
                ✎
              </span>
            )}
          </>
        </div>
      </div>
    </>
  );
}
