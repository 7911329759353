import React, { useRef } from "react";
import agitLogo from "./images/agit-tlumaczenia-300x109.png";
import { useInput } from "./components/useInput";
import { useNavigate, Link } from "react-router-dom";
import { URL } from "./components/global";

function Login(props) {
  const { loggedIn, setLoggedIn, user, setUser, logOut } = props;
  const navigate = useNavigate();
  const resultMessage = useRef(null);
  const { value, handleChange } = useInput({
    username: "",
    password: "",
  });

  function makeLoginAttempt() {
    fetch(URL + "components/login.php", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: `login=${value.username}&password=${value.password}`,
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.accept) {
          setLoggedIn(result.accept);
          setUser({
            name: result.user,
            id: result.userID,
            type: result.type,
            employeeID: result.employeeID,
            languages: result.languages
          });
          navigate("/");
        } else {
          resultMessage.current.innerHTML = "Nieprawidłowe dane lub błąd logowania.";
        }
        //return response.json();
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (value.username && value.password) {
      makeLoginAttempt();
    }
    else {
      resultMessage.current.innerHTML = "Uzupełnij pola."
    }
  }

  return (
    <div className="loginModule">
      <div className="wrapper">
        <div className="splash">
          <div className="logo">
            <img className="logoimg" src={agitLogo} />
          </div>
          {loggedIn ? (
            <button onClick={logOut}>Wyloguj</button>
          ) : (
            <form onSubmit={handleSubmit} className="loginform">
              <h1>Logowanie do systemu</h1>
              <div className="inwrap">
                <input
                  value={value.username}
                  placeholder="użytkownik"
                  type="user"
                  name="username"
                  onChange={handleChange}
                />

                <br />
                <input
                  value={value.password}
                  placeholder="hasło"
                  type="password"
                  name="password"
                  onChange={handleChange}
                />
                <br />
                <input type="hidden" name="continue" />
                <button type="submit">Zaloguj</button>
                <div ref={resultMessage} className="result">
                  {loggedIn ? `Zalogowano jako ${user}` : ""}
                </div>
              </div>
            </form>
          )}
        </div>
        <div className="register-button"><Link to="/register">Rejestracja</Link></div>
      </div>
    </div>
  );
}

export default Login;
