import React, { useState, useEffect } from "react";
import OrderItem from "./orders/OrderItem";
import FullOrderItem from "./orders/FullOrderItem";
import Details from "./Details";
import { ordersPrepared, plDate } from "./functions";
//Images

import ActiveTimesheetItem from "./ActiveTimesheetItem";
import BackButton from "./BackButton";

function TranslationForm(props) {
  const [orderDescription, setOrderDescription] = useState("");
  const [orderData, setOrderData] = useState();
  const [timesheetNow, setTimesheetNow] = props.status;

  const orders = props.orders.data;

  const [filteredDOM, setFilteredDOM] = useState("");
  //console.log(orders)

  // when clicked
  const updateInput = (e) => {
    let newInput = "";
    let target = e.target;
    if (!e.target.classList.contains("item")) {
      target = e.target.parentNode;
    }

    newInput = target.dataset.number;
    //console.log(newInput)

    setOrderDescription("");

    const currentOrder = ordersPrepared(orders).filter(
      (item) => item["id"] === target.dataset.id
    );
    if (!currentOrder || !currentOrder.length) return null;
    const currentID = currentOrder[0]["id"];
    /*const documents = props.documents.filter(
      (doc) => doc["id_orders"] === currentID
    );*/
    console.log(currentID);

    setOrderData(
      currentOrder.map((item) => (
        <FullOrderItem
          data={item}
        />
      ))
    );
  };

  //effect:
  const updateFiltered = () => {
    if (orderDescription !== "") {
      let filtered = orders.filter((item) => {
        return item["valid_order"].indexOf(orderDescription) === 0;
      });

      setFilteredDOM(
        filtered.map((item) => (
          <OrderItem
            validOrder={item["valid_order"]}
            clientName={item["customers_name"]}
            key={item["id"]}
            id={item["id"]}
          />
        ))
      );

      setOrderData("");
    } else {
      setFilteredDOM("");
    }
  };

  const addToTimesheet = () => {
    const orderDataSpecific = orderData[0].props;

    const now = new Date();
    setTimesheetNow({
      order: orderDataSpecific,
      timeStart: now,
      active: true,
    });
    console.log(timesheetNow);
  };

  useEffect(updateFiltered, [orderDescription]);

  const rawData = orderData ? orderData[0].props.data : {};

  return (
    <>
      {!timesheetNow.active ? (
        <form className="specificForm translation" data-type="translation">
          <BackButton link="../activity"/>
          <label>
            <div className="labelWrapper">
              <div className="label">Numer zlecenia</div>
              <input
                name="orderDescription"
                type="text"
                value={orderDescription}
                onChange={(e) => setOrderDescription(e.target.value)}
                autoComplete="off"
                autoFocus
              ></input>
              <div className="found" onClick={updateInput}>
                {filteredDOM}
              </div>
              {orderData ? (
                <Details order={orderData} addToTimesheet={addToTimesheet} />
              ) : (
                <></>
              )}
            </div>
          </label>
        </form>
      ) : (
        <ActiveTimesheetItem
          timesheetNow={timesheetNow}
          setTimesheetNow={setTimesheetNow}
          orderData={orderData}
          rawData={rawData}
          setUpdateCount={props.setUpdateCount}
          newItem={true}
        />
      )}
    </>
  );
}

export default TranslationForm;
