export default function ExecutiveDailyText({ data, translators }) {
  return (
    <ol className="ugly-list">
      <li>
        Ile stron przetłumaczonych - biuro:{" "}
        <strong>{data.english.translatedPages}</strong> ang /
        {/*} <strong>{loading2 ? <>---</> : proofreadPagesEnglish}</strong>{" "}
                korekta;{" "}*/}{" "}
        <strong>{data.german.translatedPages}</strong> niem /
        {/*} <strong>{loading2 ? <>---</> : proofreadPagesGerman}</strong>{" "}
                korekta;*/}{" "}
        (liczba tłumaczy: <strong>{translators.english}</strong> ang,{" "}
        <strong>{translators.german}</strong> niem)
        {data.all.orderless.pages ? (
          <div
            title={`${
              data.all.orderless.chars
            }\r\n${data.all.orderless.documents.split(",")}`}
          >
            Przetł. strony bez zlecenia (próbne, wewn.): <strong>{data.all.orderless.pages}</strong>
          </div>
        ) : (
          <></>
        )}
      </li>
      <li>
        Ile zleceń zostało wydanych klientom:{" "}
        <strong>{data.all.givenOrders}</strong>
      </li>
      <li>
        Ile zleceń przyjętych łącznie <strong>{data.all.receivedOrders}</strong>
        : <strong>{data.english.receivedOrders}</strong> ang,{" "}
        <strong>{data.german.receivedOrders}</strong> niem,{" "}
        <strong>{data.other.receivedOrders}</strong> inne
      </li>
      <li>
        Od jakich klientów (przychodzący, instytucje, maile):{" "}
        <strong>{data.all.customerTypes.institutions}</strong> instytucje,{" "}
        <strong>{data.all.customerTypes.mail}</strong> mail,{" "}
        <strong>{data.all.customerTypes.person}</strong> os
      </li>
      <li>
        Ile stron weszło do realizacji z angielskiego, a ile z niemieckiego,
        biuro:
        <br />
        <strong>{data.english.receivedPages}</strong> ang,{" "}
        <strong>{data.german.receivedPages}</strong> niem,
      </li>
      <li>
        Ile jest obecnie stron angielskich w tłumaczeniu:
        <br />
        tł:{" "}
        {data.english.pagesToTranslateEditor ? (
          <strong title={data.english.pagesToTranslate}>
            {data.english.pagesToTranslateEditor}
          </strong>
        ) : (
          <strong>
            {data.english.pagesToTranslate > 0
              ? data.english.pagesToTranslate
              : 0}
            {/*({pages.englishPagesAvailable} - {pages.englishTranslatedAdjustedToOriginal})*/}
          </strong>
        )}
        {/*kor:{" "}
                <strong>{loading2 ? <>---</> : pages.englishProofread}</strong>*/}
      </li>
      <li>
        Ile jest obecnie stron niemieckich w tłumaczeniu: <br />
        tł:{" "}
        {data.german.pagesToTranslateEditor ? (
          <strong title={data.german.pagesToTranslate}>
            {data.german.pagesToTranslateEditor}
          </strong>
        ) : (
          <strong>
            {data.german.pagesToTranslate > 0
              ? data.german.pagesToTranslate
              : 0}
            {/*({pages.englishPagesAvailable} - {pages.englishTranslatedAdjustedToOriginal})*/}
          </strong>
        )}
        {/* kor:{" "}
                <strong>{loading2 ? <>---</> : pages.germanProofread}</strong> */}
      </li>
    </ol>
  );
}
