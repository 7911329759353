export default function prettyNumber(number, params) {
  const numString = String(number);
  const numArr = numString.replace(".", ",").split(",");
  const integer = numArr[0];
  const decimals =
    numArr[1] ? "," + numArr[1] : "";

  let integerPretty = integer.replace(/(?<=\d)(?=(\d\d\d)+(?!\d))/g, " ");

  /*
    switch (integer.length) {
        case 4: 
            integerPretty = integer.at(0) + integer.slice(1,4);
        break;
        case 5:
            integerPretty = integer.at(0) + integer.slice(1,4);
        break;
    }*/

  return (
    <span
      className={params?.className}
      style={
        params && params.scale
          ? {
              ...params?.style,
              color: `rgb(0,${((integer / params.scale) * 90) % 255},${
                (integer / params.scale) * 50 % 200
              })`,
            }
          : { ...params?.style}
      }
    >
      {String(integerPretty) + String(decimals)} {params?.postfix}
    </span>
  );
}
