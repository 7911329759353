import TableUniversal from "../../TableUniversal";
import { propMap } from "./Dictionaries/SwornRegistryPropMap";

const dictionary = {
  year: {
    name: "Rok",
    order: "year, number",
  },
  number: {
    name: "Nr",
    order: "number",
  },

  client: {
    name: "Klient",
    order: "client",
    style: {
      width:"250px"
    },
  },
  document_name: {
    name: "Dokument",
    order: "document_name",
    style: {
      width:"200px"
    },
  },
  document_date: {
    name: "Data d.",
    order: "document_date",
    style: {
      width:"100px"
    },
    transform: (v) => !isNaN(new Date(v).getTime()) ? new Date(v).toLocaleDateString() : "- brak -"
  },
  document_reference: {
    name: "Nr dok."
  },
  drawn_up_by: {
    name: "Sporządził",
    style: {
      width: "150px"
    }
  },
  order_number: {
    name: "Nr zl.",
    order: "order_number",
  },
  date_added: {
    name: "Dodany",
    order: "date_added",
  },
  user_name: {
    name: "Użytk.",
    order: "user_name",
  },
};

export default function SwornRegistryTable() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Repertorium"
        defLimit={10}
        data="sworn-registry-table"
        classNames="big"
        dictionary={dictionary}
        orderBy="number"
        orderDesc="DESC"
        propMap={propMap}
        searchPattern={{
          year: {
            value: new Date().getFullYear(),
            element: "input",
            type: "number",
          },
          number: {
            value: "",
            exact: true,
          },
          client: "",
          order_number: "",
        }}
      />
    </div>
  );
}
