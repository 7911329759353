import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useFormikContext } from "formik";
import { ContentState, EditorState, convertToRaw } from "draft-js";

export default function TextEditor({ name }) {
  const { initialValues,values, setFieldValue } = useFormikContext();
  const value = initialValues[name];

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (value) {
      const html = "" + value + "";
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [initialValues]);

  useEffect(() => {
    setFieldValue(
      name,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  }, [editorState]);

  function onEditorStateChange(editorState) {
    setEditorState(editorState);
  }
  return (
    <>
      <Editor
        localization={{ locale: "pl" }}
        toolbar={{
          //inline: { inDropdown: true },
          textAlign: { inDropdown: true },
          // list: { inDropdown: true },
          blockType: {
            inDropdown: true,
            options: ["Normal", "H1", "H2", "H3"],
          },
          options: [
            "inline",
            "blockType",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "remove",
            "history",
          ],
        }}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="rdw-editor-main"
        onEditorStateChange={onEditorStateChange}
      />
      {/*editorState ? <textarea
      style={{fontSize:"9px", display:"block"}}
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    /> : <></>*/}
    </>
  );
}
