export const invoiceSchema = [
  {
    category: "Podstawowe",
    properties: [
      "number_facture",
      "tax_rate",
      "price",
      "price_brutto",
      "pro_forma",
      "pro_forma_used_id",
      "pro_forma_used_number",
      "pro_forma_used_date",
      "document_sell_receipt_number",
      "duplicate",
      "duplicate_date",
      "create_date",
      "last_modification_date",
      "date_issue",
      "date_sell",
      "users_name",
      "users_initials",
      "status",
      "cancel_reason",
      "cancel_date",
      "discount_for_all_documention",
      "section_type",
      "active",
    ],
  },
  {
    category: "Zlecenie",
    properties: [ "orders_number", "language_main"],
  },
  {
    category: "Klient",
    properties: [
      "customers_name",
      "customers_email",
      "customers_phone",
      "customers_nip",
      "customers_pesel",
      "customers_street",
      "customers_town",
      "customers_post_code",
      "customers_nr_place",
      "customers_nr_home",
      "customers_countries_name",
      "customers_private_firm",
      "customers_branch_id",
      "customers_branch_name",
      "is_customers_info",
    ],
  },

  {
    category: "Płatność",
    properties: [
      "name_payer",
      "nip_payer",
      "street_payer",
      "town_payer",
      "post_code_payer",
      "payment_methods_name",
      "payment_methods_payed",
      "payment_methods_payed_date",
      "payment_methods_payed_user_name",
      "payment_days",
      "payment_maturity_date",
      "id_bank_accounts",
      "bank_accounts_name",
    ],
  },
  /*{
    category: "Sprzedający",
    properties: [
      "seller_name",
      "seller_phone",
      "seller_email",
      "seller_nip",
      "seller_street",
      "seller_town",
      "seller_post_code",
      "seller_nr_place",
      "seller_nr_home",
    ],
  },*/
];

export const invoiceFullSchema = [
  "id",
  "id_orders",
  "orders_number",
  "groupped",
  "pro_forma",
  "pro_forma_used_id",
  "pro_forma_used_number",
  "pro_forma_used_date",
  "advanced",
  "advanced_used_id",
  "advanced_used_number",
  "advanced_used_amount",
  "advanced_used_date",
  "document_sell_receipt_number",
  "duplicate",
  "duplicate_date",
  "create_date",
  "last_modification_date",
  "year",
  "month",
  "day",
  "date_issue",
  "date_sell",
  "language_main",
  "language_flag",
  "number",
  "number_facture",
  "name",
  "is_english",
  "id_users",
  "users_name",
  "users_initials",
  "id_users_edit",
  "users_edit_name",
  "users_edit_initials",
  "customers_id",
  "customers_countries_name",
  "customers_countries_flag",
  "customers_symbol",
  "customers_name",
  "customers_email",
  "customers_phone",
  "customers_nip",
  "customers_pesel",
  "customers_street",
  "customers_town",
  "customers_post_code",
  "customers_nr_place",
  "customers_nr_home",
  "customers_private_firm",
  "customers_branch_id",
  "customers_branch_name",
  "is_customers_info",
  "who_read_customers_info",
  "name_payer",
  "nip_payer",
  "street_payer",
  "town_payer",
  "post_code_payer",
  "seller_name",
  "seller_phone",
  "seller_email",
  "seller_nip",
  "seller_street",
  "seller_town",
  "seller_post_code",
  "seller_nr_place",
  "seller_nr_home",
  "status",
  "cancel_reason",
  "cancel_users_id",
  "cancel_users_name",
  "cancel_users_initials",
  "cancel_date",
  "id_payment_methods",
  "payment_methods_name",
  "payment_methods_payed",
  "payment_methods_payed_date",
  "payment_methods_payed_user_id",
  "payment_methods_payed_user_name",
  "payment_methods_payed_user_initials",
  "payment_days",
  "payment_maturity_date",
  "id_bank_accounts",
  "bank_accounts_name",
  "bank_accounts_number",
  "remarks",
  "price",
  "discount_for_all_documention",
  "tax_rate",
  "tax_rate_name",
  "price_brutto",
  "section_type",
  "id_regional_office",
  "active",
  "new entry",
];

export const invoiceFullSchemaDescription = {
  "id": "ID faktury",
  "id_orders": "ID zlecenia do faktury",
  "orders_number": "Dotyczy zlecenia",
  "groupped": "Faktura zbiorcza?",
  "pro_forma": "Pro forma?",
  "pro_forma_used_id": "ID odnośnej faktury pro forma",
  "pro_forma_used_number": "Nr odnośnej faktury pro forma",
  "pro_forma_used_date": "Data odnośnej faktury pro forma",
  "advanced": "Faktura zaliczkowa?",
  "advanced_used_id": "ID faktury, w której wykorzystano zaliczkę",
  "advanced_used_number": "Nr faktury, w której wykorzystano zaliczkę",
  "advanced_used_amount": "Kwota faktury zaliczkowej",
  "advanced_used_date": "Data faktury zaliczkowej",
  "document_sell_receipt_number": "Numer paragonu fiskalnego",
  "duplicate": "Wystawiono duplikat?",
  "duplicate_date": "Data wystawienia dupllikatu",
  "create_date": "Data utworzenia",
  "last_modification_date": "Data ostatniej modyfikacji",
  "year": "Rok",
  "month": "Miesiąc",
  "day": "Dzień",
  "date_issue": "Data wystawienia",
  "date_sell": "Data sprzedaży",
  "language_main": "Języki zleceń zafakturowanych",
  "language_flag": "Flagi języków zleceń",
  "number": "Numer kolejności faktury",
  "number_facture": "Numer faktury",
  "name": "Numer faktury",
  "is_english": "Wydruk po angielsku",
  "id_users": "ID osoby tworzącej fakturę",
  "users_name": "Nazwa osoby tworzącej fakturę",
  "users_initials": "Inicjały osoby tworzącej fakturę",
  "id_users_edit": "Nr osoby ostatnio edytującej fakturę",
  "users_edit_name": "Nazwa osoby ostatnio edytującej fakturę",
  "users_edit_initials": "Inicjały osoby ostatnio edytującej fakturę",
  "customers_id": "ID kontrahenta",
  "customers_countries_name": "Kraj klienta",
  "customers_countries_flag": "Flaga kraju klienta",
  "customers_symbol": "Symbol",
  "customers_name": "Nazwa",
  "customers_email": "E-mail",
  "customers_phone": "Telefon",
  "customers_nip": "NIP",
  "customers_pesel": "PESEL",
  "customers_street": "Ulica",
  "customers_town": "Miasto",
  "customers_post_code": "Kod pocztowy",
  "customers_nr_place": "Nr lokalu",
  "customers_nr_home": "Nr domu",
  "customers_private_firm": "Typ",
  "customers_branch_id": "Id filii",
  "customers_branch_name": "Nazwa filii",
  "is_customers_info": "Czy jest informacja od klienta",
  "who_read_customers_info": "Kto czytał informację od klienta",
  "name_payer": "Nazwa płatnika",
  "nip_payer": "NIP płatnika",
  "street_payer": "Ulica płatnika",
  "town_payer": "Miasto płatnika",
  "post_code_payer": "Kod pocztowy płatnika",
  "seller_name": "Nazwa sprzedawcy",
  "seller_phone": "Telefon sprzedawcy",
  "seller_email": "E-mail sprzedawcy",
  "seller_nip": "NIP sprzedawcy",
  "seller_street": "Ulica sprzedawcy",
  "seller_town": "Miasto sprzedawcy",
  "seller_post_code": "Kod pocztowy sprzedawcy",
  "seller_nr_place": "Nr lokalu sprzedawcy",
  "seller_nr_home":"Nr domu sprzedawcy",
  "status": "Status",
  "cancel_reason": "Przyczyna anulowania",
  "cancel_users_id": "Użytkownik, który anulował",
  "cancel_users_name": "Nazwa osoby anulującej",
  "cancel_users_initials": "Inicjały osoby anulującej",
  "cancel_date": "Data anulowania",
  "id_payment_methods": "Metoda płatności (id)",
  "payment_methods_name": "Metoda płatności",
  "payment_methods_payed": "Czy zapłacono",
  "payment_methods_payed_date": "Data opłacenia",
  "payment_methods_payed_user_id": "Osoba odnotowująca zapłatę (id)",
  "payment_methods_payed_user_name": "Osoba odnotowująca zapłatę",
  "payment_methods_payed_user_initials": "Inicjały osoby odnotowującej",
  "payment_days": "Dni płatności",
  "payment_maturity_date": "Termin płatności",
  "id_bank_accounts": "Konto bankowe",
  "bank_accounts_name": "Nazwa banku",
  "bank_accounts_number": "Nr rachunku",
  "remarks": "Uwagi",
  "price": "Wartość netto",
  "discount_for_all_documention": "Rabat dla wszystkich pozycji",
  "tax_rate": "Stawka VAT %",
  "tax_rate_name": "Stawka VAT",
  "price_brutto": "Wartość brutto",
  "section_type": "Tabela danych źródłowych",
  "id_regional_office": "ID biura regionalnego",
  "active": "Aktywna",
  "new entry": "Nowa",
};
