import TableUniversal from "../../TableUniversal";

export default function EmployeeTable() {
  const dictionary = {
    id: {
      name: "id",
    },
    first_name: {
      name: "Imię",
      order:"first_name"
    },
    last_name: {
      name: "Nazwisko",
      order: "last_name",
    },
    cardname: {
      name: "Nazwa rejestrator"
    },
    group_name: {
      name:"Grupa",
      order: "group_name"
    },
    location: {
      name: "Lokalizacja",
      style: {
        display:"none"
      }
    },
    
    group_location: {
      name:"Lokalizacja"
    }
  };
  return (
    <div className="fullscreen">
      <TableUniversal
        name="Pracownicy"
        dictionary={dictionary}
        data="employees-table"
        classNames="big"
        orderBy="last_name"
        orderDesc="ASC"
        searchPattern={{
          first_name: "",
          last_name: "",
          location: {
            value: "",
            exact:true,
            element: "select",
            options: {
              "wszystkie": "",
              "hotel": 0,
              "biuro": 1
            }
          }
        }}
      />
    </div>
  );
}
