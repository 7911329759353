export default function DocumentItem({
  props,
  item,
  index,
  setActive,
  length,
}: {
  props: any;
  item: any;
  setActive: any;
  index: number;
  length: number;
}) {
  const {
    name,
    document_name,
    type,

    pages_count_translator,
    translator_name,
    registry_number,
  } = item;

  const translators = translator_name.split(",").map((translator: string) =>
    translator
      .split(/[ -]/)
      .map((f: string) => f.slice(0, 1))
      .join("")
  );

  return (
    <div
      className="search-box-document-item"
      onClick={() => (setActive ? setActive(true) : undefined)}
    >
      {index !== undefined || props.index !== undefined ? (
        <div className="number">
          {index !== undefined ? index + 1 : props.index + 1}
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ fontSize: "11px", whiteSpace: "nowrap" }}>
        {name ? name : document_name ? document_name : type}
      </div>
      <div
        className="minor"
        style={{ textAlign: "center", width: "max-content" }}
      >
        {translators.map((item: any) => (
          <span> {item}</span>
        ))}
      </div>
      <div
        className="minor"
        style={{ textAlign: "center", background: "rgb(190,190,190)" }}
      >
        {pages_count_translator}
      </div>
      {registry_number ? (
        <div
          className="reg_number"
          style={{
            background: "#65d498",
            fontSize: "10px",
            padding: "1px 2px",
            display: "flex",
            marginLeft: "6px",
            borderRadius: "14px",
            color: "black",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {registry_number}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}
