import TableUniversal from "../../TableUniversal";

const dictionary = {
  cardperson: {
    name: "Osoba",
    order: "cardperson, date, time",
    style: {
      width: "200px",
      textAlign: "left",
    },
  },
  date: {
    name: "Data",
    style: {
      display: "none",
    },
  },

  time: {
    name: "Czas",
    order: "date, time",
    style: {
      width: "180px",
    },
    transform: (value, row) =>
      row.typeshort === 0 ? (
        <span style={{ color: "green" }}>▲ {value}</span>
      ) : row.typeshort === 1 ? (
        <span style={{ color: "orangered" }}>▼ {value}</span>
      ) : row.typeshort === 2 ? (
        <span style={{ color: "#25b8e7" }}>▲ {value}</span>
      ) : row.typeshort === 3 ? (
        <span style={{ color: "blue" }}>▼ {value}</span>
      ) : (
        ""
      ),
  },
  source_word: {
    name: "Miejsce",
    order: "source_word, date, time",
    style: {
      width: "150px",
    },
    /*transform: (value) =>
      value === "attendance" || value === "attendance_custom"
        ? "HOTEL"
        : value === "attendance_3maja" || value === "attendance_3maja_custom"
        ? "BIURO"
        : "",*/
  },
  mod_data: {
    name: "Dane zmiany",
    //style: { width: "100px" },
  },
};

export default function AttendanceTable() {
  return (
    <div className="attendance-table">
      <div className="top-bar"></div>
      <div
        className="tableWrapper"
        style={{ maxHeight: "90vh", overflowY: "auto", maxWidth: "100vw" }}
      >
        {/*<Translators/>*/}
        <TableUniversal
          data="attendance-all"
          name="Dane rejestratora czasu pracy"
          classNames="big"
          defLimit={15}
          searchPattern={{
            cardperson: {
                value: ""
            },
            source_word: {
                value: "",
                element: "select",
                options: {
                    "wszystkie": "",
                    "biuro": "biuro",
                    "hotel": "hotel"
                }
            },
            date: {
                value: "",
                element: "input",
                type: "date"
            },
            time: {
                value: "",
                element: "input",
                type: "time"
            },
          }}
          groupBy={[
            "date",
            (d) =>
              new Date(d).toLocaleDateString("pl-PL", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              }),
          ]}
          orderBy="date, time"
          orderDesc="DESC, ASC"
          options={{
            selectable: true,
          }}
          dictionary={dictionary}
        />
      </div>
    </div>
  );
}
