export default function BagItemOrder({ name, item, setContents }) {
  return (
    <div className="arrows-order">
      <span
        style={{ cursor: "pointer", paddingLeft: "1px", paddingRight: "1px" }}
        onClick={(e) => {
          setContents((prev) => {
            const thisContents = prev[name];
            const fromIndex = thisContents.indexOf(item);
            const element = thisContents[fromIndex];
            thisContents.splice(fromIndex, 1);
            thisContents.splice(fromIndex - 1, 0, element);
            return {
              ...prev,
              [name]: thisContents,
            };
          });
        }}
      >
        ◂
      </span>
      <span
        style={{ cursor: "pointer", paddingLeft: "1px", paddingRight: "1px" }}
        onClick={(e) => {
          setContents((prev) => {
            const thisContents = prev[name];
            const fromIndex = thisContents.indexOf(item);
            const element = thisContents[fromIndex];
            thisContents.splice(fromIndex, 1);
            thisContents.splice(fromIndex + 1, 0, element);
            return {
              ...prev,
              [name]: thisContents,
            };
          });
        }}
      >
        ▸
      </span>
      <span
        onClick={() =>
          setContents((prev) => {
            const afterDeletion = prev[name].filter((f) => f !== item);
            return {
              ...prev,
              [name]: afterDeletion,
            };
          })
        }
        style={{
          display: "inline-block",
          marginLeft: "5px",
          fontSize: "12px",
          color: "orangered",
          cursor: "pointer",
        }}
      >
        ✖
      </span>
    </div>
  );
}
