import { useContext, useEffect } from "react";
import { TableContext } from "../TableUniversal";
import { Link } from "react-router-dom";

export default function SearchWindow({
  data,
  name,
  target,
  targetColumn,
  display,
  visible,
  setVisible,
  setInput,
  setInputData,
  searchLink,
  setSearchDisplay,
  ignore,
  setClearPreFields,
  standard,
  standardDB,
  inputElement,
  exact
}) {
  const {
    setSearch,
    search,
    searchPattern,
    setInitialize,
    setIgnoreColumns,
    loading,
  } = useContext(TableContext);

/*
  useEffect(() => {
    if (document.activeElement === inputElement.current) {
      setVisible(true);
    }
   
  },
  [inputElement.current]);*/

  //    console.log(searchPattern[target])
  return (
    <div className={"search-window" + (visible ? " active" : "")}>
      {data && data.length === 0 ? 
      <div style={{padding:"2px 5px", fontSize:"11px", color:"grey", fontStyle:"italic"}}>Brak wyników</div>
      : data ? (
        data
          .map((prop) => ({
            data: prop,
            view: display(prop),
            value: prop?.[name],
            id: prop?.["id"],
          }))
          .map((prop) =>
            searchLink ? (
              <Link
                state={prop.data}
                to={`?${searchLink[0]}=${prop.data[searchLink[1]]}`}
                key={prop?.id}
              >
                <div
                  onClick={() => {
                    setSearchDisplay(prop.view);
                    setInput(prop.value);
                    setInputData(prop.data);
                    setVisible(false);

                    if (ignore) {
                      setIgnoreColumns(ignore);
                    }
                  }}
                >
                  {prop.view}
                </div>
              </Link>
            ) : (
              <div
                onClick={() => {
                  setInput(prop.value);
                  setInputData(prop.data);
                  setVisible(false);
                  console.log(prop.data, target);
                  setSearch((prev) => ({
                    ...prev,
                    [target]: {
                      ...prev[targetColumn],
                      value: prop?.data?.[targetColumn],
                      exact: exact === undefined ? true : exact,
                      standard: standard,
                      db: standardDB,
                    },
                  }));
                  setInitialize(false);
                  setClearPreFields(false);
                  if (ignore) {
                    setIgnoreColumns(ignore);
                  }
                }}
              >
                {prop.view}
              </div>
            )
          )
      ) : (
        <div style={{ padding: "5px 20px", fontSize: "13px" }}>
          Wyszukiwanie...
        </div>
      )}
    </div>
  );
}
