import { useEffect, useState } from "react";
import prettyDate from "../../../functions/prettyDate";

export default function SettlementList({
  index,
  selectedFull,
  settlement,
  settlementDone,
  currentInput,
  setCurrentInput,
  setSettlement,
  editor,
  createOpen,
  setCreateOpen,
  comeBack,
}) {
  const done =
    settlementDone &&
    settlementDone.join(",").match(selectedFull.map((s) => s.id).join(","));

  /*
  useEffect(() => {
    if (settlement && settlement.length) {
      setCurrentInput(selectedFull);
    }
  }, [settlement]);*/

  return (
    <div className="settlement-list-group">
      <div className="summary">
        <div>{selectedFull && selectedFull[0].translator_name}</div>
        <div style={{ fontSize: "15px", textAlign: "center" }}>
          {selectedFull &&
            Math.round(
              selectedFull
                .map((i) => Math.round(parseFloat(i.amount) * 100) / 100)
                .reduce((a, b) => a + b, 0) * 100
            ) /
              100 +
              " zł"}
          {editor && !done && (createOpen === false || createOpen !== index) ? (
            <button
              onClick={() => {
                setCurrentInput(selectedFull);
                setCreateOpen(index);
              }}
              className="settle-button"
            >
              ROZLICZ
            </button>
          ) : done ? (
            <div
              style={{ cursor: "initial", background: "lightgrey" }}
              className="settle-button"
            >
              ✓ Rozliczone
            </div>
          ) : (
            <div
              style={{ cursor: "initial",  }}
              className="settle-button"
            >
              ▶
            </div>
          )}
        </div>
      </div>
      {selectedFull &&
        selectedFull.map((i, index) => (
          <div key={i.id} className="item">
            <div>{index + 1}</div>
            <div className="item-details">
              <div>{i.order_number}</div>
              <div>{i.order_customer}</div>
              {editor ? (
                <div style={{ color: "grey", fontSize: "11px" }}>
                  {prettyDate(i.time_added)}
                </div>
              ) : (
                <></>
              )}
            </div>

            {i.amount ? (
              <div style={{ paddingRight: "12px" }}>
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                >
                  {parseFloat(i.amount)} zł
                </div>
                <div
                  style={{
                    textAlign: "right",
                    marginTop: "3px",
                    color: "grey",
                    fontSize: "8px",
                  }}
                >
                  <span style={{ fontStyle: "italic" }}>
                    {parseFloat(i.rate)} zł
                  </span>{" "}
                  x {parseFloat(i.quantity)}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div
              className="close"
              onClick={() => {
                console.log(settlement);
                if (settlement.length === 1) {
                  comeBack();
                }
                setSettlement((prev) => {
                  return prev.filter((f) => f !== i.id);
                });
              }}
            >
              ✖
            </div>
          </div>
        ))}
    </div>
  );
}
