import React from "react";

function OrderAdditionalInfo(props) {
    const { item, view, details, params } = props;
    return (
        <div className="orderAdditional">
                {params.type === "archive" ? (
                  <span
                    style={{
                      fontWeight: "bold",
                      padding: "0 6px 0 0",
                      color: "#407c87",
                    }}
                  >
                    GOTOWE
                  </span>
                ) : (
                  <></>
                )}
                {item.users_name}{" "}
                <span
                  style={{
                    marginLeft: "5px",
                    fontWeight: view.fresh ? "bold" : "normal",
                  }}
                >
                  {new Date(item.create_date)
                    .toLocaleString("pl-PL")
                    .slice(0, -3)}
                </span>{" "}
                {!details ? (
                  <>
                    <span style={{ float: "right" }}>
                      {item.documents.length}{" "}
                      {Array.from([
                        ...new Set(
                          item.documents.map((doc) => doc.type_of_translation)
                        ),
                      ])
                        .join(", ")
                        .replace(/przysięgłe/, "uwierz.")
                        .replace(/zwykłe/, "zw.")}
                    </span>
                  </>
                ) : (
                  ""
                )}
              </div>
    )
}

export default OrderAdditionalInfo;