import { createContext, useEffect } from "react";

export const ModalContentContext = createContext();

export default function ModalContent({ content, passProps }) {
  const { setData, setCloseField, sub, setSub, updates, contentData } =
    passProps;


  return (
    <ModalContentContext.Provider value={passProps}>
      <div className="content">
        {content}
        {/*cloneElement(content, {
        setModal: setData,
        setData: setData,
        setCloseField: setCloseField,
        sub: sub,
        setSub: setSub,
        updates:updates
      })*/}
      </div>
    </ModalContentContext.Provider>
  );
}
