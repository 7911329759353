import React, { useEffect, useState, useContext } from "react";
import OrderContext from "./contexts/order-context";
import DocumentRow from "./DocumentRow";

function Documents({ order, content }) {
  //const context = useContext(OrderContext);
  const sumChars = content
    .map((item) => item.total_count)
    .reduce((a, b) => Number(a) + Number(b), 0);

  const moreThan2Done = content.filter(item => item.total_count > 0).length > 1;

  return (
    <div className="documents">
      <table className="documentsTable">
        <thead>
          <tr className="docsHeader">
            <th className="docNumber">Nr</th>
            <th className="docName">Nazwa</th>
            <th className="docPages">
              {(order?.customers_name === "Polska Agencja Prasowa S.A." || order?.customers_name === "Polska Agencja Prasowa S.A. w likwidacji") &&
              order.remarks_top_secret
                ? "Wyr."
                : "Str."}
            </th>
            <th className="docPrice">Cena Brutto</th>
            <th className="docRate">Stawka</th>
            <th className="docType">Typ</th>
            <th className="docTranslator">T</th>
            {/*<th className="docProofreader">S</th>*/}
            <th className="docCharacters">
              {moreThan2Done && sumChars > 0 ? (
                <span style={{ fontWeight: "700" }}>{sumChars}</span>
              ) : (
                <>Zn.</>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {content ? (
            <>
              {content.map((item, index, arr) => {
                if (item.id > 0 && item.id !== arr[item.id - 1]) {
                  return (
                    <DocumentRow
                      item={item}
                      order={order}
                      index={index}
                      key={item.id}
                    />
                  );
                }
              })}
            </>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Documents;
