import React, { useState, useEffect } from "react";
import ClockSince from "./ClockSince";
import stopButton from "../images/icons/stop-button.svg";
import pauseButton from "../images/icons/pause-button.svg";
import addToTimesheet from "./addToTimesheet";
import { useNavigate } from "react-router-dom";
import CommentArea from "./CommentArea";

function ActiveTimesheetItem(props) {
  const [displayDetails, setDisplayDetails] = useState(false);
  const [comment, setComment] = useState("");
  const [chars, setChars] = useState("");
  const { timesheetNow, setTimesheetNow, orderData, rawData, newItem } = props;
  let navigate = useNavigate();

  const timesheetPause = () => {
    // put here some logic to add another order/break/or else
    // put here some logic to add this to overall timesheet with resume option

    // reset timesheetNow
    setTimesheetNow({
      active: false,
    });
  };

  const timesheetEnd = () => {
    // put here some logic to add this to overall timesheet
    const now = new Date();
    addToTimesheet({
      queryType: "endItem",
      //date: now,
      //startTime: now,
      comments: comment,
      characters: chars ? parseInt(chars) : 0,
      endTime: now,
    });

    // reset timesheetNow
    setTimesheetNow({
      active: false,
    });
    props.setUpdateCount((prev) => prev + 1);
    navigate("../activity");
  };

  useEffect(() => {
    const now = new Date();
    console.log(rawData.valid_order);
    if (newItem) {
      addToTimesheet({
        queryType: "add",
        user: "tymczasowy",
        orderID: rawData.id,
        orderString: rawData.valid_order,
        date: now,
        startTime: now,
        lastTime: now,
        type: 0, // 0 - tłumaczenie, 1 - sprawdzanie, itd.
        status: 0,
        comments: "",
      });
      props.setUpdateCount((prev) => prev + 1);
    }
  }, []);

  return (
    <div className="currentTSitem">
      <div className="display">
        <div className="component orderShort">
          <h1 className="currentOrderNumber">{rawData.valid_order}</h1>
          <h3>{rawData.customers_name}</h3>
          <h3 title={rawData.finish_date}>
            {rawData.language_from} <span className="littleSign">➤</span>{" "}
            {rawData.language_to} - {rawData.orderDate} | {rawData.orderHour}
          </h3>
        </div>
        <div className="component time">
          od:{" "}
          {timesheetNow.timeStart
            ? timesheetNow.timeStart.toLocaleTimeString("pl-PL",{ hour: "2-digit", minute:"2-digit"})
            : ""}
        </div>
        {/*<div className="component timeSince">
          <ClockSince since={timesheetNow.timeStart} />
  </div>*/}
        <hr />
        <div className="buttons">
          <button onClick={timesheetPause}>
            <img src={pauseButton} width="20" />
            Przerwij
          </button>
          <button onClick={timesheetEnd}>
            <img src={stopButton} width="20" />
            Zakończ
          </button>
        </div>
        <div
          className="inputs"
        >
          <div>
            <label htmlFor="chars">Znaki ze spacjami</label>
            <input name="chars" type="number" value={chars} min="0" max="9999999" onChange={(e) => setChars(e.target.value)}/>
          </div>
          <div>
            <label htmlFor="comment">Uwagi</label>
            <CommentArea comment={[comment, setComment]} />
          </div>
        </div>
        <div className="orderData">
          <button onClick={() => setDisplayDetails((prev) => !prev)}>
            {!displayDetails ? "Pokaż szczegóły" : "Ukryj szczegóły"}
          </button>
        </div>
      </div>
      {displayDetails ? <div className="detailed">{orderData}</div> : <></>}
    </div>
  );
}

export default ActiveTimesheetItem;
