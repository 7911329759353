import DocumentItem from "../../main/TimesheetRev/NewItem/SearchBox/ResultTypes/DocumentItem";
import OrderItem from "../../main/TimesheetRev/NewItem/SearchBox/ResultTypes/OrderItem";
import NumberOrder from "../../tables/common/NumberOrder";
import orderIcon from "../../../images/icons/buttons/orders.svg";
import reverseIcon from "../../../images/icons/reverse.svg";
import onIcon from "../../../images/icons/switch-on.svg";
import offIcon from "../../../images/icons/switch-off.svg";

function smartReverseName(v) {
  v = v.replace(/ $/, "").replace(/  /, " ");
  if (v.split(" ").length === 2) {
    return v.split(" ").reverse().join(" ");
  } else if (v.split(" ").length === 3) {
    v = v.split(" ");
    let first = v.shift();
    return [...v, first].join(" ");
  } else if (v?.split) {
    return v;
  }
}

/*
[action_type: "Ang"
amount_received: 43.05
client: "PIT-RADWAR S.A."
copies: 1
copy_of_number: ""
date: "2023-10-06"
date_added: "2023-10-06T16:26:42.282Z"
document_condition: 1
document_date: ""
document_name: "test"
document_reference: "test"
document_reference_person_concerned: ""
document_return: ""
drawn_up_by:"test"
form: 1
gross_per_page: 43.05
number: ""
oral_translation: ""
order_id: "148196"
order_number: "522.09.23.ang"
pages: 1
rejection: ""
source_language: "Ang"
userID: 0
user_name: ""
year: 2023]*/

/*Dodatkowy egzemplarz dokumentu o nr rep.
Str. tłum.
Liczba egz.
Zwrot dok. z tłumaczeniem
Pobrane wyn. brutto (zł)*/

export const calculate = [
  ["pages", "gross_per_page", "copies"],
  (data, edited) => {
    const baseAmount = Math.round(data.pages * data.gross_per_page * 100) / 100;
    return {
      amount_received:
        Math.round(
          (baseAmount +
            // Math.round(data.copies > 1 ? (data.copies - 1) * 50 : 0),
            (data.copies > 1 ? (data.copies - 1) * baseAmount * 0.2 : 0)) *
            100
        ) / 100,
    };
  },
];

export const newFormData = [
  {
    order_hybrid: {
      label: "zlecenie",
      element: "hybrid",
      buttons: (item) => [
        item?.id ? (
          <div>
            <NumberOrder
              id={item?.id}
              content={<img src={orderIcon} />}
              modalID={2}
            />
          </div>
        ) : (
          <></>
        ),
      ],
      // In hybrid fields the hidden inputs that represent a selected object as a value (e.g. id)
      formFields: [{ name: "order_id", property: "id" }],

      // take defaults from these values
      /*defaultFrom: (values) => ({
        //client: values?.client,
        //id: values.order_id,
        number: values?.order_number?.slice(0, -4),
        //to: values?.source_language === "Pol" ? "Ang" : "Pol",
        //from: values?.source_language,
      }),*/

      defaultFromDB: (values) => ({
        name: "searchOrders",
        query: {
          id: values?.order_id,
        },
      }),
      setValues: (item) => ({
        client: item?.client
          ? item.client.replace(/  /, " ").replace(/ $/, "")
          : undefined,
        source_language: item?.from,
        action_type: item?.from,
        pages: item?.pages,
        order_number:
          item && item.number && item.mainLang
            ? item?.number.replace(/\//g, ".") +
              "." +
              item?.mainLang.toLowerCase()
            : "",
      }),
      searchBoxProps: {
        db: "searchOrders",
        species: "order",
        queryDefaults: {
          searchString: "",
          sworn: 1,
          language: "Ang",
        },
        queryDefaultOrder: ["finish_date", "ASC"],
        //updateOnChanged={}
        resultComponent: (props) => <OrderItem {...props} item={props.item} />,
        selectedComponent: (props) => (
          <OrderItem {...props} item={props.item} />
        ),
        genericSearch: false,
      },
      style: {
        //boxShadow: "0 0 25px rgba(0,0,0,.3) inset",
        background: "rgb(73, 133, 133)",
        //borderRadius: "0 0 8px"
      },
      labelStyle: {
        //fontWeight: "bold",
        color: "white",
      },
      inputStyle: {
        //boxShadow: "3px 3px 13px rgba(0,0,0,.3)",
      },
    },

    client: {
      label: "zleceniodawca",
      required: true,
      type: "text",
      buttons: (item) =>
        item.value &&
        ((item.value.split(" ").length > 1 &&
          item.value.split(" ").length < 4) ||
          (item.value.split("  ").length > 1 &&
            item.value.split("  ").length < 4))
          ? [
              <div
                onClick={() =>
                  item.setValue(item.name, smartReverseName(item.value))
                }
              >
                <img style={{ width: "16px" }} src={reverseIcon} />
              </div>,
            ]
          : [],
    },

    fromAndDate: {
      group: true,
      fields: {
        date: {
          label: "data wpisu",
          type: "date",
          style: { flexBasis: "50%" },
        },
        source_language: {
          label: "język źródłowy",
          element: "select",
          options: [
            {
              value: "Ang",
              text: "angielski",
            },
            {
              value: "Pol",
              text: "polski",
            },
          ],
        },
      },
    },

    action_type: {
      label: "czynność",
      element: "select",
      options: [
        { value: "Pol", text: "Poświadczenie na język angielski" },
        { value: "Ang", text: "Poświadczenie na język polski" },
        {
          value: "Pol/Ang",
          text: "Poświadczenie zgodności - dokument dwujęzyczny",
        },
        { value: "PolT", text: "* Tłumaczenie pisemne na język angielski" },
        { value: "AngT", text: "* Tłumaczenie pisemne na język polski" },
      ],
    },
    group3: {
      group: true,
      fields: {
        document_return: {
          label: "data zwrotu",
          type: "date",
        },
        copy_of_number: {
          label: "dodatkowy egz. -poprz. nr r.",
          style: {
            flexBasis: "170%",
          },
        },
      },
    },
    details: {
      group: true,
      fields: {
        copies: {
          label: "Liczba egz.",
          type: "number",
          style: { width: "90px" },
          min: 1,
          max: 100,
        },
        pages: {
          label: "Str. tłum.",
          type: "number",
          step: ".5",
          style: { width: "90px" },
          min: 1,
        },
      },
    },
  },
  {
    document_hybrid: {
      label: "dokument",
      //formFieldName: "document_id",
      formFields: [{ name: "document_id", property: "id" }],
      element: "hybrid",
      required: true,
      /*
      defaultFrom: (values) => ({
        id: values?.document_id,
        pages_count_translator: values?.pages,
        rate: Math.round((values?.gross_per_page / 1.23) * 100) / 100,
      }),*/
      updateOnChangedField: "order_id",
      defaultFromDB: (values) => ({
        name: values?.id_orders || values?.id ? "getDocsByOrder" : false,
        query: {
          search: {
            id_orders: { value: values?.order_id, exact: true, standard: true },
            id: {
              value: values?.document_id,
              exact: true,
              standard: true,
              db: "d",
            },
          },
        },
      }),
      setValues: (item) => {
        const baseRate = Math.round(item?.rate * 1.23 * 100) / 100;
        const discount = 1 - item?.discount / 100;
        const special = 1 + item?.special / 100;
        const express = 1 + item?.expert / 100;
        const discountHand = item?.discount_hand
          ? Math.floor(
              (item?.price_core / item?.pages_count_translator) * 1.23 * 100
            ) / 100
          : undefined;
        return {
          pages: item?.pages_count_translator,
          gross_per_page: discountHand
            ? discountHand
            : Math.floor(baseRate * 100 * discount * special * express) / 100,
          // Math.floor(
          //   (((((((Math.round(item?.rate * 1.23 * 100) / 100) *
          //     Math.round((1 - item?.discount / 100) * 100)) /
          //     100) *
          //     Math.round((1 + item.special / 100) * 100)) /
          //     100) *
          //     Math.round((1 + item.expert / 100) * 100)) /
          //     100) *
          //     100
          // ) / 100,
        };
      },
      /*setValues: (item) => ({
        docName: item && (item.name
          ? item.name
          : item.document_name
          ? item.document_name
          : item.type),
      }),*/
      style: {
        //boxShadow: "0 0 25px rgba(0,0,0,.3) inset",
        background: "rgb(62, 111, 111)",
        //borderRadius: "0 0 0 8px"
      },
      labelStyle: {
        color: "white",
      },
      searchBoxProps: {
        db: "getDocsByOrder",
        species: "documents",
        resultWindowStyle: {
          minWidth: "100%",
        },
        /*queryDefaults: {
          default: undefined,
          search: {
            id_orders: {
              value: "x",
              exact: true,
              standard: true,
            },
          },
        },*/
        searchBy: "document_name",
        resultComponent: (props, index) => (
          <DocumentItem props={props} {...props} index={index} />
        ),
        selectedComponent: (props, index) => (
          <DocumentItem props={props} {...props} index={index} />
        ),
      },
      searchBoxGenerateDefaults: (item) => ({
        default: !item?.id ? true : undefined,
        search: item?.id
          ? {
              id_orders: {
                value: item?.id,
                exact: true,
                standard: true,
              },
            }
          : undefined,
      }),
      searchBoxGetDefaultFrom: "order_hybrid",
    },
    document_name: {
      label: "nazwa dokumentu",
      type: "text",
      required: true,
    },
    docConditionAndDate: {
      group: true,
      fields: {
        document_date: {
          label: "data dokumentu",
          type: "date",
          style: { flexBasis: "50%" },
        },
        document_reference: {
          label: (values) =>
            values["document_reference_person_concerned"] === 1
              ? "Dotyczy (brak oznaczenia)"
              : "oznaczenie dokumentu",
          type: "text",
          buttonsStyle: {
            right: "18px",
            top: "4px",
          },
          buttons: (item) => [
            <div
              onClick={() => {
                item.setValue(
                  "document_reference_person_concerned",
                  item.values["document_reference_person_concerned"] === 1
                    ? 0
                    : 1
                );
                item.setLabel(
                  item.values["document_reference_person_concerned"] !== 1
                    ? "Dotyczy (brak oznaczenia)"
                    : "oznaczenie dokumentu"
                );
              }}
            >
              <img
                style={{ width: "20px" }}
                src={
                  item.values["document_reference_person_concerned"] === 1
                    ? onIcon
                    : offIcon
                }
              />
            </div>,
          ],
        },
      },
    },

    drawn_up_by: {
      label: "sporządził",
      type: "text",
      //required:false,
    },

    returnReceived: {
      group: true,
      fields: {
        form: {
          label: "rodzaj dokumentu",
          element: "select",
          options: [
            { value: 1, text: "oryginał" },
            { value: 2, text: "kopia" },
            { value: 3, text: "wydruk" },
            { value: 4, text: "elektroniczny" },
            { value: 5, text: "skan" },
          ],
        },
        document_condition: {
          label: "stan dokumentu",
          element: "select",
          style: {
            minWidth: "48%",
          },
          options: [
            {
              value: 1,
              text: "stan dobry",
            },
            {
              value: 2,
              text: "nieczytelny",
            },
          ],
        },
      },
    },

    money: {
      group: true,
      fields: {
        gross_per_page: {
          label: "stawka brutto",
          disabled: true,

          //hidden: true,
        },
        amount_received: {
          label: "pobr. wyn. brutto (zł)",
          type: "number",
          step: ".01",
        },
      },
    },

    document_reference_person_concerned: {
      //label: "dot.",
      hidden: true,
    },
    user_name: {
      label: "Nazwa użytkownika",
      disabled: true,
      hidden: true,
    },
    rejection: {
      hidden: true,
    },
    order_number: {
      label: "numer zlec.",
      disabled: true,
      hidden: true,
    },
    number: {
      label: "numer rep.",
      disabled: true,
      hidden: true,
    },
    oral_translation: {
      hidden: true,
    },
  },
];
