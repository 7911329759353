import { useGeneric } from "../../../useGeneric";
import { useSearchParams } from "react-router-dom";
import Loading from "../../../main/Loading";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import invoiceImage from "../../../../images/icons/buttons/invoice.svg";
import languageDisplay from "../displays/languageDisplay";
import GoogleDriveLink from "../../../orders/GoogleDriveLink";
import NumberOrder from "../../common/NumberOrder";

export default function PurchaseInvoiceView({ id, number, inModal, search }) {
  const [query, setQuery] = useSearchParams();
  const invoiceID = query.get("id") ? query.get("id") : id || search;

  const standardParams = { exact: true, standard: true, db: "f" };


  let zeroValues = false;

  const searchQuery = {};
  if (search) {
    const keys = Object.keys(search);
    keys.forEach((k) => {
      searchQuery[k] = { ...standardParams, value: search[k] };
      if (!search[k]) { zeroValues = true }
    });
    
  }
  

  const useData = useGeneric({
    name: invoiceID && !zeroValues ? "purchase-invoice-view" : undefined,
    query: {
      search: search
        ? searchQuery
        : {
            value: invoiceID,
            ...standardParams,
          },
    },
  });

  const invoice = !useData.loading ? useData.rows?.[0] : undefined;

  

  const orders =
    invoice && invoice.orders
      ? JSON.parse("[" + invoice.orders + "]")
      : undefined;

  return invoice ? (
    <div className={"invoice-view-new" + (inModal ? "" : " fullScreen")}>
      <div className="order-main">
        <div>
          <div className="invoice-number">
            <div>{invoice.name}</div>
            <div style={{ fontSize: "14px" }}>{invoice.source_doc_name}</div>
          </div>
          <div className="customer-name">{invoice.seller_name} </div>
          <div>{invoice.facture_types_description}</div>
          <div className="price-data">
            <div>
              <div>NETTO</div> <div>{prettyNumber(invoice.net_value)} zł</div>
            </div>
            <div>
              <div>BRUTTO</div>{" "}
              <div>{prettyNumber(invoice.gross_value)} zł</div>
            </div>
          </div>
        </div>
        <div>
          <div className="minor-label">Utworzona</div>
          {prettyDate(invoice.create_date)}
          {invoice.approve_person ? (
            <div style={{ fontSize: "10px" }}>{invoice.approve_person}</div>
          ) : (
            <></>
          )}
        </div>

        <div>
          <div style={{ marginBottom: "15px" }}>
            <div className="minor-label">Status</div>
            {invoice.is_payed ? (
              <div style={{ color: "green" }}>✓ ZAPŁACONA</div>
            ) : (
              <div style={{ color: "brown" }}>✖ NIEZAPŁACONA</div>
            )}
          </div>
          <div className="minor-label">
            <>Termin płatności</>
          </div>

          {prettyDate(invoice.payment_date, "", true)}
        </div>
      </div>
      <div className="partial-values">
        <table className="universal-table big">
          <thead>
            <tr>
              <th style={{ width: "30px" }}>Lp.</th>
              <th>Pozycja</th>
              <th>Stawka podatku</th>
              <th>Netto</th>
              <th>Podatek</th>
              <th>Brutto</th>
            </tr>
          </thead>
          <tbody>
            {invoice?.partial_values.split("#")?.map((v, ind) => {
              const a = v.split("|");
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{a[0]}</td>
                  <td>{a[1]}</td>
                  <td>{prettyNumber(a[2], { postfix: "zł" })}</td>
                  <td>{prettyNumber(a[3], { postfix: "zł" })}</td>
                  <td>{prettyNumber(a[4], { postfix: "zł" })}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {orders ? (
        <div className="order-documents">
          <table className="universal-table big">
            <thead>
              <tr>
                <th>Nr zlecenia</th>
                <th>Z</th>
                <th>Na</th>
                <th>Termin</th>
                <th>Przyjęte przez</th>
                <th>Strony</th>
                <th>Cena netto</th>
                <th>Cena brutto</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders).map((key) => {
                const i = orders[key];
                return (
                  <tr>
                    <td>
                      {
                        <NumberOrder
                          id={i.id}
                          number_order={i.number}
                          withLang={true}
                          lang={i.language}
                        />
                      }
                    </td>
                    <td style={{ width: "50px" }}>
                      {languageDisplay({ name: i.from }, i.from)}
                    </td>
                    <td style={{ width: "50px" }}>
                      {languageDisplay({ name: i.to }, i.to)}
                    </td>
                    <td>{prettyDate(i.dl)}</td>
                    <td>{i.usr}</td>
                    <td>{i.pages}</td>
                    <td>{prettyNumber(i.price)} zł</td>
                    <td>{prettyNumber(i.price_brutto)} zł</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : useData.loading ? (
    <Loading />
  ) : (
    <>Brak danych</>
  );
}
