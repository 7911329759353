export default function OrderDocument({
  doc,
  index,
  selectedOrder,
  setSelectedOrder,
  translatorData,
  types
}) {
  return (
    <div
      className={
        "document" + (selectedOrder.document_id == doc.id ? " selected" : "")
      }
      key={doc.id}
      onClick={() => {
        if (selectedOrder.document_id === doc.id) {
          setSelectedOrder((prev) => ({ ...prev, document_id: undefined }));
        } else {
          setSelectedOrder((prev) => ({ ...prev, document_id: doc.id }));
        }
      }}
    >
      <div>{index + 1}</div>{" "}
      <div style={{ justifyContent: "flex-start", padding:"3px 1px" }}>{doc.name}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {translatorData ? (
          translatorData.map((t) => {
            const translators =
              t?.translators && JSON.parse("[" + t.translators + "]");
            const translatorDetails =
              t?.ts_details && JSON.parse("[" + t.ts_details + "]");

            return (
              <div className="docTranslators" title={translatorData.comment}>
                {translators.map((translator) => {
                  const actionTypes = [...new Set(translatorDetails
                    .filter((d) => d.employeeID === translator.employeeID)
                    .map((d) => types.find(t => t.id === d.action_type).name))];
                  return (
                    <div className={actionTypes.join(",") === "sprawdzanie" ? "proofreading" : ""} title={actionTypes.join(",")}>
                      {translator.first_name} {translator.last_name}
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <></>
        )}

        {/*translators ? (
        doc.translators
          .split(",")
          .map((translator) => <div>{translator}</div>)
      ) : (
        <></>
      )*/}
      </div>
      <div>{doc.type}</div>
      <div>{doc.pages}</div>
    </div>
  );
}
