import { useContext, useState } from "react";
import { useGeneric } from "../../../useGeneric";
import { Link, useSearchParams } from "react-router-dom";
import Loading from "../../../main/Loading";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import invoiceImage from "../../../../images/icons/buttons/invoice.svg";
import languageDisplay from "../displays/languageDisplay";
import GoogleDriveLink from "../../../orders/GoogleDriveLink";
import NumberInvoice from "../../common/NumberInvoice";

import OrderViewCharsEditor from "./OrderViewCharsEditor";
import AppContext from "../../../contexts/app-context";
import DownloadGUS from "./DownloadGUS";

export default function OrderView({ id, inModal, search }) {
  const [query, setQuery] = useSearchParams();
  const [updates, setUpdates] = useState(0);
  const orderID = query.get("id") ? query.get("id") : id || search;
  const { modal, setModal } = useContext(AppContext);

  const standardParams = { exact: true, standard: true, db: "o" };

  const searchQuery = {};
  if (search) {
    const keys = Object.keys(search);
    keys.forEach((k) => {
      searchQuery[k] = { ...standardParams, value: search[k] };
    });
  }

  //const [orderID, setOrderID] = useState(146824);
  const useData = useGeneric({
    name: orderID ? "order-view" : undefined,
    query: {
      search: search
        ? searchQuery
        : {
            id: {
              value: orderID,
              ...standardParams,
            },
          },
    },
    update: updates,
  });

  const documents = !useData.loading ? useData.rows : undefined;

  const order = documents ? documents[0] : undefined;

  return documents && order ? (
    <div className={"order-view-new" + (inModal ? "" : " fullScreen")}>
      <div className="order-main">
        <div>
          <div className="order-number">
            <div>
              {order.number_order.replace(/\//g, ".")}.
              {order.language_main.toLowerCase()}
            </div>
            <div className="language-pair">
              {languageDisplay({ name: order.language_from })} <div>➔</div>{" "}
              {languageDisplay({ name: order.language_to })}
            </div>
          </div>

          <div
            className="customer-name"
            onClick={() => {
              if (order.customers_nip) {
                setModal({
                  show: true,
                  type: "Dane z rejestru GUS",
                  content: <DownloadGUS nip={order.customers_nip} />,
                });
              }
            }}
          >
            {order.customers_name}{" "}
          </div>
        </div>
        <div className="remarks-full">
          <div>{order.remarks_general}</div>
          <div>{order.remarks_translators}</div>
          <div>{order.remarks_top_secret}</div>
        </div>
        <div style={{ textAlign: "center" }}>
          <GoogleDriveLink
            customString={[order.number_order.replace(/\//g, ".")]}
            caption={"Szukaj na dysku"}
          />
        </div>
        <div className="order-info">
          <div>{order.users_name}</div>
          <div className="order-info-receipt">
            ↗{" "}
            <span
              style={{
                display: "inline-block",
                marginLeft: "5px",
                padding: "0px 5px",
                borderRadius: "5px",
                width:"fit-content",
                fontSize: "10px",
                boxShadow: "0 0 2px .5px rgba(0,0,0,.23) inset",
              }}
            >
              {order.receive_methods}
            </span>{" "}
            {prettyDate(order.create_date)}
          </div>
          <div className="order-info-acceptance"
            style={
              order.acceptance_methods_retrieved
                ? { fontWeight: "bold", color: "grey" }
                : {}
            }
          >
            ↘{" "}
            <span
              style={{
                display: "inline-block",
                marginLeft: "5px",
                padding: "0px 5px",
                width:"fit-content",
                borderRadius: "5px",
                fontSize: "10px",
                boxShadow: "0 0 2px .5px rgba(0,0,0,.23) inset",
              }}
            >
              {order?.acceptance_methods_name}
            </span>{" "}
            {prettyDate(order.finish_date)}
          </div>
          <div>
            {order.acceptance_methods_retrieved ? (
              <span style={{ color: "darkgreen", fontWeight: "bold" }}>✓</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="order-main">
        <div className="price-data">
          <div>
            <div>NETTO</div> <div>{prettyNumber(order.order_price)} zł</div>
          </div>
          <div>
            <div>BRUTTO</div>{" "}
            <div>{prettyNumber(order.order_price_brutto)} zł</div>
          </div>
        </div>
        {order &&
          [order].map((doc) => {
            const externals = JSON.parse("[" + doc.external_translators + "]");

            return (
              <div
                style={{
                  fontSize: "14px",
                  background: "whitesmoke",
                  padding: "5px 9px",
                  borderRadius: "5px",
                  boxShadow: "0 0 2px 1px rgba(0,0,0,.28)",
                }}
              >
                {externals.map((external) => (
                  <>
                    <div style={{ color: "brown", maxWidth: "200px" }}>
                      <Link
                        style={{ color: "brown" }}
                        to={"../tables/sent?translator=" + external.id}
                      >
                        {external.first_name} {external.last_name}
                      </Link>
                    </div>
                    {external.amount ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>{prettyNumber(external.amount)} zł</div>
                        <div
                          style={{
                            fontSize: "11px",
                            color: "rgb(100,100,100)",
                            marginLeft: "9px",
                          }}
                        >
                          {prettyNumber(external.quantity)}{" "}
                          {external.unit_number !== 1 ? (
                            <>
                              {external.unit_name} (
                              {prettyNumber(
                                Math.round(
                                  (external.quantity / external.unit_number) *
                                    100
                                ) / 100
                              )}{" "}
                              {external.unit_joint_name_short})
                            </>
                          ) : (
                            <>{external.unit_name}</>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </div>
            );
          })}
        <div>
          {order.acceptance_methods_retrieved ? (
            <div className="retrieved">
              <div className="retrieved-yes">odebrane</div>
              <div>{prettyDate(order.acceptance_methods_retrieved_date)}</div>
            </div>
          ) : (
            <div className="retrieved">--- nieodebrane ---</div>
          )}
        </div>
        {documents.find((i) => i.register_number) ? (
          <div>
            <div style={{ fontSize: "9px", color: "grey" }}>Repertorium</div>
            <div
              style={{
                fontSize: "14px",
                display: "flex",
                maxWidth: "150px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {Array.from(
                new Set([
                  ...documents
                    .find((r) => r.register_number)
                    .register_number?.split(",")
                    .sort((a, b) => a - b),
                ])
              )?.map((v) => (
                <div
                  style={{
                    borderRadius: "5px",
                    margin: "1px",
                    padding: "1px 3px",
                    background: "whitesmoke",
                    boxShadow: "0 0 0 .5px rgba(0,0,0,.2)",
                  }}
                >
                  {v}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="invoice-data">
          {order.document_sell_numbers ? (
            <>
              <img src={invoiceImage} />
              <div>
                <div className="invoice-caption">Faktura nr</div>{" "}
                <NumberInvoice
                  clickable={order.document_sell_ids ? true : false}
                  id={order.document_sell_ids}
                  number={order.document_sell_numbers}
                />
              </div>
            </>
          ) : (
            <>
              <div>brak faktury</div>
            </>
          )}
        </div>
      </div>
      <div className="order-documents">
        <table className="universal-table big">
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Nazwa</th>
              <th>Rodzaj</th>
              <th>Strony</th>
              <th style={{ width: "30px" }}>Nr rep.</th>
              <th>Stawka</th>
              <th>Cena netto</th>
              <th>Cena brutto</th>
              <th>Tłumacz</th>
              <th>Znaki tł.</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((i, index) => {
              const individualSwornNumbers = JSON.parse(
                "[" + i.register_numbers + "]"
              );
              console.log(individualSwornNumbers, i.id);
              const thisSwornNumber = individualSwornNumbers?.find(
                (n) => n.document_id === i.document_id
              )?.number;
              return (
                <tr key={i.id}>
                  <td>{index + 1}</td>
                  <td style={{ maxWidth: "250px" }}>
                    {i.name
                      ? i.name
                      : i.document_name
                      ? i.document_name
                      : i.type}
                  </td>
                  <td>
                    {i.type_of_translation === "przysięgłe"
                      ? "uwierzyt."
                      : i.type_of_translation}
                  </td>

                  <td>{prettyNumber(i.pages)}</td>
                  {thisSwornNumber ? (
                    <td style={{ textAlign: "center" }}>
                      <div
                        style={{
                          borderRadius: "5px",
                          width: "max-content",
                          padding: "1px 3px",
                          background: "#799797",
                          color: "white",
                          boxShadow: "0 0 0 .5px rgba(0,0,0,.2)",
                          margin: "0 auto",
                        }}
                      >
                        {thisSwornNumber}
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{i.rate}</td>
                  <td>{i.price}</td>
                  <td>{i.price_brutto}</td>
                  <td>
                    <div className="translator">
                      <div style={{ maxWidth: "240px" }}>
                        {i.translator?.split(",")?.map((t) => (
                          <div>{t}</div>
                        ))}{" "}
                        {i.action_type === 3 ? <>(spr.)</> : <></>}
                      </div>
                      {i.last_worked ? (
                        <>
                          <div
                            style={{
                              fontSize: "10px",
                              color: "rgb(70,70,70)",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div> {prettyDate(i.last_worked)}</div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/*
                    <div style={{ color: "brown", maxWidth: "200px" }}>
                      <Link
                        to={
                          "../tables/sent?translator=" +
                          i.external_translator_id
                        }
                      >
                        {i.external_translator}
                      </Link>
                    </div>
                    {i.external_amount ? (
                      <div>{prettyNumber(i.external_amount)} zł</div>
                    ) : (
                      <></>
                    )}*/}
                    </div>
                  </td>
                  <td>
                    <OrderViewCharsEditor i={i} setUpdates={setUpdates} />
                  </td>
                </tr>
              );
            })}
            {documents.length > 1 ? (
              <tr>
                {[...Array(9).keys()].map((i) => (
                  <td></td>
                ))}
                <td>
                  {documents
                    .map((i) =>
                      i.char_count
                        ? i.char_count
                        : i.last_worked && i.chars
                        ? i.chars
                        : 0
                    )
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0)}{" "}
                  zzs
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  ) : useData.loading ? (
    <Loading />
  ) : (
    <>Brak danych</>
  );
}
