import { useContext, useState } from "react";
import { compareDay, isToday } from "../../../functions";
import TableUniversal from "../../TableUniversal";
import { roundingMethod, sentOrdersMap } from "./maps/sentOrdersMap";
import flags from "../../../flags";
import { languagesList } from "../../../global";
import SettleControls from "./ExternalDashboard/SettleControls";
import languageDisplay from "../displays/languageDisplay";
import { propMap } from "../examples/Dictionaries/SentOrdersPropMap";
import pagesIcon from "../../../../images/icons/buttons/sentpages.svg";
import prettyNumber from "../../functions/prettyNumber";
import customMenuItems from "./customMenuItems";
import SettlementGroupControls from "./ExternalDashboard/SettlementGroupControls";
import NumberOrder from "../../common/NumberOrder";

export default function ExternalDashboard() {
  const [effects, setEffects] = useState([]);

  return (
    <div className="externals fullScreen">
      <TableUniversal
        name="Wysłane zlecenia"
        icon={pagesIcon}
        classNames="big"
        propMap={propMap}
        options={{
          selectable: true,
          editable: true,
        }}
        replacement={(currentData) => (
          <SettleControls currentData={currentData} />
        )}
        disableSelect={["settled", 1]}
        groupBy={[
          "settled_id",
          (v, p, setUpdate, search) => (
            <SettlementGroupControls
              v={v}
              p={p}
              setUpdate={setUpdate}
              search={search}
            />
          ),
        ]}
        multiControls={<SettleControls />}
        defLimit={10}
        data="sent-orders-table"
        del="del-externals"
        dictionary={dictionary}
        orderBy="settled, settled_id, id"
        orderDesc="ASC, DESC, DESC"
        active={true}
        searchPattern={{
          translator_id: {
            value: "",
            exact: true,
            standard: true,
            db: "n",
            style: {
              display: "none",
            },
            fromURL: "translator",
          },
          translator_name: {
            label: "Tłumacz",
            value: "",
            className: "pick-translator",
            fillOnEmpty: {
              name: "translator",
              source: "translator_id",
              target: "full_name",
              from: {
                db: "new-externals-list",
                searchColumn: "id",
              },
            },
            optionsDB: {
              name: "translator_id",
              standard: true,
              standardDB: "n",
              style: {
                width: "230px",
                fontWeight: "400",
                height: "32px",
                fontSize: "16px",
                //padding:"20px 8px",
                background: "white",
                color: "#46797e",
              },
              props: {
                exact: true,
              },
              target: "translator_id",
              db: "new-externals-sent",
              searchColumn: "search_string",
              targetColumn: "translator_id",
              orderBy: "id",
              orderDesc: "DESC",
              displayOptions: (row) => {
                const languages = row?.languages_main?.split(",");
                const firstLanguage = languages?.[0];
                const secondLanguage = languages?.[1];
                return (
                  <div
                    className="translator-picker"
                    title={row.languages_main.toLowerCase()}
                  >
                    <img
                      className="flag"
                      src={
                        flags[
                          languagesList.find((i) => i.code === firstLanguage)
                            ?.flagCode
                        ]
                      }
                    />

                    {row.full_name}
                  </div>
                );
              },
            },
          },
          language_full_name: {
            label: "Język",
            type: "search",
            value: "",
            optionsDB: {
              name: "language_full_name",
              target: "language_full_name",
              db: "languages-searchable",
              searchColumn: "full_name",
              targetColumn: "full_name",
              displayOptions: languageDisplay,
              orderBy: "importance",
              orderDesc: "ASC",
            },
          },
          order_customer: {
            value: "",
            optionsDB: {
              name: "order_customer",
              style: { width: "150px" },
              target: "order_customer",
              db: "customers-sent-list",
              searchColumn: "order_customer",
              targetColumn: "order_customer",
              orderBy: "id",
              orderDesc: "DESC",
              displayOptions: (row) => (
                <div style={{ fontSize: "12px" }}>{row.order_customer}</div>
              ),
            },
          },
          active: {
            value: 1,
            exact: true,
            hidden: true,
          },
          order_number: {
            value: "",
          },

          settled: {
            label: "Rozl.",
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              nierozliczone: 0,
              rozliczone: 1,
            },
          },
          settled_id: {
            label: "Nr rozliczenia",
            value: "",
          },
          description_distinctor: {
            label: "Opis/typ stawki",
            value: "",
          },
        }}
        /*customSearchComponent={
          <CustomSearchComponent effects={effects} setEffects={setEffects} />
        }
        searchMap={{ translator: "translator_id" }}*/
        createMap={sentOrdersMap}
        customMenu={customMenuItems}
      />
    </div>
  );
}

const dictionary = {
  time_added: {
    name: "Dodany",
    order: "time_added",
    style: {
      width: "100px",
      fontSize: "12px",
      textAlign: "center",
    },
    transform: (v, a) => {
      const now = new Date();
      const vd = new Date(v);
      //const recent = now - vd < 0.25 * 60 * 60 * 1000; // last 15 minutes
      const today = isToday(vd);
      const yesterday = compareDay(vd, -1);
      const just = now - vd < 60 * 1000; // last minute
      const isThisYear = new Date().getFullYear() === vd.getFullYear();
      const lastWeek = new Date() - vd < 1000 * 60 * 60 * 24 * 6;
      return (
        <div
          title={
            vd.toLocaleString() + "\r\n" +
            a.created_by_string +
            "\r\nEdycja: " +
            (a.last_edited_by_string ? a.last_edited_by_string : "brak")
          }
        >
          {just ? (
            <strong
              style={{
                background: "rgb(220,220,220)",
                padding: "5px 9px",
                boxShadow: "0 0 4px rgba(0,0,0,.1)",
                borderRadius: "8px",
                fontSize: "11px",
              }}
            >
              przed chwilą
            </strong>
          ) : (
            <div>
              {today ? "dzisiaj, " : yesterday ? "wczoraj, " : ""}
              {vd.toLocaleString(
                "pl-PL",
                today || yesterday
                  ? {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  : {
                      day:  lastWeek ? undefined : isThisYear ? "numeric" : "2-digit",
                      month: lastWeek ? undefined : isThisYear ? "short":"2-digit",
                      year: isThisYear ? undefined : "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      weekday: lastWeek ? "long" : undefined
                    }
              )}
            </div>
          )}
        </div>
      );
    },
  },
  translator_name: {
    name: "Tłumacz",
    style: {
      width: "199px",
      color: "#14a15a",
    },
    order: "translator_name",
    transform: (v, a) => (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {v}{" "}
        {/* <Link to={"?translator=" + a.translator_id}>
          <button
            style={{
              marginLeft: "9px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0",
              width: "12px",
              height: "12px",
              fontSize: "9px",
            }}
          >
            ?
          </button>
          </Link>*/}
      </div>
    ),
  },
  translator_id: {
    name: "Id tłumacza",
    style: {
      display: "none",
    },
  },
  order_number: {
    name: "Nr zlec.",
    order: "order_id",
    style: {
      width: "120px",
    },
    transform: (v, a) =>
      v ? (
        <NumberOrder id={a.order_id} number_order={v} withLang={true} />
      ) : (
        <></>
      ),
  },
  order_customer: {
    name: "Klient",
    style: {
      maxWidth: "250px",
    },
    order: "order_customer",
    transform: (v) => (
      <div
        style={{
          padding: "3px 5px 3px 5px",
          background: "rgb(220, 247, 230)",
          width: "max-content",
          maxWidth: "250px",
          //margin: "2px 5px",
          //boxShadow: "-2px 3px 2px  -3px rgba(0,0,0,.3)",
          borderRadius: "2px",
          fontSize: "85%",
        }}
      >
        {v}
      </div>
    ),
  },
  language_full_name: {
    name: "Język",
    style: {
      width: "90px",
    },
    order: "language, language_to, id",
    transform: (v, a) => {
      const langViews = [a.lang_from_name, a.lang_to_name].map((lang) => (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "15px 12px",
              alignItems: "center",
              fontSize: "9px",
              color: "grey",
              textTransform: "uppercase",
              position: "relative",
            }}
          >
            <img
              src={flags[languagesList.find((i) => i.code === lang)?.flagCode]}
              style={{ width: "15px", boxShadow: "0 0 0 1px rgba(0,0,0,.14)" }}
              className="flag"
            />
            <span
              style={{
                padding: "1px",
              }}
            >
              {lang?.slice(0, 3)}
            </span>
          </div>
        </>
      ));
      const langViewsRel = [
        <div>{langViews[0]}</div>,
        <div style={{ fontSize: "17px", color: "rgb(200,200,200)", margin: "0 3px 0 0" }}>
          ▸
        </div>,
        langViews[1],
      ];
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {langViewsRel}
        </div>
      );
    },
  },
  /*id: {
    name: "id",
  },*/

  description: {
    name: "Opis/typ stawki",
    style: {
      width: "150px",
      fontSize: "11px",
      textAlign:"center"
    },
    transform: (v, a) => (
      <>
        {v ? <div>{v}</div> : <></>}{" "}
        {a.rate_distinctor ? <div>{a.rate_distinctor}</div> : <></>}
      </>
    ),
  },
  quantity: {
    name: "Liczba",
    order: "quantity, id",
    style: {
      width: "120px",
      textAlign: "center",
    },
    transform: (v, a) => {
      const rounder = roundingMethod(a, String(a.rounding_method));
      return (
        <div>
          <div>
            {prettyNumber(
              Math.round(100 * rounder(Number(v) / Number(a.unit_number))) / 100
            )}{" "}
            {Number(a.unit_number) !== 1
              ? a.unit_joint_name_short
              : a.unit_name}
          </div>
          {Number(a.unit_number) !== 1 ? (
            <div style={{ fontSize: "11px", marginTop: "2px", color: "grey" }}>
              {prettyNumber(v)} {a.unit_name}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  },
  rate: {
    name: "Stawka",
    order: "rate, id",
    style: {
      width: "70px",
      padding:0,
      textAlign:"center"
    },
    transform: (v, a) => (
      <div
        title={
          "Marża biura na stronie/jednostce:\r\n" +
          (a.client_paid_rate !== "0.00"
            ? "••• " +
              Math.round(100 * (a.client_paid_rate - a.rate)) / 100 +
              " zł"
            : "?")
        }
      >
        <div>{prettyNumber(v)} zł</div>
        {/*  <div
          style={{ marginTop: "3px", fontSize: "9.5px", color: "green" }}
          title="Marża biura na stronie/jednostce"
        >
          {a.client_paid_rate !== "0.00"
            ? "••• " +
              Math.round(100 * (a.client_paid_rate - a.rate)) / 100 +
              " zł"
    : "?"}
        </div>*/}
      </div>
    ),
  },
  /*client_paid_rate: {
    name: "Marża biura/jedn.",
    style: {
      width:"100px"
    },
    transform: (v, a) =>
    v !== "0.00" ? Math.round(100 * (v - a.rate)) / 100 : "?",  
  },*/
  amount: {
    name: "Netto",
    order: "amount, id",
    style: {
      background: "rgba(160,30,50,.05)",
      textAlign: "center",
      width: "70px",
      
      fontSize:"13px",
      padding:0
    },
    transform: (v, all) => (
      <div>
        <span>{prettyNumber(v)} zł</span>
        {all.sending_cost && parseInt(all.sending_cost) ? (
          <span
            title="koszt doliczany"
            style={{
              boxShadow: "0 .5px 0 rgba(0,0,0,.1)",
              padding: "1px 0px",
              display: "inline-block",
              marginLeft: "4px",
              fontSize: "70%",
              color: "grey",
            }}
          >
            ({prettyNumber(all.sending_cost)})
          </span>
        ) : (
          <></>
        )}
      </div>
    ),
  },
  amount_gross: {
    name: "Brutto",
    order: "amount, id",
    style: {
      background: "rgba(160,30,50,.05)",
      textAlign: "center",
      width: "70px",
      color:"brown",
      fontSize:"13px",
      
      padding:0
    },
    transform: (v, all) => (
      <div>
        <span>{prettyNumber(v)} zł</span>
      </div>
    ),
  },
  settled: {
    name: "R",
    order: "settled_id, settled, id",
    dir: "DESC, DESC, DESC",
    style: {
      textAlign: "center",
      width: "20px",
      padding: 0,
      fontSize: "9px",
      display:"none"
    },
    transform: (v) => (v ? <>✓</> : <>{/*✖*/}</>),
  },
};
