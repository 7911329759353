import React, { useState, useRef } from "react";
import { readableNumberPL } from "../functions";

function SpreadsheetCell(props) {
  const { mode, prev, dataItem, item, index, editCell, additional, controls } = props;
  //console.log(prev);
  function makeValueString(value, dataItem) {
    if (additional.identifier === dataItem.identifier && additional.value)
      value = additional.transform(value);
    return dataItem?.dataType === "integer" || dataItem?.dataType === "float"
      ? readableNumberPL(value)
      : dataItem?.transform
      ? dataItem?.transform(value, item).jsx || dataItem?.transform(value, item)
      : value;
  }

  let valueString = dataItem.group
    ? dataItem.content.map((i, index) => (
        <div
          className={"subitem" + (i.className ? " " + i.className : "")}
          style={i.style}
          key={index}
        >
          {makeValueString(item?.[i.identifier], i)}
        </div>
      ))
    : makeValueString(item?.[dataItem.identifier], dataItem);

  //const value = groupedValues ? groupedValues :

  const inputEl = useRef(null);

  const attributes = {
    ref: inputEl,
    defaultValue: item?.[dataItem.identifier],
    type: dataItem.formElementType,
  };

  let same = "";

  if (dataItem.group && dataItem.content && prev) {
    //console.log(dataItem.content)
    if (
      dataItem.content.find((i, index) => {
        //console.log(item[i.identifier], item[i.identifier] === prev[dataItem.content[index].identifier])
        return item[i.identifier] !== prev[dataItem.content[index].identifier];
      })
    ) {
      same = "";
    } else {
      same = " same";
    }
  } else {
    same =
      item?.[dataItem.identifier] == prev?.[dataItem.identifier] ? " same" : "";
  }

  return (
    <td
      className={
        (dataItem.className ? dataItem.className : "") +
        (mode === "history" && prev ? same : "") +
        (prev ? same : "")
      }
      style={dataItem.style}
    >
      <div className="dataGroup">
        <span
          
          className={
            dataItem.unit
              ? "value"
              : "" +
                (item.modified == "0" && dataItem.grayFirst
                  ? " unedited"
                  : "")
          }
        >
          {valueString}
        </span>
        {dataItem.unit ? <span className="unit">{dataItem.unit}</span> : <></>}
        
      </div>
      {controls ? controls : <></>}
    </td>
  );
}

export default SpreadsheetCell;
