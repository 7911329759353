import prettyNumber from "../../../tables/functions/prettyNumber";

export default function PercentDone({ documents, order, simple }) {
  const allDone = documents
    ?.filter((d) => d.name != "dodatkowy egzemplarz")
    ?.map((d) => (d.chars ? true : false))
    ?.reduce((a, b) => a & b, true);

  const pagesTS = documents
    .map((d) =>
      d.type_of_translation !== "przysięgłe"
        ? d.chars_ts / 1600
        : d.chars_ts / 1125
    )
    .reduce((a, b) => a + b, 0);

  const charsRemaining = documents.map((d) => {
    const onePointSix =
      order.remarks_general.includes("1600zzs") ||
      order.remarks_general.includes("1600 zzs") ||
      order.remarks_general.includes("1600 zn");
    const divi = onePointSix
      ? 1600
      : d.type_of_translation !== "przysięgłe" &&
        order.customers_private_firm == 2
      ? 1800
      : d.type_of_translation !== "przysięgłe" &&
        order.customers_private_firm != 2
      ? 1600
      : 1125;
    const p = d.chars_ts / divi;

    const remaining = d.pages_count - p > 0 ? (d.pages_count - p) * divi : 0;
    return remaining;
  });

  const pages = documents.map((d) => d.pages_count).reduce((a, b) => a + b, 0);

  const percentDone =
    pagesTS / pages < 1 ? Math.round((pagesTS / pages) * 100) : 100;

  const remaining = charsRemaining.reduce((a, b) => a + b, 0);

  return (
    <div
      title={percentDone + "%"}
      style={{
        position: "absolute",
        bottom: 0,

        background:
          percentDone < 30
            ? "#8ab88a"
            : percentDone < 50
            ? "#8ab88a"
            : percentDone < 75
            ? "#8bbe51"
            : "lightgreen",
        height: "4px",
        borderRadius: "5px",
        width: allDone ? "100%" : percentDone + "%",
      }}
    >
      <div
        style={{
          position: "absolute",
          left:
            percentDone === 0
              ? "22%"
              : allDone
              ? "93%"
              : !remaining
              ? "88%"
              : !allDone && percentDone < 83
              ? "calc(100% - 14px)"
              : "82%",
          padding: "0px 3px",
          borderRadius: "8px",
          background: percentDone === 0 ? "whitesmoke" : "white",
          color: "grey",
          width: "max-content",
          boxShadow: "0 0 2px rgba(0,0,0,.2)",
          top: "-110%",
          fontSize: "9px",
          zIndex: 2,
          //fontFamily: "monospace",
        }}
      >
        {simple ? (
          allDone ? (
            "100%"
          ) : percentDone ? (
            percentDone + "%"
          ) : (
            ""
          )
        ) : allDone || percentDone === 100 ? (
          <>100%</>
        ) : (
          <>
            ➤ {prettyNumber((Math.round(remaining / 100) * 100) / 1000)} tys.
            zn.
          </>
        )}
        {simple && order?.ext_translators ? (
          <span
            style={{
              display: "inline-block",
              borderRadius: "10px",
              padding: "0 10px",
              background: "magenta",
            }}
            title={order.ext_translators}
          >
            Wysłane
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
