import { useContext, useState } from "react";
import { TableContext, patternToSearch } from "../TableUniversal";
import FilterItem from "./FilterItem";

export default function Filters() {
  const {
    setOffset,
    setUpdate,
    search,
    setSearch,
    searchPattern,
    setInitialize,
    count,
    loading
  } = useContext(TableContext);

  const [clearPreFields, setClearPreFields] = useState(false);

  return (
    <>
      <div className="filter-collection" /*style={{display: loading ? "none" : undefined }}*/>
        {Object.keys(search).map((s) =>
          search[s]?.hidden ? (
            <></>
          ) : (
            <FilterItem
              search={search}
              s={s}
              setInitialize={setInitialize}
              clearPreFields={clearPreFields}
              setClearPreFields={setClearPreFields}
            />
          )
        )}
        {count ? <div className="number-of-results">Liczba wpisów: {count}</div> : <></>}
      </div>

      <button
        style={
          Object.keys(search).find(
            (i) =>
              (search[i]?.value && String(search[i]?.value).length > 0) ||
              searchPattern[i]?.get
          )
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
        onClick={() => {
          setSearch((prev) => {
            return patternToSearch(searchPattern);
          });
          setClearPreFields(true);
          setOffset(0);
          setUpdate((prev) => prev + 1);
        }}
      >
        Wyczyść
      </button>
    </>
  );
}
