import { createContext, useContext, useEffect, useState } from "react";
import Field from "./Field";
import { URL } from "../../global";

export const DocContext = createContext({
  name: "",
  type: "",
  pages: "",
});

export default function Document({
  doc,
  order,
  index,
  edit,
  setEdit,
  hasBlank,
  setHasBlank,
  blank,
  setUpdates
}) {
  const [data, setData] = useState({
    name: doc.name,
    type: doc.type,
    pages: doc.pages,
  });

  async function addDocument() {
    const response = await fetch(
      URL + "components/db-insert-custom-document.php",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          params: {
            ...data,
            follows_id: doc.follows,
            order: order.id,
            username: "",
          },
        }),
      }
    );
    const result = await response.json();
    return result;
  }

  return (
    <>
      <tr
        title={doc.id}
        key={doc.id}
        className={
          "docRow" + (blank ? " blank" : "") + (doc.custom ? " custom" : "")
        }
      >
        <td className="controls">
          <div className="editorButtons">
            <button
              title="dodaj niżej"
              className="regularButton new"
              onClick={() => (blank ? setHasBlank(false) : setHasBlank(doc.id))}
            >
              {blank ? <span style={{ fontSize: "16px" }}>✖</span> : <>+</>}
            </button>
          </div>
        </td>

        <td>{blank ? "•" : index + 1}</td>
        <DocContext.Provider
          value={{
            data: data,
            setData: setData,
            id: doc.id,
            edit: edit,
            setEdit: setEdit,
          }}
        >
          <Field value="name" form="input" />
          <Field
            value="type"
            form="select"
            options={[
              { value: 0, text: "zwykłe" },
              { value: 1, text: "uwierz." },
            ]}
          />
          <Field value="pages" form="input" />
        </DocContext.Provider>
        <td>
          {blank && data.name && data.pages ? (
            <button
              onClick={() => {
                addDocument();
                setUpdates(prev => prev+1);
                setHasBlank(false);
              }}
              className="regularButton"
            >
              Zapisz
            </button>
          ) : (
            doc.translators
          )}
        </td>
      </tr>
      {hasBlank ? (
        <Document
          key="new"
          doc={{
            id: "new",
            follows: doc.id,
            name: "",
            type: 0,
            pages: "",
          }}
          order={order}
          edit={true}
          setHasBlank={setHasBlank}
          hasBlank={false}
          blank={true}
          setUpdates={setUpdates}
        />
      ) : (
        <></>
      )}
    </>
  );
}
