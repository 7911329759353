import { useLocation, useNavigate } from "react-router-dom";
import TableUniversal from "../../TableUniversal";
import { sentOrdersMap } from "./maps/sentOrdersMap";
import { isToday } from "../../../functions";
import flags from "../../../flags";
import { languagesList } from "../../../global";
import pagesIcon from "../../../../images/icons/buttons/sentpages.svg";
import moneyIcon from "../../../../images/icons/buttons/money.svg";
import statsIcon from "../../../../images/icons/buttons/stats.svg";
import translatorsIcon from "../../../../images/icons/buttons/translators.svg";
import prettyNumber from "../../functions/prettyNumber";
import languageDisplay from "../displays/languageDisplay";
import customersIcon from "../../../../images/icons/buttons/customers.svg";
import customMenuItems from "./customMenuItems";

const dictionary = {
  first_name: {
    order: "first_name",
    name: "Imię i nazwisko",
    style: {
      width: "180px",
    },
    transform: (v, a) => (
      <>
        {v} {a.last_name}
      </>
    ),
  },
  last_name: {
    order: "last_name",
    name: "Nazwisko",
    style: {
      display: "none",
    },
  },
  language: {
    name: "Język",
    order: "language, id",

    transform: (v) => (
      <div
        style={{ display: "flex", alignItems: "center", justifyContent:"center" }}
      >
        <img
          style={{ margin: "0 4px", borderRadius: "4px", width: "15px" }}
          src={flags[languagesList.find((i) => i.name === v)?.flagCode]}
        />
        {v}
      </div>
    ),
  },
  items: {
    order: "items",
    name: "Liczba zleceń",
    style: {
      textAlign: "center",
    },
  },
  sum_amount: {
    order: "sum_amount, id",
    name: "Suma",
    transform: (v) => <div>{prettyNumber(v)} zł</div>,
    style: {
      textAlign: "center",
    },
  },
  sum_units: {
    order: "sum_units, id",
    name: "Ilość",
    transform: (v, a) => (
      <div>
        {prettyNumber(v)} {a.unit_name}
      </div>
    ),
    style: {
      textAlign: "center",
    },
  },

  min_rate: {
    order: "min_rate",
    name: "Stawka min.",
    style: {
      textAlign: "center",
    },
    transform: (v) => <div>{prettyNumber(v)} zł</div>,
  },
  max_rate: {
    order: "max_rate",
    name: "Stawka maks.",
    style: {
      textAlign: "center",
    },
    transform: (v) => <div>{prettyNumber(v)} zł</div>,
  },
  settled: {
    name: "Rozliczone",
    order: "settled, id",
    transform: (v) => (v === 0 ? "nie" : v === 1 ? "tak" : "-"),
    style: {
      textAlign: "center",
    },
  },
};

export default function SentStats() {
  const pathID = parseInt(useLocation().pathname.split("/").at(-1));
  const selectedID = pathID ? pathID : "";

  return (
    <div className="fullScreen">
      <TableUniversal
        name="Statystyki wysłanych zleceń"
        classNames="big"
        icon={statsIcon}
        options={
          {
            //selectable: true,
            //editable: true,
          }
        }
        customMenu={customMenuItems}
        searchPattern={{
          first_name: {
            label:"imię",
            value: "",
          },
          last_name: {
            value: "",
          },
          language: {
            label: "Język",
            value: "",
            optionsDB: {
              name: "language",
              target: "language",
              db: "languages-searchable",
              searchColumn: "full_name",
              targetColumn: "full_name",
              displayOptions: languageDisplay,
              orderBy: "importance",
              orderDesc: "ASC",
            },
          },
          settled: {
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              tak: 1,
              nie: 0,
            },
          },
        }}
        data="sent-orders-stats"
        dictionary={dictionary}
        orderBy="settled,id"
        orderDesc="DESC"
      />
    </div>
  );
}
