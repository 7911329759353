import React from "react";
import { Link } from "react-router-dom";
import BackButton from "./BackButton";
import Icon from "./Icon";

function Activity() {
  return (
    <div className="add">
      <div>
        <BackButton link="../" />
        <div>
          <p>Co teraz robisz?</p>
        </div>
        <Link to="../order">
          <button className="main pictorial">
            <Icon name="order" />
            <div>Zlecenie</div>
          </button>
        </Link>
        <Link to="../break">
          <button className="main pictorial">
            <Icon name="break" />
            <div>Przerwa</div>
          </button>
        </Link>
        <Link to="../other">
          <button className="main pictorial">
            <Icon name="other" />
            <div>Inne</div>
          </button>
        </Link>
        <div className="buttonGroup">
          <Link to="../out_duty">
            <button className="main pictorial">
              <Icon name="out_duty" />
              <div>Wyjście służbowe</div>
            </button>
          </Link>
          <Link to="../out_private">
            <button className="main pictorial">
              <Icon name="out_private" />
              <div>Wyjście prywatne</div>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Activity;
