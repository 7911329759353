import React, { useContext, useEffect, useState } from "react";
import SpreadsheetCell from "./SpreadsheetCell";
import editIcon from "../../images/icons/icons8-edit-26.png";
import removeIcon from "../../images/icons/icons8-remove-32.png";
import historyIcon from "../../images/icons/history.svg";
import approveIcon from "../../images/icons/icons8-approve-32.png";
import AppContext from "../contexts/app-context";
import SpreadsheetForm from "./SpreadsheetForm";
import { readableNumberPL } from "../functions";
import SwornHistory from "./swornRegistry/SwornHistory";
import GoogleDriveLink from "../orders/GoogleDriveLink";
import Form from "../forms-universal/Form";
import { calculate, newFormData } from "./swornRegistry/new-form-data";

function SpreadsheetRow(props) {
  const {
    mode,
    dataStructure,
    calculables,
    item,
    index,
    array,
    deleteRow,
    editData,
    submitData,
    acceptRow,
    selected,
    setSelected,
    options,
    checkedIndices,
    setCheckedIndices,
    historyComponent,
  } = props;
  const context = useContext(AppContext);
  const { modal, setModal } = context;
  const checked = checkedIndices[item.id];

  const now = new Date();

  useEffect(() => {
    if (checked) {
      const index = selected.findIndex((p) => p.id === item.id);
      if (index === -1) {
        setSelected((prev) => [...prev, item]);
      } else {
        setSelected((prev) => {
          prev[index] = item;
          return prev;
        });
      }
    } else {
      setSelected((prev) => prev.filter((p) => p.id !== item.id));
    }
    //checked ? setSelected((prev) => [...prev, item]) : setSelected((prev) => prev.filter((p) => p.id !== item.id));
  }, [checked, item]);

  const sumAmount = readableNumberPL(
    array
      .filter((i) => i.group_id === item.group_id)
      .map((i) => parseFloat(i.amount))
      .reduce((a, b) => a + b, 0),
    2
  );

  return (
    <>
      {(item.settled == 1 &&
        item.group_id != 0 &&
        array?.[index - 1]?.group_id !== item.group_id) ||
      (item.settled == 1 && array?.[index - 1]?.settled == 0) ||
      (item.settled == 1 && index === 0) ? (
        <tr>
          <td className="rowGroupInfo selectRow">
            <input
              type="checkbox"
              //checked={}
              onChange={(e) =>
                setCheckedIndices((prev) => {
                  const result = { ...prev };
                  const indices = array
                    .filter((i) => i.group_id == item.group_id)
                    .map((i) => i.id);
                  indices.forEach((id) => (result[id] = e.target.checked));
                  return result;
                })
              }
            />
          </td>
          <td className="rowGroupInfo" colSpan={dataStructure.length}>
            <div>
              Rozliczone nr {item.group_id} / {item.year}{" "}
              <div className="sumAmount">{sumAmount} zł</div>
            </div>
          </td>
        </tr>
      ) : item.settled == 0 && array?.[index - 1]?.settled != 0 ? (
        <tr>
          <td className="rowGroupInfo selectRow">
            <input
              type="checkbox"
              //checked={}
              onChange={(e) =>
                setCheckedIndices((prev) => {
                  const result = { ...prev };
                  const indices = array
                    .filter((i) => i.settled != true)
                    .map((i) => i.id);
                  indices.forEach((id) => (result[id] = e.target.checked));
                  return result;
                })
              }
            />
          </td>
          <td className="rowGroupInfo" colSpan={dataStructure.length}>
            <div>
              Nierozliczone
              <div className="sumAmount">{sumAmount} zł</div>
            </div>
          </td>
        </tr>
      ) : (
        <></>
      )}
      <tr
        style={
          item.settled == 1
            ? { filter: "drop-shadow(1px 1px rgba(0,0,0,.2))" }
            : {}
        }
        className={
          (item.accept == 1 ? "acceptedRow" : "") +
          (item.modified == "1" ? " modified" : "")
        }
      >
        <td className="selectRow">
          <input
            type="checkbox"
            checked={checked}
            onChange={(e) =>
              setCheckedIndices((prev) => ({
                ...prev,
                [item.id]: !prev[item.id],
              }))
            }
          />
        </td>
        {options?.numberColumn ? (
          <td>
            <span>{index + 1}</span>
          </td>
        ) : (
          <></>
        )}
        {dataStructure.map((dataItem, subIndex, arr) => (
          <>
            {!dataItem.hidden && !dataItem.hiddenTable ? (
              <SpreadsheetCell
                mode={mode}
                prev={
                  mode === "history" ? array?.[index + 1] : array?.[index - 1]
                }
                key={subIndex}
                dataItem={dataItem}
                item={item}
                additional={{
                  identifier: dataItem.group
                    ? dataItem.content.find(
                        (i) => i.identifier === dataItem?.additionalSwitch
                      )?.additionalSwitch.affects
                    : dataItem.additionalSwitch
                    ? dataItem.identifier
                    : false,
                  value: parseInt(item?.document_reference_person_concerned),
                  transform: (v) =>
                    dataItem.group
                      ? dataItem.content
                          .find(
                            (i) => i.identifier === dataItem?.additionalSwitch
                          )
                          ?.additionalSwitch.transform(v)
                      : dataItem?.additionalSwitch?.transform(v),
                }}
                controls={
                  <>
                    {dataItem.identifier === "number" && options?.addCopy ? (
                      options.addCopy(item)
                    ) : (
                      <></>
                    )}
                    {dataItem.identifier === "order_number" &&
                    options?.driveLink ? (
                      <GoogleDriveLink customString={item[options.driveLink]} />
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            ) : (
              <></>
            )}
          </>
        ))}
        {!options.hideOptions ? (
          <td className="rowOptions">
            <div>
              <span
                title="Edytuj wpis"
                onClick={(e) => {
                  setModal({
                    type: (
                      <>
                        Edycja{" "}
                        <span
                          style={{
                            display: "inline-block",
                            padding: "2px 8px",
                            marginLeft: "10px",
                            background: "#a0bebf",
                            color: "black",
                            fontSize: "13px",
                            borderRadius: "5px",
                            fontWeight: "500",
                          }}
                        >
                          {item.number}
                          <span style={{ fontSize: "10px" }}>
                            {" "}
                            / {item.year}
                          </span>
                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "10px",
                              padding: "1px 3px",
                              fontSize: "11px",
                              borderRadius: "5px",
                            }}
                          >
                            ostatnio: {item.user_name} z {item.date_added}
                          </span>
                        </span>
                      </>
                    ),
                    show: true,
                    width: "960", //"wider-plus",
                    className: "sworn-registry-form",
                    style: {
                      borderRadius: "10px",
                      border: "none",
                    },
                    content: (
                      <>
                        <Form
                          fieldData={newFormData}
                          submitData={editData}
                          defaultFieldValues={{
                            ...item,
                            document_return:
                              item.document_return.search(/\*\*manual/) === -1
                                ? ""
                                : item.document_return,
                            document_date:
                              item.document_date === "0000-00-00"
                                ? ""
                                : item.document_date,
                          }}
                          calculate={calculate}
                          edit={true}
                          setModal={setModal}
                        />{" "}
                        {/*
                      <SpreadsheetForm
                        mode="edit"
                        dataStructure={dataStructure}
                        calculables={calculables}
                        params={{
                          year: now.getFullYear(),
                          month: now.getMonth(),
                        }}
                        submitData={editData}
                        defaults={item}
                        button="Aktualizuj"
                      />*/}
                      </>
                    ),
                  });
                }}
              >
                <img
                  style={{
                    filter:
                      "contrast(0) sepia(1) hue-rotate(12deg) saturate(3)",
                  }}
                  src={editIcon}
                />
              </span>
              {options?.deleteRows ? (
                <span
                  onClick={(e) => {
                    if (window.confirm("Usunąć wpis nr " + item.id + "?")) {
                      deleteRow(e, item.id);
                    }
                  }}
                >
                  <img
                    style={{
                      filter:
                        "contrast(0.2) sepia(1) hue-rotate(-35deg) saturate(2)",
                    }}
                    src={removeIcon}
                  />
                </span>
              ) : (
                <></>
              )}
              {options?.acceptRows ? (
                <span
                  onClick={(e) => {
                    acceptRow(e, { id: item.id, accept: item.accept });
                  }}
                >
                  <img
                    style={{
                      filter:
                        "contrast(0.2) sepia(1) hue-rotate(45deg) saturate(2)",
                    }}
                    src={approveIcon}
                  />
                </span>
              ) : (
                <></>
              )}
              {item.modified == "1" ? (
                <span
                  title="Historia wpisu"
                  onClick={() =>
                    setModal({
                      show: true,
                      width: "screen",
                      type: "Historia wpisu",
                      content: React.cloneElement(historyComponent, {
                        number: item.number,
                        year: item.year
                      }),
                    })
                  }
                >
                  <img
                    style={{
                      opacity: ".96",
                      filter:
                        "invert(1) brightness(0.55) sepia(1) hue-rotate(144deg) saturate(1.7)",
                    }}
                    src={historyIcon}
                  />
                </span>
              ) : (
                <></>
              )}
              {options?.addSimilar ? options.addSimilar(item) : <></>}
            </div>
          </td>
        ) : (
          <></>
        )}
      </tr>
    </>
  );
}

export default SpreadsheetRow;
