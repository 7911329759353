import React from 'react'

function CommentArea(props) {
    const [comment, setComment] = props.comment;
    function handleChange(e) {
        setComment(e.target.value);
      }
    return (
        <textarea className="commentArea"
            placeholder="wpisz ewentualne uwagi do wpisu"
            value={comment}
            onChange={(e) => handleChange(e)}
            maxLength="1024"
          ></textarea>
    )
    
}

export default CommentArea;