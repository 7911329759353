import { useContext } from "react";
import Cell from "./Cell";
import { TableContext } from "../TableUniversal";
import removeNumberKeys from "../functions/removeNumberKeys";
import { Link } from "react-router-dom";
import BoxFrame from "./BoxFrame";

export default function Box({ key, data, raw, i }) {
  const { headings, selectable, options, setDetails, offset, limit, buttons } =
    useContext(TableContext);

  return (
    <div className="table-boxes">
      {/*selectable ? (
        <div className="checkbox">
          <input type="checkbox" />
        </div>
      ) : (
        <></>
      )*/}
      {/*<div>{i + 1 + offset * limit}</div>*/}
      {headings.map((cell, index) => (
        <BoxFrame key={cell} data={data[cell]} heading={cell}/>
      ))}
      {/*
      <div style={{ width: "30px" }}>
        <div className="row-buttons">
          <button onClick={() => setDetails(i)}>🔍</button>
          {options?.editable ? (
            <>
              <Link to={`edit/${raw.id}`} state={raw}>
                <button>✎</button>
              </Link>
              <Link to={`delete/${raw.id}`} state={raw}>
                <button>✖</button>
              </Link>
            </>
          ) : (
            <></>
          )}
          {buttons ? (
            buttons.map((button) => (
              <button onClick={button.action}>{button.content(data)}</button>
            ))
          ) : (
            <></>
          )}
        </div>
          </div>*/}
    </div>
  );
}
