import { useEffect } from "react";
import { URL } from "../global";
import SpreadsheetForm from "../spreadsheets/SpreadsheetForm";
import { useGeneric } from "../useGeneric";

const tableSchema = [
  {
    identifier: "customers_name",
    shortText: "Klient",
    longText: "Klient",
    value: "",
    description: "",
    dataType: "string",
    attributes: null,
    source: "",
    formElement: "input",
    required: true,
  },
  {
    identifier: "comments",
    shortText: "Uwagi",
    longText: "Uwagi",
    value: "",
    description: "",
    dataType: "string",
    attributes: null,
    source: "",
    formElement: "input",
  },
  {
    identifier: "finish_date",
    shortText: "Termin",
    longText: "Termin",
    value: "",
    default: new Date().toISOString().slice(0, 10),
    description: "",
    dataType: "string",
    attributes: null,
    source: "",
    formElement: "input",
    formElementType: "date",

    required: true,
  },
  {
    identifier: "finish_time",
    shortText: "Na godzinę",
    longText: "Na godzinę",
    value: "",
    //default: new Date().toTimeString().slice(0,5),
    description: "",
    dataType: "time",
    attributes: null,
    source: "",
    formElement: "input",
    formElementType: "time",
    required: true,
  },
  {
    identifier: "language_pair",
    shortText: "Para językowa",
    longText: "Para językowa",
    value: "",
    //default: new Date().toTimeString().slice(0,5),
    description: "",
    dataType: "string",
    default: "Ang_Pol",
    attributes: null,
    source: "",
    formElement: "select",
    //formElementType: "time",
    options: [
      { value: "Ang_Pol", text: "en▸pl" },
      { value: "Pol_Ang", text: "pl▸en" },
      { value: "Niem_Pol", text: "de▸pl" },
      { value: "Pol_Niem", text: "pl▸de" },
    ],
    required: true,
  },

  {
    identifier: "type_of_translation",
    shortText: "Rodzaj",
    longText: "Rodzaj",
    value: "",
    //default: new Date().toTimeString().slice(0,5),
    description: "",
    dataType: "string",
    default: "z",
    attributes: null,
    source: "",
    formElement: "select",
    options: [
      { value: "z", text: "zwykłe" },
      { value: "u", text: "uwierzytelnione" },
    ],
    required: true,
  },
  {
    identifier: "name",
    shortText: "Nazwa dokumentu #1",
    longText: "Nazwa dokumentu #1",
    value: "",
    //default: new Date().toTimeString().slice(0,5),
    description: "",
    dataType: "string",
    attributes: null,
    source: "",
    formElement: "input",
    required: true,
  },
];



export default function NewOrderCustom({ setModal, setUpdateCustom, editID }) {
 
  async function submitData(e, formData) {
    e.preventDefault();
    const response = await fetch(
      URL + "components/db-create-custom-order.php",
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(formData),
      }
    );
    const result = await response.json();
    setUpdateCustom((prev) => prev + 1);
    return result;
  }

  const editData = useGeneric({ name: "customOrders", query: { id: editID } });
  const defaults = !editData.loading ? editData.rows?.[0] : undefined;
  return !editID || defaults ? (
    <div>
      <SpreadsheetForm
        dataStructure={tableSchema}
        defaults={defaults}
        submitData={submitData}
        setData={() => {
          setModal((prev) => ({ ...prev, show: false }));
        }}
        button={editID ? "Edytuj" : "Dodaj"}
      />
    </div>
  ) : (
    <></>
  );
}
