import { useEffect, useState } from "react";
import { useGeneric } from "../useGeneric";
import prettyDate from "../tables/functions/prettyDate";
import dateToSQL from "./TimesheetRev/dateToSQL";

export default function Now({ partial }) {
  const [extended, setExtended] = useState(false);
  const [pickedDate, setPickedDate] = useState(new Date());
  const dataFetch = useGeneric({
    name: "db-now",
    query: {
      limit: 100,
      offset: 0,
      orderBy: "start",
      orderDesc: "DESC",
      search: {
        last_started_day: {
          value: dateToSQL(pickedDate),
          exact: true,
        },
      },
      /*  search: active
          ? { ...search, active: { value: 1, exact: true } }
          : search,*/
    },
    update: pickedDate.toDateString(),
    reload: 45,
  });

  const data =
    !dataFetch.loading ? dataFetch.rows : undefined;

  const dataByPerson = {};

  if (data && data.length) {
    data.forEach((item) => {
      if (item.employee_group !== 10) return;
      if (!dataByPerson[item.cardname]) {
        dataByPerson[item.cardname] = {};
        dataByPerson[item.cardname].items = [];
        dataByPerson[item.cardname].name = item.cardname;
        dataByPerson[item.cardname].startWork = item.startWork;
        dataByPerson[item.cardname].endWork = item.endWork;
        dataByPerson[item.cardname].employee_group = item.employee_group;
      }
      dataByPerson[item.cardname].items.push(item);
    });
  }

  console.log(dataByPerson);
  return (
    <div className={"now-wrap" + (partial ? " partScreen" : " fullScreen")}>
      <div className="switching">
        <button
          className={extended ? "active" : ""}
          onClick={() => setExtended(true)}
        >
          Wszystkie
        </button>
        <button
          className={extended ? "" : "active"}
          onClick={() => setExtended(false)}
        >
          Ostatnie
        </button>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "40px 120px 40px",
            marginLeft: "20px",
          }}
        >
          <button
            onClick={() =>
              setPickedDate((prev) => {
                const yesterday = new Date(prev);
                yesterday.setDate(yesterday.getDate() - 1);
                if (yesterday.getDay() === 6) {
                  yesterday.setDate(yesterday.getDate() - 1);
                } else if (yesterday.getDay() === 0) {
                  yesterday.setDate(yesterday.getDate() - 2);
                }
                return yesterday;
              })
            }
            style={{ fontSize: "32px", padding: 0 }}
          >
            ◂
          </button>
          <input
            style={{ padding: "2px", fontSize: "15px" }}
            value={pickedDate.toISOString().split("T")[0]}
            onChange={(e) => setPickedDate(new Date(e.target.value))}
            type="date"
          />
          <button
            onClick={() =>
              setPickedDate((prev) => {
                const tomorrow = new Date(prev);
                tomorrow.setDate(tomorrow.getDate() + 1);
                if (tomorrow.getDay() === 6) {
                  tomorrow.setDate(tomorrow.getDate() + 2);
                } else if (tomorrow.getDay() === 0) {
                  tomorrow.setDate(tomorrow.getDate() + 1);
                }
                return tomorrow;
              })
            }
            style={{ fontSize: "32px", padding: 0 }}
          >
            ▸
          </button>
        </div>
      </div>
      {!dataFetch.loading ? (
        <div className="data-view">
          {Object.keys(dataByPerson)
            //.sort((a, b) => a.localeCompare(b))
            .sort(
              (a, b) =>
                new Date(dataByPerson[a].startWork) -
                new Date(dataByPerson[b].startWork)
            )
            .map((key) => (
              <div className="person">
                <div
                  className={
                    "person-name" + (dataByPerson[key].endWork ? " went" : "")
                  }
                >
                  <div>{key}</div>
                  <div className="person-work-start">
                    {new Date(dataByPerson[key].startWork).toLocaleTimeString(
                      "pl-PL",
                      {
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                    {dataByPerson[key].endWork ? (
                      "▸" +
                      new Date(dataByPerson[key].endWork).toLocaleTimeString(
                        "pl-PL",
                        {
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="person-items">
                  {dataByPerson[key].items.map((item, index, arr) => {
                    return !extended && index !== 0 /*!== arr.length - 1*/ ? (
                      <></>
                    ) : (
                      <div className="person-item">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="started">
                            {prettyDate(item.last_started, "")}
                          </div>
                          <div className="action">
                            {item.action_type_string}
                          </div>
                        </div>
                        {item.order_string ? (
                          <>
                            <div className="order">
                              {item.order_string}.
                              {item.language_main
                                ? item.language_main.toLowerCase()
                                : ""}
                            </div>
                            <div className="customer">{item.customer_name}</div>
                            {item.count_zzs && parseInt(item.count_zzs) ? (
                              <div className="count">
                                {parseInt(item.count_zzs) + " zzs"}
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div style={{ padding: "40px" }}>
          Wczytywanie
        </div>
      )}
    </div>
  );
}
