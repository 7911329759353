import { useState } from "react";

export default function NewItemOther({ types, otherData, setOtherData }) {
  return (
    <div>
      {types && (
        <div className="other-types">
          {types
            .filter((t) => t.category === "other")
            .map((t) => (
              <button
                className={
                  "action-type" + (otherData.action_type === t.id ? " active" : "")
                }
                style={
                  otherData.action_type === t.id 
                    ? { boxShadow: "0 -5px " + t.color + " inset" }
                    : {}
                }
                onClick={() => {
                  setOtherData((prev) => ({ ...prev, action_type: t.id }));
                }}
              >
                {t.name}
              </button>
            ))}
        </div>
      )}
    </div>
  );
}
