import React, { useContext } from "react";
import HolidaysContext from "../contexts/holidays-context";
import { processDates } from "./holidayFunctions";

function HolidayCalendar() {
  const context = useContext(HolidaysContext);
  const {
    translators,
    translatorsRaw,
    user,
    date,
    setHoliday,
    setChange,
    activeDates,
    dayOffTypes,
    setContextParams,
    today,
  } = context;

  const person = translators.filter(
    (person) =>
      parseInt(user.id) === parseInt(person.id) ||
      user.name === "tymczasowy" ||
      parseInt(user.type) === 0
  )[0];
  console.log(activeDates, translatorsRaw);

  const calendarData = processDates(date, activeDates, person).aggregateGroups;
  let startDay = calendarData[0].content[0].dayObj.getDay();
  startDay = startDay ? startDay : 7;
  const offset = [...Array(startDay - 1).keys()];
  console.log(calendarData);
  return (
    <div className="holidayCalendarWrap">
      <div className="holidayCalendarHeading">
        <div>Poniedziałek</div>
        <div>Wtorek</div>
        <div>Środa</div>
        <div>Czwartek</div>
        <div>Piątek</div>
        <div>Sobota</div>
        <div>Niedziela</div>
      </div>
      <div className="holidayCalendar">
        {offset.map((item) => (
          <div></div>
        ))}
        {calendarData.map(
          (group, index) =>
            group &&
            group.content.map((day, index) => {
              const dayOffType = dayOffTypes.find(
                (item) => item.code === parseInt(day.type)
              );

              return (
                <div
                  className={
                    day.dayClass +
                    (day.off ? " off" : "") +
                    (dayOffType ? " " + dayOffType.className : "") +
                    (day.active ? " active" : "") +
                    (day.publicHoliday ? " holiday" : "")
                  }
                  onClick={() => {
                    // if (!canControl) return false;
                    if (!day.off) {
                      if (!day.active) {
                        setHoliday("add", {
                          person: person.id,
                          employeeID: person.employeeID,
                          day: day.dayISO,
                        });
                        console.log(person);
                        setChange((prev) => (prev = prev + 1));
                      } else {
                        setHoliday("remove", {
                          person: person.id,
                          day: day.dayISO,
                        });
                        setChange((prev) => (prev = prev + 1));
                      }
                    }
                  }}
                  onContextMenu={(e) => {
                    if (!day.off) {
                      e.preventDefault();
                      setContextParams(() => ({
                        person: person.id,
                        day: day.dayISO,
                        left:
                          e.target.getBoundingClientRect().left +
                          e.target.getBoundingClientRect().width / 2,
                        top:
                          e.target.getBoundingClientRect().top +
                          (e.target.getBoundingClientRect().height - 5),
                        visible: true,
                        group: false,
                        new: day.active ? false : true,
                        formattedDate:
                          new Date(day.dayISO).toLocaleDateString() +
                          ", " +
                          new Date(day.dayISO).toLocaleDateString("pl-PL", {
                            weekday: "long",
                          }),
                      }));
                    }
                  }}
                >
                  <div className="dayNumber">{day.dayNumber}</div>
                  <div className="allInfo">
                    <div className="holidayType">
                      {dayOffType ? dayOffType.fullName : day.holidayDescript}
                    </div>
                  </div>
                  <div className="otherPeople">
                    {activeDates
                      .filter(
                        (item) =>
                          item.date === day.dayISO &&
                          parseInt(item.translatorID) !== person.id
                      )
                      .map((i) => (
                        <div>
                          {
                            translatorsRaw.find(
                              (t) => parseInt(i.translatorID) === t.id
                            )?.Initial
                          }
                        </div>
                      ))}
                  </div>
                </div>
              );
            })
        )}
      </div>
    </div>
  );
}

export default HolidayCalendar;
