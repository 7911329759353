import { useFormikContext } from "formik";
import FieldGroup from "./forms/FieldGroup";
import { useEffect, useState } from "react";
import saveIcon from "../../images/icons/buttons/save.svg";

export default function SectionGroup({
  structure,
  pathID,
  bagContents,
  locationState,
  setBagContents,
  editData,
  propToName,
  setAllFormSourceData,
  allFormSourceData,
  hiddenFields,
  setHiddenFields,
}) {
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  const [setterActed, setSetterActed] = useState(0);

  useEffect(() => {
    Object.keys(structure.fields).map((key) => {
      const item = structure.fields[key];

      if (item.hideShow) {
        const hideShow = item.hideShow[Number(values[key])];

        if (hideShow) {
          setHiddenFields((prev) => {
            return [...prev.filter((i) => i !== hideShow.hide), hideShow.show];
          });
        }
      }

      if (
        item.affect &&
        Array.isArray(item.affect) &&
        Array.isArray(item.affectAction)
      ) {
        item.affect.forEach((a, index) =>
          setFieldValue(
            a,
            item.affectAction[index](values, allFormSourceData?.[item.name])
          )
        );
      } else if (item.affect) {
        console.log(item.affect, values[item.affect])
        setFieldValue(
          item.affect,
          item.affectAction(values, allFormSourceData?.[item.name])
        );
      }
    });
  }, [JSON.stringify(values)]);

  return (
    <>
      <div className={"section-group"}>
        {structure.sections.map((section) => (
          <div
            style={section.columns ? { gridColumn: section.columns } : {}}
            className="section"
          >
            <div className="section-title">{section.title}</div>
            <div className="section-fields">
              {section.fields.map((fieldgroup) => {
                let fieldnames = [];

                if (fieldgroup.fields) {
                  fieldnames = fieldgroup.fields;
                } else if (!Array.isArray(fieldgroup)) {
                  fieldnames = [fieldgroup];
                } else {
                  fieldnames = fieldgroup;
                }

                return (
                  <div>
                    {fieldgroup.heading ? (
                      <div className="field-group-heading">
                        {fieldgroup.heading}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className={"columns-" + fieldnames.length}
                      style={fieldgroup?.style}
                    >
                      {fieldnames.map((fieldname) => {
                        const item = propToName(structure.fields)[fieldname];

                        return !item || item.hide ? (
                          <></>
                        ) : (
                          <>
                            {item.prefix ? item.prefix : <></>}
                            {hiddenFields &&
                            hiddenFields.includes(fieldname) ? (
                              <></>
                            ) : (
                              <FieldGroup
                                createMap={structure}
                                inits={locationState}
                                bagContents={bagContents}
                                setBagContents={setBagContents}
                                setAllFormSourceData={setAllFormSourceData}
                                allFormSourceData={allFormSourceData}
                                item={item}
                                pathID={pathID}
                                all={editData}
                                preFill={
                                  editData && editData[0]
                                    ? editData[0][item.name]
                                    : undefined
                                }
                                setSetterActed={setSetterActed}
                              />
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="button-wrap">
        {isSubmitting ? (
          <div className="create-info">...</div>
        ) : (
          <button type="submit">
            <img src={saveIcon} /> Zapisz
          </button>
        )}
      </div>
    </>
  );
}
