import { isSameDay } from "../../main/WorkTime";

function minuteString(number) {
  let r = number + " minut temu";
  switch (number) {
    case "":
      r = "";
      break;
    case 0:
      r = "przed chwilą";
      break;
    case 1:
      r = "minutę temu";
      break;
    case 2:
    case 3:
    case 4:
      r = number + " minuty temu";
      break;
  }
  if (number > 19) {
    switch (String(number).at(-1)) {
      case "2":
      case "3":
      case "4":
        r = number + " minuty temu";
        break;
    }
  }
  return r;
}

function hourString(number) {
  let r = number + " godzin temu";
  switch (number) {
    case "":
      r = "";
      break;
    case 0:
      r = "";
      break;
    case 1:
      r = "godzinę temu";
      break;
    case 2:
    case 3:
    case 4:
      r = number + " godziny temu";
      break;
  }
  if (number > 19) {
    switch (String(number).at(-1)) {
      case "2":
      case "3":
      case "4":
        r = number + " godziny temu";
        break;
    }
  }
  return r;
}

function dayString(number, date) {
  let r = number + " dni temu";
  switch (number) {
    case "":
      r = "";
      break;
    case 0:
      r = "";
      break;
    case 1:
      r = "wczoraj";
      break;
    case 2:
      r = "przedwczoraj";
      break;
  }
  if (number > 2 && number < 7) {
    r = date.toLocaleDateString("pl-PL", { weekday: "long" });
  }

  if (number === 7) {
    r = "tydzień temu";
  }

  return r;
}

function monthString(number, date) {
  let r = number + " miesięcy temu";
  switch (number) {
    case "":
      r = "";
      break;
    case 0:
      r = "";
      break;
    case 1:
      r = "miesiąc temu";
      break;
  }
  if (number > 1 && number < 12) {
    r = date.toLocaleDateString("pl-PL", { month: "long" });
  }

  return r;
}

function yearString(number) {
  let r = number + " lat temu";
  switch (number) {
    case "":
      r = "";
      break;
    case 0:
      r = "";
      break;
    case 1:
      r = "rok temu";
      break;
    case 2:
    case 3:
    case 4:
      r = number + " lata temu";
  }

  return r;
}

export default function timeAgo(d) {
  //const date = new Date(d);
  const date = new Date(d);
  const now = new Date();

  let diff = now - date;

  const years = Math.floor(diff / 1000 / 60 / 60 / 24 / 365);

  const months = Math.floor((diff / 1000 / 60 / 60 / 24 / 30) % 12);

  const days = months < 1 ? Math.floor((diff / 1000 / 60 / 60 / 24) % 365) : "";

  const hours = isSameDay(date, now) ? Math.floor(diff / 1000 / 60 / 60) : "";
  const minutes =
    hours !== "" && Number(hours) < 1 ? Math.floor(diff / 1000 / 60) : "";

  return minutes !== ""
    ? minuteString(minutes)
    : hours !== ""
    ? hourString(hours)
    : days !== ""
    ? dayString(days, date)
    : months !== ""
    ? monthString(months, date)
    : years > 0
    ? yearString(years)
    : "";

  return (
    "y: " +
    years +
    " m: " +
    months +
    " / d: " +
    days +
    " h: " +
    hours +
    " m: " +
    minutes
  );
}
