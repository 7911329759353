import { Link } from "react-router-dom";
import TableUniversal from "../../TableUniversal";
import { translatorsMap } from "./maps/translatorsMap";
import languageDisplay from "../displays/languageDisplay";
import { languagesList } from "../../../global";
import { translatorsPropMap } from "../examples/Dictionaries/TranslatorsPropMap";
import pagesIcon from "../../../../images/icons/buttons/sentpages.svg";
import rateIcon from "../../../../images/icons/buttons/rate.svg";
import moneyIcon from "../../../../images/icons/buttons/money.svg";
import statsIcon from "../../../../images/icons/buttons/stats.svg";
import customersIcon from "../../../../images/icons/buttons/customers.svg";
import translatorsIcon from "../../../../images/icons/buttons/translators.svg";
import customMenuItems from "./customMenuItems";

const dictionary = {
  id: {
    name: "id",
    style: {
      width: "20px",
    },
    order: "id",
  },
  first_name: {
    name: "Imię",
    style: {
      width: "100px",
    },
  },
  last_name: {
    name: "Nazwisko",
    style: {
      width: "140px",
    },
    order: "last_name",
  },
  languages_short: {
    name: "Język",
    //order: "language, last_name",
    style: {
      width: "80px",
      textAlign: "center",
    },
    transform: (v) =>
      v && (
        <div
          style={{
            width: "max-content",
            background: "white",
            borderRadius: "10px",
            boxShadow: "0 0 2px rgba(0,0,0,.1)",
            padding: "3px",
          }}
        >
          {v.split(",").map((lang) => (
            <div
              style={{
                padding: "2px",
                display: "flex",
                justifyContent: "left",
                fontSize: "11px",
              }}
            >
              {languageDisplay(
                { name: lang },
                languagesList.find((i) => lang === i.code)?.name,
                { padding: "0", width: "100%" }
              )}
            </div>
          ))}
        </div>
      ),
  },

  type: {
    name: "Przys.",
    order: "type, last_name",
    style: {
      fontSize: "80%",
      textAlign: "center",
      width: "80px",
    },
    transform: (v) =>
      v === 0 ? (
        <>-</>
      ) : (
        <strong style={{ fontSize: "15px", color: "green" }}>★</strong>
      ),
  },
  written_or_oral: {
    name: "Ustny",
    order: "written_or_oral",
    style: {
      width: "54px",
      textAlign: "center",
    },
    transform: (v) =>
      v === 1 ? (
        <div style={{ fontWeight: "bold", color: "green" }}>✓</div>
      ) : (
        <div style={{ color: "lightgrey" }}>✖</div>
      ),
  },
  town: {
    name: "Miejsce",
    style: {
      width: "110px",
    },
  },
  phone: {
    name: "Tel.",
    style: {
      width: "110px",
    },
  },
  email: {
    name: "Email",
    style: {
      width: "150px",
      fontSize: "12px",
    },
    transform: (v) => <a href={"mailto:" + v}>{v}</a>,
  },

  date_added: {
    name: "Dodano",
    order: "date_added",
    style: {
      color: "grey",
      width: "90px",
      fontSize: "80%",
    },
    transform: (v) => new Date(v).toLocaleDateString(),
  },
  full_notes: {
    name: "Szczegóły",
    order: "language, last_name",
    style: {
      width: "170px",
      textAlign: "left",
      paddingRight: "6px",
      fontSize: "11px",
    },
    title: (v, all) => ({
      jsx:
        (all?.education ? "WYKSZTAŁCENIE:\r\n" : "") +
        all?.education +
        "\r\n" +
        (all?.experience ? "DOŚWIADCZENIE:\r\n" : "") +
        all?.experience +
        "\r\n" +
        (all?.comment ? "UWAGI:\r\n" : "") +
        all?.comment,
      present: all?.education || all?.experience || all?.comment,
    }),
    transform: (v) => <div className="tiny-scrollbar notes">{v}</div>,
  },
};

export default function NewExternals() {
  return (
    <div className="fullScreen new-externals">
      <TableUniversal
        name="Tłumacze"
        data="new-externals"
        classNames="big"
        icon={translatorsIcon}
        propMap={translatorsPropMap}
        defLimit={10}
        createMap={translatorsMap}
        del="del-externals-translators"
        buttons={[
          {
            action: () => console.log("!"),
            link: (rowData) => "../sent/?translator=" + rowData.id.base,
            content: (rowData) => (
              <img style={{ width: "14px" }} src={pagesIcon} />
            ),
          },
        ]}
        customMenu={customMenuItems}
        searchPattern={{
          first_name: "",
          last_name: "",
          language: {
            label: "Język",
            value: "",
            optionsDB: {
              name: "language",
              target: "language",
              db: "languages-searchable",
              searchColumn: "full_name",
              targetColumn: "full_name",
              displayOptions: languageDisplay,
              orderBy: "importance",
              orderDesc: "ASC",
              exact: false,
            },
          },
          full_notes: {
            label: "Specj./uwagi",
            value: "",
          },
          town: "",
          type: {
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              zwykły: 0,
              przysięgły: 1,
            },
          },
          written_or_oral: {
            value: "",
            element: "select",
            options: {
              "-": "",
              tak: 1,
              nie: 0,
            },
          },
          user_group: {
            label: "tryb",
            element: "select",
            value: "",
            options: {
              wszystkie: "",
              obsługa: 0,
              "koordynator ang.": 1,
            },
          },
          active: {
            label: "Aktywne",
            value: 1,
            element: "select",
            options: {
              tak: 1,
              nie: 0,
            },
          },
        }}
        orderBy="last_name"
        orderDesc="ASC"
        options={{
          // selectable: true,
          editable: true,
        }}
        dictionary={dictionary}
      />
    </div>
  );
}
