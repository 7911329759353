import React from "react";

import TranslatorItem from "./TranslatorItem";
import Loading from "../../main/Loading";

function TranslatorsViewer(props) {
  const { translators, setSub, sub, setUpdates, setModal } = props;

  return (
    <div className="translators_editor">
      {!translators.loading ? (
        translators.editor.map((item) => (
          <TranslatorItem
            key={item.id}
            item={item}
            setUpdates={setUpdates}
            setSub={setSub}
            sub={sub}
            setModal={setModal}
          />
        ))
      ) : (
        <Loading text="Pobieranie" />
      )}
    </div>
  );
}

export default TranslatorsViewer;
