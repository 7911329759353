import { useEffect, useState } from "react";
import NumberOrder from "./common/NumberOrder";
import { isSameDate } from "../functions";

export default function ExecutiveDailyOrderTableLang({
  viewData,
  mode,
  selectedDate,
  style,
  onlyLeft,
  setSum,
}) {
  const numbers = viewData.map((i) => {
    const translated = JSON.parse("[" + i.translated_details + "]");
    const created = JSON.parse("[" + i.active_details + "]");
    const sent = JSON.parse("[" + i.sent_details + "]");
    const dec = 2;

    const orderData = created.map((x) => {
      const sysPages = (r = 1) => Math.round(x.pages * r) / r;

      const sysToday = (r = 1) =>
        isSameDate(x.create_date, selectedDate)
          ? Math.round(x.pages * r) / r
          : 0;

      const transPages = (r = 1) =>
        translated &&
        Math.round(
          translated
            ?.filter((t) => t?.order_id === x.order_id)
            .map((t) => Number(t.translated_pages_cut))
            .reduce((a, b) => a + b, 0) * r
        ) / r;

      const transPagesNoCut = (r = 1) =>
        translated &&
        Math.round(
          translated
            ?.filter((t) => t?.order_id === x.order_id)
            .map((t) => Number(t.translated_pages))
            .reduce((a, b) => a + b, 0) * r
        ) / r;

      const translatedToday = (r = 1) =>
        translated &&
        Math.round(
          translated
            ?.filter((t) => isSameDate(t?.start, selectedDate))
            ?.filter((t) => t?.order_id === x.order_id)
            .map((t) => Number(t.translated_pages))
            .reduce((a, b) => a + b, 0) * r
        ) / r;

      const sentPages = (r = 1) =>
        sent &&
        Math.round(
          sent
            ?.filter((s) => s?.order_id === x.order_id)
            ?.map((s) => Number(s.pages))
            .reduce((a, b) => a + b, 0) * r
        ) / r;

      const transPagesAdjustForHuge =
        transPagesNoCut(dec) > transPages(dec) * 3
          ? sysPages(dec)
          : transPages(dec);
      return {
        ...x,
        translated: translated,
        translatedToday: translatedToday(dec),

        sysPages: sysPages(dec),
        sysToday: sysToday(dec),
        transPages: transPagesAdjustForHuge,
        transPagesNoCut: transPagesNoCut(dec),
        sentPages: sentPages(dec),
        sum:
          sysPages(dec) - transPagesAdjustForHuge - sentPages(dec) >= 0
            ? sysPages(dec) - transPagesAdjustForHuge - sentPages(dec)
            : 0,
      };
    });

    return {
      orderData: orderData,
      orderDataCustom: orderData,
      calcSums: (orderData) => ({
        sumSys: orderData
          .map((i) => Number(i.sysPages))
          .reduce((a, b) => a + b, 0),
        sumSysToday: orderData
          .map((i) => Number(i.sysToday))
          .reduce((a, b) => a + b, 0),
        sumTrans: orderData
          .map((i) => Number(i.transPages))
          .reduce((a, b) => a + b, 0),
        sumTransToday: orderData
          .map((i) => Number(i.translatedToday))
          .reduce((a, b) => a + b, 0),
        sumSent: orderData
          .map((i) => Number(i.sentPages))
          .reduce((a, b) => a + b, 0),
        sumAll: orderData.map((i) => Number(i.sum)).reduce((a, b) => a + b, 0),
      }),
    };
  });

  const [activeRows, setActiveRows] = useState(() =>
    numbers[0].orderData.map((i) => i.order_id)
  );

  numbers[0].orderDataCustom = numbers[0].orderData.filter((i) =>
    activeRows.includes(i.order_id)
  );

  useEffect(() => {
    setSum((prev) =>
      Math.round(numbers[0].calcSums(numbers[0].orderDataCustom).sumAll)
    );
  }, [activeRows.length]);

  return (
    <table style={style} className="pretty-table orders-list-combined">
      <thead>
        <tr>
          <th colSpan={3}>Zlecenie</th>
          <th colSpan={4}>Strony</th>
        </tr>
        <tr>
          <th>Data</th>
          <th>Numer</th>
          <th>Klient</th>
          <th>Sys.</th>
          <th>Tł.</th>
          <th>Wysł.</th>
          <th>Suma</th>
        </tr>
      </thead>
      <tbody>
        <tr className="sums">
          <td colSpan={3} style={{ textAlign: "right" }}>
            SUMY
          </td>

          <td>
            {numbers
              .map((n) => n.calcSums(n.orderDataCustom).sumSys)
              .reduce((a, b) => a + b, 0)}
            <br />
            <span
              style={{
                display: "inline-block",
                background: "white",
                padding: "1px 10px",
                fontSize: "80%",
                fontWeight: "100",
                color: "grey",
              }}
            >
              {Math.floor(numbers
                .map((n) => n.calcSums(n.orderDataCustom).sumSysToday)
                .reduce((a, b) => a + b, 0))}
            </span>
          </td>
          <td>
            {numbers
              .map((n) => n.calcSums(n.orderDataCustom).sumTrans)
              .reduce((a, b) => a + b, 0)}
            <br />
            <span
              style={{
                display: "inline-block",
                background: "white",
                padding: "1px 10px",
                fontSize: "80%",
                fontWeight: "100",
                color: "grey",
              }}
            >
              {Math.floor(numbers
                .map((n) => n.calcSums(n.orderDataCustom).sumTransToday)
                .reduce((a, b) => a + b, 0))}
            </span>
          </td>
          <td>
            {numbers
              .map((n) => n.calcSums(n.orderDataCustom).sumSent)
              .reduce((a, b) => a + b, 0)}
          </td>
          <td>
            {numbers
              .map((n) => n.calcSums(n.orderDataCustom).sumAll)
              .reduce((a, b) => a + b, 0)}
          </td>
        </tr>
        {numbers.map((i) =>
          i.orderData.map((x) =>
            onlyLeft && !x.sum ? (
              <></>
            ) : (
              <tr
                onClick={() =>
                  setActiveRows((prev) => {
                    if (prev.find((i) => i === x.order_id)) {
                      return prev.filter((i) => i !== x.order_id);
                    } else {
                      return [...prev, x.order_id];
                    }
                  })
                }
                className={
                  "selector-row" +
                  (!activeRows.find((r) => r === x.order_id) ? " inactive" : "")
                }
              >
                <td
                  style={{
                    textAlign: "center",
                    width: "65px",
                    fontSize: "10px",
                  }}
                >
                  {new Date(x.create_date)
                    .toLocaleDateString("pl-PL", {
                      day: "numeric",
                      month: "long",
                      weekday: "short",
                    })
                    .split(",")
                    .reverse()
                    .map((d, index) =>
                      index === 0 &&
                      new Date() - new Date(x.create_date) <
                        1000 * 60 * 60 * 24 * 7 ? (
                        <></>
                      ) : (
                        <span> {d}</span>
                      )
                    )}
                </td>
                <td style={{ width: "120px" }}>
                  <NumberOrder
                    id={x.order_id}
                    number_order={x.order_number}
                    withLang={true}
                    lang={x.language_main}
                  />
                </td>
                <td style={{ maxWidth: "400px" }}>{x.customer_name}</td>
                <td style={{ width: "50px", textAlign: "center" }}>
                  {x.sysPages}
                </td>
                <td
                  title={x.transPagesNoCut}
                  style={{ width: "50px", textAlign: "center" }}
                >
                  {x.translated &&
                    x.translated
                      ?.filter((t) => t?.order_id === x.order_id)
                      ?.map((t) => (
                        <div className="translated-details">
                          <div style={{ fontSize: "10px" }}>
                            {t.translator_name}
                          </div>
                          <div className="translated-details-pages">
                            <div>
                              {Math.round(t.translated_pages * 10) / 10}
                            </div>
                            <div>{">"}</div>
                            <div style={{ fontWeight: "bold" }}>
                              {Math.round(t.translated_pages_cut * 10) / 10}
                            </div>
                          </div>
                        </div>
                      ))}

                  {x.transPages || <></>}
                </td>
                <td style={{ width: "50px", textAlign: "center" }}>
                  {x.sentPages || <></>}
                  {/*sent &&
                        sent
                          ?.filter((s) => s?.order_id === x.order_id)
                          ?.map((s) => (
                            <>
                              <div>{s.translator_name}</div>
                              <div>{s.quantity}</div>
                              <div>{s.unit}</div>
                            </>
                          ))*/}
                </td>
                <td style={{ width: "50px", textAlign: "center" }}>
                  {Number(x.sum > 0) ? x.sum : <></>}
                </td>
              </tr>
            )
          )
        )}
      </tbody>
    </table>
  );
}
