import React from "react";

function MainButton(props) {
  return (
    <div
      onClick={() => {
        props.setClicked(props.alt);
      }}
      className={"mainButton" + (props.clicked ? " clicked" : "")}
    >
      <img alt={props.alt} src={props.icon} style={{boxSizing: "border-box",padding: (6 * props.iconPadding) + "px"}}></img>
      <h2
        style={{
          backgroundColor: props.styles.borderColor
            ? props.styles.borderColor
            : "inherit",
        }}
      >
        {props.text}
      </h2>
    </div>
  );
}

export default MainButton;
