import React, { useState } from "react";
import useInvoices from "../useInvoices";
import { URL } from "../global";
import { invoiceSchema, invoiceFullSchemaDescription } from "../schemas";

function Invoice(props) {
  const [detailed, setDetailed] = useState(false);
  const { item, index } = props;
  if (item.pro_forma != "1") {
    item.pro_forma = "$h";
    item["pro_forma_used_id"] = "$h";
    item["pro_forma_used_number"] = "$h";
    item["pro_forma_used_date"] = "$h";
  }
  if (item.duplicate == "0") {
    item["duplicate"] = "$h";
    item["duplicate_date"] = "$h";
  }
  return (
    <div
      className="item"
      key={index}
      style={{
        background:
          item?.customers_countries_name !== "Polska"
            ? "linear-gradient(45deg,whitesmoke,lightgrey)"
            : "rgba(255,255,255,.7)",
      }}
    >
      <div className="heading" onClick={() => setDetailed((prev) => !prev)}>
        <span className="invoiceNumber">
          Faktura {item?.pro_forma == "1" ? "PRO FORMA " : <></>}{" "}
          {item?.pro_forma == "1" ? "PRO FORMA " : <></>}
          {item?.number_facture}
        </span>
        <span className="client">{item?.customers_name}</span>do zlec.{" "}
        {item?.orders_number}
        <span className="brutto">
          {String(item.price_brutto).replace(/\./, ",")} zł
        </span>
        <span className="adres">
          {item.customers_street} {item.customers_nr_home}/
          {item.customers_nr_place} {item.customers_town}
        </span>
        <span style={{ color: item.status === "payed" ? "green" : "red" }}>
          {item.status === "payed" ? "zapłacona" : "niezapłacona"}
        </span>
      </div>
      {detailed ? (
        <div className="invoiceDetails">
          {invoiceSchema.map((propGroup) => (
            <div className="innerGroup">
              <div className="innerHeading">{propGroup.category}</div>
              <div className="innerGroupData">
                {propGroup?.properties?.map((prop) => {
                  if (item[prop] === "$h") return null;
                  return (
                    <div className="innerRow">
                      <div className="definition" title={prop}>
                        {invoiceFullSchemaDescription[prop]}
                      </div>
                      <div className={"value " + prop}>
                        {item[prop] ? item[prop] : "-"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function Invoices() {
  const [date, setDate] = useState(() => {
    const selectedDate = new Date();
    //selectedDate.setDate(-1);
    selectedDate.setDate(0);
    return selectedDate;
  });
  const invoices = useInvoices({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  });
  const [jpkLink, setJPKlink] = useState(null);

  function generateJPK(settings) {
    console.log("");
    const data = invoices.jpk[settings.schema];

    fetch(URL + "components/jpk_dom.php", {
      method: "POST",
      body: JSON.stringify({
        data: data,
        schemaType: settings.schema,
      }),
    })
      .then((res) => res.text())
      .then((text) => {
        setJPKlink(URL + "components/" + text);
      });
  }

  return (
    <>
      <div className="top-bar invoices-top">
        <div className="jpk">
          <button
            onClick={() =>
              setDate((prev) => {
                const newDate = new Date(prev);
                newDate.setMonth(prev.getMonth() - 1);
                return new Date(newDate);
              })
            }
          >
            Poprzedni miesiąc
          </button>
          <button
            onClick={() => {
              setDate((prev) => {
                const newDate = new Date(prev);
                newDate.setMonth(prev.getMonth() + 1);
                return new Date(newDate);
              });
            }}
          >
            Następny miesiąc
          </button>
          <div class="separator"></div>
          <button onClick={() => generateJPK({ schema: "JPK_V7M (2)" })}>
            JPK-V7M (2)
          </button>
          <button onClick={() => generateJPK({ schema: "JPK_VAT (3)" })}>
            JPK-VAT (3)
          </button>
          <button onClick={() => generateJPK({ schema: "JPK_FA (4)" })}>
            JPK-FA (4)
          </button>
          {jpkLink ? (
            <a className="jpkLink" href={jpkLink} target="_blank">
              Pobierz plik {jpkLink.split("/").at(-1)}
            </a>
          ) : (
            <></>
          )}
        </div>
        <div>
          {date.toLocaleDateString("pl-PL", { month: "long" })}{" "}
          {date.toLocaleDateString("pl-PL", { year: "numeric" })}
        </div>
      </div>
      <div className="invoices">
        {!invoices.loading &&
          invoices.data.length &&
          invoices.data.map((item, index) => (
            <Invoice item={item} index={index}></Invoice>
          ))}
      </div>
    </>
  );
}
export default Invoices;
