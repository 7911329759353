import { useContext } from "react";
import SettlementList from "./SettlementList";
import { TableContext } from "../../../TableUniversal";
import { Link } from "react-router-dom";

export default function SettlementLists({
  translators,
  settlement,
  settlementDone,
  setSettlement,
  selectedFullByTranslator,
  currentInput,
  setCurrentInput,
  createOpen,
  setCreateOpen,
  editor,
}) {
  const { setReplace, setUpdate } = useContext(TableContext);

  function comeBack() {
    setUpdate((prev) => prev + 1);
    setReplace([
      false,
      {
        translators: translators,
        settlement: settlement,
        setSettlement: setSettlement,

        selectedFullByTranslator: selectedFullByTranslator,
        editor: false,
      },
    ]);
  }

  return settlement && settlement.length && selectedFullByTranslator ? (
    <div className="settlement-list-box">
      <div className="summary">
        {editor ? (
          <div style={{ display: "flex" }}>
            <button
              style={{
                fontSize: "10px",
                padding: "5px 10px",
                borderRadius: "5px",
                width:"100px"
              }}
              onClick={() => comeBack()}
            >
              Powrót
            </button>
            <div style={{ width: "300px", marginLeft: "10px" }}>
              Edytor rozliczeń
            </div>
          </div>
        ) : (
          <></>
        )}
        {!editor ? (
          <div>
            <button
              onClick={() => {
                setReplace([
                  true,
                  {
                    translators: translators,
                    settlement: settlement,
                    setSettlement: setSettlement,
                    selectedFullByTranslator: selectedFullByTranslator,
                    currentInput: currentInput,
                    setCurrentInput: setCurrentInput,
                    createOpen: createOpen,
                    setCreateOpen: setCreateOpen,
                    editor: true,
                  },
                ]);
              }}
              style={{
                fontSize: "10px",
                padding: "5px 10px",
                borderRadius: "5px",
              }}
            >
              Rozlicz ▶
            </button>
          </div>
        ) : (
          <></>
        )}
        <div>Tłumacze: {translators.length}</div>
      </div>
      {translators.map((key,index) => (
        <SettlementList
          index={index}
          key={key}
          selectedFull={selectedFullByTranslator[key]}
          settlement={settlement}
          settlementDone={settlementDone}
          setSettlement={setSettlement}
          editor={editor}
          currentInput={currentInput}
          setCurrentInput={setCurrentInput}
          createOpen={createOpen}
          setCreateOpen={setCreateOpen}
          comeBack={comeBack}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}
