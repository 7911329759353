export const translatorsPropMap = {
    "id": "Nr ident.",
    "first_name": "Imię",
    "last_name": "Nazwisko",
    "company_name": "Nazwa firmy",
    "languages_main": "Języki",
    "languages_additional": "Języki dodatkowe",
    "type": "Przysięgły",
    "written_or_oral": "Ustny czy pisemny",
    "notes": "Uwagi",
    "comment": "Komentarz",
    "other_id": "Inne id",
    "phone": "Nr tel.",
    "phone2": "Nr tel. 2",
    "other_contact": "Inny kontakt",
    "email": "E-mail",
    "email2": "E-mail 2",
    "settlement_type_id": null,
    "settlement_type_id2": null,
    "settlement_type_id3": null,
    "rate_ids": null,
    "date_added": "Data dodania",
    "active": "Aktywny",
    "street": "Ulica",
    "post_code": "Kod pocztowy",
    "town": "Miejscowość",
    "education": "Wykształcenie",
    "experience": "Doświadczenie",
    "id_specialty": null,
    "id_status": null,
    "id_label": null,
    "oral_translation_type": "Typ tłumaczenia ustnego",
    "recommended_by": "Polecenie",
    "user_group": "Grupa użytkowników",
    "languages_main_full": "Pełna nazwa języka",
    "language": "Język",
    "languages_short": "Język (skr.)",
    "full_notes": "Pełne uwagi",
    "full_name": "Imię i nazwisko",
    "search": "Ciąg wyszukiwania",
    "count": "Wszystkie"
  }