import React, { useEffect, useState } from "react";
import Spreadsheet from "../Spreadsheet";
import swornRegistryTable from "./data";
import SpreadsheetForm from "../SpreadsheetForm";
import useSworn, { useSwornSearch, useAll } from "./useSworn";
import SwornHistory from "./SwornHistory";
import { URL, languagesList, correctDocumentTypeName } from "../../global";
import Checkbox from "../../Checkbox";
import printIcon from "../../../images/icons/print.svg";
import xlsIcon from "../../../images/icons/xls.svg";
import wordIcon from "../../../images/icons/word.svg";
import replicateIcon from "../../../images/icons/icons8-replicate-rows-48.png";
import newCopyIcon from "../../../images/icons/icons8-new-copy-32.png";
import { useLocation } from "react-router-dom";
import exportToExcel from "../../main/exportToExcel";
import generateWord from "./generateWord";
import Form from "../../forms-universal/Form";
import OrderItem from "../../main/TimesheetRev/NewItem/SearchBox/ResultTypes/OrderItem";
import { calculate, newFormData } from "./new-form-data";
//import SavePDF from "../../SavePDF";

function SwornRegistry(props) {
  const { orders, handleModal } = props;
  const [modal, setModal] = handleModal;
  const [updates, setUpdates] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear());
  const [page, setPage] = useState(null);
  const [orderSearchString, setOrderSearchString] = useState("");
  const [customerSearchString, setCustomerSearchString] = useState("");
  const [numberSearchString, setNumberSearchString] = useState("");
  const [print, setPrint] = useState(false);
  const [exportMode, setExportMode] = useState(false);
  const [docExportMode, setDocExportMode] = useState(false);
  const [pdfSave, setPdfSave] = useState(false);
  const today = new Date();
  const thisYear = today.getFullYear();

  const dataSchema = swornRegistryTable(orders);

  const dataRaw = useSworn(year, page, updates);
  const dataAllRaw = useAll(
    year,
    print || exportMode || docExportMode || pdfSave
  );
  const dataSearch = useSwornSearch(
    orderSearchString,
    customerSearchString,
    updates
  );

  const data = dataRaw.items?.filter((i) => i.history === 0);

  const dataAll = dataAllRaw.items?.filter((i) => i.history === 0);

  const countLast = Math.floor(
    parseInt(dataRaw.count - 1 + parseInt(dataRaw.firstNum - 1)) / 5
  );
  const firstNumPages = parseInt(dataRaw.firstNum)
    ? Math.ceil(parseInt(dataRaw.firstNum - 1) / 5)
    : 0;
  const lastNumPages = parseInt(dataRaw.lastNum)
    ? Math.ceil(parseInt(dataRaw.lastNum) / 5)
    : 0;

  //console.log(data);
  //console.log(page, firstNumPages);

  const location = useLocation();
  //console.log(location);

  const calculables = {
    action_type: (obj) =>
      !obj.custom_source_language ? obj.source_language : obj.action_type,
    amount_received: (obj) =>
      Math.round(
        //(obj.pages * obj.gross_per_page + 10 * obj.pages * (obj.copies - 1)) *
        (obj.pages * obj.gross_per_page +
          0.2 * obj.gross_per_page * obj.pages * (obj.copies - 1)) *
          100
      ) / 100,
    // correct this because it updates when you edit anything
  };

  useEffect(() => {
    setPage(countLast);
  }, [countLast]);

  useEffect(() => {
    if (location.state && location.state.newData) {
      const orderData = location.state.newData.order;
      const docData = location.state.newData.item;
      setModal({
        show: true,
        type: "Nowy wpis ze zlecenia",
        width: "960",
        className: "sworn-registry-form",
        style: {
          borderRadius: "10px",
          border: "none",
        },
        updates: 0,
        contentData: {},
        content: (
          <>
            <Form
              fieldData={newFormData}
              submitData={submitData}
              defaultFieldValues={{
                date: new Date().toISOString().substring(0, 10),
                order_id:orderData.id,
                order_number: orderData.valid_order,
                client: orderData.customers_name,
                document_id: docData.id,
                document_name: correctDocumentTypeName(docData.document_name),
                source_language: orderData.language_from,
                pages: docData.pages_count_translator,
                copies: 1,
                gross_per_page: Math.round(parseFloat(docData.rate) * 1.23 * 100) / 100,
                document_reference_person_concerned: 0,
              }}
              calculate={calculate}
              edit={false}
              duplicate={true}
              setModal={setModal}
            />
          </>
        ) /*content: (

          <SpreadsheetForm
            mode="create"
            dataStructure={dataSchema}
            submitData={submitData}
            calculables={calculables}
            defaults={{
              date_added: new Date(),
              order_id: orderData.id,
              order_number: orderData.valid_order,
              client: orderData.customers_name,
              document_name: correctDocumentTypeName(docData.document_name),
              source_language: orderData.language_from,
              pages: docData.pages_count_translator,
              gross_per_page:
                Math.round(parseFloat(docData.rate) * 1.23 * 100) / 100,
            }}
          />
        )*/,
      });
      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    //console.log(dataAll);
    if (print && dataRaw.success) {
      window.print();
      setPrint(false);
      setPage(countLast);
    }
  }, [dataRaw]);

  useEffect(() => {
    //console.log(dataAllRaw);
    if (exportMode && dataAllRaw.success) {
      let rows = dataAll.slice().map((i) => {
        const dataSchemaGroups = dataSchema
          .filter((i) => i.group)
          .map((j) => j.content);
        const dataSchemaNoGroups = dataSchema.filter((i) => !i.group);
        const dataSchemaFlat = [
          ...dataSchemaNoGroups,
          ...dataSchemaGroups,
        ].flat(1);

        let values = {};
        Object.entries(i).forEach(([key, val]) => {
          //console.log(key, val);
          const currentItem = dataSchemaFlat.find((v) => v.identifier === key);
          values[key] =
            currentItem && currentItem.transform && val
              ? currentItem.transform(val, i).raw ||
                currentItem.transform(val, i)
              : val;
        });
        //console.log(i, values);
        const alignment = {
          wrapText: "true",
          horizontal: "center",
          vertical: "center",
        };
        const defaultFont = {
          sz: 9,
        };
        return {
          "L.p.": {
            v: values.number,
            t: "n",
            s: {
              font: {
                bold: "true",
                //color: { rgb: "5F9EA0" },
              },
              alignment: alignment,
            },
          },
          "Data zlecenia": {
            v: values.date,
            t: "d",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Oznaczenie zleceniodawcy albo zamawiającego wykonanie oznaczonego dokumentu":
            {
              v: values.client,
              t: "s",
              s: {
                alignment: alignment,
                font: defaultFont,
              },
            },
          "Nazwa, data i oznaczenie dokumentu": {
            v:
              values.document_name +
              "\n" +
              (values.document_date === "brak daty"
                ? "brak daty"
                : new Date(values.document_date).toLocaleDateString("pl-PL", {
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                  })) +
              "\n" +
              (!values.document_reference ? `brak oznaczenia` : ``) +
              (parseInt(values.document_reference_person_concerned)
                ? `brak oznaczenia\n(dot. ${values.document_reference})`
                : values.document_reference),
            t: "s",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          /*"Nazwa dok.": {
            v: values.document_name,
            t: "s",
            s: {
              alignment: alignment,
            },
          },
          "Data dok.": {
            v: values.document_date,
            t: "d",
            s: {
              alignment: alignment,
            },
          },
          "Oznaczenie dok.": {
            v: values.document_reference,
            t: "s",
            s: {
              alignment: alignment,
            },
          },*/
          "Język, w którym sporządzono dokument": {
            v: values.source_language,
            t: "s",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Osoba lub instytucja, która sporządziła dokument": {
            v: values.drawn_up_by ? values.drawn_up_by : "b.d.",
            t: "s",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Uwagi o rodzaju, treści, formie i stanie dokumentu": {
            v: values.form + ", " + values.document_condition,
            t: "s",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Rodzaj wykonanej czynności i język tłumaczenia": {
            v: values.action_type,
            t: "s",
            s: {
              alignment: alignment,
              font: {
                sz: 9,
              },
            },
          },
          "Liczba str. tłum.": {
            v: String(values.pages)?.replace(",", "."),
            t: "n",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Liczba egz.": {
            v: values.copies,
            t: "n",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Zwrot dokumentu wraz z tłumaczeniem": {
            v: values.document_return,
            t: "s",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Wysokość pobranego wynagrodzenia (brutto) w zł": {
            v: String(values.amount_received).replace(",", "."),
            t: "n",
            s: {
              alignment: alignment,
              font: defaultFont,
            },
          },
          "Opis tłum. ust": { v: "", t: "s" },
          Odmowa: { v: "", t: "s" },
        };
      });

      /*rows.push({
        Kwota: {
          t: "n",
          f: "=SUM(E2:E" + (rows.length + 1) + ")",
        },
        "Liczba znaków": {
          t: "n",
          f: "=SUM(D2:D" + (rows.length + 1) + ")",
        },
      });*/

      exportToExcel({
        fileName: "Repertorium " + year,
        rows: rows,
        colStyles: [
          { width: 6 },
          { width: 11 },
          { width: 15 },
          { width: 24 },
          { width: 9 },
          { width: 10 },
          { width: 10 },
          { width: 13 },
          { width: 6 },
          { width: 6 },
          { width: 10 },
          { width: 8 },
          { width: 5 },
          { width: 5 },
        ],
        rowStyle: { hpt: 85 },
        colFormatData: {
          columns: [13],
          string: "0,00 zł",
        },
      })
        .then((result) => {
          console.log("Eksport gotowy");
        })
        .then((r) => setExportMode(false));
    }
  }, [dataAllRaw]);

  useEffect(() => {
    if (docExportMode && dataAllRaw.success) {
      const rowsData = {
        rep: dataAll.slice().map((i) => {
          const dataSchemaGroups = dataSchema
            .filter((i) => i.group)
            .map((j) => j.content);
          const dataSchemaNoGroups = dataSchema.filter((i) => !i.group);
          const dataSchemaFlat = [
            ...dataSchemaNoGroups,
            ...dataSchemaGroups,
          ].flat(1);

          let values = {};
          Object.entries(i).forEach(([key, val]) => {
            //console.log(key, val);
            const currentItem = dataSchemaFlat.find(
              (v) => v.identifier === key
            );
            values[key] =
              currentItem && currentItem.transform && val
                ? currentItem.transform(val, i).raw ||
                  currentItem.transform(val, i)
                : val;
          });

          return {
            number: values.number,
            date: new Date(values.date).toLocaleDateString("pl-PL", {
              month: "2-digit",
              year: "numeric",
              day: "2-digit",
            }),
            client: values.client,
            doc_name: values.document_name,
            doc_date:
              values.document_date === "brak daty"
                ? "brak daty"
                : new Date(values.document_date).toLocaleDateString("pl-PL", {
                    month: "long",
                    year: "numeric",
                    day: "numeric",
                  }),
            doc_ref:
              (!values.document_reference ? `brak oznaczenia` : ``) +
              (parseInt(values.document_reference_person_concerned)
                ? `brak oznaczenia\n(dot. ${values.document_reference})`
                : values.document_reference),
            lang: values.source_language,
            drawn_up_by: values.drawn_up_by ? values.drawn_up_by : "b.d.",
            form: values.form,
            condition: values.document_condition,
            action_type: values.action_type,
            pages: String(values.pages)?.replace(",", "."),
            copies: values.copies,
            return: values.document_return,
            amount: String(values.amount_received).replace(",", "."),
          };
        }),
      };
      /*
      const rowsData = {
        rep: dataAll.map((item) => ({
          number: item.number,
          date: item.date,
          customers_name: item.client,
          document_name: item.document_name,
          document_date: item.document_date,
          document_number: item.document_reference,
          document_language: item.source_language,
          action_type: item.action_type,
          pages: item.pages,
          copies: item.copies,
          return_date: item.document_return,
          amount_received: item.amount_received,
          drawn_up_by: item.drawn_up_by
        })),
      };*/
      generateWord(rowsData).then((result) => setDocExportMode(false));
    }
  }, [dataAllRaw]);

  function submitData(e, formData, queryType = "add") {
    e.preventDefault();
    return fetch(URL + "components/db-sworn.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        queryType: queryType,
        query: {
          ...formData,
          date_added: new Date(),
          userID: 0,
          year: new Date(formData.date).getFullYear(),
        },
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setUpdates((prev) => prev + 1);
        if (!location.state) {
          setPage(countLast);
        }
        setOrderSearchString("");
        setCustomerSearchString("");
        setYear(new Date().getFullYear());

        if (!result.error_message) {
          setModal({ show: false });
        }
        return result;
        /*
        if (result.error_message) {
          setSubmitError(true);
        } else {
          setSubmitError(false);
          return result;
        }*/
      });
  }

  function editData(e, formData) {
    e.preventDefault();
    return fetch(URL + "components/db-sworn.php", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        queryType: "edit",
        query: {
          ...formData,
          date_added: new Date(),
          userID: 0,
          year: new Date(formData.date).getFullYear(),
        },
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setUpdates((prev) => prev + 1);
        if (!result.error_message) {
          setModal({ show: false });
        }
        return result;
        /*if (result.error_message) {
          setSubmitError(true);
        } else {
          setSubmitError(false);
          return result;
        }*/
      });
  }
  return (
    <div className="swornRegistry spreadsheetWrapper">
      <div className="top-bar">
        <div className="selectMy">
          <button
            className="standardButton newEntry"
            onClick={() => {
              window.history.replaceState({}, document.title);

              setModal({
                show: true,
                type: "Nowy wpis",
                className: "sworn-registry-form",
                width: "960", //"wider-plus",
                style: {
                  borderRadius: "10px",
                  border: "none",
                },
                updates: 0,
                contentData: {},
                content: (
                  <>
                    <Form
                      fieldData={newFormData}
                      submitData={submitData}
                      defaultFieldValues={{
                        date: new Date().toISOString().substring(0, 10),
                        copies: 1,
                        gross_per_page: 0,
                        document_reference_person_concerned: 0,
                      }}
                      calculate={calculate}
                      edit={false}
                      setModal={setModal}
                    />
                    {/* <SpreadsheetForm
                    mode="create"
                    dataStructure={dataSchema}
                    submitData={submitData}
                    calculables={calculables}
                    defaults={{
                      date_added: new Date(),
                    }}
                    //defaults={{number: parseInt(data.length ? data.at(-1).number : 0) + 1}}
                      />*/}
                  </>
                ),
              });
            }}
          >
            +
          </button>
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{
              fontSize: "18px",
              padding: "1px 6px",
              fontWeight: "bold",
              marginRight: "13px",
              marginLeft: "9px",
            }}
          >
            {[
              thisYear,
              thisYear - 1,
              thisYear - 2,
              thisYear - 3,
              thisYear - 4,
              thisYear - 5,
              thisYear - 6,
              thisYear - 7,
              thisYear - 8,
              thisYear - 9,
              thisYear - 10,
            ]
              .filter((year) => year > 2021)
              .map((year) => (
                <option value={year}>{year}</option>
              ))}
          </select>

          {!dataRaw.error ? (
            <div className="selectMy">
              {orderSearchString.length || customerSearchString.length ? (
                <div className="reg_info">
                  <div className="reg_info_text">
                    Znaleziono: {dataSearch?.items?.length}
                  </div>
                  <button
                    className="reg_info_button"
                    onClick={() => {
                      setCustomerSearchString("");
                      setOrderSearchString("");
                    }}
                  >
                    Powrót do przeglądania
                  </button>
                </div>
              ) : !print ? (
                <>
                  <button
                    className={
                      "pageNavButton" +
                      (page === firstNumPages ? " disabled" : "")
                    }
                    onClick={() =>
                      page === firstNumPages ? null : setPage(firstNumPages)
                    }
                  >
                    |◄
                  </button>
                  <button
                    className={
                      "pageNavButton" +
                      (page === firstNumPages ? " disabled" : "")
                    }
                    onClick={() =>
                      page === firstNumPages
                        ? null
                        : setPage((prev) => (prev > 1 ? prev - 1 : 0))
                    }
                  >
                    ◄
                  </button>
                  <div className="register_page_shown">
                    {data?.length && Math.ceil(data?.[0]?.number / 5)} z{" "}
                    {lastNumPages}
                    <span
                      style={{
                        fontSize: "80%",
                        marginLeft: "12px",
                        color: "grey",
                      }}
                    >
                      ({data?.[0]?.number}-{data?.at(-1)?.number})
                    </span>
                  </div>

                  <button
                    className={
                      "pageNavButton" + (page === countLast ? " disabled" : "")
                    }
                    onClick={() =>
                      setPage((prev) =>
                        prev < countLast ? prev + 1 : countLast
                      )
                    }
                  >
                    ►
                  </button>
                  <button
                    className={
                      "pageNavButton" + (page === countLast ? " disabled" : "")
                    }
                    onClick={() => setPage(countLast)}
                  >
                    ►|
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="selectMy">
          {orderSearchString.length || customerSearchString.length ? (
            <></>
          ) : (
            <>
              <label className="search_label" htmlFor="page">
                Strona
              </label>
              <input
                onChange={(e) => setPage(e.target.value - 1)}
                value={parseInt(page) + 1}
                name="page"
                type="number"
                min={firstNumPages + 1}
                max={lastNumPages}
                style={{ width: "60px" }}
              />
              <label className="search_label" htmlFor="number">
                Nr rep.
              </label>
              <input
                onChange={(e) => {
                  setNumberSearchString(e.target.value);
                  setPage(Math.floor((e.target.value - 1) / 5));
                }}
                value={numberSearchString}
                name="number"
                type="number"
                min={dataRaw.firstNum}
                max={dataRaw.lastNum}
                style={{ width: "60px" }}
              />
            </>
          )}
        </div>
        <div className="selectMy">
          <label className="search_label" htmlFor="order">
            Nr zlec.
          </label>
          <input
            onChange={(e) => setOrderSearchString(e.target.value)}
            value={orderSearchString}
            name="order"
            type="search"
            style={{ width: "70px" }}
          />
          <label className="search_label" htmlFor="customer">
            Klient
          </label>
          <input
            onChange={(e) => setCustomerSearchString(e.target.value)}
            value={customerSearchString}
            name="customer"
            type="search"
            style={{ width: "120px" }}
          />
        </div>
        <div className="selectMy">
          {/*<Checkbox label="Wszystkie" def={false} />*/}
          {/*<button
            className="standardButton editButton"
            onClick={() => {
              setPrint(true);
              setPage("all");
            }}
          >
            <img src={printIcon} />
            <span>Drukuj</span>
          </button>*/}
          {/*<SavePDF data={dataAll} loaded={dataAllRaw.success} pdfSave={pdfSave} setPdfSave={setPdfSave}/>*/}
          <button
            className="standardButton tight editButton"
            onClick={() => {
              setExportMode(true);
            }}
          >
            <img height="30" src={xlsIcon} />
            <span>Excel</span>
          </button>
          <button
            className="standardButton tight editButton"
            onClick={() => {
              setDocExportMode(true);
            }}
          >
            <img src={wordIcon} />
            <span>Word</span>
          </button>
          <div className="numInfo">
            <div>
              <span className="numCaption">Strony</span> {countLast + 1}{" "}
            </div>
            <div>
              <span className="numCaption">Ostatni nr</span> {dataRaw.lastNum}
            </div>
          </div>
        </div>
      </div>
      {dataRaw.error ? (
        <div className={"fullInfo"}>Brak wpisów</div>
      ) : (
        <Spreadsheet
          superHeader={{
            className: "littleHeaderInfo only-print",
            content: (
              <div>
                <div>
                  1 Opis tłumaczenia ustnego wskazujący datę, miejsce, zakres i
                  czas trwania tłumaczenia,
                </div>
                <div>
                  2 Informacje o odmowie wykonania tłumaczenia na rzecz
                  podmiotów, o których mowa w art. 15
                </div>
              </div>
            ),
          }}
          dataStructure={dataSchema}
          editData={editData}
          submitData={submitData}
          firstPage={
            firstNumPages && !(orderSearchString || customerSearchString)
              ? firstNumPages
              : 1
          }
          data={
            !orderSearchString && !customerSearchString
              ? data
              : dataSearch.items
          }
          calculables={calculables}
          options={{
            numberColumn: false,
            deleteRows: false,
            acceptRows: false,
            driveLink: "order_number",
            addSimilar: (item) => (
              <span
                //style={{ fontSize: "32px", padding: "5px"  }}
                className="addSimilarButton"
                title="Dodaj podobny"
                onClick={() =>
                  setModal({
                    show: true,
                    type: "Nowy wpis według poprzedniego",
                    className: "sworn-registry-form",
                    width: "960",
                    content: (
                      <>
                        <Form
                          fieldData={newFormData}
                          /* date_added: new Date(),
                          order_id: item.order_id,
                          order_number: item.order_number,
                          client: item.client,
                          source_language: item.source_language,
                          pages: 1,
                          gross_per_page: item.gross_per_page,
                          form: item.form,*/
                          submitData={submitData}
                          defaultFieldValues={{
                            copies: 1,
                            date: new Date().toISOString().slice(0, 10),
                            action_type: item?.action_type,
                            order_id: item?.order_id,
                            document_id: item?.document_id,
                            order_number: item?.order_number,
                            client: item?.client,
                            source_language: item?.source_language,
                            form: item?.form,
                            year: new Date().getFullYear(),
                          }}
                          calculate={calculate}
                          duplicate={true}
                          setModal={setModal}
                        />
                        {/*
                      <SpreadsheetForm
                        mode="create"
                        dataStructure={dataSchema}
                        submitData={submitData}
                        calculables={calculables}
                        defaults={{
                          date_added: new Date(),
                          order_id: item.order_id,
                          order_number: item.order_number,
                          client: item.client,
                          source_language: item.source_language,
                          pages: 1,
                          gross_per_page: item.gross_per_page,
                          form: item.form,
                        }}
                      />*/}
                      </>
                    ),
                  })
                }
              >
                <img src={replicateIcon} />
              </span>
            ),
            addCopy: (item) => (
              <span
                //style={{ fontSize: "32px", padding: "5px"  }}
                className="addCopyButton"
                title="Nowy wpis - dodatkowy egzemplarz do tego nr. rep."
                onClick={() =>
                  setModal({
                    show: true,
                    width: "960",
                    className: "sworn-registry-form",
                    type: (
                      <span>
                        Dodatkowy egzemplarz na podst. nr. rep.{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {item.number} / {item.year}
                        </span>{" "}
                      </span>
                    ),
                    content: (
                      <>
                        <Form
                          fieldData={newFormData}
                          disabledFields={[
                            "client",
                            //"pages",
                            "amount_received",
                            "action_type",
                            "drawn_up_by",
                            "form",
                            "document_condition",
                            "source_language",
                            "document_name",
                            "document_date",
                            "document_reference",
                          ]}
                          submitData={submitData}
                          defaultFieldValues={{
                            client: item?.client,
                            document_name: item?.document_name,
                            copies: 1,
                            form: item?.form,
                            document_condition: item?.document_condition,
                            source_language: item?.source_language,
                            drawn_up_by: item?.drawn_up_by,
                            document_date: item?.document_date,
                            document_reference: item?.document_reference,
                            document_id: item?.document_id,
                            action_type: item?.action_type,
                            order_id: item?.order_id,
                            order_number: item?.order_number,
                            date: new Date().toISOString().slice(0, 10),
                            year: new Date().getFullYear(),
                            copy_of_number: item.number + "/" + item.year,
                          }}
                          calculate={calculate}
                          duplicate={true}
                          setModal={setModal}
                        />
                        {/*<SpreadsheetForm
                        mode="newCopy"
                        dataStructure={dataSchema}
                        calculables={calculables}
                        params={{
                          year: today.getFullYear(),
                          month: today.getMonth(),
                        }}
                        submitData={submitData}
                        submitDataOption="addCopy"
                        defaults={{
                          ...item,
                          copy_of_number: item.number + "/" + item.year,
                          gross_per_page: 10,
                          amount_received: item.gross_per_page * item.pages,
                          document_return: new Date()
                            .toISOString()
                            .slice(0, 10),
                        }}
                        button="Dodaj wpis (dod. egzemplarz)"
                        disabledFields={[
                          "order_id",
                          "date",
                          "client",
                          "document_name",
                          "document_date",
                          "document_reference",
                          "source_language",
                          "drawn_up_by",
                          "form",
                          "document_condition",
                          "action_type",
                          "copy_of_number",
                        ]}
                      />*/}
                      </>
                    ),
                  })
                }
              >
                <img src={newCopyIcon} />
              </span>
            ),
          }}
          print={print}
          historyComponent={<SwornHistory orders={orders} />}
        />
      )}
    </div>
  );
}

export default SwornRegistry;
