import React, { useState, useEffect } from "react";
import { readableNumberPL } from "../functions";

function SpreadsheetForm(props) {
  const {
    params,
    submitData,
    submitDataOption,
    calculables,
    defaults,
    button,
    setData,
    mode,
    disabledFields,
  } = props;

  let dataStructure = props.dataStructure;
  dataStructure = dataStructure.map((i) => (i.group ? i.content : i)).flat();

  const [calculate, setCalculate] = useState(0);
  const [query, setQuery] = useState(null);
  const [switchElement, setSwitchElement] = useState(true);

  const [formData, setFormData] = useState(() => {
    const formElements = {};
    const defparams = { ...params, ...defaults };

    console.log(defparams);
    dataStructure.forEach((dataItem) => {
      formElements[dataItem.identifier] = dataItem.default
        ? dataItem.default
        : dataItem.dataType === "integer" || dataItem.dataType === "float"
        ? 0
        : "";
      if (mode === "edit") {
      defparams[dataItem.identifier] = dataItem.transformForm
        ? dataItem.transformForm(defparams[dataItem.identifier])
        : defparams[dataItem.identifier];
      }
    });

    for (const param in defparams) {
      formElements[param] = defparams[param];
    }
    return formElements;
  });

  const [custom, setCustom] = useState({});
  const [additional, setAdditional] = useState(() => {
    let initial = {};
    let additionals = dataStructure.filter((d) => d.additionalSwitch);
    additionals.forEach((item) => {
      initial[item.identifier] =
        formData[item.additionalSwitch.identifier] == "1" ? true : false;
    });
    return initial;
  });

  const calculablesObj = {};

  function calculateValues() {
    for (const attr in calculables) {
      if (formData[attr]?.length > 0 && calculate === 0) continue;
      calculablesObj[attr] = calculables[attr](formData);
    }
    setFormData((prev) => ({
      ...prev,
      ...calculablesObj,
    }));
  }

  //CALCULATE THE CALCULABLES ACCORDING TO PROVIDED FORMULA
  useEffect(() => {
    calculateValues();
  }, [calculate]);
  /*
  useEffect(() => {
    let additionalOn = dataStructure
      .filter((d) => d.additionalSwitch)
      .map((a) => ({ [a.identifier]: true}));
    setAdditional((prev) => ({ ...prev, ...additionalOn }));
  }, [dataStructure.length]);
*/
  useEffect(() => {
    dataStructure.map((dataItem) => {
      if (dataItem.customFormElement) {
        const options = dataItem.options.map((j) => j.value);
        const found = options.filter(
          (opt) => opt == formData[dataItem.identifier]
        );
        console.log(
          options,
          formData[dataItem.identifier],
          dataItem.identifier,
          found
        );
        if (!found.length && formData[dataItem.identifier]) {
          setFormData((prev) => ({
            ...prev,
            ["custom_" + dataItem.identifier]: formData[dataItem.identifier],
            [dataItem.identifier]: false,
          }));
          setCustom((prev) => ({ ...prev, [dataItem.identifier]: true }));
        } else {
          //  setCustom(prev => ({...prev, [dataItem.identifier]: false}));
        }
      }
    });
  }, []);

  return (
    <form className={"spreadsheet-form" + (mode ? " " + mode : "")}>
      {dataStructure.map((dataItem, index) => {
        let { identifier, formElementType, formElementAttributes } = dataItem;
        if (
          dataItem.customFormElement?.identifier &&
          custom[dataItem.identifier]
        ) {
          identifier = dataItem.customFormElement?.identifier;
        }
        if (dataItem.formType === 1) return;
        if (dataItem.hideInMode && dataItem.hideInMode === mode) return;

        const inputProps = {
          name: identifier,
          type: formElementType,
          ...formElementAttributes,
          placeholder: dataItem.placeholder,
          value:
            dataItem.formElementType === "date"
              ? formData?.[identifier].slice(0, 10)
              : formData?.[identifier]
              ? formData?.[identifier]
              : dataItem.formElement === "disabled"
              ? dataItem.formElementType === "number"
                ? 0
                : ""
              : "",
          required: dataItem.required,
          style: {
            ...dataItem.formStyle,
          },
          disabled: disabledFields
            ? disabledFields.find((item) => item === dataItem.identifier)
            : false,
          onChange: (e) => {
            if (calculables && !calculables[identifier])
              setCalculate((prev) => prev + 1);
            setFormData((prev) => {
              const attributes = {
                ...prev,
                [e.target.name]: e.target.value,
              };
              if (dataItem.affects)
                dataItem.affects.forEach((affectedValue) => {
                  const allAffected = JSON.parse(
                    e.target.options[e.target.selectedIndex].getAttribute(
                      "affected"
                    )
                  );
                  if (
                    dataStructure.some((d) => d.identifier === affectedValue)
                  ) {
                    attributes[affectedValue] = allAffected[affectedValue];
                  }
                });
              return attributes;
            });
          },
        };

        return (
          <>
            <div
              className={
                "formItem" +
                ((formData?.[dataItem.identifier] ||
                  formData?.["custom_" + dataItem.identifier]) &&
                formData?.[dataItem.identifier] != "0000-00-00"
                  ? " filled"
                  : " empty")
              }
              style={dataItem.formStyle}
              key={index}
            >
              <label htmlFor={dataItem.identifier}>
                <>
                  <div title={dataItem.description}>{dataItem.shortText}</div>{" "}
                  {dataItem.customFormElement ? (
                    <div
                      className="customFieldSwitch"
                      onClick={() => {
                        setCustom((prev) => ({
                          ...prev,
                          [dataItem.identifier]: !prev[dataItem.identifier],
                        }));
                        if (custom[dataItem.identifier]) {
                          setFormData((prev) => {
                            let mod = prev;
                            delete mod["custom_" + dataItem.identifier];
                            return mod;
                          });
                        } else {
                          setFormData((prev) => ({
                            ...prev,
                            ["custom_" + dataItem.identifier]:
                              formData[dataItem.identifier],
                          }));
                        }
                      }}
                    >
                      {custom[dataItem.identifier] ? "wpis ▸" : "wybór ▸"}
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataItem.transformSwitch &&
                  (formData[dataItem.identifier].replace(/ $/, "").split(" ")
                    .length == 2 ||
                    formData[dataItem.identifier].replace(/ $/, "").split(" ")
                      .length == 3) ? (
                    <div
                      className="customFieldSwitch"
                      title={dataItem.transformSwitch.description}
                      onClick={() =>
                        setFormData((prev) => ({
                          ...prev,
                          [dataItem.identifier]:
                            dataItem.transformSwitch.action(
                              prev[dataItem.identifier]
                            ),
                        }))
                      }
                    >
                      {dataItem.transformSwitch.button}
                    </div>
                  ) : (
                    <></>
                  )}
                  {dataItem.additionalSwitch ? (
                    <div
                      className="customFieldSwitch"
                      onClick={() => {
                        setAdditional((prev) => ({
                          ...prev,
                          [dataItem.identifier]: !prev[dataItem.identifier],
                        }));
                        setFormData((prev) => ({
                          ...prev,
                          [dataItem.additionalSwitch.identifier]: !parseInt(
                            formData[dataItem.additionalSwitch.identifier]
                          ),
                        }));
                      }}
                    >
                      {additional[dataItem.identifier]
                        ? dataItem?.additionalSwitch?.button[1]
                        : dataItem?.additionalSwitch?.button[0]}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </label>
              {dataItem.formElement === "select" ? (
                <>
                  {dataItem.formElementExtended ? (
                    <div className="inputGroup">
                      <input
                        type="checkbox"
                        name="switch"
                        style={{ width: "auto" }}
                        onChange={() => setSwitchElement((prev) => !prev)}
                        checked={switchElement}
                      />

                      <label>{dataItem.formElementSwitch}</label>

                      {!switchElement ? (
                        <>
                          <input
                            type="text"
                            placeholder="wyszukaj wg numeru zlecenia"
                            onChange={(e) => setQuery(e.target.value)}
                          ></input>
                          {dataItem.formElementExtendedView({
                            query: query,
                            inputProps: inputProps,
                          })}
                        </>
                      ) : (
                        <select {...inputProps}>
                          <option value={formData[dataItem.identifier]}>
                            {dataItem.toData ? dataItem.toData(formData) : "-"}
                          </option>
                          {dataItem.options.map((opt) => (
                            <option
                              key={opt.value}
                              value={opt.value}
                              affected={JSON.stringify(opt.affected)}
                            >
                              {opt.text}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {!dataItem.formElementExtended ? (
                    !custom[dataItem.identifier] ? (
                      <select {...inputProps}>
                        <option value={0}></option>
                        {dataItem.options.map((opt) => (
                          <option
                            key={opt.value}
                            value={opt.value}
                            affected={JSON.stringify(opt.affected)}
                          >
                            {opt.text}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        {...inputProps}
                        type={dataItem.customFormElement?.formElementType}
                      ></input>
                    )
                  ) : (
                    <></>
                  )}
                </>
              ) : dataItem.formElement === "textarea" ? (
                <textarea {...inputProps} />
              ) : dataItem.formElement === "disabled" ? (
                <div className="disabledField">
                  {dataItem.value
                    ? dataItem.value
                    : dataItem.dataType === "integer" ||
                      dataItem.dataType === "float"
                    ? readableNumberPL(inputProps.value)
                    : inputProps.value}{" "}
                  {dataItem.unit ? (
                    <span className="unit">{dataItem.unit}</span>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="inputGroup">
                  <input onWheel={(e) => e.target.blur()} {...inputProps} />
                  {dataItem.unit ? (
                    <span className="unit">{dataItem.unit}</span>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </>
        );
      })}
      <button
        type="submit"
        onClick={(e) => {
          for (let attr in custom) {
            if (attr.slice(0, 7) === "custom_" && !custom[attr]) {
              delete formData[attr];
            }
          }
          if (
            dataStructure
              .filter((dataItem) => dataItem.required)
              .find(
                (required) =>
                  formData[required.identifier] === "" ||
                  formData[required.identifier] === null
              ) === undefined
          ) {
            submitData(e, formData, submitDataOption);
            setData((prev) => (prev.show = !prev.show));
          } else {
            window.alert("Najpierw uzupełnij obowiązkowe pola.");
          }
        }}
      >
        {button ? button : "Dodaj wpis"}
      </button>
      {/*<div className="messageField">{dataStructure.map(item => <div>{item.required}</div>)}</div>*/}
    </form>
  );
}

export default SpreadsheetForm;
