import { isToday } from "../../functions";
import { languagesList } from "../../global";
import ShowQueriedOrders from "../../ShowQueriedOrders";
import NumberOrder from "../../tables/common/NumberOrder";

const swornRegistryTable = (orders) => [
  {
    identifier: "number",
    shortText: "Nr rep.",
    longText: "L.p.",
    description:
      "Numer repertorium / liczba porządkowa / numer kolejny wpisu w danym roku",
    //dataType: "integer",
    formElement: "disabled",
    formType: 1,
    className: "registry_number",
    style: null,
    attributes: null,
    source: "",
  },

  {
    identifier: "order_id",
    toData: (props) => props["order_number"] + " " + props["client"],
    formElement: "select",
    formElementExtended: true,
    formElementExtendedView: (props) => <ShowQueriedOrders {...props} />,
    formElementSwitch: "Bieżące zlecenia",
    affects: [
      "client",
      "source_language",
      // "document_name",
      "order_number",
      //"document_return",
      "pages",
      "gross_per_page",
    ],
    shortText: "Nr zlec.",
    longText: "Nr zlec.",
    placeholder: "Wybierz zlecenie",
    description:
      "Wybierz zlecenie - niektóre pola zostaną automatycznie uzupełnione",
    dataType: "string",
    style: null,
    formStyle: {
      fontWeight: "bold",
      boxShadow: "0px 0px 9px rgba(0, 0, 0, .32)",
      background: "#ffffff",
    },
    attributes: null,
    hiddenTable: true,
    default: 0,
    options: [
      ...orders?.data?.filter(
        (i) =>
          i.docPagesSworn > 0 ||
          i?.documents?.find(
            (doc) => doc?.type_of_translation === "poświadczenie"
          )
      ),
    ]
      ?.sort(
        (a, b) =>
          new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
      )
      .map((order) => {
        return {
          value: order.id,
          text: order.valid_order + " " + order.customers_name, // + " (" + order.price_brutto.replace(".",",") + " zł)",
          affected: {
            client: order.customers_name,
            source_language: order.language_from,
            //document_name: order.documents?.[0].document_name,
            order_number: order.valid_order,
            //document_return: order.finish_date.slice(0, 10),
            pages: order.documents.length === 1 ? order.docPagesSworn : 1,
            gross_per_page:
              Math.round(
                parseFloat(
                  order.documents?.find(
                    (i) => i.type_of_translation === "przysięgłe"
                  )?.rate
                ) *
                  1.23 *
                  100
              ) / 100,
          },
        };
      }),
  },

  {
    identifier: "date",
    shortText: "Data wpisu",
    required: true,
    longText: "Data zlecenia",
    description: "Data dokonania wpisu do repertorium",
    formElement: "input",
    formElementType: "date",
    dataType: "date",
    default: new Date().toISOString().slice(0, 10),
    style: null,
    className: "date",
    attributes: null,
    source: "",
    transform: (text) => ({
      jsx:
        text === "0000-00-00" ? (
          <span className="missing_data">brak daty</span>
        ) : (
          new Date(text).toLocaleDateString("pl-PL", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
        ),
      raw: text === "0000-00-00" ? "brak daty" : new Date(text),
    }),
  },
  {
    identifier: "order_number",
    //hiddenTable:true,
    shortText: "Nr zlec.",
    formElement: "disabled",
    className: "order_number",
    style: { width: "100px" },
    transform: (v, a) => {
      //let numArr = v.split(".");
      return (
        <>
          <NumberOrder id={a?.order_id} number_order={v} />
        </>
      );
    },
    formType: 1,
  },
  {
    identifier: "client",
    shortText: "Zleceniodawca",
    required: true,
    longText:
      "Oznaczenie zleceniodawcy albo zamawiającego wykonanie oznaczonego dokumentu",
    description: "Nazwa zleceniodawcy lub imię i nazwisko",
    dataType: "string",
    formElement: "input",
    formElementType: "text",
    attributes: null,
    className: "customer",
    source: "customers_name",
    transformSwitch: {
      action: (v) => {
        v = v.replace(/ $/, "");
        if (v.split(" ").length === 2) {
          return v.split(" ").reverse().join(" ");
        } else if (v.split(" ").length === 3) {
          v = v.split(" ");
          let first = v.shift();
          return [...v, first].join(" ");
        } else if (v?.split) {
          return v;
        }
      },
      button: "<->",
      description: "Odwróć imię i nazwisko",
    },
    placeholder: "Imię Nazwisko",
  },
  {
    group: true,
    identifier: "document_data",
    shortText: "Nazwa, data i oznaczenie dokumentu",
    longText: "Nazwa, data i oznaczenie dokumentu",
    additionalSwitch: "document_reference",
    style: { width: "240px" },
    description: "",
    className: "document_data",
    content: [
      {
        identifier: "document_name",
        required: true,
        group: "document_data",
        shortText: "Nazwa dokumentu",
        longText: "Nazwa dokumentu",
        description: "",
        dataType: "string",
        formElement: "input",
        formElementType: "text",
        className: "document_name",
        attributes: null,
        transform: (v) =>
          v ? (
            v.charAt(0).toUpperCase() +
            v.slice(1).replace(/( [owzi]| na| do| we| od| ze) /g, "$1\u00a0")
          ) : (
            <span className="missing_data">brak nazwy</span>
          ),
      },
      {
        identifier: "document_date",
        group: "document_data",
        shortText: "Data dokumentu",
        longText: "Data dokumentu",
        description: "",
        formElement: "input",
        formElementType: "date",
        dataType: "date",
        attributes: null,
        transform: (text) => ({
          jsx:
            text === "0000-00-00" ? (
              <span className="missing_data">brak daty</span>
            ) : (
              new Date(text).toLocaleDateString("pl-PL", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })
            ),
          raw: text === "0000-00-00" ? "brak daty" : new Date(text),
        }),
      },
      {
        identifier: "document_reference",
        group: "document_data",
        shortText: "Oznaczenie dokumentu",
        longText: "Oznaczenie dokumentu",
        placeholder: "",
        description: "",
        dataType: "string",
        formElement: "input",
        formElementType: "text",
        style: null,
        attributes: null,
        transform: (text) => ({
          raw: text ? text : "brak oznaczenia",
          jsx: text ? (
            text
          ) : (
            <span className="missing_data">brak oznaczenia</span>
          ),
        }),
        additionalSwitch: {
          identifier: "document_reference_person_concerned",
          affects: "document_reference",
          button: ["nr ▸", "dot. ▸"],
          transform: (v) => (
            <>
              <span className="missing_data">brak oznaczenia</span>
              <br />
              <span style={{ color: "grey" }}>{"(dot. "}</span>
              <span style={{ fontStyle: "italic" }}>{v}</span>
              <span style={{ color: "grey" }}>{")"}</span>
            </>
          ),
        },
      },
    ],
  },
  {
    identifier: "document_reference_person_concerned",
    hiddenTable: true,
    formType: 1,
    dataType: "boolean",
  },
  {
    identifier: "source_language",
    shortText: "Język źródłowy",
    longText: "Język, w którym sporządzono dokument",
    description: "",
    formElement: "select",

    className: "source_language",
    options: [
      {
        value: "Pol",
        text: "polski",
      },
      { value: "Ang", text: "angielski" },
      { value: "Pol/Ang", text: "polski i angielski" },
    ],
    transform: (v) => {
      if (v !== "Pol" && v !== "Ang" && v !== "Pol/Ang") {
        return v;
      }
      const languages = v.split("/");
      return {
        jsx: languages.map((l) =>
          v ? (
            <div>
              {"język " +
                languagesList.filter((i) => l == i?.code)[0]?.["name"]}
            </div>
          ) : (
            <span className="missing_data">b.d.</span>
          )
        ),
        raw: languages.map((l) =>
          v
            ? "język " + languagesList.filter((i) => l == i?.code)[0]?.["name"]
            : "b.d."
        ),
      };
    },
    dataType: "",
    style: null,
    attributes: "",
    customFormElement: {
      identifier: "custom_source_language",
      formElement: "input",
      formElementType: "text",
    },
  },
  {
    identifier: "drawn_up_by",
    shortText: "Sporządził/a",
    longText: "Osoba lub instytucja, która sporządziła dokument",
    formElement: "input",
    formElementType: "text",
    description: "",
    dataType: "",
    style: null,
    attributes: "",
    className: "drawn_up_by",
    transform: (text) =>
      text ? (
        text.replace(/( [owzi]| na| do| we| od| ze) /g, "$1\u00a0")
      ) : (
        <span className="missing_data">b.d.</span>
      ),
  },
  {
    group: true,
    identifier: "form_and_condition",
    shortText: "Uwagi",
    longText: "Uwagi o rodzaju, treści, formie i stanie dokumentu",
    content: [
      {
        identifier: "form",
        shortText: "Rodzaj dokumentu",
        description: "oryginał, kopia, wydruk",
        longText: "Uwagi o rodzaju dokumentu",
        formElement: "select",
        options: [
          { value: 1, text: "oryginał" },
          { value: 2, text: "kopia" },
          { value: 3, text: "wydruk" },
          { value: 4, text: "elektroniczny" },
          { value: 5, text: "skan" },
        ],
        description: "",
        default: 1,
        dataType: "",
        style: null,
        attributes: "",
        transform: (v) =>
          v === "1" ? (
            "oryginał"
          ) : v === "2" ? (
            "kopia"
          ) : v === "3" ? (
            "wydruk"
          ) : v === "4" ? (
            "elektroniczny"
          ) : v === "5" ? (
            "skan"
          ) : v === "0" ? (
            <span className="missing_data">b.d.</span>
          ) : (
            v
          ),
        customFormElement: {
          identifier: "custom_form",
          formElement: "input",
          formElementType: "text",
        },
      },
      {
        identifier: "document_condition",
        shortText: "Stan dokumentu",
        longText: "Uwagi o stanie dokumentu",
        formElement: "select",
        options: [
          { value: 1, text: "stan dobry" },
          { value: 2, text: "nieczytelny" },
        ],
        transform: (v) =>
          v === "1" ? (
            "stan dobry"
          ) : v === "2" ? (
            "wpisy nieczytelne"
          ) : v === "0" ? (
            <span className="missing_data">b.d.</span>
          ) : (
            v
          ),
        description: "",
        default: 1,
        dataType: "",
        style: null,
        attributes: "",
        customFormElement: {
          identifier: "custom_document_condition",
          formElement: "input",
          formElementType: "text",
        },
      },
    ],
  },
  {
    identifier: "action_type",
    shortText: "Czynność",
    longText: "Rodzaj wykonanej czynności i język tłumaczenia",
    formElement: "select",
    options: [
      { value: "Pol", text: "Poświadczenie na język angielski" },
      { value: "Ang", text: "Poświadczenie na język polski" },
      {
        value: "Pol/Ang",
        text: "Poświadczenie zgodności - dokument dwujęzyczny",
      },
      { value: "PolT", text: "Tłumaczenie pisemne na język angielski" },
      { value: "AngT", text: "Tłumaczenie pisemne na język polski" },
    ],
    transform: (v, item) => {
      const copy_of_number = item.copy_of_number ? (
        <div style={{ color: "grey", paddingTop: "4px", fontStyle: "italic" }}>
          dodatkowy egzemplarz:
          <br />
          <span style={{ fontWeight: "bold" }}>nr {item.copy_of_number}</span>
        </div>
      ) : (
        <></>
      );
      const copy_of_number_raw = item.copy_of_number
        ? `\n(dodatkowy egzemplarz: nr ${item.copy_of_number})`
        : "";
      return {
        raw:
          v == "Pol"
            ? "Poświadczenie tłumaczenia pisemnego na język angielski " +
              copy_of_number_raw
            : v == "Ang"
            ? "Poświadczenie tłumaczenia pisemnego na język polski" +
              copy_of_number_raw
            : v == "Pol/Ang"
            ? "Poświadczenie zgodności treści dokumentu w języku angielskim z jego treścią w języku polskim " +
              copy_of_number_raw
            : v == "PolT"
            ? "Tłumaczenie pisemne na język angielski" + copy_of_number_raw
            : v == "AngT"
            ? "Tłumaczenie pisemne na język polski" + copy_of_number_raw
            : v,
        jsx:
          v == "Pol" ? (
            <>
              Poświadczenie
              <br />
              tłumaczenia pisemnego
              <br /> <span className="target-language">na język angielski</span>
              {copy_of_number}
            </>
          ) : v == "Ang" ? (
            <>
              Poświadczenie
              <br />
              tłumaczenia pisemnego
              <br />
              <span className="target-language">na język polski</span>
              {copy_of_number}
            </>
          ) : v == "Pol/Ang" ? (
            <>
              <span className="target-language">Poświadczenie zgodności</span>
              <br />
              treści dokumentu
              <br /> w języku angielskim
              <br /> z jego treścią
              <br />w języku polskim
              {copy_of_number}
            </>
          ) : v == "PolT" ? (
            <>
              Tłumaczenie pisemne
              <br />
              <span className="target-language">na język angielski</span>
              {copy_of_number}
            </>
          ) : v == "AngT" ? (
            <>
              Tłumaczenie pisemne
              <br />
              <span className="target-language">na język polski</span>
              {copy_of_number}
            </>
          ) : v.length > 3 ? (
            <>
              {v}
              <br />
              {copy_of_number}
            </>
          ) : (
            <></>
          ),
      };
    },
    style: { width: "164px", fontSize: "90%" },
    //formElement: "disabled",
    description: "",
    dataType: "",
    attributes: "",
    customFormElement: {
      identifier: "custom_action_type",
      formElement: "input",
      formElementType: "text",
    },
  },

  {
    identifier: "copy_of_number",
    shortText: "Dodatkowy egzemplarz dokumentu o nr rep.",
    longText: "Dodatkowy egzemplarz dokumentu o nr rep.",
    formElement: "input",
    formElementType: "text",
    hiddenTable: true,
    hideInMode: "create",
    grayFirst: false,
    //formType: 1,
  },
  {
    identifier: "pages",
    //affects: ["amount_received"],
    shortText: "Str. tłum.",
    longText: "Liczba str. tłum.",
    formElement: "input",
    formElementType: "number",
    description: "",
    dataType: "float",
    style: null,
    className: "pages",
    attributes: "",
    formElementAttributes: {
      step: 0.5,
      min: 0,
    },
    default: 1,
    hideInMode: "create",
    grayFirst: true,
    /*  affected: {
      amount_received: 1
    },*/
    //additional: true,
    //hiddenTable: true,
    //formType: 1,
  },
  {
    identifier: "copies",
    shortText: "Liczba egz.",
    longText: "Liczba egz.",
    formElement: "input",
    formElementType: "number",
    description: "",
    dataType: "",
    className: "copies",
    default: 1,
    style: null,
    attributes: "",
    formElementAttributes: {
      step: 1,
      min: 1,
    },
    //additional: true,
    //hiddenTable: true,
    //formType: 1,
    hideInMode: "create",
    grayFirst: true,
  },
  {
    identifier: "document_return",
    shortText: "Zwrot dok. z tłumaczeniem",
    longText: "Zwrot dokumentu wraz z tłumaczeniem",
    description: "",
    dataType: "date",
    style: null,
    default: null,
    className: "document_return",
    attributes: "",
    formElement: "input",
    formElementType: "date",
    transformForm: (v) => {
      let dateData, date, info;
      if (!v) {
        date = "";
      } else {
        dateData = v.split("**");
        date = dateData[0];
        info = dateData[1];
      }
      return info === "manual" ? date : "";
    },
    transform: (v, item) => {
      let dateData, date, info;
      if (v) {
        dateData = v.split("**");

        date = dateData[0];
        info = dateData[1];
      }
      const infos = {
        manual: {
          text: "data podana ręcznie we wpisie",
          sign: "podana we wpisie",
        },
        actual: {
          text: "data faktycznego zwrotu",
          sign: "✔ potwierdzone",
        },
        deadline: {
          text: "data na podstawie terminu zlecenia",
          sign: "wg terminu",
        },
      };
      return {
        jsx:
          v && v.slice(0, 3) != "000" ? (
            <>
              <span title={infos[info].text}>
                {new Date(date).toLocaleDateString("pl-PL")}
              </span>
              <div
                style={{
                  color:
                    info === "manual"
                      ? "grey"
                      : info === "actual"
                      ? "forestgreen"
                      : "rgb(167, 167, 126)",
                }}
                title={infos[info].text}
                className="dateTypeSign only-screen"
              >
                {infos[info].sign}
              </div>
            </>
          ) : (
            <span className="missing_data" style={{ color: "grey" }}>
              oczekuje
              <br />
              na odbiór
            </span>
          ),
        raw: new Date(date).toLocaleDateString("pl-PL"),
      };
    },
    //additional: true,
    //hiddenTable: true,
    //formType: 1,
    hideInMode: "create",
  },
  {
    identifier: "amount_received",
    shortText: "Pobrane wyn. brutto (zł)",
    longText: "Wysokość pobranego wynagrodzenia (brutto) w zł",
    formElement: "input",
    formElementType: "number",
    description: "",
    dataType: "",
    style: null,
    className: "amount_received",
    unit: "zł",
    attributes: "",
    formElementAttributes: {
      min: 0,
    },
    transform: (value) => ({
      jsx:
        value === "0" ? (
          <span className="missing_data">-</span>
        ) : (
          String(value).replace(".", ",")
        ),
      raw: value === "0" ? "-" : String(value).replace(".", ","),
    }),
    //additional: true,
    //hiddenTable: true,
    //formType: 1,
    hideInMode: "create",
  },
  {
    identifier: "oral_translation",
    shortText: "Tłum. ust.",
    longText: (
      <>
        Opis tłum. ust<sup>1</sup>
      </>
    ),
    description:
      "Opis tłumaczenia ustnego wskazujący datę, miejsce, zakres i czas trwania tłumaczenia,",
    dataType: "",
    style: null,
    attributes: "",
    //additional: true,
    default: "",
    //hiddenTable: true,
    transform: (v) => (v == "0" ? "" : v),
    className: "only-print",
    formType: 1,
  },
  {
    identifier: "rejection",
    shortText: "Odmowa",
    longText: (
      <>
        Odmowa<sup>2</sup>
      </>
    ),
    description:
      "Informacje o odmowie wykonania tłumaczenia na rzecz podmiotów, o których mowa w art. 15",
    dataType: "",
    style: null,
    attributes: "",
    //additional: true,
    //hiddenTable: true,
    className: "only-print",
    transform: (v) => (v == "0" ? "" : v),
    formType: 1,
  },
  {
    group: true,
    shortText: "Wpisane przez/czas",
    longText: "Użytkownik i czas dokonania wpisu",
    className: "only-screen user_name",
    content: [
      {
        identifier: "user_name",
        shortText: "Wpisał",
        longText: "Wpisał",
        formType: 1,
      },
      {
        identifier: "date_added",
        hiddenTable: true,
        formElement: "disabled",
        formType: 1,
        transform: (date) => {
          let t = date.split(/[- :]/);
          let dateAdded = new Date(
            Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
          );
          return (
            <span className="date_added">
              {isToday(dateAdded)
                ? "dzisiaj"
                : dateAdded.toLocaleString("pl-PL", {
                    timeZone: "Europe/Warsaw",
                    weekday: "long",
                  })}
              {", "}
              {dateAdded
                .toLocaleString("pl-PL", { timeZone: "Europe/Warsaw" })
                .split(":")
                .slice(0, 2)
                .join(":")}
            </span>
          );
        },
      },
    ],
  },
  {
    identifier: "gross_per_page",
    longText: "Cena brutto za str.",
    hiddenTable: true,
    default: 0,
    formType: 1,
  },
];

export default swornRegistryTable;
