import flags from "../../../../flags";
import { languagesList } from "../../../../global";
import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";
import languageDisplay from "../../displays/languageDisplay";
import { document_number } from "./common/document_number";
import { order_number } from "./common/order_number";
import rate from "./common/rate";
import { translator_name } from "./common/translator_name";

const unitName = (row) =>
  row ? row.joint_name + " (" + row.number + " " + row.name + ")" : "";

export function roundingMethod(v, method) {
  let rounder = (v) => Math.round(v * 100) / 100;
  switch (method) {
    case "1":
    case 1:
      rounder = (v) => Math.ceil(v);
      break;
    case "2":
    case 2:
      rounder = (v) => (Math.floor(v) ? Math.floor(v) : Math.ceil(v));
      break;
    case "3":
    case 3:
      rounder = (v) => Math.ceil(2 * v) / 2;
      break;
    case "4":
    case 4:
      rounder = (v) =>
        Math.floor(2 * v) / 2 ? Math.floor(2 * v) / 2 : Math.ceil(2 * v) / 2;
      break;
  }
  return rounder;
}

export const sentOrdersMap = {
  db: "components/set/set-externals.php",
  initials: {
    id: "id",
    affects: "translator_name",
    action: (data, id) => {
      const v = data.find((i) => i.id === parseInt(id));
      console.log(id, v);
      return v ? v.first_name + " " + v.last_name : "";
    },
  },
  // hiddenFields: ["rate"],
  fields: {
    translator_name: translator_name,
    translator_id: {
      label: "id tłumacza wg tabeli",
    },
    client_paid_rate: {
      label: "stawka netto płacona przez klienta",
      hide: true,
    },
    order_number: {
      ...order_number,
      dataSource: {
        ...order_number.dataSource,
        info: (row) =>
          row ? (
            <table className="normal-table">
              <thead>
                <tr>
                  <th colSpan={3}>Zlecenie</th>
                  <th colSpan={2}>Stawka</th>
                  <th colSpan={2}>Suma</th>
                </tr>
                <tr>
                  <th>Klient</th>
                  <th>Ilość</th>
                  <th>Termin</th>
                  <th>Netto</th>
                  <th>Brutto</th>
                  <th>Netto</th>
                  <th>Brutto</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ maxWidth: "100px", textAlign: "left" }}>
                    {row.client}
                  </td>
                  <td>{row.pages_to_translate_count}</td>
                  <td>{new Date(row.finish_date).toLocaleString()}</td>
                  <td>
                    {String(
                      Math.floor(
                        (100 * Number(row.price)) /
                          Number(row.pages_to_translate_count)
                      ) / 100
                    ).replace(".", ",")}
                  </td>
                  <td>
                    {String(
                      Math.floor(
                        (100 * Number(row.price_brutto)) /
                          Number(row.pages_to_translate_count)
                      ) / 100
                    ).replace(".", ",")}
                  </td>
                  <td>{String(row.price).replace(".", ",")}</td>
                  <td>{String(row.price_brutto).replace(".", ",")}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            ""
          ),
      },
    },

    order_id: {
      label: "id zlecenia wg systemu",
    },
    unitCounter: {
      label: "counter",
      default: 1,
    },
    unit: {
      label: "id jednostki wg tabeli jednostek",
      default: "1",
      //hide:true
      /*affect: "unitName",
      affectAction: (vs) =>  vs,
      affectProps: ["unit"],*/
    },
    unitIndividualName: {
      label: "",
      default: "",
    },
    unitName: {
      label: "jednostka",
      //element: "select",
      dataSource: {
        name: "translation-units",
        //working!
        initFrom: {
          prop: "unit", // which field in form to use
          in: "id", // look up this column with the prop value
          display: (row) => (row ? unitName(row) : ""), // final_display
          query: {
            exact: true, // query options, value is added automatically
          },
        },
        query: (value, all, formValues) => ({
          joint_name: {
            value: value,
          },
        }),
        value: "joint_name",
        setters: (row) => [
          ["unit", row ? row.id : ""],
          ["unitName", row ? unitName(row) : ""],
          ["unitCounter", row ? row.number : 1],
          ["unitIndividualName", row ? row.name : ""],
        ],
        //distinct: "joint_name",
        display: (v) => (
          <div
            style={{
              fontSize: "14px",
              padding: "5px 12px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{v.joint_name + " (" + v.number + " " + v.name + ")"}</div>
            <div style={{ fontSize: "9px" }}>
              podaj <strong>{v.name}</strong>
            </div>
          </div>
        ),
        text: (v) => v.joint_name,
        /*    options: (row) => ({
          value: row.id,
          text: row.joint_name + " " + row.number + " " + row.name,
        }),*/
      },
    },
    time_approved: { label: "kiedy zatwierdzono" },
    time_added: { label: "kiedy dodano" },
    tax: {
      label: "podatek",
      element: "select",
      //value: 23,
      default: 23,
      options: [
        { value: 23, text: "23%" },
        { value: 0, text: "0%" },
      ],
    },
    settled: { label: "rozliczono" },
    rate_all: rate(),
    rate: {
      label: "stawka netto",
      affect: "amount", //gross ? ["amount"] : ["amount", "rateGross"],
      affectAction: (vs) => {
        const rounder = roundingMethod(vs, vs.rounding_method);
        const result = vs.unitCounter
          ? Math.round(
              100 *
                (rounder(Number(vs.quantity) / Number(vs.unitCounter)) *
                  Number(vs.rate)  +
                  (vs.sending_cost ? Number(vs.sending_cost) : 0))
            ) / 100
          : "-";
        /*console.log(
        rounder,
        vs.rounding_method,
        vs.unitCounter,
        vs.quantity,
        vs.rate,
        result
      );*/
        return result;
      },
      affectProps: [
        "quantity",
        "rate",
        "rate_all",
        "sending_cost",
        "unit",
        "rounding_method",
        "is_gross",
      ],
    },
    rate_gross: {
      label: "stawka_brutto",
    },
    is_gross: {
      label: "rodzaj",
      element: "select",
      //      value: "0",
      affect: "rate_gross",
      affectAction: (row) =>
      Math.round(Number(row.rate_all) *
        (1 +
          (row.is_gross === "1" || row.is_gross === 1
            ? 0
            : Number(row.tax) / 100)) *100)/100,
      affectProps: ["rate", "rate_all", "tax", "is_gross"],
      options: [
        { value: 0, text: "netto" },
        { value: 1, text: "brutto" },
      ],
    },
    rate_distinctor: {
      label: (v) => (
        <div title="wyróżnij nową stawkę wg opisu - np. rodzaj dokumentu">
          Wyróżnik stawki
        </div>
      ),
      type: "search",
      maxLength: 50,
      dataSource: {
        /// check last rate distinctors
        name: "sent-orders-rate-distinctors",
        active: 1,
        params: {
          orderBy: "id",
          orderDesc: "DESC",
        },
        query: (value, all, formValues) => ({
          rate_distinctor: {
            value: value,
          },
          translator_id: {
            value: formValues.translator_id,
            exact: true
          },
        }),
        setters: (row) => {
          return [["rate_distinctor", row ? row.rate_distinctor : ""]];
        },
        display: (v) => (
          <div style={{ padding: "8px" }}>
            <div>{v.rate_distinctor}</div>
            <div style={{ fontSize: "10px" }}>{prettyDate(v.time_added)}</div>
            <div style={{ fontSize: "10px" }}>{v.order_customer}</div>
          </div>
        ),
        text: (v) => v.rate_distinctor,
      },
    },
    quantity: {
      label: (vs) => (
        <div>
          liczba{" "}
          {!vs.unitIndividualName ? (
            <></>
          ) : (
            <span style={{ fontWeight: "700" }}>{vs.unitIndividualName}</span>
          )}
        </div>
      ),
      type: "number",
      affect: "pages",
      affectAction: (vs) => {
        const rounder = roundingMethod(vs, vs.rounding_method);
        const result = vs.unitCounter
          ? Math.round(
              100 * rounder(Number(vs.quantity) / Number(vs.unitCounter))
            ) / 100
          : "-";

        return vs.quantity == result ? "" : result;
      },
      affectProps: [
        "quantity",
        "rate",
        "rate_all",
        "sending_cost",
        "unit",
        "rounding_method",
      ],
      // affect: "amount",
      //affectAction: (vs) => parseFloat(vs.quantity) * parseFloat(vs.rate),
    },
    pages: {
      label: (v) => (v.quantity === v.pages ? "" : v.unitName),
      disabled: true,
    },
    order_customer: { label: "nazwa klienta", required: true },
    languag: {
      label: "język",
      type: "hidden",
    },
    language_from: {
      label: "źródłowy",
      element: "select",
      default: 5,
      dataSource: {
        name: "languages",
        options: (row) => ({ value: row.id, text: row.full_name }),
        /*infoOptions: (row) =>
          row ? (
            <>
              <div>na: {String(row.rate_to_normal)?.replace(".", ",")} zł</div>
              <div>z: {String(row.rate_from_normal)?.replace(".", ",")} zł</div>
            </>
          ) : (
            <></>
          ),*/
      },
    },
    language_to: {
      label: "docelowy",
      element: "select",
      default: 5,
      dataSource: {
        name: "languages",
        options: (row) => ({ value: row.id, text: row.full_name }),
        /*infoOptions: (row) =>
          row ? (
            <>
              <div>na: {String(row.rate_to_normal)?.replace(".", ",")} zł</div>
              <div>z: {String(row.rate_from_normal)?.replace(".", ",")} zł</div>
            </>
          ) : (
            <></>
          ),*/
      },
    },
    group_id: { label: "nr grupy" },
    finished_date: { label: "data wykonania/przekazania przez tłumacza" },
    description: {
      label: "opis zlecenia/dokumentu",
      element: "textarea",
    },
    createdBy: { label: "wpis utworzony przez", hide: true },
    comment: { label: "uwagi" },
    /*sending_cost: {
      label: "koszt wysyłki / inny doliczany do całości",
      type: "number",
      step: ".01",
    },*/
    sending_cost: {
      label: (
        <div>
          <div>wysyłka/inne</div>
        </div>
      ),
      type: "number",
      step: "1",

      dataSource: {
        /// check last sending_cost
        name: "sent-orders-sending-costs",
        active: 1,
        params: {
          orderBy: "id",
          orderDesc: "DESC",
        },
        query: (value, all, formValues) => ({
          sending_cost: {
            value: value,
          },
          translator_id: {
            value: formValues.translator_id,
            exact: true
          },
        }),
        setters: (row) => {
          return [["sending_cost", row ? row.sending_cost : ""]];
        },
        display: (v) => (
          <div style={{ padding: "8px" }}>
            <div>{prettyNumber(v.sending_cost)} zł</div>
            <div style={{ fontSize: "10px" }}>{prettyDate(v.time_added)}</div>
            <div style={{ fontSize: "10px" }}>{v.order_customer}</div>
          </div>
        ),
        text: (v) => Number(v.sending_cost),
      },
    },
    rounding_method: {
      label: "zaokrąglenie",
      element: "select",
      default: 0,
      options: [
        { value: 0, text: "nie" },
        { value: 1, text: "do góry do całej strony" },
        { value: 3, text: "do góry co pół strony" },
        { value: 2, text: "do dołu do całej strony" },
        { value: 4, text: "do dołu co pół strony" },
      ],
    },
    amount: {
      label: "suma netto",
      wrapStyle: {
        display: "grid",
        boxShadow: "2px 2px 5px rgba(0,0,0,.2)",
        width: "130px",
        borderRadius: "10px",
        gridTemplateColumns: "85px 40px",
        alignItems: "center",
        padding: "6px 13px",
      },
      //inputStyle: {  },
      appendix: <div style={{ fontSize: "13px", marginLeft: "5px" }}>zł</div>,
      disabled: true,
      affect: "amount_gross",
      affectAction: (v) => Math.round(v.amount * (1 + Number(v.tax)/100) * 100) / 100,
    },
    amount_gross: {
      label: "brutto",
      wrapStyle: {
        display: "grid",
        width: "130px",
        borderRadius: "10px",
        gridTemplateColumns: "85px 40px",
        alignItems: "center",
        padding: "6px 13px",
      },
      appendix: <div style={{ fontSize: "13px", marginLeft: "5px" }}>zł</div>,
      disabled: true,
    },

    //active: { label: "" },
    accept_date: {
      type: "date",
      label: "data przyjęcia przez tłumacza",
      default: new Date().toISOString().slice(0, 10),
    },
    finished_date: {
      type: "date",
      label: "data wykonania tłumaczenia",
    },
    settlement_document_type: {
      label: "Rodzaj rozliczenia",
      element: "select",
      default: 0,
      options: [
        { value: 0, text: "-- brak --" },
        { value: 1, text: "Faktura" },
        { value: 2, text: "Rachunek" },
        { value: 3, text: "Umowa o dzieło" },
        { value: 4, text: "Umowa zlecenie" },
        { value: 5, text: "Gotówka" },
        { value: 6, text: "Inne" },
      ],
    },
    settlement_document_number: {
     /* ...document_number,
      dataSource: {
        ...document_number.dataSource,
        setters: (row) => [["settlement_document_number", row.source_doc_name]],
      },*/
      label: "Nr dokumentu rozl.",
    },
    //accept: { label: "zatwierdzono" },
  },
  sections: [
    {
      title: "Informacje rozliczeniowe",
      fields: [
        "translator_name",
        {
          style: { gridTemplateColumns: "2fr 1fr" },
          fields: ["rate_all", "is_gross"],
        },
       // ["rate", "rate_gross"],
        {
          style: { gridTemplateColumns: "3fr 2fr", alignItems: "flex-end" },
          fields: ["unitName"],
        },

        {
          style: { gridTemplateColumns: "1fr 1fr", alignItems: "flex-end" },
          fields: ["rounding_method", "rate_distinctor"],
        },
        {
          style: {
            gridTemplateColumns: "1fr 1fr",
            alignItems: "flex-end",
            marginTop: "21px",
            borderRadius: "8px",
            padding: "3px 10px",
            background: "rgb(241 241 241)",
            boxShadow: "0 0 0px 0.4px rgba(0,0,0,.35)",
          },
          fields: ["quantity", "pages"],
        },
        {
          style: {
            display:"grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap:"9px",
            alignItems: "flex-start",
            paddingTop: "20px",
          },
          fields: ["amount", "tax", "sending_cost"],
        },
        "amount_gross"
        /*{
          style: {
            gridTemplateColumns:"2fr 1fr"
          },
          fields: ["amount_gross", "tax"]
      },*/
      ],
    },
    {
      title: "Dane zlecenia",
      fields: [
        "order_number",
        {
          style: { gridTemplateColumns: "1fr 1fr" },
          fields: ["language_from", "language_to"],
        },
        "order_customer",
        "description",
        ["accept_date", "finished_date"],
        {
          heading: "Rozlicz od razu",
          style: {
            background: "rgb(233,233,233)",
            borderRadius: "10px",
            padding: "1px 7px",
          },
          fields: ["settlement_document_type", "settlement_document_number"],
        },
      ],
    },
  ],
};
