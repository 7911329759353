import DataToExcel from "../../DataToExcel";
import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import languageDisplay from "../displays/languageDisplay";
import customMenuItems from "./customMenuItems";
import xlsIcon from "../../../../images/icons/xls.svg";
import { Link } from "react-router-dom";

const dictionary = {
  year: {
    name: "Rok",
    style: {
        width:"70px"
    },
    order: "year"
  },
  language_main: {
    name: "Język",
    style: {
        textAlign: "center",
        width:"90px"
    },
    transform: (v) => <div style={{width:"50px"}}>{languageDisplay({name: v}, v)}</div>
  },
  orders: {
    name: "Liczba zleceń",
    transform: (v) => prettyNumber(v),
    style: {
        textAlign: "center",
        width:"120px"
    },
    order: "orders"
  },
  sum_pages: {
    name: "Liczba stron",
    style: {
        textAlign: "center",
        width:"120px"
    },
    order: "sum_pages",
    transform: (v) => prettyNumber(Math.round(v))
  },
  sum_netto: {
    name: "Suma netto",
    style: {
        textAlign: "center",
        width:"120px"
    },
    order: "sum_netto, year",
    transform: (v) => prettyNumber(Math.round(v)) + " zł"
  },
  sum_brutto: {
    name: "Suma brutto",
    style: {
        textAlign: "center",
        
    },
    order: "sum_brutto, year",
    transform: (v) => prettyNumber(Math.round(v)) + " zł"
  },
  
  
};

export default function OrdersSums() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Statystki zleceń"
        classNames="big"
        defLimit={50}
        data="order-stats"
        orderBy="sum_brutto, year"
        orderDesc="DESC, DESC"
        searchPattern={{
            year: {
                label: "rok",
                value: ""
            },
            language_main: {
                label: "Język",
                value: ""
            }
        }}
        dictionary={dictionary}
      />
    </div>
  );
}
