import { compareDay, isToday } from "../../functions";

export default function prettyDate(
  dateString,
  todayString = "dziś, ",
  dateOnly
) {
  const date = new Date(dateString);
  const now = new Date();
  const thisYear = now.getFullYear();
  let tomorrowString = "jutro, "
  if (dateOnly) {
    todayString = "dziś";
    tomorrowString = "jutro"
  }

  return isToday(date)
    ? todayString +
        (!dateOnly
          ? date.toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "numeric",
            })
          : "")
    : compareDay(date,1) ? tomorrowString +
    (!dateOnly
      ? date.toLocaleTimeString("pl-PL", {
          hour: "numeric",
          minute: "numeric",
        })
      : "") :
    date.toLocaleString("pl-PL", {
        day: "numeric",
        month: !dateOnly ? "short" : "long",
        hour: !dateOnly ? "numeric" : undefined,
        minute: !dateOnly ? "numeric" : undefined,
        year: date.getFullYear() !== thisYear ? "numeric" : undefined,
      });
}
