export const propMap = {"id": "",
"id_customers": "**klient nadrzędny",
"countries_name": "kraj klienta",
"countries_flag": "**flaga kraju klienta",
"symbol": "symbol",
"name": "nazwa",
"email": "e-mail",
"phone": "telefon",
"nip": "NIP",
"pesel": "*PESEL",
"street": "ulica",
"town": "miasto",
"post_code": "kod pocztowy",
"notice": "uwagi",
"notice_for_next_orders": "*Uwagi do nastepnego zlecenia",
"notice_for_all_orders": "*Uwagi do wszystkich zleceń",
"office_info": "informacje o biurze",
"private_firm": "**rodzaj",
"private_firm_name": "typ klienta",
"factures_bill": "**dokument rozliczeniowy",
"account_check": "**sprawdz konto",
"rate": "stawka ogólna standardowa",
"discount": "rabat ogólny standardowy",
"car_rate": "stawka ogólna samochodowa",
"car_discount": "rabat ogólny samochodowy",
"spoken_rate": "stawka ogólna ustna",
"spoken_discount": "rabat ogólny ustny",
"only_car": "**tylko samochodowe",
"active": "**aktywny",
"payer_name": "nazwa płatnika",
"payer_nip": "nip płatnika",
"payer_street": "ulica",
"payer_nr_place": "numer lokalu płatnika",
"payer_nr_home": "numer domu płatnika",
"payer_town": "miasto",
"payer_post_code": "kod pocztowy płatnika",
"correspond_email": "email do korespondecji",
"correspond_name": "nazwa",
"correspond_street": "ulica do korespondencji",
"correspond_nr_place": "nr lokalu do korespondencji",
"correspond_nr_home": "nr domu do korespondencji",
"correspond_post_code": "kod pocztowy korespondecji",
"correspond_town": "miasto",
"date_added": "**data dodania",
"rec_added_customer_name": "**nazwa uztkownika który dodał dany rekord",
"rec_change_customer_name": "**nazwa uzytkownika ktory ostatnio dokonał zmiany",
"date_change": "**data ostatniej zmiany",
"change_customer_id": "**id uzytkownika dokonujacego edycji",
"add_customer_id": "**id uzytkownika dodającego rekord",
"id_payment_methods": "sposób płatności",
"payment_num_days": "ilość dni formy płatności",
"payment_document_each_order": "dokument sprzedazy do każdego zamówienia",
"payment_document_summary_order": "zbiorczy dokument sprzedaży",
"id_acceptance_methods": "sposób odbioru",
"receive_methods": "sposób przyjęcia",
"facture_receipt": "dokument płatności",
"facture_type": "tyo wystawianej faktury klientowi",
"is_english": "czy faktura/paragon ma wydruk po angielsku?**",
"nr_place": "nr_lokalu",
"nr_home": "nr_domu",
"number_contract": "*Numer umowy z klientem",
"no_payed_facture_allow": "*Czy mozna wystawić nowe zgłoszenie bez akcpetacji nieopłaconych faktur",
"is_credit_limit": "*Czy jest limit na kredyt kupiecki",
"credit_limit": "*limit kredytu",
"balance": "*stan konta klienta",
"balance_brutto": "*stan konta brutto",
"send_reminder": "*czy wysylać ponaglenia do klienta?",
"day_to_send_reminder": "*Dzień miesiąca do wysłania ponaglenia",
"send_email_status_change_order": "wysyłaj automatyczne powiadomienia wystawienia zleceń i zmiany ich statusu",
"id": "",
"id_customers": "**klient nadrzędny",
"countries_name": "kraj klienta",
"countries_flag": "**flaga kraju klienta",
"symbol": "symbol",
"name": "nazwa",
"email": "e-mail",
"phone": "telefon",
"nip": "NIP",
"pesel": "*PESEL",
"street": "ulica",
"town": "miasto",
"post_code": "kod pocztowy",
"notice": "uwagi",
"notice_for_next_orders": "*Uwagi do nastepnego zlecenia",
"notice_for_all_orders": "*Uwagi do wszystkich zleceń",
"office_info": "informacje o biurze",
"private_firm": "**rodzaj",
"private_firm_name": "typ klienta",
"factures_bill": "**dokument rozliczeniowy",
"account_check": "**sprawdz konto",
"rate": "stawka ogólna standardowa",
"discount": "rabat ogólny standardowy",
"car_rate": "stawka ogólna samochodowa",
"car_discount": "rabat ogólny samochodowy",
"spoken_rate": "stawka ogólna ustna",
"spoken_discount": "rabat ogólny ustny",
"only_car": "**tylko samochodowe",
"active": "**aktywny",
"payer_name": "nazwa płatnika",
"payer_nip": "nip płatnika",
"payer_street": "ulica",
"payer_nr_place": "numer lokalu płatnika",
"payer_nr_home": "numer domu płatnika",
"payer_town": "miasto",
"payer_post_code": "kod pocztowy płatnika",
"correspond_email": "email do korespondecji",
"correspond_name": "nazwa",
"correspond_street": "ulica do korespondencji",
"correspond_nr_place": "nr lokalu do korespondencji",
"correspond_nr_home": "nr domu do korespondencji",
"correspond_post_code": "kod pocztowy korespondecji",
"correspond_town": "miasto",
"date_added": "**data dodania",
"rec_added_customer_name": "**nazwa uztkownika który dodał dany rekord",
"rec_change_customer_name": "**nazwa uzytkownika ktory ostatnio dokonał zmiany",
"date_change": "**data ostatniej zmiany",
"change_customer_id": "**id uzytkownika dokonujacego edycji",
"add_customer_id": "**id uzytkownika dodającego rekord",
"id_payment_methods": "sposób płatności",
"payment_num_days": "ilość dni formy płatności",
"payment_document_each_order": "dokument sprzedazy do każdego zamówienia",
"payment_document_summary_order": "zbiorczy dokument sprzedaży",
"id_acceptance_methods": "sposób odbioru",
"receive_methods": "sposób przyjęcia",
"facture_receipt": "dokument płatności",
"facture_type": "tyo wystawianej faktury klientowi",
"is_english": "czy faktura/paragon ma wydruk po angielsku?**",
"nr_place": "nr_lokalu",
"nr_home": "nr_domu",
"number_contract": "*Numer umowy z klientem",
"no_payed_facture_allow": "*Czy mozna wystawić nowe zgłoszenie bez akcpetacji nieopłaconych faktur",
"is_credit_limit": "*Czy jest limit na kredyt kupiecki",
"credit_limit": "*limit kredytu",
"balance": "*stan konta klienta",
"balance_brutto": "*stan konta brutto",
"send_reminder": "*czy wysylać ponaglenia do klienta?",
"day_to_send_reminder": "*Dzień miesiąca do wysłania ponaglenia",
"send_email_status_change_order": "wysyłaj automatyczne powiadomienia wystawienia zleceń i zmiany ich statusu",
};