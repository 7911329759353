import { useContext } from "react";
import AppContext from "../../contexts/app-context";
import OrderView from "../instances/newExternals/OrderView";

export default function NumberOrder({
  id,
  number_order,
  withLang,
  lang,
  content,
  search,
  clickable = true,
  modalID = 1
}) {
  const modal = useContext(AppContext);
  const arr = number_order ? number_order?.replace(/\//g, ".")?.split(".") : [""];
  const modalProps = {
    show: true,
    type: "Zlecenie",
    width: "superwide",
    content: <OrderView inModal={true} id={id} search={search} />,
  };
  
  return (
    <div
      onClick={() => {
        if (clickable) {
          if (modalID === 2) {
            modal.setModal2(modalProps);
          }
          else {
            modal.setModal(modalProps);
          }
        }
      }}
      className="clickable-details"
      style={{ cursor: "pointer" }}
      title={id}
    >
      {content ? (
        content
      ) : arr ? (
        <div>
          <span>{arr[0]}</span>.<span>{arr[1]}</span>.<span>{arr[2]}</span>
          {withLang ? (
            <>
              .
              <span style={{ color: "grey" }}>
                {lang ? lang.toLowerCase() : arr[3]}
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  /*return (
      <Link to={`../order-view?id=${data.id}`}>
        <div title={data.id}>{data.number_order.replaceAll("/", ".")}</div>
      </Link>
    );*/
}
