import { durationToString } from "../TimesheetRev/Row";
import { minutesToTimeString } from "../WorkTimeView";
import { useState, Fragment } from "react";

export default function PersonReport({ report, setViewUser }) {
  const [details, setDetails] = useState(false);
  const employee =
    report && report[0]
      ? report[0].first_name + " " + report[0].last_name
      : undefined;
  const workStart =
    report && report[0] && report[0].work_start
      ? new Date(report[0].work_start).toLocaleTimeString("pl-PL", {
          hour: "numeric",
          minute: "numeric",
        })
      : "";
  const workEnd =
    report && report?.[0]?.work_end
      ? new Date(report[0].work_end).toLocaleTimeString("pl-PL", {
          hour: "numeric",
          minute: "numeric",
        })
      : "";
  const workTime =
    report && report[0] ? durationToString(report[0].work_time) : "";

  report = report ? report.filter((item) => item.action_type !== 0) : undefined;

  const reportEN = report
    ? report.filter((item) => item.language === "Ang")
    : [];
  const reportDE = report
    ? report.filter((item) => item.language === "Niem")
    : [];
  const reportOther = report ? report.filter((item) => !item.language) : [];

  const reportsArray = [
    {
      language: "angielski",
      data: reportEN,
    },
    {
      language: "niemiecki",
      data: reportDE,
    },
    {
      language: "inne",
      data: reportOther,
    },
  ].map((item) => ({
    ...item,
    sums: {
      count: item.data.length
        ? item.data
            .filter((item) => item.unit === "zzs")
            .map((item) => item.sum_count)
            .reduce(
              (a, b) =>
                (parseFloat(a) ? parseFloat(a) : 0) +
                (parseFloat(b) ? parseFloat(b) : 0),
              0
            )
        : null,
      countTranslation: item.data.length
        ? item.data
            .filter((item) => item.unit === "zzs" && item.action_type === 1)
            .map((item) => item.sum_count)
            .reduce(
              (a, b) =>
                (parseFloat(a) ? parseFloat(a) : 0) +
                (parseFloat(b) ? parseFloat(b) : 0),
              0
            )
        : null,
      minutes: item.data.length
        ? item.data
            .map((item) => item.sum_minutes)
            .reduce(
              (a, b) =>
                (parseFloat(a) ? parseFloat(a) : 0) +
                (parseFloat(b) ? parseFloat(b) : 0),
              0
            )
        : null,
      average: item.data.length
        ? Math.floor(
            item.data
              .filter(
                (item) =>
                  item.unit === "zzs" &&
                  item.action_type === 1 &&
                  ![2, 5, 6, 8].includes(item.text_type)
              )
              .map((item) => item.average)
              .reduce(
                (a, b) =>
                  (parseFloat(a) ? parseFloat(a) : 0) +
                  (parseFloat(b) ? parseFloat(b) : 0),
                0
              ) /
              item.data.filter(
                (item) =>
                  item.unit === "zzs" &&
                  item.action_type === 1 &&
                  ![2, 5, 6, 8].includes(item.text_type) &&
                  parseInt(item.sum_count) > 0
              ).length
          )
        : null,
    },
  }));

  return (
    <div className="personReport">
      <table className="reportTable">
        <thead className="topHead">
          <tr>
            <th
              className="employeeName"
              colSpan={2}
              onClick={() => setDetails((prev) => !prev)}
            >
              {employee}
            </th>
            <td style={{ border: "none", padding: "0px" }} colSpan={3}>
              <button
                className="regularButton"
                onClick={() =>
                  setViewUser({
                    employeeID: report[0].employeeID,
                    fullname: report[0].cardname,
                  })
                }
              >
                timesheet
              </button>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="mainHead">
            <th width="100">od</th>
            <th width="100">do</th>
            <th colSpan={3}>czas pracy</th>
          </tr>

          <tr>
            <td className="reportData">{workStart}</td>
            <td className="reportData">{workEnd}</td>
            <td className="reportData" colSpan={3}>
              {workTime}
            </td>
          </tr>
        </tbody>

        <tbody
          className={"reportTable details" + (details ? " showDetails" : "")}
        >
          <tr className="mainHead">
            <th style={{ width: "120px" }}>czynność</th>
            <th style={{ width: "120px" }}>rodzaj</th>
            <th style={{ width: "120px" }}>czas</th>
            <th>suma tłumaczeń</th>
            <th>średnia ~</th>
          </tr>
          {reportsArray.map((report) => (
            <Fragment key={report.language}>
              <tr className="mainRow">
                {report.data.length ? (
                  <>
                    <th
                      colSpan={2}
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {report.language}
                    </th>
                    <th>{minutesToTimeString(report.sums.minutes)}</th>
                    <th className="reportData">
                      {report.sums.countTranslation && report.sums.countTranslation !== "0.0" ? (
                        <>
                          {report.sums.countTranslation} <span className="unit">zzs</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </th>
                    <th title="tłumaczenie - wszystkie standardowe">
                      {report.sums.average ? (
                        <>
                          {report.sums.average}{" "}
                          <span className="unit">zzs/h</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </th>
                  </>
                ) : (
                  <></>
                )}
              </tr>
              {report &&
                report.data.map((item, index, arr) => (
                  <tr>
                    {arr[index - 1] &&
                    arr[index - 1].action_type === item.action_type ? (
                      <></>
                    ) : (
                      <th
                        rowSpan={
                          arr.filter((a) => a.action_type === item.action_type)
                            .length
                        }
                        className="actionType"
                      >
                        {item.action_type_name}
                      </th>
                    )}
                    <th className="textType">{item.text_type_name}</th>
                    <td>{minutesToTimeString(item.sum_minutes)}</td>
                    <td className="reportData">
                      {parseInt(item.sum_count) ? (
                        <>
                          {item.unit === "zzs"
                            ? parseInt(item.sum_count)
                            : item.sum_count}{" "}
                          <span className="unit">{item.unit}</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="reportData">
                      {parseInt(item.sum_count) ? (
                        <>
                          {item.average}{" "}
                          <span className="unit">{item.unit}/h</span>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
