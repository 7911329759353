import React from "react";
import Spreadsheet from "../Spreadsheet";
import { useSwornHistory } from "./useSworn";
import swornRegistryTable from "./data";

function SwornHistory({ number, year, orders }) {
  const data = useSwornHistory(number, year);
  const dataSchema = swornRegistryTable(orders);

  return (
    <Spreadsheet
      mode="history"
      dataStructure={dataSchema}
      data={data}
      options={{
        numberColumn: false,
        deleteRows: false,
        acceptRows: false,
        driveLink: true,
        hideOptions: true,
      }}
    />
  );
  /*
  return (
    <table className="historyTable">
      <thead>
        <tr>
          <th>Lp.</th>
          <th>Data</th>
          <th>Nr zlec.</th>
          <th>Klient</th>
          <th>Nazwa,data i oznaczenie dokumentu</th>
          <th>Język źródłowy</th>
          <th>Uwagi</th>
          <th>Czynność</th>
          <th>Liczba stron</th>
        </tr>
      </thead>
      <tbody>
        {data.map((i) => (
          <tr>
            <td>{i.number}</td>
            <td>{i.date}</td>
            <td>{i.order_number}</td>
            <td>{i.client}</td>
            <td>
              {i.document_name}
              <br />
              {i.document_date}
              <br />
              {i.document_reference}
            </td>
            <td>{i.source_language}</td>
            <td>{i.drawn_up_by}</td>
            <td>
              {i.form}
              <br />
              {i.document_condition}
            </td>
            <td>{i.action_type}</td>
            <td>{i.pages}</td>
            <td>{i.copies}</td>
          </tr>
        ))}
       
      </tbody>
    </table>
  );*/
}

export default SwornHistory;
