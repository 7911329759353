import React from "react";

function ListingTableRow({
  item,
  index,
  numberJSX,
  checkToday,
  checked,
  setChecked,
  displayPrevious,
  nextDocInOrderCellStyle,
}) {
  const display = checked[item.id] ? true : item.previous ? displayPrevious : true;
  

  return (
    <>
      { display ? (
        <tr
          className={checkToday ? "today" : null}
          style={{ opacity: item.checked ? "1" : "0.4" }}
          key={item.id}
        >
          <td>
            <input
              type="checkbox"
              checked={checked[item.id]}
              onChange={() =>
                setChecked((prev) => ({
                  ...prev,
                  [item.id]: prev[item.id] ? undefined : true,
                }))
              }
            />
          </td>
          <td>{index + 1}</td>
          <td style={nextDocInOrderCellStyle}>
            {new Date(item.create_date).toLocaleDateString("pl-PL", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </td>
          <td style={nextDocInOrderCellStyle}>{numberJSX}</td>
          <td>{item.name ? item.name : item.document_name}</td>
          <td style={{ textAlign: "center" }}>{item.remarks_top_secret}</td>
          <td style={{ textAlign: "center", width: "72px" }}>
            {
              /*item.pages_to_translate_count.replace(".", ",")*/
              String(
                Math.round(
                  (parseFloat(item.price) / parseFloat(item.rate)) * 10
                ) / 10
              ).replace(".", ",")
            }
          </td>
          <td style={{ textAlign: "center" }}>{item.rate}</td>
          <td style={{ textAlign: "center" }}>
            {String(item.price).replace(".", ",")}
          </td>
          <td style={{ textAlign: "center" }}>
            {String(item.price_brutto).replace(".", ",")}
          </td>
          <td className="no-print" style={{ textAlign: "center" }}>
            {checkToday ? (
              ">" + new Date(item.finish_date).toLocaleTimeString().slice(0, -3)
            ) : (
              <></>
            )}
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
}

export default ListingTableRow;
