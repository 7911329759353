import TableUniversal from "../../TableUniversal";
import NumberInvoice from "../../common/NumberInvoice";
import NumberOrder from "../../common/NumberOrder";
import prettyDate from "../../functions/prettyDate";
import menuCustomers from "../newExternals/menuCustomers";
import { propMap } from "./Dictionaries/InvoicesPropMap";

const dictionary = {
  id: {
    name: "ID",
    style: {
      width: "30px",
    },
  },
  number_facture: {
    name: "Nr",
    style: {
      width: "30px",
    },
    transform: (v, a) => <NumberInvoice number={v} id={a.id} />,
  },
  year: {
    name: "Rok",
    order: "year, month, id",
    style: {
      width: "30px",
      display: "none",
    },
  },
  month: {
    name: "Miesiąc",
    order: "month",
    transform: (v) =>
      new Date(2000, v - 1, 1).toLocaleDateString("pl-PL", { month: "long" }),
    style: {
      width: "40px",
      display: "none",
    },
  },
  create_date: {
    name: "Data utw.",
    order: "create_date",
    style: {
      width: "80px",
    },
    transform: (v, a) => prettyDate(v),
  },
  customers_name: {
    name: "Klient",
    order: "customers_name",
    style: {
      width: "250px",
      padding: 0,

    },
    transform: (v) => (
      <div
        style={{
          padding: "4px 7px 4px 7px",
          background: "rgb(220, 247, 230)",
          width: "max-content",
          maxWidth: "250px",
          //margin: "2px 5px",
          //boxShadow: "-2px 3px 2px  -3px rgba(0,0,0,.3)",
          borderRadius: "2px",
          fontSize: "93%",
          margin:"3px"
        }}
      >
        {v}
      </div>
    ),
  },
  orders_number: {
    name: "Do zlec.",
    transform: (v,a) => (
      <div style={{ display: "flex", fontSize: "10px", flexWrap: "wrap" }}>
        {v &&
          v.split(";").map((i,ind) => (
            <NumberOrder
              id={a?.id_orders?.split(";")?.[ind]}
              number={i}
              content={
                <div
                  style={{
                    margin: "2px",
                    padding: "2px 4px",
                    background: "#f4f4f4",
                    boxShadow: "1px 1px 3px rgba(0,0,0,.14)",
                    opacity: ".85",
                    borderRadius: "5px",
                  }}
                >
                  {i}
                </div>
              }
           
            />
          ))}
      </div>
    ),
    style: {
      width: "200px",
    },
  },
  price_brutto: {
    name: "Kwota brutto",
    order: "price_brutto",
    style: {
      width: "100px",
    },
    transform: (v) => (v ? <>{String(v).replace(".", ",")} zł</> : <></>),
  },
  payment_methods_payed: {
    name: "Zapł.",
    order: "payment_methods_payed",
    style: {
      width: "50px",
    },
    transform: (v) =>
      v === 1 ? (
        <div style={{ fontWeight: "bold", color: "green" }}>✓</div>
      ) : (
        <div style={{ color: "lightgrey" }}>✖</div>
      ),
  },
};

export default function InvoicesTable() {
  return (
    <div className="fullScreen">
      <div className="top-bar"></div>
      <div
        className="tableWrapper"
        style={{ maxHeight: "90vh", overflowY: "auto", maxWidth: "100vw" }}
      >
        {/*<Translators/>*/}
        <TableUniversal
          data="invoices-table"
          name="Faktury"
          classNames="big"
          customMenu={menuCustomers}
          defLimit={15}
          propMap={propMap}
          searchPattern={{
            orders_number: "",
            customers_name: "",
            create_date: {
              value: "",
              element: "input",
              type: "date",
            },
            year: {
              value: "",
              element: "input",
              type: "number",
            },
            payment_methods_payed: {
              value: "",
              element: "select",
              options: {
                "-": "",
                tak: 1,
                nie: 0,
              },
            },
          }}
          /*groupBy={[
            "create_date",
            (d) =>
              new Date(d).toLocaleDateString("pl-PL", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              }),
            (c) => new Date(c).toDateString(),
          ]}*/
          groupBy={[
            "month",
            (v, p) => (
              <div style={{ fontWeight: "700", textTransform: "uppercase" }}>
                {new Date(p.year, v - 1, 1).toLocaleDateString("pl-PL", {
                  month: "long",
                  year: "numeric",
                })}
              </div>
            ),
          ]}
          orderBy="id"
          orderDesc="DESC"
          options={{
            selectable: true,
          }}
          dictionary={dictionary}
        />
      </div>
    </div>
  );
}
