import React, { useState } from "react";
import { languagesList } from "../global";
import flags from "../flags";
import useOrderMulti from "../useOrderMulti";
import EditOrder from "../customerService/EditOrder";

function CustomerService(props) {
  const [search, setSearch] = useState({
    language_main: "",
    customers_nip: "",
    customers_name: "",
    customers_symbol: "",
    number_order: "",
    status: "",
  });
  const [refreshID, setRefreshID] = useState(0);
  const [editMode, setEditMode] = useState({ active: false, order: null });

  const orders = useOrderMulti("list", search, 50, refreshID);
  const now = new Date();
  orders.sort(
    (a, b) => new Date(b.reception_date) - new Date(a.reception_date)
  );

  const menus = {
    orders: [
      {
        text: "Nowe zlecenie",
        action: () => {
         // setEditMode((prev) => ({ active: true, order: null }));
        },
      },
      {
        text: "Zlecenia pisemne",
      },
      {
        text: "Zlecenia ustne",
      },
      {
        text: "Zlecenia reklamacyjne",
      },
      /*
      {
        text: "Do akceptacji",
      },
      {
        text: "Archiwalne",
      },
      {
        text: "Anulowane",
      },
      {
        text: "Wstrzymane",
      },
      {
        text: "Do weryfikacji",
      },*/
      {
        text: "Raport listy zleceń",
      },
    ],
  };
  return (
    <div className="customer-service">
      <div className="top-bar">
        <nav>
          <ul>
            <li>
              <div>Zlecenia</div>
              <ul className="cs-sub-menu">
                {menus.orders.map((i) => (
                  <li>{i.text}</li>
                ))}
              </ul>
            </li>
            <li>
              <div>Faktury</div>
              <ul className="cs-sub-menu">
                <li>Opłacone</li>
                <li>Nieopłacone</li>
                <li>Przeterminowane</li>
                <li>Anulowane</li>
                <li>Do paragonów</li>
                <li>Pro forma</li>
                <li>Zaliczkowe</li>
                <li>Korekty</li>
                <li>JPK</li>
              </ul>
            </li>
            <li>
              <div>Kartoteki</div>
              <ul className="cs-sub-menu">
                <li>Nowy kontrahent</li>
                <li>Kontrahenci</li>
                <li>Finanse</li>
                <li>Tłumacze</li>
                <li>Kategorie reklamacji</li>
                <li>Biura regionalne</li>
                <li>Języki</li>
                <li>Grupy językowe</li>
                <li>Dokumenty</li>
                <li>Sprzęt do tłumaczeń ustnych</li>
              </ul>
            </li>
            <li>
              <div>Ustawienia</div>
              <ul className="cs-sub-menu">
                <li>Ustawienia firmy</li>
                <li>Sposoby odbioru</li>
                <li>Koszty wysyłki</li>
                <li>Jednostki miary</li>
                <li>Sposoby płatności</li>
                <li>Opcje rabatu</li>
                <li>Stawki VAT</li>
                <li>Użytkownicy</li>
                <li>Użytkownicy nieaktywni</li>
                <li>Grupy</li>
              </ul>
            </li>
            <li>
              <div>Zadania</div>
              <ul className="cs-sub-menu">
                <li>Lista zadań</li>
                <li>Folder</li>
                <li>Zadania szablonowe</li>
                <li>Kartoteka folderów</li>
              </ul>
            </li>
            <li>
              <div>Raporty</div>
              <ul className="cs-sub-menu">
                <li>Raport zleceń</li>
                <li>Raport faktur</li>
                <li>Raporty automatyczne</li>
                <li>Raporty zdarzeń systemu</li>
              </ul>
            </li>
          </ul>
        </nav>
        <form className="selectMy">
          <div>
            <label htmlFor="nip">NIP</label>
            <input
              type="text"
              name="NIP"
              value={search.customers_nip}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  customers_nip: e.target.value,
                }))
              }
            ></input>
          </div>
          <div>
            <label htmlFor="customer-name">Nazwa kontrahenta</label>
            <input
              type="text"
              name="customer-name"
              value={search.customers_name}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  customers_name: e.target.value,
                }))
              }
            ></input>
          </div>
          <div>
            <label htmlFor="customer-symbol">Symbol kontrahenta</label>
            <input
              type="text"
              name="customer-symbol"
              value={search.customers_symbol}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  customers_symbol: e.target.value,
                }))
              }
            ></input>
          </div>
          <div>
            <label htmlFor="order-number">Nr zlecenia</label>
            <input
              type="text"
              name="order-number"
              value={search.number_order}
              onChange={(e) =>
                setSearch((prev) => ({
                  ...prev,
                  number_order: e.target.value,
                }))
              }
            ></input>
          </div>
          <div>
            <label htmlFor="order-type">Typ zlecenia</label>
            <select
              name="order-type"
              value={search.status}
              onChange={(e) =>
                setSearch((prev) => ({ ...prev, status: e.target.value }))
              }
            >
              <option value="">wszystkie</option>
              <option value="1">aktualne</option>
              <option value="0">anulowane</option>
              <option value="3">archiwalne</option>
              <option value="4">do archiwizacji</option>
              <option value="5">niezatwierdzone</option>
            </select>
          </div>
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setRefreshID((prev) => prev + 1);
              }}
            >
              Szukaj
            </button>
          </div>
        </form>
      </div>
      <div className="cs-interface">
        <div className="cs-sidebar">
          <div className="cs-sidebar-buttons">
            {menus.orders.map((i) => (
              <button onClick={i.action}>{i.text}</button>
            ))}
          </div>
        </div>
        <div className="cs-main">
          {editMode.active ? (
            <EditOrder editMode={editMode} setEditMode={setEditMode}/>
          ) : (
            <table className="cs-main-table">
              <thead>
                <tr>
                  <th colSpan="8">Liczba wpisów: {orders.length}</th>
                </tr>
                <tr>
                  <th>Lp.</th>
                  <th>Nr. zlecenia</th>
                  <th></th>
                  <th>Data przyjęcia</th>
                  <th>Data odbioru</th>
                  <th>Kontrahent</th>
                  <th>Dokumenty</th>
                  <th>Etap realizacji</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.length ? (
                  orders.map((item, index) => {
                    let statusString = [
                      "anulowane",
                      "niegotowe",
                      "gotowe",
                      "archiwalne",
                      "wstrzymane",
                      "niezatwierdzone",
                    ];

                    let names =
                      item.documents_names || item.documents_types
                        ? item.documents_names.split(",")
                        : [];
                    const types = item.documents_types
                      ? item.documents_types.split(",")
                      : [];
                    names = [
                      ...new Set(
                        names.map((i, index) => (i ? i : types[index]))
                      ),
                    ];

                    const diff = Math.round(
                      (new Date(item.finish_date) - now) / 60 / 60 / 1000
                    );
                    const diffString =
                      diff <= 0
                        ? "brak tłumaczenia"
                        : diff < 12
                        ? "za " + diff + " godz."
                        : "za " +
                          Math.round(diff / 24) +
                          (Math.round(diff / 24) === 1 ? " dzień" : " dni");

                    const diffClass =
                      item.status == "2"
                        ? "ready"
                        : item.status == "0"
                        ? "cancelled"
                        : diff <= 0
                        ? "past"
                        : diff < 2
                        ? "soon"
                        : "waiting";
                    return (
                      <tr className={diffClass}>
                        <td title={item.id}>{index + 1}</td>
                        <td
                          title={
                            item.status + " / " + statusString[item.status]
                          }
                          className="number_order"
                        >
                          {item.number_order.split("/")[0]}
                        </td>
                        <td className="language">
                          <div>
                            <img
                              src={
                                flags[
                                  languagesList.find(
                                    (i) => i.code == item.language_main
                                  )?.flagCode
                                ]
                              }
                            />
                            {item.language_main}
                          </div>
                        </td>
                        <td className="dateTime reception">
                          <div>
                            {new Date(item.reception_date).toLocaleDateString(
                              "pl-PL",
                              {
                                month: "short",
                                day: "2-digit",
                                year: "2-digit",
                              }
                            )}
                          </div>
                          <div>
                            {item.reception_date.split(" ")[1].slice(0, -3)}
                          </div>
                        </td>
                        <td className="dateTime finish">
                          <div>
                            {new Date(item.finish_date).toLocaleDateString(
                              "pl-PL",
                              {
                                month: "short",
                                day: "2-digit",
                                year: "2-digit",
                              }
                            )}
                          </div>
                          <div>
                            {item.finish_date.split(" ")[1].slice(0, -3)}
                          </div>
                        </td>
                        <td className="customers_name">
                          <div>
                            <div
                              onClick={() =>
                                setEditMode({ active: true, order: item })
                              }
                            >
                              {item.customers_name}
                            </div>
                            <div
                              className={
                                item.payment_methods_payed == "1"
                                  ? "sp paid"
                                  : "sp"
                              }
                              title={item.payment_methods_payed}
                            >
                              {item.payment_methods_name} (
                              {item.facture_receipt.slice(0, 1).toUpperCase()}):{" "}
                              <span>
                                {String(item.price_brutto).replace(".", ",")} zł
                              </span>{" "}
                              {item.payment_methods_payed == "1" ? (
                                <span>
                                  ({item.payment_methods_payed_user_initials}:{" "}
                                  {new Date(
                                    item.payment_methods_payed_date
                                  ).toLocaleTimeString("pl-PL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                  )
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="so">
                              {item.acceptance_methods_name}
                            </div>
                          </div>
                        </td>
                        <td className="documents">
                          <div className="tabDocumentsWrap">
                            <div className="tabDocumentsNumber">
                              {item.documents_count}
                            </div>
                            <div className="tabDocuments">
                              {names.map((i) => (
                                <span>{i}</span>
                              ))}
                            </div>
                            <div className="tabPages">
                              {parseFloat(item.pages_to_translate_count)} str.
                            </div>
                          </div>
                        </td>
                        <td className={diffClass}>
                          {item.status === "1"
                            ? diffString
                            : item.status == "2"
                            ? "gotowe"
                            : item.status == "0"
                            ? "anulowane"
                            : item.status == "3"
                            ? "archiwalne"
                            : "inne"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8" style={{ padding: "100px 20px" }}>
                      Wczytywanie
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerService;
