import { useContext, useState } from "react";
import { DocContext } from "./Document";

export default function Field({ value, form, options }) {
  const { data, setData, id, edit, setEdit } = useContext(DocContext);
  const text = options ? options.find(item => item.value === data[value])?.text : "";
  return (
    <td className="editableField">
      {edit ? (<>
       {form === "input" && <input
          value={data[value]}
          placeholder="-"
          onChange={(e) =>
            setData((prev) => ({ ...prev, [value]: e.target.value }))
          }
        />
       }
       {form === "select" && <select
          value={data[value] === "przysięgłe" ? 1 : data[value]}
          placeholder="-"
          onChange={(e) =>
            setData((prev) => ({ ...prev, [value]: e.target.value }))
          }
        >
            {options.map(option => <option value={option.value} >{option.text}</option>)}
        </select>
       }
       </>
      ) : (
        <div onClick={() => setEdit(id)}>
          {text && text ? text : data[value]}
        </div>
      )}
    </td>
  );
}
