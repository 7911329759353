import React from "react";
import { URL } from "./global";

const defaultTimesheetItem = {
  //'id'
  user: "",
  date: null,
  startTime: null,
  endTime: null,
  lastTime: null,
  type: 0,
  status: 0,
  orderID: 0,
  orderString: null,
  documentID: 0,
  characters: 0,
  template: 0,
  flags: 0,
  comments: "",
  queryType: 'add'
};

function addToTimesheet(data) {
  console.log(data);
  fetch(`${URL}components/db-timesheet.php`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ ...defaultTimesheetItem, ...data }),
  })
    .then((response) => response.text())
    .then((text) => {
      console.log(text);
    });
}

export default addToTimesheet;
