import { useState } from "react";
import useAttendance from "../useAttendance";
import Now from "./Now";

export default function Attendance() {
  const dataRaw = useAttendance({ query: { location: "biuro" }, offset: 0 });

  const [expand, setExpand] = useState(false);

  const data = !dataRaw.loading
    ? dataRaw.rows.filter(
        (p) =>
          (p.source === "attendance_3maja" ||
            p.source === "attendance_3maja_custom " ||
            p.source === "attendance_3maja_custom") &&
          (p.typeshorts.at(-1) === "0" || p.typeshorts.at(-1) === "2") &&
          new Date(p.max).getDate() === new Date().getDate()
      )
    : null;

  function ago(timestring) {
    const timearray = timestring.split(".")[0].timestring.split(":");
    const hours = timearray[0];
    const minutes = timearray[1];
    return (
      <>
        (hours ? <>{hours} h</> : <></>) (minutes ? <>{minutes} min</>)
      </>
    );
  }
  return data ? (
    <div
      className="attendance-bar"
      style={expand ? { height: "400px", display: "block" } : undefined}
    >
      {expand ? (
        <div>
          <button onClick={() => setExpand(false)}>▾▾▾</button>
          <Now partial={true} />
        </div>
      ) : (
        <>
          <div
            style={{ background: "whitesmoke", order: 0, cursor:"pointer" }}
            className="attendance-item"
            onClick={() => setExpand(true)}
          >
            W PRACY
          </div>
          {data.map((p) => (
            <div
              className={
                "attendance-item" +
                (p.typeshorts.at(-1) === "2" ? " remote" : "") +
                (new Date() - new Date(p.max) < 60 * 60 * 0.5 * 1000
                  ? " just"
                  : "")
              }
              style={{ order: p.typeshorts.at(-1) === "2" ? 1 : 0 }}
            >
              <div className="attLong" style={{ fontWeight: "bold" }}>
                {p.cardperson.split(" ")[0][0]}. {p.cardperson.split(" ")[1]}
              </div>
              <div className="attShort" style={{ fontWeight: "bold" }}>
                {p.cardperson.split(" ")[0][0]}
                {p.cardperson.split(" ")[1][0]}
              </div>
              <div style={{ marginLeft: "3px" }}>
                od{" "}
                {new Date(p.max).toLocaleTimeString("pl-PL", {
                  hour: "numeric",
                  minute: "2-digit",
                })}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  ) : (
    <></>
  );
}
