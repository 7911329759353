import { useEffect, useState } from "react";
//import { updateDevice } from "./useAttendance";
import { URL } from "./global";

async function getAttendance(params) {
    const response = await fetch(URL + "components/get_foreign/get-my-attendance.php", { method: "POST", credentials:"include", body: JSON.stringify(params) });
    const result = await response.json();
    return result;
}

export default function useAttendanceSingle(params) {
    const [attendance,setAttendance] = useState({ loading:true });

    useEffect(() => {
        //updateDevice();
        getAttendance(params).then((result) => {
            setAttendance({...result, loading: false})
        });
        
        const interval = setInterval(() => getAttendance(params).then((result) => {
           // updateDevice();
            setAttendance({...result, loading: false});
        }), 12000);
        return () => clearInterval(interval);
    }, [params.update])
    return attendance;
}