import React from "react";
import FullOrderItem from "./FullOrderItem";
import NumberOrder from "../tables/common/NumberOrder";

function FullScreenToggle({ setModal, item }) {
  return (
    <NumberOrder
      id={item.id}
      number_order={item.valid_order}
      content={
        <div className="fullScreenToggle" title="Zobacz pełne szczegóły">
          🢅
        </div>
      }
    />
  );
  /*return (
    <div
      className="fullScreenToggle"
      title="Zobacz pełne szczegóły"
      onClick={() =>
        setModal((prev) => ({
          ...prev,
          show: !prev.show,
          content: (
            <FullOrderItem
              data={item}
              //abridged={false}
            />
          ),
          type: "Szczegóły zlecenia",
          width: "wide",
        }))
      }
    >
      🢅
    </div>
  );*/
}

export default FullScreenToggle;
