import { Link, Route, Routes } from "react-router-dom";
import invoicesIcon from "../../images/icons/m/system_icon-05.svg";
import InvoicesTable from "./instances/examples/InvoicesTable";
import Translators from "./instances/newExternals/Translators";
import AttendanceTable from "./instances/examples/AttendanceTable";
import OrdersTable from "./instances/newExternals/OrdersTable";
import TimesheetTable from "./instances/examples/TimesheetTable";
import CustomersTable from "./instances/examples/CustomersTable";
import SwornRegistryTable from "./instances/examples/SwornRegistryTable";
import EmployeeTable from "./instances/examples/EmployeeTable";
import NewExternals from "./instances/newExternals/NewExternals";
import SentOrdersTable from "./instances/newExternals/SentOrdersTable";
import ExternalDashboard from "./instances/newExternals/ExternalDashboard";
import Settlements from "./instances/newExternals/Settlements";
import SentStats from "./instances/newExternals/SentStats";
import TradeDocuments from "./instances/examples/TradeDocuments";
import Lsi from "./instances/examples/Lsi";
import Rates from "./instances/newExternals/Rates";
import OrdersSums from "./instances/newExternals/OrdersSums";
import OrdersTranslatorsDay from "./instances/newExternals/OrdersTranslatorsDay";
import ExecutiveDailyReport from "./ExecutiveDailyReport";
import PurchaseInvoicesTable from "./instances/examples/PurchaseInvoicesTable";
import BigCustomersTable from "./instances/examples/BigCustomersTable";
import OrderView from "./instances/newExternals/OrderView";
import CustomerDetailsTable from "./instances/newExternals/CustomerDetailsTable";

export default function Tables() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <div style={{ display: "flex" }}>
            <div className="mainButtons smaller">
              <Link to="invoices">
                <button className="mainButton">Faktury sprzedażowe</button>
              </Link>
              <Link to="purchase-invoices">
                <button className="mainButton">Faktury zakupowe</button>
              </Link>
              <Link to="../../invoices">
                <button className="mainButton">
                  <img src={invoicesIcon} />
                  Faktury JPK
                </button>
              </Link>
              <Link to="trade-documents">
                <button className="mainButton">Paragony</button>
              </Link>
              <Link to="orders">
                <button className="mainButton">Zlecenia</button>
              </Link>
              <Link to="orders-stats">
                <button className="mainButton">Statystyki zleceń</button>
              </Link>
              <Link to="orders-translators-day">
                <button className="mainButton">Zlecenia - postępy dzienne</button>
              </Link>
              <Link to="attendance">
                <button className="mainButton">Obecność</button>
              </Link>
              <Link to="/./obsluga">
                <button className="mainButton">Zlecenia prototyp</button>
              </Link>
              <Link to="timesheet">
                <button className="mainButton">Timesheet</button>
              </Link>
              <Link to="customers">
                <button className="mainButton">Klienci</button>
              </Link>
              <Link to="major-customers">
                <button className="mainButton">Duzi klienci</button>
              </Link>
              <Link to="sworn-registry">
                <button className="mainButton">Repertorium</button>
              </Link>

              <Link to="lsi">
                <button className="mainButton">LSI test</button>
              </Link>
              <Link to="employees">
                <button className="mainButton">Pracownicy</button>
              </Link>
              <Link to="externals">
                <button className="mainButton">Tłumacze zewnętrzni</button>
              </Link>
              <Link to="settlements">
                <button className="mainButton">Rozliczenia</button>
              </Link>
              <Link to="sent">
                <button className="mainButton">Panel wysłanych</button>
              </Link>
              <Link to="sent-stats">
                <button className="mainButton">Statystyki</button>
              </Link>
              <Link to="rates">
                <button className="mainButton">Stawki</button>
              </Link>
              <Link to="/./now">
                <button className="mainButton">Teraz</button>
              </Link>
              <Link to="daily-report">
                <button className="mainButton">Raport zleceń</button>
              </Link>
              <Link to="order-view">
                <button className="mainButton">Podgląd zlecenia</button>
              </Link>
              <Link to="customer-details-table">
                <button className="mainButton">Szczegóły klientów</button>
                </Link>
            </div>
          </div>
        }
      />
      <Route path="/invoices/*" element={<InvoicesTable />} />
      <Route path="/purchase-invoices/*" element={<PurchaseInvoicesTable />} />
      <Route path="/orders/*" element={<OrdersTable />} />
      <Route path="/orders-stats/*" element={<OrdersSums />} />
      <Route path="/orders-translators-day/*" element={<OrdersTranslatorsDay />} />
      <Route path="/attendance/*" element={<AttendanceTable />} />
      <Route path="/timesheet/*" element={<TimesheetTable />} />
      <Route path="/customers/*" element={<CustomersTable />} />
      <Route path="/major-customers/*" element={<BigCustomersTable />} />
      <Route path="/sworn-registry/*" element={<SwornRegistryTable />} />
      <Route path="/employees/*" element={<EmployeeTable />} />
      <Route path="/externals/*" element={<NewExternals />} />
      <Route path="/trade-documents/*" element={<TradeDocuments />} />
      <Route path="/lsi/*" element={<Lsi />} />
      <Route path="/sent-stats/*" element={<SentStats />} />
      <Route path="/settlements/*" element={<Settlements />} />
      <Route path="/sent/*" element={<ExternalDashboard />} />
      <Route path="/rates/*" element={<Rates />} />
      <Route path="/daily-report/*" element={<ExecutiveDailyReport />} />
      <Route path="order-view" element={<OrderView/>}/>
      <Route path="customer-details-table/*" element={<CustomerDetailsTable/>}/>
    </Routes>
  );
}
