import React, { useCallback } from "react";
import exportToExcel from "./exportToExcel";
import xlsIcon from "../../images/icons/xls.svg";

function ListingExcelExport({ data, params }) {
  function xport() {
    //const table = document.querySelector(".listingTableXLS");
    //const wb = utils.table_to_book(table);
    const alignment = {
      wrapText: "true",
      horizontal: "center",
      vertical: "center",
    };
    const rows = data
      .filter((i) => i.checked)
      .map((i, index) => ({
        "Lp.": { f: "=ROW()-1", s: { alignment: alignment } },
        Data: { v: i.create_date, t: "d", s: { alignment: alignment } },
        "Nr zlec.": {
          v:
            String(i.number_order).replace(/\//g, ".") +
            "." +
            i.language_main.toLowerCase(),
          t: "s",
          s: { alignment: alignment },
        },
        Tytuł: {
          v: i.document_name ? i.document_name : i.name,
          t: "s",
          s: { alignment: {...alignment, horizontal: "left"}, font: { sz: 9, italic: "true " } },
        },
        Wyrazy: {
          v: i.remarks_top_secret,
          t: "n",
          s: { alignment: alignment },
        },
        "Wyrazy / 300": {
          //v: Math.round((i.remarks_top_secret / 300) * 100) / 100,
          f: `=ROUND(E${index + 2}/300, 2)`,
          t: "n",
          s: { alignment: alignment },
        },
        Strony: {
          v: Math.round((parseFloat(i.price) / parseFloat(i.rate)) * 10) / 10,
          //f: `=CEILING(E${index + 2}/300, 0.5)`,
          t: "n",
          s: { alignment: alignment },
        },
        "Cena netto": {
          //v: i.price,
          f: `=J${index + 2}*G${index + 2}`,
          t: "n",
          s: { alignment: alignment },
        },
        "Cena brutto": {
          //v: i.price_brutto,
          f: `=J${index + 2}*G${index + 2}*1.23`,
          t: "n",
          s: { alignment: alignment },
        },
        "Cena jedn. netto": { v: i.rate, t: "n" },
        //Język: i.language_main,
      }));
    rows.push({
      Strony: {
        t: "n",
        f: "=SUM(G2:G" + (rows.length + 1) + ")",
        s: {
          font: {
            bold: "true",
          },
          alignment: alignment,
        },
      },
      "Cena netto": {
        t: "n",
        f: "=SUM(H2:H" + (rows.length + 1) + ")",
        s: {
          font: {
            bold: "true",
          },
          alignment: alignment,
        },
      },
      "Cena brutto": {
        t: "n",
        f: "=SUM(I2:I" + (rows.length + 1) + ")",
        s: {
          font: {
            bold: "true",
          },
          alignment: alignment,
        },
      },
    });

    const monthDate = new Date(
      params.year,
      params.month - 1,
      1
    );
    const monthString = monthDate.toLocaleDateString("pl-PL", { month: "long" });
    const monthNumber = monthDate.toLocaleDateString("pl-PL", { month: "2-digit" });

    exportToExcel({
      fileName: `Zestawienie ${
        params.customer === "Polska Agencja Prasowa S.A."
          ? "PAP"
          : params.customer
      } - ${monthNumber} ${monthString} ${params.year}`,
      workBookName: `${monthNumber} ${monthString} ${params.year}`,
      rows: rows,
      colStyles: [
        { width: 4 },
        { width: 11 },
        { width: 13 },
        { width: 36 },
        { width: 6 },
        { width: 8 },
        { width: 6 },
        { width: 12 },
        { width: 12 },
      ],
      colFormatData: {
        columns: [7, 8, 9],
        string: "0.00 zł",
      },
    });
  }

  return (
    <div className="selectMy">
      <button
      className="standardButton editButton"
        style={{ width: "300px", borderRadius: "5px", fontSize: "17px" }}
        onClick={xport}
      >
        <img src={xlsIcon}/>
        <b>Pobierz plik Excel</b>
      </button>
    </div>
  );
}

export default ListingExcelExport;
