import React from "react";
import {
  filterOrdersByDate,
  filterOrdersByDateOffset,
  filterOrders,
  getWeekendOffsets,
} from "../functions";
import OrderStatistics from "../orders/OrderStatistics";
import OrdersView from "../orders/OrdersView";
import OrderContext from "../contexts/order-context";
import NewOrderCustom from "./NewOrderCustom";

function OrdersDay(props) {
  const now = new Date();
  let weekendOffsets = getWeekendOffsets();
  const { offset, filter } = props;


  let heading,
    filterParam,
    offsetParam,
    modifier = null;
  switch (offset) {
    case "-4":
      //invalid = false;
      //heading = "-";
      filterParam = -4;
      offsetParam = -4;
      break;
    case "-3":
      //invalid = false;
      // heading = "-";
      filterParam = -3;
      offsetParam = -3;
      break;
    case "-2":
      //invalid = false;
      //heading = "Przedwczoraj";
      filterParam = -2;
      offsetParam = -2;
      break;
    case "-1":
      //invalid = false;
      //heading = "Wczoraj";
      filterParam = -1;
      offsetParam = -1;
      break;
    case "0":
      //nie ma -dziś-, gdy jest sobota, niedziela, albo brak zleceń
      //heading = "Dziś";
      filterParam = weekendOffsets[0];
      offsetParam = 0;
      break;
    case "1":
      // invalid = false;
      // heading = now.getDay() > 4 || now.getDay() === 0 ? "Poniedziałek" : "Jutro";
      filterParam = 1 + weekendOffsets[1];
      offsetParam = 1;
      break;
    case "2":
      //invalid = false;
      /*heading = now.getDay() > 4 || now.getDay() === 0
          ? "Wtorek"
          : now.getDay() === 4
          ? "Poniedziałek"
          : "Pojutrze";*/
      filterParam = 2 + weekendOffsets[2];
      offsetParam = 2;
      break;
    case "3":
      //filterParam = 2 + weekendOffsets[2];
      offsetParam = 3;
      break;
    case "4":
      offsetParam = 4;
      break;
    case "other":
      //invalid = false;
      heading = "Kolejne dni";
      filterParam = 100;
      offsetParam = 5;
      modifier = "future";
      break;
    case "past":
      //invalid = false;
      heading = "Pozostałe";
      filterParam = -1;
      offsetParam = -1;
      modifier = "past";
      break;
  }


  return (
    <OrderContext.Consumer>
      {(context) => {
        const {
          globalFilter,
          orders: ordersSource,
          customOrders,
          orderParams,
          translators,
          details,
          setDetails,
          modal,
          view,
          setModal,
        } = context;
        const orders = { ...ordersSource, data: ordersSource.data };
        if (orderParams.type === "archive")
          orders.data = ordersSource.dataArchive;

        const dateFilter = filterOrdersByDateOffset(
          orders,
          offsetParam,
          modifier
        );
        const filteredOrders = filterOrders(
          //filterOrdersByDate(orders, filterParam),
          dateFilter.orders,
          globalFilter
        );
        console.log(filteredOrders);
        if (
          (offset === "0" &&
            (now.getDay() > 5 ||
              now.getDay() === 0 ||
              !filterOrdersByDate(orders, 0)?.length)) ||
          !filteredOrders?.length
        )
          return false;
        return (
          <div className="orderDay">
            {filteredOrders.map((item) => (
              <a className="hashLink" name={item.id}></a>
            ))}
            <h1>{heading || dateFilter.day}</h1>
            <div className="orderInfo">
              <OrderStatistics orders={filteredOrders} />
              <div>
                <button
                  style={{
                    background: "rgba(220,220,220)",
                    fontSize: "10px",
                    padding: "5px",
                    marginLeft: "10px",
                    borderRadius: "5px",
                  }}
                  onClick={() =>
                    setModal((prev) => ({
                      ...prev,
                      show: true,
                      content: <NewOrderCustom setModal={setModal}/>,
                      type: "Nowe zlecenie specjalne",
                    }))
                  }
                >
                  + NOWE
                </button>
              </div>
            </div>
            <div>
              {/*customOrders ? customOrders.map(item => <div>{item.customers_name}</div>) : undefined*/}
            </div>
            <OrdersView
              orders={filteredOrders}
              date={dateFilter.date.setHours(12)}
              customOrders={customOrders}
              params={orders.params}
              details={details}
              setDetails={setDetails}
              handleModal={[modal, setModal]}
              view={view}
              heading={heading}
            />
          </div>
        );
      }}
    </OrderContext.Consumer>
  );
}

export default OrdersDay;
