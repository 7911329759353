import React, { useContext } from "react";
import mailIcon from "../../images/icons/icons8-gmail-24.png";
import { defaultEmailAccount } from "../global";
import { isToday } from "../functions";
import AppContext from "../contexts/app-context";

function MailLink(props) {
  const { item } = props;
  const receiveMethod = item.receive_methods;
  const acceptanceMethod = item.acceptance_methods_name;
  const { accountID } = useContext(AppContext);
  const defaultAccount =
    accountID.id1 === false || accountID.id1 === "false"
      ? defaultEmailAccount
      : accountID.id1;
  const defaultAccount2 =
    accountID.id2 === false || accountID.id2 === "false"
      ? "koordynator@agit.com.pl"
      : accountID.id2;

  // EMAIL PREPARED
  let customerName = item.customers_name;
  switch (customerName) {
    case "Polska Agencja Prasowa S.A.":
      customerName = "PAP";
    case "Polska Agencja Prasowa S.A. w likwidacji":
      customerName = "PAP"
  }
  const emailDateTime = new Date(item.finish_date);

  let emailDate = emailDateTime.toLocaleString("pl-PL", {
    weekday: "short",
    day: "numeric",
    month: "short",
  });
  const emailTime = emailDateTime.toLocaleTimeString("pl-PL", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const emailDateString =
    (isToday(emailDateTime) ? "" : emailDate + ", ") + emailTime;

  const mailToAccount = "-";
  const clientRemarks =
    item.remarks_translators || item.remarks_general
      ? `Uwagi: ${
          item.remarks_translators
            ? encodeURIComponent(item.remarks_translators)
            : ""
        }%0d%0a%0d%0a${
          item.remarks_general
            ? encodeURIComponent(item.remarks_general) + "%0d%0a%0d%0a"
            : ""
        }`
      : "";

  const overallType = Array.from(
    new Set(
      item.documents.map((doc) => {
        let transType = doc.type_of_translation;
        if (doc.type_of_translation === "przysięgłe")
          transType = "uwierzytelnione";
        return transType;
      })
    )
  );
  const overallTypeString = overallType.length
    ? "Rodzaj: " + overallType.join(", ") + "%0d%0a%0d%0a"
    : "";

  const mailBody = `Cześć,%0d%0a%0d%0aprzesyłam zlecenie.%0d%0a%0d%0a${clientRemarks}${overallTypeString}Odbiór: ${acceptanceMethod}%0d%0a%0d%0aPozdr.`;
  
  const mailSubject = `${item.valid_order} >${item.language_to_short} / na ${emailDateString} / ${customerName}`;
  const mailLink = `https://mail.google.com/mail/u/${defaultAccount}/?extsrc=mailto&url=mailto%3A${mailToAccount}%3Fsubject%3D${mailSubject}%26body%3D${mailBody}`;
  const mailLink2 = `https://mail.google.com/mail/u/${defaultAccount2}/?extsrc=mailto&url=mailto%3A${mailToAccount}%3Fsubject%3D${mailSubject}%26body%3D${mailBody}`;

  return (
    <a
      href={mailLink}
      onContextMenu={(e) => {
        e.preventDefault();
        window.open(mailLink2, "_blank");
      }}
      style={{ color: "black" }}
      target="_blank"
    >
      <div title={"Utwórz e-mail: " + mailSubject}>
        <img alt="email" src={mailIcon}></img>
      </div>
    </a>
  );
}

export default MailLink;
