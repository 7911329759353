import { useEffect, useRef, useState } from "react";
import { shortenLanguage } from "../../functions";
import { URL } from "../../global";
import { useGeneric } from "../../useGeneric";
import ActionTypeButton from "./ActionTypeButton";
import { dateToSQLTime } from "./dateToSQL";
import NewItemPreview from "./NewItemPreview";
import TextTypeButton from "./TextTypeButton";
import SearchBox from "./NewItem/SearchBox/SearchBox";
import OrderItem from "./NewItem/SearchBox/ResultTypes/OrderItem";
import NewOrderItem from "./NewItem/NewOrderItem";
import NewItemOther from "./NewItem/NewItemOther";
import TimeEdit from "./NewItem/TimeEdit";
import flags from "../../flags";
import addItem from "./NewItem/addItem";
import prettyDate from "../../tables/functions/prettyDate";
import languageDisplay from "../../tables/instances/displays/languageDisplay";

export default function NewItem({
  user,
  workStarted,
  ts,
  setUpdate,
  setShowCreate,
  lastEmpty,
  selectedDate,
  defaults,
  showEdit,
  locationState,
}) {
  const fetchTypes = useGeneric({ name: "TSactionTypes" });
  const types = fetchTypes && fetchTypes.rows ? fetchTypes.rows : undefined;
  const addActions = {
    lastEmpty: lastEmpty,
    setUpdate: setUpdate,
    setShowCreate: setShowCreate,
  };

  const [language, setLanguage] = useState(
    defaults?.language
      ? defaults.language
      : locationState?.language
      ? locationState?.language
      : user?.languages === "de"
      ? "Niem"
      : localStorage.getItem("timesheet-language-last-selected")
      ? localStorage.getItem("timesheet-language-last-selected")
      : "Ang"
  );

  useEffect(() => {
    if (!defaults?.language) {
      localStorage.setItem("timesheet-language-last-selected", language);
    }
  }, [language, defaults?.language]);

  const [category, setCategory] = useState(
    defaults?.action_type?.category &&
      defaults?.action_type?.category === "order"
      ? 0
      : defaults?.action_type?.category === "other"
      ? 1
      : defaults?.action_type?.category === "break"
      ? 2
      : defaults?.action_type?.category === "out"
      ? 3
      : 0
  );
  const [comment, setComment] = useState(defaults?.comment || "");
  const [orderData, setOrderData] = useState(
    defaults?.order || {
      id: "",
      number: "",
      mainLang: language,
      to: language,
      from: "Pol",
    }
  );
  const [orderDataTS, setOrderDataTS] = useState({
    action_type: defaults?.action_type?.id || 1,
    text_type: defaults?.text_type || 1,
  });
  const [otherData, setOtherData] = useState({
    action_type: defaults?.action_type?.id || 6,
  });

  const now = new Date();
  const workEndedAlready =
    ts &&
    ts.length &&
    ts.at(-1).endWork &&
    now - new Date(ts.at(-1).endWork) > 0
      ? true
      : false;
  const [startTime, setStartTime] = useState(() => {
    if (defaults?.start) {
      return dateToSQLTime(new Date(defaults.start));
    }
    let time;
    // if there are any past entries today
    if (ts && ts.length) {
      // if last entry is an empty one
      if (lastEmpty.value) {
        time = dateToSQLTime(new Date(ts.at(-1).start));
      }
      // if work has already ended
      else if (workEndedAlready) {
        const beforeEnd = new Date(ts.at(-1).endWork);
        //beforeEnd.setMinutes(beforeEnd.getMinutes() - 1);

        time = dateToSQLTime(beforeEnd);
      }
      // otherwise it's now
      else {
        time = dateToSQLTime(now);
      }
    } else {
      time = dateToSQLTime(workStarted);
    }

    return time;
  });

  const buttons = [
    {
      text: "ZLECENIE",
    },
    {
      text: "INNE",
    },
    {
      text: "PRZERWA",
    },
    {
      text: "WYJŚCIE PRYW.",
    },
  ];

  return (
    <div className="ts-new-item-rev">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="ts-new-buttons">
          {buttons.map((button, index) => (
            <button
              className={category === index ? "active" : ""}
              onClick={() => setCategory(index)}
            >
              {button.text}
            </button>
          ))}
        </div>
        {category === 0 ? (
          <div>
            <button
              onClick={() => {
                setLanguage("Ang");
                if (!orderData?.client) {
                  setOrderData((prev) => ({ ...prev, from: "Pol", to: "Ang" }));
                }
              }}
              className={"langButton" + (language === "Ang" ? " active" : "")}
            >
              <img width={20} src={flags.gb} />
            </button>
            <button
              onClick={() => {
                setLanguage("Niem");
                if (!orderData?.client) {
                  setOrderData((prev) => ({
                    ...prev,
                    from: "Pol",
                    to: "Niem",
                  }));
                }
              }}
              className={"langButton" + (language === "Niem" ? " active" : "")}
            >
              <img width={20} src={flags.de} />
            </button>
          </div>
        ) : (
          <></>
        )}
        <div>
          <button
            className="regularButton"
            onClick={(e) => {
              e.preventDefault();
              setShowCreate(false);
              if (defaults) showEdit.set(false);
            }}
          >
            ✖
          </button>
        </div>
      </div>
      <div style={category !== 0 ? { display: "none" } : {}}>
        <NewOrderItem
          types={types}
          orderData={orderData}
          setOrderData={setOrderData}
          orderDataTS={orderDataTS}
          setOrderDataTS={setOrderDataTS}
          otherData={otherData}
          language={language}
          defaults={defaults}
        />
      </div>
      <div style={category !== 1 ? { display: "none" } : {}}>
        <NewItemOther
          types={types}
          otherData={otherData}
          setOtherData={setOtherData}
        />
      </div>
      <div
        className={
          "comment-row" +
          (!orderData?.client && category === 0 ? " additional-info" : "")
        }
      >
        <textarea
          className="TScomments"
          placeholder={
            orderData?.client && category === 0
              ? "opcjonalne uwagi"
              : category > 0
              ? "szczegóły czynności"
              : "opis zlecenia"
          }
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={200}
        />
        {!orderData?.client && category === 0 ? (
          <div className="set-custom-language">
            {language === "Ang" ? (
              <>
                <button
                  className={
                    orderData?.from === "Pol" && orderData?.to === "Ang"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setOrderData((prev) => ({
                      ...prev,
                      from: "Pol",
                      to: "Ang",
                    }));
                  }}
                >
                  PL{">"}EN
                </button>
                <button
                  className={
                    orderData?.from === "Ang" && orderData?.to === "Pol"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setOrderData((prev) => ({
                      ...prev,
                      from: "Ang",
                      to: "Pol",
                    }));
                  }}
                >
                  EN{">"}PL
                </button>
              </>
            ) : language === "Niem" ? (
              <>
                <button
                  className={
                    orderData?.from === "Pol" && orderData?.to === "Niem"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setOrderData((prev) => ({
                      ...prev,
                      from: "Pol",
                      to: "Niem",
                    }));
                  }}
                >
                  PL{">"}DE
                </button>
                <button
                  className={
                    orderData?.from === "Niem" && orderData?.to === "Pol"
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    setOrderData((prev) => ({
                      ...prev,
                      from: "Niem",
                      to: "Pol",
                    }));
                  }}
                >
                  DE{">"}PL
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className="TSrowPreview"
        style={{
          gridTemplateColumns: "1fr 200px max-content",
          alignItems: "center",
          gridGap: "30px",
        }}
      >
        <TimeEdit
          ts={ts}
          lastEmpty={lastEmpty}
          workStarted={workStarted}
          defaults={defaults}
          startTime={startTime}
          setStartTime={setStartTime}
        />
        <div
          style={{
            fontSize: "12px",
            display: "flex",
          }}
        >
          
          {orderData?.client && orderData?.due && category === 0 ? (
            <div
              style={{
                padding: "2px",
              }}
            >
              na {prettyDate(orderData.due)}
            </div>
          ) : (
            <></>
          )}
          <div
            style={{
              padding: "2px 9px",
              width: "max-content",
              background: "whitesmoke",
              borderRadius: "5px",
              fontWeight:"700",
              margin: "0 auto",
            }}
          >
            {orderData && category === 0 ? (
              <>
                {shortenLanguage(orderData?.from)} {">"}{" "}
                {shortenLanguage(orderData?.to)}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <button
            className="regularButton major"
            onClick={(e) => {
              e.preventDefault();
              const common = {
                start:
                  selectedDate.toISOString().split("T")[0] + " " + startTime,
                employeeID: user.employeeID,
                comment: comment ? comment : "",
                customer_name: "",
                document_id: "",
                document_name: "",
                language_from: "",
                language_main: "",
                language_to: "",
                order_id: "",
                order_string: "",
                sworn: 0,
                text_type: 0,
                unit: "zzs",
              };
              addItem(
                category === 0
                  ? {
                      ...common,
                      order_id: orderData ? orderData.id : "",
                      order_string: orderData ? orderData.number : "",
                      action_type: orderDataTS ? orderDataTS.action_type : 0,
                      text_type: orderDataTS
                        ? orderDataTS.action_type === 4
                          ? 4
                          : orderDataTS.action_type === 1 ||
                            orderDataTS.action_type === 3
                          ? orderDataTS.text_type
                          : 0
                        : 0,
                      sworn:
                        orderData &&
                        orderData.documents &&
                        orderData.document_id &&
                        orderData.documents.find(
                          (d) => d.id === orderData.document_id
                        )?.type === "przysięgłe"
                          ? 1
                          : 0,
                      document_id:
                        orderData && orderData.document_id
                          ? orderData.document_id
                          : "",
                      document_name:
                        orderData &&
                        orderData.documents &&
                        orderData.document_id
                          ? orderData.documents.find(
                              (d) => d.id === orderData.document_id
                            )?.name
                          : "",
                      customer_name: orderData?.client || "",
                      language_from: orderData?.from || "",
                      language_to: orderData?.to || "",
                      language_main: orderData?.mainLang || "",
                    }
                  : category === 1
                  ? {
                      ...common,
                      action_type: otherData ? otherData.action_type : 0,
                    }
                  : category === 2
                  ? {
                      ...common,
                      action_type: 14,
                      text_type: 0,
                    }
                  : category === 3
                  ? {
                      ...common,
                      action_type: 13,
                      text_type: 0,
                    }
                  : undefined,
                addActions,
                !defaults ? false : defaults.id
              );
              if (showEdit) {
                showEdit.set(false);
              }
            }}
          >
            {!defaults ? <>✓ Dodaj</> : <>✓ Aktualizuj</>}
          </button>
        </div>
      </div>
    </div>
  );
}
