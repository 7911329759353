import React, { useState,useEffect } from "react";
import { URL } from "./global";

function getOrders(query) {
    return fetch(URL + "components/db-query-orders.php", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({type: "search", query: query}),
      }).then((response) => response.json())
}

function useOrderSearch(query) {
    const [orders,setOrders] = useState([]);
    useEffect(() => {
        if (query) getOrders(query).then(result => setOrders(result));
    },[query]
    )
    return orders;
}

export default useOrderSearch;
