import React from "react";
import pageIcon from "../../images/icons/icons8-matt-paper-12.png";

function sumPages(orders, transType) {
  return orders.length > 0
    ? orders.map((item) => Math.ceil(item[transType])).reduce((a, b) => a + b)
    : "brak";
}

function sumPrice(orders) {
  if (orders.length > 1) {
    let sumPriceString = "";
    let sumPrice = orders
      .map((item) => parseFloat(item.price_brutto))
      .reduce((a, b) => a + b);
    sumPrice = parseInt(sumPrice * 100) / 100;
    sumPriceString = sumPrice.toString().replace(".", ",");
    return sumPriceString;
    
  } 
  else if (orders.length === 1) return orders[0].price_brutto;
  else return "";
}

function OrderStatistics(props) {
  const orders = props.orders.filter(order => !order.personal_done)
  return (
    <div className="orderStatistics">
      {orders && orders.length ? (
        <>
          <span>{orders.length} zlec.</span> |{" "}
          <span>{sumPages(orders, "docPages")} str.</span>
          <span className="price">{sumPrice(orders)} zł</span>
          <span
            className="sworn"
            title="liczba kartek tłumaczeń uwierzytelnionych wg wycen"
          >
            <img
              src={pageIcon}
            ></img>
            {Math.ceil(sumPages(orders, "docPagesSwornRounded") / 2)}{" "}
          </span>
        </>
      ) : (
        <>...</>
      )}
    </div>
  );
}

export default OrderStatistics;
