import flags from "../../../../../flags";
import { languagesList } from "../../../../../global";
import timeAgo from "../../../../functions/timeAgo";

export const translator_name = {
  label: "Tłumacz",
  type: "search",
  required: true,
  dataSource: {
    name: "new-externals-list",
    active: 1,
    params: {
      orderBy: "latestDate, last_name",
      orderDesc: "DESC, ASC",
    },
    query: (value) => ({
      search: {
        value: value,
      },
      active: {
        value: 1
      }
    }),
    init: {
      name: "translator_id",
      display: (r) => r?.full_name,
      query: (value) => ({
        id: {
          value: value,
          exact: true,
        },
      }),
    },
    value: "id",
    setters: (row) => [
      ["translator_name", row ? row.first_name + " " + row.last_name : ""],
      ["translator_id", row ? row.id : undefined],
    ],
    display: (row) => (
      <div
        className={row.latestDate ? "latest-added-wrap" : ""}
        style={{ padding: "6px 9px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: "15px" }}>
            {row.first_name + " " + row.last_name}{" "}
            {row.type === 0 ? (
              <></>
            ) : (
              <span style={{ color: "green" }}>★ </span>
            )}
          </div>

          <div
            style={{
              fontSize: "10px",
              display: "flex",
              background: "whitesmoke",
              padding: "0px 5px",
              borderRadius: "5px",
              //boxShadow: "0 0 2px rgba(0,0,0,0.2)",
            }}
          >
            {row?.languages_main_full && row?.languages_main_full.split(",").map((item) => (
              <div
                style={{
                  padding: "2px 5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "14px",
                    marginRight: "5px",
                  }}
                  src={
                    flags[languagesList.find((i) => i.name === item)?.flagCode]
                  }
                />{" "}
                {item}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "9px",
          }}
        >
          <div
            style={{
              margin: "1px 1px 0 0",
              color:"grey"
              // padding: "0px 5px 0px 1px",
              // //borderRadius: "3px",
              //background: "whitesmoke",
              // boxShadow: "0 0 2px rgba(0,0,0,0.2)",
            }}
          >
            {row.written_or_oral === 0
              ? "pisemny"
              : row.written_or_oral === 1
              ? "ustny"
              : "pisemny i ustny"}
          </div>
          <div className="latest-added">
            {row?.latestDate ? (
              <>
                <span className="latest-added-label"></span>{" "}
                <span className="latest-added-date">
                  {timeAgo(row?.latestDate)}
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ),
    text: (row) => row.first_name + " " + row.last_name,
  },
}; // not present in db
