export const propMap = {
  id: "id faktury",
  id_orders: "zlecenie do którego wystawiono fakturę",
  orders_number: "wyfakturowane zlecenie",
  groupped: "faktura zbiorcza?",
  pro_forma: "czy to faktura pro forma?",
  pro_forma_used_id: "ID faktury pro forma do której ta faktura się odnosi",
  pro_forma_used_number:
    "numer faktury pro forma do której ta faktura się odnosi",
  pro_forma_used_date:
    "data faktury pro forma do której ta faktura się odnosi",
  advanced: "czy to jest faktura zaliczkowa?",
  advanced_used_id: "ID faktury w której wykorzystano zaliczkę",
  advanced_used_number: "numer faktury w której wykorzystano zaliczkę",
  advanced_used_amount: "kwota faktury zaliczkowej",
  advanced_used_date: "data faktury zaliczkowej",
  document_sell_receipt_number: "numer paragonu fiskalnego",
  duplicate: "czy wystawiono duplikat?",
  duplicate_date: "data wystawienia duplikatu",
  create_date: "data stworzenia",
  last_modification_date: "data ostatniej modyfikacji",
  year: "rok",
  month: "miesiac",
  day: "dzien",
  date_issue: "data wystawienia",
  date_sell: "data sprzedaży",
  language_main: "języki zleceń zafakturowanych",
  language_flag: "flagi języków zleceń",
  number: "numer kolejności faktury",
  number_facture: "numer faktury",
  name: "numer faktury",
  is_english: "czy faktura ma wydruk po angielsku?",
  id_users: "osoba tworzaca fakturę",
  users_name: "osoba tworzaca fakturę",
  users_initials: "inicjały osoby tworzącej fakturę",
  id_users_edit: "osoba ostatnio edytująca fakturę",
  users_edit_name: "osoba ostatnio edytująca fakturę",
  users_edit_initials: "osoba ostatnio edytująca fakturę",
  customers_id: "id kontrahenta",
  customers_countries_name: "kraj klienta",
  customers_countries_flag: "flaga kraju klienta",
  customers_symbol: "symbol kontrahenta",
  customers_name: "kontrahent",
  customers_email: "email kontrahenta",
  customers_phone: "telefon kontrahenta",
  customers_nip: "NIP kontrahenta",
  customers_pesel: "Pesel kontrahenta",
  customers_street: "ulica kontrahenta",
  customers_town: "miasto kontrahenta",
  customers_post_code: "kod pocztowy kontrahenta",
  customers_nr_place: "numer lokalu kontrahenta",
  customers_nr_home: "numer domu kontrahenta",
  customers_private_firm: "typ kontrahenta",
  customers_branch_id: "id filii na ktora jest zgloszenie",
  customers_branch_name: "filia kontrahenta",
  is_customers_info: "czy jest jakas informacja od klienta",
  who_read_customers_info: "Kto czytał info od klienta",
  name_payer: "nazwa płatnika",
  nip_payer: "NIP płatnika",
  street_payer: "nazwa ulicy płatnika",
  town_payer: "nazwa miasta płatnika",
  post_code_payer: "kod pocztowy płatnika",
  seller_name: "nazwa sprzedawcy",
  seller_phone: "telefon sprzedawcy",
  seller_email: "email sprzedawcy",
  seller_nip: "NIP sprzedawcy",
  seller_street: "ulica sprzedawcy",
  seller_town: "miasto sprzedawcy",
  seller_post_code: "kod pocztowy sprzedawcy",
  seller_nr_place: "nr lokalu sprzedawcy",
  seller_nr_home: "nr domu sprzedawcy",
  status: "status faktury",
  cancel_reason: "przyczyna anulowania",
  cancel_users_id: "użytkownik który anulował",
  cancel_users_name: "użytkownik który anulował",
  cancel_users_initials: "inicjały osoby anulującej",
  cancel_date: "data anulowania",
  id_payment_methods: "metoda płatności",
  payment_methods_name: "metoda płatności",
  payment_methods_payed: "czy zapłacono",
  payment_methods_payed_date: "data opłacenia",
  payment_methods_payed_user_id: "osoba która opłaciła fakturę",
  payment_methods_payed_user_name: "osoba która opłaciła fakturę",
  payment_methods_payed_user_initials: "inicjały osoby opłacającej",
  payment_days: "dni płatności",
  payment_maturity_date: "termin płatności",
  id_bank_accounts: "konto bankowe",
  bank_accounts_name: "nazwa banku",
  bank_accounts_number: "numer konta bankowego",
  remarks: "uwagi",
  price: "wartość netto",
  discount_for_all_documention: "Rabat dla wszytskich pozycji",
  tax_rate: "stawka VAT",
  tax_rate_name: "stawka VAT",
  price_brutto: "wartość brutto",
  section_type: "Z której tabeli bierzemy dane",
  id_regional_office: "id biura regionalnego",
  active: "aktywna",
  id: "id faktury",
  id_orders: "zlecenie do którego wystawiono fakturę",
  orders_number: "wyfakturowane zlecenie",
  groupped: "faktura zbiorcza?",
  pro_forma: "czy to faktura pro forma?",
  pro_forma_used_id: "ID faktury pro forma do której ta faktura się odnosi",
  pro_forma_used_number:
    "numer faktury pro forma do której ta faktura się odnosi",
  pro_forma_used_date:
    "data faktury pro forma do której ta faktura się odnosi",
  advanced: "czy to jest faktura zaliczkowa?",
  advanced_used_id: "ID faktury w której wykorzystano zaliczkę",
  advanced_used_number: "numer faktury w której wykorzystano zaliczkę",
  advanced_used_amount: "kwota faktury zaliczkowej",
  advanced_used_date: "data faktury zaliczkowej",
  document_sell_receipt_number: "numer paragonu fiskalnego",
  duplicate: "czy wystawiono duplikat?",
  duplicate_date: "data wystawienia duplikatu",
  create_date: "data stworzenia",
  last_modification_date: "data ostatniej modyfikacji",
  year: "rok",
  month: "miesiac",
  day: "dzien",
  date_issue: "data wystawienia",
  date_sell: "data sprzedaży",
  language_main: "języki zleceń zafakturowanych",
  language_flag: "flagi języków zleceń",
  number: "numer kolejności faktury",
  number_facture: "numer faktury",
  name: "numer faktury",
  is_english: "czy faktura ma wydruk po angielsku?",
  id_users: "osoba tworzaca fakturę",
  users_name: "osoba tworzaca fakturę",
  users_initials: "inicjały osoby tworzącej fakturę",
  id_users_edit: "osoba ostatnio edytująca fakturę",
  users_edit_name: "osoba ostatnio edytująca fakturę",
  users_edit_initials: "osoba ostatnio edytująca fakturę",
  customers_id: "id kontrahenta",
  customers_countries_name: "kraj klienta",
  customers_countries_flag: "flaga kraju klienta",
  customers_symbol: "symbol kontrahenta",
  customers_name: "kontrahent",
  customers_email: "email kontrahenta",
  customers_phone: "telefon kontrahenta",
  customers_nip: "NIP kontrahenta",
  customers_pesel: "Pesel kontrahenta",
  customers_street: "ulica kontrahenta",
  customers_town: "miasto kontrahenta",
  customers_post_code: "kod pocztowy kontrahenta",
  customers_nr_place: "numer lokalu kontrahenta",
  customers_nr_home: "numer domu kontrahenta",
  customers_private_firm: "typ kontrahenta",
  customers_branch_id: "id filii na ktora jest zgloszenie",
  customers_branch_name: "filia kontrahenta",
  is_customers_info: "czy jest jakas informacja od klienta",
  who_read_customers_info: "Kto czytał info od klienta",
  name_payer: "nazwa płatnika",
  nip_payer: "NIP płatnika",
  street_payer: "nazwa ulicy płatnika",
  town_payer: "nazwa miasta płatnika",
  post_code_payer: "kod pocztowy płatnika",
  seller_name: "nazwa sprzedawcy",
  seller_phone: "telefon sprzedawcy",
  seller_email: "email sprzedawcy",
  seller_nip: "NIP sprzedawcy",
  seller_street: "ulica sprzedawcy",
  seller_town: "miasto sprzedawcy",
  seller_post_code: "kod pocztowy sprzedawcy",
  seller_nr_place: "nr lokalu sprzedawcy",
  seller_nr_home: "nr domu sprzedawcy",
  status: "status faktury",
  cancel_reason: "przyczyna anulowania",
  cancel_users_id: "użytkownik który anulował",
  cancel_users_name: "użytkownik który anulował",
  cancel_users_initials: "inicjały osoby anulującej",
  cancel_date: "data anulowania",
  id_payment_methods: "metoda płatności",
  payment_methods_name: "metoda płatności",
  payment_methods_payed: "czy zapłacono",
  payment_methods_payed_date: "data opłacenia",
  payment_methods_payed_user_id: "osoba która opłaciła fakturę",
  payment_methods_payed_user_name: "osoba która opłaciła fakturę",
  payment_methods_payed_user_initials: "inicjały osoby opłacającej",
  payment_days: "dni płatności",
  payment_maturity_date: "termin płatności",
  id_bank_accounts: "konto bankowe",
  bank_accounts_name: "nazwa banku",
  bank_accounts_number: "numer konta bankowego",
  remarks: "uwagi",
  price: "wartość netto",
  discount_for_all_documention: "Rabta dla wszytskich pozycji",
  tax_rate: "stawka VAT",
  tax_rate_name: "stawka VAT",
  price_brutto: "wartość brutto",
  section_type: "Z której tabeli bierzemy dane",
  id_regional_office: "id biura regionalnego",
  active: "aktywna",
};
