import { useContext } from "react";
import Field from "./Field";
import { FieldType, FieldsType } from "./interfaces";
import { MyFormContext } from "./Form";

export default function Fields({ fields }: { fields: FieldsType | undefined }) {
  const {
    calculate,
    fieldValueObjects,
    setFieldValueObjects,
    defaultValues,
    edit,
  } = useContext(MyFormContext);
  return fields ? (
    <fieldset className="uf-field-set">
      {Object.keys(fields).map((key: string) => {
        const field = fields[key];
        return field.group ? (
          <Fields
            fields={field.fields}
          />
        ) : (
          <Field
            field={field}
            name={key}
            defaultValue={defaultValues?.[key]}
          />
        );
      })}
    </fieldset>
  ) : (
    <></>
  );
}
