import { URL } from "../../../global";

export default async function addItem(params, addActions, modify) {
    const {lastEmpty, setUpdate, setShowCreate } = addActions;
    console.log(params, modify);
    const response = await fetch(URL + "components/TS_insert.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        modify: modify ? modify : lastEmpty.obj ? lastEmpty.obj.id : false,
        params: params,
      }),
    });
    const result = await response.json();
    await setUpdate((prev) => prev + 1);
    await setShowCreate(false);
    return result;
  }