import { useContext } from "react";
import { SearchBoxContext } from "./SearchBoxContext";

export default function ResultItem({ item, index, setActive }) {
  const context = useContext(SearchBoxContext);
  const resultItem = context.resultComponent;

  return (
    <div
      onClick={() => {
        context.setSelectedItem({...item, index: index});
        setActive(false);
      }}
    >
      {resultItem({ item: {...item, index: index} }, index )}
    </div>
  );
}
