import TableUniversal from "../../TableUniversal";
import menuCustomers from "../newExternals/menuCustomers";

const dictionary = {
  NumerPelny: {
    name: "numer",
    order: "NumerPelny",
    style: {
      width: "150px",
    },
  },
  Data: {
    name: "data",
    transform: (v) =>
      new Date(v).toLocaleDateString("pl-PL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
  },
  TypDokumentu: {
    name: "typ",
  },
  Nazwa: {
    name: "kontrahent",
  },
  SumaNetto: {
    name: "Suma netto",
    transform: (v, a) => (
      <>
        {v} {a.BruttoCySymbol === "PLN" ? <>zł</> : <>{a.BruttoCySymbol}</>}
      </>
    ),
  },
  SumaBrutto: {
    name: "Suma brutto",
    transform: (v, a) => (
      <>
        {v} {a.BruttoCySymbol === "PLN" ? <>zł</> : <>{a.BruttoCySymbol}</>}
      </>
    ),
  },
  SumaVAT: {
    name: "Suma VAT",
    transform: (v, a) => (
      <div>
        <div> {Math.round((Number(v) / Number(a.SumaNetto)) * 100)}%</div>
        <div>
          {v} {a.BruttoCySymbol === "PLN" ? <>zł</> : <>{a.BruttoCySymbol}</>}
        </div>
      </div>
    ),
  },
};

export default function TradeDocuments() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Paragony (Enova)"
        classNames="big"
        data="trade-documents"
        customMenu={menuCustomers}
        /*  groupBy={[
          "month",
          (m, props) =>
            new Date(props.year, m - 1, 1).toLocaleDateString("pl-PL", {
              month: "long",
              year: "numeric",
            }),
        ]}*/
        orderBy="id"
        orderDesc="DESC"
        options={{ selectable: true }}
        dictionary={dictionary}
      />
    </div>
  );
}
