import flags from "../../../../flags";
import { languagesList } from "../../../../global";
import languageDisplay from "../../displays/languageDisplay";
import { document_number } from "./common/document_number";
import { order_number } from "./common/order_number";
import { translator_name } from "./common/translator_name";

export const settlementMap = {
  db: "components/set/set-settlement.php",
  create: false,
  className: "three-columns",
  fields: {
    translator_name: { ...translator_name, disabled: true },
    translator_id: {
      label: "id tłumacza wg tabeli",
    },
    settlement_number: { disabled: true, hiddenOnEmpty: true, label: "Numer" },
    sent_order: {
      label: "Wysłane zlecenia",
      addToBag: "entries",
      bagID: "sent_order_all",
      dataSource: {
        name: "sent-orders-table",
        params: {
          orderBy: "id",
          orderDesc: "DESC",
        },
        query: (value, all) => ({
          order_number: {
            value: value,
          },
          translator_id:
            all && all.translator_id
              ? {
                  value: all.translator_id,
                  exact: true,
                }
              : undefined,
        }),
        value: "order_id",
        setters: (row) => [
          ["sent_order", row.order_number],
          ["sent_order_all", row],
        ],
        display: (row) => (
          <>
            {row.order_number} {row.order_customer}
          </>
        ),
        text: (row) => row.order_number,
      },
    },
    sent_order_all: {},
    document_type: {
      label: "Rodzaj rozliczenia",
      element: "select",
      default: 1,
      options: [
        { value: 1, text: "Faktura" },
        { value: 2, text: "Rachunek" },
        { value: 3, text: "Umowa o dzieło" },
        { value: 4, text: "Umowa zlecenie" },
        { value: 5, text: "Gotówka" },
        { value: 6, text: "Inne" },
      ],
    },
    entries: {
      type: "bag",
      source: "sent_order_all",
      disabled: true,
      //display:
      display: (v) => <div style={{ width: "100px" }}>{v.order_number}</div>,
      convert: (contents) => {
        //console.log(contents && contents.map((i) => i.id));
        return contents && contents.map((i) => i.id).join(",");
      },
      revert: (contents, all) => {
        console.log(all.order_numbers.split(","));

        return all.order_numbers.split(",").map((item, index) => ({
          order_number: item,
          id: all.entries.split(",")[index],
        }));
        /*
        const values = contents && contents.split(",");
        console.log(values);
        const numbers = all["order_numbers"].split(",");
        return values.map((v, index) => numbers[index]);*/
      },
      /*const position = entries.indexOf(v);
        return order_numbers[position];*/
    },

    document_number: document_number,
    
    total_net: {
      label: "Suma",
      disabled: true,
      display: (v) => v.replace(".", ",") + " zł",
    },
    comment: {
      element: "textarea",
    },
    document_amount: {
      label: "Kwota dokumentu",
    }
  },
  sections: [
    {
      title: "Ogólne",
      fields: ["translator_name", ["settlement_number", "total_net"]],
    },
    {
      title: "Dane rozliczeniowe",
      fields: ["document_type", "document_number", "document_amount"],
    },
    { title: "Zlecenia", fields: ["entries"] },
    { title: "Uwagi", fields: ["comment"] },
  ],
};
