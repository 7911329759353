import { useEffect } from "react";
import BagItem from "./BagItem";
import { useFormikContext } from "formik";

export default function Bag({
  currentName,
  createMap,
  name,
  data,
  contents,
  setContents,
  convert,
  setFieldValue,
  disabled,
}) {
  const { values } = useFormikContext();
  const contentsConvert =
    contents[name] && contents[name].length
      ? convert(contents[name], data && data[0])
      : "";
  console.log(contents, contentsConvert);

  useEffect(() => {
    setFieldValue(currentName, contentsConvert);
    return () => console.log(values[currentName], contentsConvert);
  }, [contentsConvert]);

  return contents && contents[name] ? (
    <div className="bag">
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "2px 2px",
          boxShadow: "0 0 12px rgba(0,0,0,.2) inset",
          margin: "0",
          borderRadius: "5px",
        }}
      >
        {contents[name].length ? (
          contents[name].map((item, index) => (
            <BagItem
              key={index}
              name={name}
              data={data}
              source={currentName}
              item={item}
              contents={contents}
              setContents={setContents}
              createMap={createMap}
              disabled={disabled}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
