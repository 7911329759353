import React from "react";
import { Link } from "react-router-dom";

function Begin() {
  return (
    <div className="add">
      <Link to="activity">
        <button className="main huge">Rozpocznij</button>
      </Link>
    </div>
  );
}

export default Begin;
