import React from 'react'

function OrderItem(props) {
    return (
        <div className="item" data-number={props.validOrder} data-id={props.id}>
                        <div className="order">{props.validOrder}</div>
                        <div className="client">{props.clientName}</div>
        </div>
    )
}

export default OrderItem
