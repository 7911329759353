export function urlQueryToObject(searchString) {
  const obj = {};
  const arr = searchString.slice(1, 1000).split("&");
  if (arr && (arr.length === 1) & (arr[0] === "")) {
    return undefined;
  } else {
    arr.forEach((item) => {
      const pair = item.split("=");
      obj[pair[0]] = pair[1];
    });
    return obj;
  }
}

export default function urlToQuery(searchString) {
  const obj = {};
  
  const arr = searchString.slice(0, 1000).split("&");
  if (arr && (arr.length === 1) & (arr[0] === "")) {
    return undefined;
  } else {
    arr.forEach((item) => {
      const pair = item.split("=");
      obj[pair[0]] = { value: Number(pair[1]), exact:true };
    });
    return obj;
  }
}
