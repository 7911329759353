import XLSX from "xlsx-js-style";

function formatColumn(worksheet, col, fmt) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  // note: range.s.r + 1 skips the header row
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col });
    if (worksheet[ref] && worksheet[ref].t === "n") {
      worksheet[ref].z = fmt;
    }
  }
}

function exportToExcel({
  fileName,
  workBookName,
  rows,
  colStyles,
  rowStyle,
  colFormatData,
}) {
  return new Promise((resolve, reject) => {   

  const ws = XLSX.utils.json_to_sheet(rows);
  if (colStyles) {
    ws["!cols"] = colStyles;
  }
  if (rowStyle) {
    ws["!rows"] = rows.map(i => rowStyle);
  }
  Object.keys(ws).forEach((key) => {
    if (key.match(/^[A-Z]1$/g)) {
      ws[key].s = {
        font: {
          sz: 8.5,
          //bold: "true",
        },
        /*fill: {
          
        },*/
        alignment: {
          wrapText: "true",
        },
        
      };
    }
    if (key.match(/^[A-Z]*[0-9]*$/g)) {
      ws[key].s = {
        ...ws[key].s,
        
        border: {
          top: { style: "thin", color: { rgb: "8f8f8f" } },
          bottom: { style: "thin", color: { rgb: "8f8f8f" } },
          left: { style: "thin", color: { rgb: "8f8f8f" } },
          right: { style: "thin", color: { rgb: "8f8f8f" } },
        },
      }
    }
  });



  if (colFormatData) {
    for (let col of colFormatData.columns) {
      formatColumn(ws, col, colFormatData.string);
    }
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, workBookName);

  /* Export to file (start a download) */
  //writeFileXLSX(workbook, fileName + ".xlsx");
  XLSX.writeFile(workbook, fileName + ".xlsx");
      
    
  resolve("SUCCESS");
    
  //reject("FAILURE")
})
}

export default exportToExcel;
