import DataToExcel from "../../DataToExcel";
import TableUniversal from "../../TableUniversal";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import languageDisplay from "../displays/languageDisplay";
import customMenuItems from "./customMenuItems";
import xlsIcon from "../../../../images/icons/xls.svg";
import invoiceImage from "../../../../images/icons/buttons/invoice.svg";
import { Link } from "react-router-dom";
import CustomerDetails from "./OrdersTable/CustomerDetails";
import menuCustomers from "./menuCustomers";
import NumberOrder from "../../common/NumberOrder";
import NumberInvoice from "../../common/NumberInvoice";

export default function OrdersTable() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Kartoteka zleceń"
        classNames="big"
        groupBy={[
          "invoice_id",
          (v, p) => (
            <div className="invoice-group">
              {p.invoice_number ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <img
                      style={{ width: "16px", marginRight: "5px" }}
                      src={invoiceImage}
                    />
                    <div>
                      <div style={{ fontSize: "8px", color: "grey" }}>
                        Faktura
                      </div>
                      <div>
                        <NumberInvoice
                          style={{ display: "inline" }}
                          clickable={p.invoice_id ? true : false}
                          content={p.invoice_number}
                          number={p.invoice_number}
                          id={p.invoice_id}
                        />{" "}
                        <span style={{ fontSize: "70%", color: "grey" }}>
                          (
                          {p.invoice_order_ids
                            ? p.invoice_order_ids?.split(";")?.length
                            : 0}{" "}
                          zlec.)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      maxWidth: "900px",
                    }}
                  >
                    {p.invoice_order_numbers ? (
                      p.invoice_order_numbers.split(";").map((o) => (
                        <div
                          style={{
                            borderRadius: "5px",
                            background: "whitesmoke",
                            boxShadow: "0 0 1px rgba(0,0,0,.53)",
                            fontSize: "11px",
                            padding: "2px 5px",
                            margin: "2px 5px",
                          }}
                        >
                          {o.replace(/\//g, ".")}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  {p.invoice_price ? (
                    <div style={{ textAlign: "right" }}>
                      <div style={{ fontSize: "15px" }}>
                        {prettyNumber(p.invoice_price_brutto)} zł
                      </div>
                      <div style={{ fontSize: "11px", color: "grey" }}>
                        {prettyNumber(p.invoice_price)} zł{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          ),
        ]}
        orderBy="id"
        orderDesc="DESC"
        sumField={(row, search, countResult) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CustomerDetails customer={row} />
            <div
              className="sumField write-in"
              style={{ display: "flex" }}
              title="Suma brutto"
            >
              <div>{countResult && prettyNumber(countResult.sum_price_brutto)} zł</div>
              <div
                style={{
                  paddingLeft: "12px",
                  width: "17px",
                  cursor: "pointer",
                }}
              >
                <DataToExcel
                  name="order-list"
                  rowsMap={(i) => ({
                    "Numer zlecenia": {
                      v:
                        i.number_order +
                        "." +
                        String(i.language_main).toLowerCase(),
                      t: "s",
                    },
                    "Data przyjęcia": { v: i.create_date, t: "d" },
                    z: { v: String(i.language_from).toLowerCase(), t: "s" },
                    na: { v: String(i.language_to).toLowerCase(), t: "s" },
                    Klient: { v: i.customers_name, t: "s" },
                    "Opis": {v: i.document_names, t: "s", s: { 
                      font: { 
                        sz: 10
                      },
                      alignment: {
                        wrapText: true
                      }
                    } },
                    "Liczba stron": { v: i.pages_to_translate_count, t: "n" },
                    "Cena netto": { v: i.price, t: "n" },
                    "Cena brutto": { v: i.price_brutto, t: "n" },
                    "Numer faktury": { v: i.invoice_number, t: "s" },
                    "Dane faktury": {
                      v: i.payment_methods_document_sell_ids,
                      t: "s",
                      s: { 
                        font: { 
                          sz: 6
                        } 
                      } 
                    },
                  })}
                  pushRows={(rows) => ({
                    "Liczba stron": {
                      t: "n",
                      f: "=SUM(G2:G" + (rows.length + 1) + ")",
                    },
                    "Cena netto": {
                      t: "n",
                      f: "=SUM(H2:H" + (rows.length + 1) + ")",
                    },
                    "Cena brutto": {
                      t: "n",
                      f: "=SUM(I2:I" + (rows.length + 1) + ")",
                    },
                  })}
                  colStyles={[
                    { width: 16 },
                    { width: 11 },
                    { width: 6 },
                    { width: 6 },
                    { width: 25 },
                    { width: 25 },
                    { width: 9 },
                    { width: 9 },
                    { width: 9 },
                    { width: 12 },
                    { width: 20 },
                  ]}
                  button={<img src={xlsIcon} />} //"🖶"
                  orderBy="id"
                  orderDir="DESC"
                  search={search}
                  filename={"AGIT_" + "_" + new Date().toLocaleString()}
                />
              </div>
            </div>
          </div>
        )}
        defLimit={20}
        data="order-list"
        /*  groupBy={[
          "month",
          (m, props) =>
            new Date(props.year, m - 1, 1).toLocaleDateString("pl-PL", {
              month: "long",
              year: "numeric",
            }),
        ]}*/

        searchPattern={{
          customers_name: {
            label: "Klient",
            value: "",
            fillOnEmpty: {
              name: "customer",
              source: "customers_id",
              target: "name",
              from: {
                db: "customers-list",
                searchColumn: "id",
              },
            },

            optionsDB: {
              name: "customer",
              target: "customers_id",
              exact: true,
              standard: true,
              standardDB: "orders",
              db: "customers-list",
              limit: 20,
              style: {
                width: "230px",
                fontWeight: "400",
                height: "32px",
                fontSize: "16px",
                //padding:"20px 8px",
                background: "white",
                color: "#46797e",
              },
              searchColumn: "name",
              targetColumn: "id",
              displayOptions: (row) => <div>{row.name}</div>,
              orderBy: "recent, orders",
              orderDesc: "DESC, DESC",
            },
          },
          customers_id: {
            value: "",
            style: {
              display: "none",
            },
            standard: true,
            db: "orders",
            exact: true,
            fromURL: "customer",
          },
          /*
          month: {
            label: "Miesiąc",
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              styczeń: 1,
              luty: 2,
              marzec: 3,
              kwiecień: 4,
              maj: 5,
              czerwiec: 6,
              lipiec: 7,
              sierpień: 8,
              wrzesień: 9,
              październik: 10,
              listopad: 11,
              grudzień: 12,
            },
            exact: true,
          },
          year: {
            label: "Rok",
            value: "",
            maxLength: 4,
            \exact: true,
          },*/
          create_date_from: {
            label: "od",
            name: "create_date_calendar",
            type: "date",
            rangeFrom: true,
            value: "",
            /*value: new Date(
              new Date().getFullYear() +
                "," +
                (new Date().getMonth() + 1) +
                ",1 12:00:00"
            )
              .toISOString()
              .slice(0, 10),*/
          },
          create_date_to: {
            label: "do",
            name: "create_date_calendar",
            type: "date",
            rangeTo: true,
            value: "", //new Date(new Date().getFullYear() + ",12,31 12:00:00").toISOString().slice(0, 10),
          },

          order_all: { label: "Zlecenie", value: "", exactLeft: true },
          language_main: "",

          status: {
            label: "status",
            value: "",
            element: "select",
            options: {
              wszystkie: "",

              "do zrobienia": 1,
              gotowe: 2,
              archiwalne: 3,
              wstrzymane: 4,
              anulowane: 0,
            },
          },
          has_invoice: {
            label: "faktura",
            value: "",
            element: "select",
            options: {
              wszystkie: "",
              tak: 1,
              nie: 0,
            },
          },
          invoice_number: {
            label: "nr faktury",
            value: "",
          },
        }}
        //options={{ selectable: true }}
        dictionary={dictionary}
        customMenu={menuCustomers}
      />
    </div>
  );
}

const dictionary = {
  /*id: {
    name: "id",
    order: "id",
    style: {
      width: "21px",
      padding: "0",
      fontSize: "9px",
      textAlign: "center",
      color: "grey",
    },
  },*/
  number_order: {
    name: "Nr zlec.",
    style: {
      width: "80px",
      textAlign: "center",
      fontSize: "15px",
    },
    order: "number_order",
    transform: (v, a) => <NumberOrder id={a.id} number_order={v} />,
  },
  language_main: {
    name: "Język",
    order: "language_main, id",
    style: {
      width: "80px",
      textAlign: "center",
    },
    transform: (v) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "56px",
          margin: "0 auto",
          fontSize: "10px",
          alignItems: "center",
        }}
      >
        {languageDisplay({ name: v })} {v}
      </div>
    ),
  },
  language_from: {
    name: "z",
    order: "language_from, id",
    style: {
      display: "none",
    },
  },
  language_to: {
    name: "na",
    order: "language_to, id",
    style: {
      display: "none",
    },
  },
  customers_name: {
    name: "Klient",
    style: {
      width: "300px",
      padding:"4px 10px",
      textAlign: "left",
    },
    order: "customers_name, id",
    transform: (v, a) => (
      <Link to={`../orders?customer=${a.customers_id}`}>
        <div>{v}</div>
      </Link>
    ),
  },
  document_count: {
    name: "Dok.",
    style: {
      width: "250px",
      textAlign: "center",
      padding: 0,
    },
    transform: (v, a) => (
      <div>
        <div className="document-names">
          {a.document_names?.split("~:").map((n) => (
            <div>{n}</div>
          ))}
        </div>
      </div>
    ),
  },
  create_date: {
    name: "Przyjęcie",
    transform: (v) => prettyDate(v),
    style: {
      width: "70px",
      textAlign: "center",
      fontSize: "11px",
    },
  },
  finish_date: {
    name: "Termin",
    style: {
      width: "70px",
      textAlign: "center",
      fontSize: "11px",
    },
    transform: (v) => prettyDate(v),
  },
  /*
  document_names: {
    name: "Dokumenty",

    transform: (i) =>
      i && (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {i.split(",").map((d) => (
            <div
              style={{
                padding: "5px 11px",
                fontSize: "9px",
                fontFamily: "monospace",
                textTransform: "uppercase",
                margin: "2px 4px",
                background: "white",
                boxShadow: " 0 0 5px rgba(0,0,0,.2)",
                borderRadius: "5px",
                fontWeight: "200",
                textAlign: "left",
              }}
            >
              {d}
            </div>
          ))}
        </div>
      ),
  },*/
  pages_to_translate_count: {
    name: "Strony",
    style: { textAlign: "center", width: "70px" },
  },

  price: {
    name: "Netto",
    style: {
      width: "80px",
      padding:0,
      textAlign: "center",
    },
    transform: (v, a) => (
      <>
        {prettyNumber(v, {
          className: "number-fancy",
          scale: 1000,
          postfix: "zł",
        })}
      </>
    ),
  },
  price_brutto: {
    name: "Brutto",
    style: {
      width: "80px",
      padding:0,
      textAlign: "center",
    },
    transform: (v) => (
      <>{prettyNumber(v, { className: "number-fancy", postfix: "zł" })}</>
    ),
  },
  /*
  invoice_number: {
    name: "nr faktury / zapł.",
    transform: (v, a) => (
      <>
        {v} {a.invoice_status === "payed" ? <span>✓</span> : <span></span>}
      </>
    ),
    order: "invoice_id, id",
    orderDir: "DESC, DESC",
  },
 
  payment_methods_document_sell_ids: {
    name: "dane faktury",
    transform: (v) => {
      if (v && v.length) {
        const arr = v.split("**");
        return (
          <div
            title={arr[0]}
            style={{
              fontSize: "10px",
            }}
          >
            <div style={{ fontWeight: "700", fontSize: "11px" }}>
              {prettyNumber(arr[3])} zł
            </div>
            <div>{prettyDate(arr[2])}</div>
            <div style={{ padding: "0 2px", color: "grey" }}>{arr[1]}</div>
          </div>
        );
      }
    },
  },*/
};
