import { translator_name } from "./common/translator_name";

export const customerDetailsMap = {
  db: "components/set/set-customer-details.php",
  //create: false,
  className: "three-columns",
  fields: {
    customer_short: {
      label: "Krótka nazwa",
    },
    rates: {
      element: "text-editor",
    },
    description: {
      element: "text-editor",
    },
    invoice: {
      element: "text-editor",
    },
    customer_id: {
      label: "id klienta",
    },
    symbol: {
      label: "symbol",
    },
    name: {
      label: "Klient",
      type: "search",
      required: true,
      dataSource: {
        name: "customers-list",
        params: {
          limit: 10,
          orderBy: "date_added",
          orderDesc: "DESC",
        },
        query: (value) => ({
          name: {
            value: value,
          },
        }),
        init: {
          name: "customer_id",
          display: (r) => r?.name,
          query: (value) => ({
            id: {
              value: value,
              exact: true,
            },
          }),
        },
        value: "customer_id",
        setters: (row) => [
          ["name", row ? row.name : ""],
          ["symbol", row ? row.symbol : ""],
          ["customer_id", row ? row.id : undefined],
        ],
        display: (row) => (
          <div style={{ padding: "3px 12px" }}>
            {row.name}{" "}
            <span style={{ color: "grey", fontSize: "9px" }}>{row.symbol}</span>
          </div>
        ),
        text: (row) => row.name,
      },
    },
  },
  sections: [
    { fields: ["name"] },
    { fields: ["customer_short"] },
    { fields: ["symbol"] },
    {
      title: "Stawki",
      fields: ["rates"],
    },
    { title: "Opis", fields: ["description"] },
    { title: "Faktura", fields: ["invoice"] },
  ],
};
