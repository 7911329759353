import { minutesToTimeString } from "../../../main/WorkTimeView";
import TableUniversal from "../../TableUniversal";

const dictionary = {
  id: {
    name: "id",
    style: {
      width: "90px",
    },
  },
  cardname: {
    name: "Prac.",
    style: {
      width: "150px",
    },
  },
  start: {
    name: "Czas.",
    style: {
      width: "90px",
    },
    transform: (a) =>
      new Date(a).toLocaleTimeString("pl-PL", {
        hours: "numeric",
        minutes: "numeric",
      }),
  },
  action_type: {
    name: "Czynności",
    style: {
      display: "none",
    },
  },
  action_type_short: {
    name: "Cz.",
  },
  order_string: {
    name: "Zlec.",
  },
  customer_name: {
    name: "Klient",
  },
  document_name: {
    name: "Dok.",
    style: {
      maxWidth: "350px",
    },
  },
  text_type_string: {
    name: "Tekst",
  },
  minutes: {
    name: "Dł.",
    transform: (m) => minutesToTimeString(m),
  },
};

export default function TimesheetTable() {
  return (
    <div className="fullScreen">
      <TableUniversal
        name="Timesheet"
        defLimit={15}
        classNames={"big"}
        data="timesheet-table"
        dictionary={dictionary}
        orderBy="id"
        groupBy={[
          "startWork",
          (t) => new Date(t).toLocaleDateString(),
          (v) => new Date(v).toLocaleDateString(),
        ]}
        orderDesc="DESC"
        searchPattern={{
          id: "",
          cardname: "",
          customer_name: "",
          action_type: {
            value: "",
            exact: true,
            element: "select",
            options: {
              wszystkie: "",
              tłumaczenie: 1,
              sprawdzanie: 3,
              przerwa: 14,
            },
          },
        }}
      />
    </div>
  );
}
