import { useGeneric } from "../../../useGeneric";
import { xml2json } from "xml-js";

export default function DownloadGUS({ nip }) {
  const fetchData = useGeneric({ name: "gus-nip", query: { nip: nip } });
  const data = !fetchData.loading ? fetchData.rows : undefined;
  const xml = data && JSON.parse(data).d;

  const json = xml && xml2json(xml);

  const dataAll = json && JSON.parse(json);
  const nipData = dataAll && dataAll?.elements?.[0]?.elements?.[0]?.elements;
  console.log(dataAll?.elements?.[0]?.elements?.[0]?.elements);
  return (
    <div style={{textAlign:"center"}}>
        <label style={{fontSize:"10px", color:"grey"}}>NIP</label>
      <div>{nip}</div>
      <div>
        {nipData ? (
          <div style={{textAlign:"center"}}>
            {nipData.filter(e => e.name !== "RegonLink").map((e) => (
              <div>
                <label style={{fontSize:"10px", color:"grey"}}>{e.name}</label>
                <div>{e.elements?.[0]?.text}</div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
