import { CSSProperties, useEffect, useState } from "react";
import { useGeneric } from "../../../../useGeneric";
import SearchInput from "./SearchInput";
import { SearchBoxContext } from "./SearchBoxContext";

export default function SearchBox({
  db,
  species,
  selectedItem,
  setSelectedItem,
  queryDefaults,
  queryDefaultOrder,
  updateOnChanged,
  resultComponent,
  selectedComponent,
  inputStyle,
  resultWindowStyle,
  updates,
  formData,
  searchBy = "searchString",
  genericSearch = true,
  defaultValue
}: {
  db: string;
  species: string;
  selectedItem: any;
  setSelectedItem: any;
  queryDefaults?: any; //searchString: "",  language: language,
  queryDefaultOrder?: string[]; // like: ["finish_date", "ASC"]
  updateOnChanged?: number;
  updates?: number;
  resultComponent: React.FC;
  selectedComponent: React.FC;
  inputStyle?: CSSProperties;
  resultWindowStyle?: CSSProperties;
  formData?: any;
  searchBy: string;
  genericSearch: Boolean;
  defaultValue?: any;
}) {
  
  // stores user text input
  const [inputValue, setInputValue] = useState("");

  // stores number of updates
  const [update, setUpdate] = useState(0);
  //console.log(queryDefaults);

  // generic query to the database using the 'db' prop
  const query: { loading: boolean; rows?: any[] | undefined } = useGeneric({
    name: db,
    limit: 20,
    offset: 0,
    query: inputValue
      ? genericSearch
        ? {
            ...queryDefaults,
            search: {
              ...queryDefaults?.search,
              [searchBy]: {
                value: inputValue,
                exact: true,
              },
            },
          }
        : {
            ...queryDefaults,
            [searchBy]: inputValue,
          }
      : {
          default: true,
          ...queryDefaults,
          orderBy: queryDefaultOrder && queryDefaultOrder[0],
          orderDesc: queryDefaultOrder && queryDefaultOrder[1],
        },
    update: (updateOnChanged ? updateOnChanged : 0) + update,
  });

  const queryResults = query && !query.loading ? query.rows : undefined;

  useEffect(() => {
    const timeout = window.setTimeout(() => setUpdate((prev) => prev + 1), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [inputValue]);

  /*
  useEffect(() => {
    setSelectedItem({});
  }, [updates])
*/
  return (
    <SearchBoxContext.Provider
      value={{
        species: species,
        selectedItem: selectedItem,
        setSelectedItem: setSelectedItem,
        resultComponent: resultComponent,
        selectedComponent: selectedComponent,
        inputStyle: inputStyle,
        resultWindowStyle: resultWindowStyle
      }}
    >
      <div
        className={"search-box" + (species ? " " + species + "-species" : "")}
      >
        <SearchInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          queryResults={queryResults}
        />
      </div>
    </SearchBoxContext.Provider>
  );
}
