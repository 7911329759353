import { useContext } from "react";
import AppContext from "../contexts/app-context";

function Settings() {
  const { accountID, setAccountID, background, setBackground, darkMode } =
    useContext(AppContext);

  return (
    <div className="settings">
      <form>
        {darkMode ? (
          <></>
        ) : (
          <div className="row">
            <label htmlFor="background">Wybierz tło aplikacji</label>
            <select
              value={background}
              onChange={(e) => {
                setBackground(e.target.value);
                localStorage.setItem("background", e.target.value);
              }}
              name="background"
            >
              <option value={0}>Domyślne</option>
              <option value={1}>Zimowy las</option>
              <option value={2}>Jesień</option>
              <option value={3}>Zielona łąka</option>
              <option value={4}>Mak wśród zbóż</option>
              <option value={5}>Mokra szyba</option>
              <option value={6}>Z lotu ptaka</option>
              <option value={7}>Niebo</option>
              <option value={8}>Kropelki</option>
              <option value={9}>Trawa</option>
              <option value={10}>Liść</option>
              <option value={11}>Obłoki</option>
              <option value={12}>Stado mew</option>
              <option value={13}>Mlecz</option>
              <option value={14}>Stawy Echo</option>
              <option value={15}>Jabłoń</option>
              <option value={16}>Biała cegła</option>
              <option value={17}>Fałdy materiału</option>
              <option value={18}>Niebo o wschodzie</option>
            </select>
          </div>
        )}
        <div className="row">
          <label htmlFor="link-google">
            <p className="mainDescription">Nr konta Google w adresie URL</p>
          </label>
          <select
            value={accountID.id1}
            onChange={(e) => {
              setAccountID(prev => ({ ...prev, id1: e.target.value }));
              localStorage.setItem("googleAccountID", e.target.value);
            }}
            name="link-google"
          >
            <option value={false}>domyślny</option>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>
        </div>
        <div className="additionalDescription">
          <p>
            Zmień to ustawienie, jeśli w systemie nie działają linki do
            wyszukiwania na dysku Google lub poczty.
          </p>
          <p>
            W innej karcie Twojej przeglądarki otwórz Dysk Google lub pocztę na
            koncie tłumaczeń. Odczytaj adres strony wyświetlony w polu na górze
            okna przeglądarki, np.
            <li>
              https://drive.google.com/drive/u/<strong>0</strong>/... (DYSK)
            </li>
            albo
            <li>
              https://mail.google.com/mail/u/<strong>0</strong>/... (POCZTA)
            </li>
          </p>
          <p>
            Sprawdź cyfrę wyświetloną po /u/ np. /u/<strong>0</strong>/, /u/
            <strong>1</strong>/ itp.
          </p>
          <p>Wskaż ją w polu powyżej.</p>
          <p>Wybór zapisuje się automatycznie, możesz potem zamknąć okno</p>
        </div>
        <div className="row">
          <label htmlFor="link-google2">
            <p className="mainDescription">Nr drugiego konta Google (prawy klik)</p>
          </label>
          <select
            value={accountID.id2}
            onChange={(e) => {
              setAccountID(prev => ({ ...prev, id2: e.target.value }));
              localStorage.setItem("googleAccountID2", e.target.value);
            }}
            name="link-google"
          >
            <option value={false}>domyślny</option>
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </select>
        </div>
      </form>
    </div>
  );
}

export default Settings;
