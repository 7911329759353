import translatorsIcon from "../../../../images/icons/buttons/translators.svg";
import moneyIcon from "../../../../images/icons/buttons/money.svg";
import statsIcon from "../../../../images/icons/buttons/stats.svg";
import pagesIcon from "../../../../images/icons/buttons/sentpages.svg";
import rateIcon from "../../../../images/icons/buttons/rate.svg";
import customersIcon from "../../../../images/icons/buttons/customers.svg";
import ordersIcon from "../../../../images/icons/buttons/orders.svg";
import invoiceIcon from "../../../../images/icons/buttons/invoice.svg";
const customMenuItems = {
  items: [
    {
      text: "Wysłane zlecenia",
      image: pagesIcon,
      link: "../sent",
    },
    {
      text: "Tłumacze",
      image: translatorsIcon,
      link: "../externals",
    },
    {
      text: "Rozliczenia",
      image: moneyIcon,
      link: "../settlements",
    },
    {
      text: "Faktury",
      image: invoiceIcon,
      link: "../purchase-invoices",
    },
    {
      text: "Statystyki",
      image: statsIcon,
      link: "../sent-stats",
    },
    {
      text: "Klienci",
      image: customersIcon,
      link: "../customers",
    },
    {
      text: "Stawki",
      image: rateIcon,
      link: "../rates",
    },

  ],
};
export default customMenuItems;
