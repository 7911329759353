import { Link } from "react-router-dom";
import modify from "../../../modify";
import CheckButton from "../CheckButton";
import moneyIcon from "../../../../../images/icons/buttons/money.svg";
import prettyNumber from "../../../functions/prettyNumber";
import NumberPurchaseInvoice from "../../../common/NumberPurchaseInvoice";
import ExcelButton from "./ExcelButton";

export default function SettlementGroupControls({ v, p, setUpdate, search }) {
  return (
    <div
      style={{
        fontWeight: "200",
        marginTop: "-5px",
        textTransform: "uppercase",
      }}
    >
      {v !== 0 ? (
        <div className="group-heading-with-options">
          <div
            style={{
              fontSize: "12px",
              width: "90%",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <NumberPurchaseInvoice
                search={{
                  source_doc_name: p.document_number,
                }}
                clickable={p.document_number ? true : false}
                content={
                  <>
                    Rozliczenie
                    <span
                      style={{
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        //width: "35px",
                        padding: "0 6px",
                        height: "25px",
                        marginLeft: "1px",
                        //  boxShadow: "2px 2px 3px rgba(0,0,0,.3)",

                        borderRadius: "5px",
                        fontSize: "13px",
                      }}
                    >
                      {v} / {p.settlement_year}
                    </span>
                  </>
                }
              />
              <div
                className="sets"
                style={{
                  color: p.paid ? "green" : "brown", //"rgb(40,40,40)",
                  fontSize: "10px",
                  textAlign: "center",
                  fontWeight: "100",
                  background: "ghostwhite",
                  lineHeight: "11px",
                  padding: "1px 9px",
                  textAlign: "center",
                  borderRadius: "12px",
                }}
              >
                <div>
                  <a
                    href="https://system.agit.com.pl/web/factures,new,operation-purchase.html"
                    target="_blank"
                  >
                    {p.document_type_string}
                  </a>
                </div>{" "}
                <div>
                  {p.document_number ? " nr " + p.document_number : <></>}
                </div>
              </div>
              <div className="sets">
                <CheckButton
                  stateTexts={["niezapłacone", "zapłacone"]}
                  icon={moneyIcon}
                  activeControl={p.paid}
                  actions={[
                    () =>
                      modify(
                        "mod-settlement-simple",
                        { paid: true },
                        p.settlement_id
                      ),
                    () =>
                      modify(
                        "mod-settlement-simple",
                        { paid: false },
                        p.settlement_id
                      ),
                  ]}
                />
              </div>
            </div>
            <div className="settlement_comment">{p.settlement_comment}</div>
            <div
              style={{
                padding: "2px 6px",
                background: "white",
                fontWeight: "100",
                borderRadius: "10px",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                marginRight: "6px",
              }}
            >
              {p.document_amount ? prettyNumber(Math.round(Number(p.document_amount) * 100) / 100) : prettyNumber(Math.round(Number(p.total_net) * 100) / 100)} zł{" "}
              <span
                style={{
                  display: "inline-block",
                  fontWeight: "100",
                  marginLeft: "5px",
                  fontSize: "10px",
                  color: "grey",
                }}
              >
                ({p?.entries?.split(",").length})
              </span>
            </div>
          </div>
          <div className="heading-options">
            <ExcelButton data={p} />
            <Link to={`../settlements/edit/${p.settlement_id}`}>
              <div>✎</div>
            </Link>
            <div
              onClick={async () => {
                const confirmation = window.confirm(
                  "Czy na pewno chcesz usunąć rozliczenie?\r\nWszystkie zawarte w nim zlecenia zostaną oznaczone jako nierozliczone."
                );
                if (confirmation) {
                  await modify(
                    "del-externals-settlements",
                    null,
                    p.settlement_id
                  );
                  await modify(
                    "del-externals-settlements-individual",
                    { entries: p.entries },
                    p.settlement_id
                  );
                  setUpdate((prev) => prev + 1);
                }
              }}
            >
              ✖
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "90%",
            fontSize: "13px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontSize: "13px",
              width: "100%",
              letterSpacing: "1px",
              display: "grid",
              gridTemplateColumns: "1fr 90px 150px",
              gridGap: "18px",
              alignItems:"center"
            }}
          >
            <div>Nierozliczone</div>
          
          {search.translator_id.value === p.translator_id ? (
            <>
                <ExcelButton className="stand-alone" caption={"xls"} data={p} unsettled={true} />
            <div title={p.translator_name}>
              SUMA:{" "}
              <span style={{ fontSize: "14px",textAlign:"center" }}>
                {prettyNumber(p.sum_similar)}
              </span>{" "}
              zł
            </div>
            
            </>
          ) : (
            <></>
          )}

</div>
        </div>
      )}
    </div>
  );
}
