import { compareDay, isToday } from "../../functions";

export default function beautyDate(dateString, par) {
  const params = { time: true, todayString: "dziś ", ...par };
  const date = new Date(dateString);
  const now = new Date();
  const thisYear = now.getFullYear();
  const todayString = params.todayString;
  const yesterdayString = "wczoraj ";
  const tomorrowString = "jutro ";
  const dateOnly = false;

  const time = !dateOnly
    ? date.toLocaleTimeString("pl-PL", {
        hour: "numeric",
        minute: "numeric",
      })
    : "";


  return isToday(date) ? (
    params?.multiRow ? (
      <>
        <div>{todayString}</div>
        {params.time ? <div>{time}</div> : <></>}
      </>
    ) : (
      <>
        {todayString}{params.time ? <> {time}</> : <></>}
      </>
    )
  ) : compareDay(date, 1) ? (
    params?.multiRow ? (
      <>
        <div>{tomorrowString}</div>
        {params.time ? <div>{time}</div> : <></>}
      </>
    ) : (
      <>
        {tomorrowString}{params.time ? <> {time}</> : <></>}
      </>
    )
  ) : (
    date
      .toLocaleString("pl-PL", {
        day: "numeric",
        weekday: "long",
        month: time ? "short" : "long",
        hour: time ? "numeric" : undefined,
        minute: time ? "numeric" : undefined,
        year: date.getFullYear() !== thisYear ? "numeric" : undefined,
      })
      .split(params?.multiRow ? ", " : undefined)
      .map((d) => <div>{d}</div>)
  );
}
