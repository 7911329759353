import { useContext } from "react";
import { TableContext } from "../TableUniversal";

export default function BoxFrame({ key, data, heading }) {
  const { dictionary, setSearch, setUpdate } = useContext(TableContext);

  return (
    <div className="table-box">
      {/*style={data?.style}*/}
      <div className="table-box-heading">{heading}</div>
      <div>{data.jsx ? data.jsx : <>-</>}</div>
    </div>
  );
}
