export default function ExecutiveDailyTable({ data, translators }) {
  return (
    <div>
      <div className="executive-reports">
        <table className="pretty-table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th colSpan={9} className="main">
                Statystyki zleceń w biurze
              </th>
            </tr>
            <tr>
              <th rowSpan={2} className="category">
                Język
              </th>
              <th colSpan={2} className="category">
                Przyjęte do realizacji
              </th>
              <th rowSpan={2} className="category">
                Wydane klientom
              </th>
              <th rowSpan={2} className="category">
                Liczba tłumaczy
              </th>
              <th colSpan={1} className="category">
                Strony w tym dniu
              </th>
              <th colSpan={1} className="category">
                Pozostało
              </th>
            </tr>
            <tr>
              <th>Zlecenia</th>
              <th>Strony</th>
              <th>Przetłum.</th>
              <th>Do tłum.</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>angielski</td>
              <td>{data.english.receivedOrders}</td>
              <td>{data.english.receivedPages}</td>
              <td>{data.english.givenOrders}</td>
              <td>{translators.english}</td>
              <td>{data.english.translatedPages}</td>

              <td>
                {data.english.pagesToTranslateEditor ? (
                  <div title={data.english.pagesToTranslate}>
                    {data.english.pagesToTranslateEditor}
                  </div>
                ) : (
                  <div>
                    {data.english.pagesToTranslate > 0
                      ? data.english.pagesToTranslate
                      : 0}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>niemiecki</td>
              <td>{data.german.receivedOrders}</td>
              <td>{data.german.receivedPages}</td>
              <td>{data.german.givenOrders}</td>
              <td>{translators.german}</td>
              <td>{data.german.translatedPages}</td>

              <td>
                {data.german.pagesToTranslateEditor ? (
                  <div title={data.german.pagesToTranslate}>
                    {data.german.pagesToTranslateEditor}
                  </div>
                ) : (
                  <div>
                    {data.german.pagesToTranslate > 0
                      ? data.german.pagesToTranslate
                      : 0}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>inne</td>
              <td>{data.other.receivedOrders}</td>
              <td>{data.other.receivedPages}</td>
              <td>{data.other.givenOrders}</td>
              <td>-</td>
              <td>-</td>

              <td>-</td>
            </tr>
            <tr>
              <td>wszystkie</td>
              <td>{data.all.receivedOrders}</td>
              <td>{data.all.receivedPages}</td>
              <td>{data.all.givenOrders}</td>
              <td>{translators.all}</td>
              <td>{data.all.translatedPages}</td>
              <td>
                {(data.english.pagesToTranslate > 0
                  ? data.english.pagesToTranslate
                  : 0) +
                  (data.german.pagesToTranslate
                    ? data.german.pagesToTranslate
                    : 0)}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="pretty-table">
          <thead>
            <tr>
              <th colSpan={2} className="main">
                Przyjęte zlecenia
              </th>
            </tr>
            <tr>
              <th>Rodzaj klienta</th>
              <th>Liczba</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>przychodzący</td>{" "}
              <td>
                <div title={"-"}>{data.all.customerTypes.person}</div>
              </td>
            </tr>
            <tr>
              <td>instytucje</td>
              <td>
                <div title={"-"}>{data.all.customerTypes.institutions}</div>
              </td>
            </tr>
            <tr>
              <td>maile</td>{" "}
              <td>
                <div title={"="}>{data.all.customerTypes.mail}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
