import { useGeneric } from "../../useGeneric";
import { minutesToTimeString } from "../WorkTimeView";

function objToArr(obj, levels) {
  let arr = [];
  let level = 1;
  if (level <= levels) {
    for (const prop in obj) {
      let data = obj[prop];
      data = objToArr(obj[prop], levels - 1);
      arr.push({ value: prop, data: data });
    }
  } else {
    arr = obj;
  }
  return arr;
}

export default function DailyReport({date, timescale}) {
  const loadReport = useGeneric({
    name: "TSreportsDay",
    query: { date: date, timescale: timescale },
    update: date + timescale,
  });

  //const report = !loadReport.loading ? loadReport.rows : undefined;
  const rep = !loadReport.loading ? loadReport.segregated : undefined;

  const report = objToArr(rep, 4);
  console.log(report);
  const reportByLang = [
    { value: "angielski", data: report.find((i) => i.value === "Ang")?.data },
    { value: "niemiecki", data: report.find((i) => i.value === "Niem")?.data },
    { value: "inne", data: report.find((i) => i.value === "")?.data },
  ];

  console.log(reportByLang);
  //report.sort((a,b) => )

  return (
    <div className="dailyReport">
      {reportByLang.map((r) => (
        <div className="langBox" style={r.style ? r.style : null}>
          <h1>{r.value}</h1>
          <div>
            {r?.data?.map((action) => {
              const flat = action.data
                .flat()
                .map((unit) => unit.data)
                .flat()
                .map((f) => f.data)
                .flat();
              const counts = flat.map((i) => i.sum_count);
              const unit = flat.length ? flat[0].unit : "";

              //const counts =u && u.length ? u.map(i => i.data) : [];
              //console.log(t);
              const sum =
                counts && counts.length ? (
                  counts.reduce(
                    (a, b) =>
                      (parseInt(a) ? parseInt(a) : 0) +
                      (parseInt(b) ? parseInt(b) : 0)
                  )
                ) : (
                  <></>
                );
              return (
                <div className="actionBox">
                  <div className="actionBoxSum">
                    <div
                      className={
                        "actionName" + (r.value === "inne" ? " other" : "")
                      }
                    >
                      {action.value}
                    </div>
                    <div className="valuePair">
                      {Number(sum) ? (
                        <>
                          <span className="value">{Number(sum)}</span>{" "}
                          <span className="unit">{unit}</span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                  </div>

                  {action?.data
                    .filter(
                      (item) => item.data.length === 1 && item.value !== "-"
                    )
                    .map((text) => (
                      <div className="textBox">
                        <div className="textName">{text.value}</div>
                        <div>
                          {text.data && text.data.length ? (
                            text.data.map((unit) => (
                              <div className="valuePair">
                                {Number(unit.data[0].sum_count) ? (
                                  <>
                                    <span className="value">
                                      {parseFloat(unit.data[0].sum_count)}
                                    </span>{" "}
                                    <span className="unit">{unit.value}</span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
