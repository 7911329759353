import { useState } from "react";
import beautyDate from "../../../tables/functions/beautyDate";
import DocumentsTable from "./DocumentsTable";
import GoogleDriveLink from "../../../orders/GoogleDriveLink";
import prettyNumber from "../../../tables/functions/prettyNumber";
import clipBoard from "../../../../images/icons/buttons/clipboard.svg";
import pencilIcon from "../../../../images/icons/buttons/pencil.svg";
import { Link } from "react-router-dom";
import flags from "../../../flags";
import { languagesList } from "../../../global";

export function percentToFactor(percent) {
  return 1 + percent / 100;
}

export default function OrderViewInner({ order }) {
  const documents = JSON.parse(order.documents);
  const [pagesEdited, setPagesEdited] = useState();
  const sumPagesEdited =
    pagesEdited &&
    Object.keys(pagesEdited)
      .map((key) => Number(pagesEdited[key]))
      .reduce((a, b) => a + b, 0);
  const netValues = {};
  const grossValues = {};
  let netValueSum = 0;
  let grossValueSum = 0;

  if (pagesEdited) {
    Object.keys(pagesEdited).forEach((key) => {
      const specialIncreases =
        percentToFactor(
          Number(documents.find((d) => d.id === Number(key))?.special)
        ) *
        percentToFactor(
          Number(documents.find((d) => d.id === Number(key))?.expert)
        ) *
        (1 -
          Number(documents.find((d) => d.id === Number(key))?.discount) / 100);
      const rate = Number(documents.find((d) => d.id === Number(key))?.rate);
      const rateGross =
        Math.round(
          Number(documents.find((d) => d.id === Number(key))?.rate) *
            percentToFactor(order.tax_rate) *
            100
        ) / 100;
      console.log(rate, rateGross);
      netValues[key] =
        Math.round(Number(pagesEdited[key]) * specialIncreases * rate * 100) /
        100;
      if (netValues[key]) {
        grossValues[key] =
          Math.round(
            Number(pagesEdited[key]) * specialIncreases * rateGross * 100
          ) / 100;
      }
    });
    netValueSum =
      Math.round(
        Object.keys(netValues)
          .map((key) => netValues[key])
          .reduce((a, b) => a + b, 0) * 100
      ) / 100;

    grossValueSum =
      Math.round(
        Object.keys(grossValues)
          .map((key) => grossValues[key])
          .reduce((a, b) => a + b, 0) * 100
      ) / 100;
  }
  return (
    <div className="order-monitor-single-wrap">
      <div className="order-monitor-single-wrap-frame">
        <div className="order-monitor-single" key={order.id}>
          <div>
            <div className="order-monitor-single-main">
              <div>
                <div className="order-monitor-single-order">
                  {order.number_order.replace(/\//g, ".")}.
                  {order.language_main.toLowerCase()}
                </div>
              </div>
              <div className="order-monitor-due">
                <div className="order-monitor-acceptance">
                  {order.acceptance_methods_name}{" "}
                  {order.dispatch_price ? (
                    <>{"(" + order.dispatch_price + ")"}</>
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ fontSize: "30px" }}>
                  {beautyDate(order.finish_date)}
                </div>
              </div>
            </div>

            <div
              className="order-monitor-customer"
              title={order.customers_name}
            >
              <div className="order-monitor-customer-inner">
                {order.customers_name && order.customers_name.length < 55
                  ? order.customers_name
                  : order.customers_symbol}
              </div>
            </div>
            <div className="order-monitor-remarks-frame">
              <div>
              <div className="order-monitor-remarks">
                {order.remarks_general}
              </div>
              <div className="order-monitor-remarks">
                {order.remarks_translators}
              </div>
              </div>
              <div className="order-monitor-lang">
                <img src={
                  flags[
                    languagesList.find((i) => i.code === order.language_from)
                      ?.flagCode
                  ]
                }/>
                <span>➔</span>
                <img src={
                  flags[
                    languagesList.find((i) => i.code === order.language_to)
                      ?.flagCode
                  ]
                }/>
              </div>
            </div>
          </div>
          <DocumentsTable
            order={order}
            docEditor={{
              pagesEdited: pagesEdited,
              setPagesEdited: setPagesEdited,
              sumPagesEdited: sumPagesEdited,
              netValues: netValues,
              grossValues: grossValues,
              netValueSum: netValueSum,
              grossValueSum: grossValueSum,
            }}
          />
          <div className="order-monitor-sums">
            <div style={{ fontSize: "20px" }}>
              <div>{order.users_name}</div>
              <div className="order-monitor-options">
                <a
                  href={
                    "https://system.agit.com.pl/orders,edit," +
                    order.id +
                    ".html"
                  }
                  target="_blank"
                >
                  <img className="invert" src={pencilIcon} />
                </a>
                <GoogleDriveLink
                  customString={[order.number_order.replace(/\//g, ".")]}
                />
                <img
                  className="invert"
                  onClick={() =>
                    navigator.clipboard?.writeText(
                      order.number_order.replace(/\//g, ".")
                    )
                  }
                  src={clipBoard}
                />
              </div>
            </div>
            <div className="order-monitor-sums-price">
              <div className="order-monitor-sums-net">
                <div>netto</div>
                <div>{prettyNumber(order.price)} zł</div>
              </div>
              <div className="order-monitor-sums-gross">
                <div>brutto</div>
                <div>{prettyNumber(order.price_brutto)} zł</div>
              </div>
            </div>
            {netValueSum ? (
              <>
                <div>{">"}</div>
                <div className="order-monitor-sums-price-calc">
                  <div className="order-monitor-sums-net">
                    <div>netto</div>
                    <div>
                      {prettyNumber(
                        netValueSum
                          ? Math.round(
                              (netValueSum + order.dispatch_price) * 100
                            ) / 100
                          : order.price
                      )}{" "}
                      zł
                    </div>
                  </div>
                  <div className="order-monitor-sums-gross">
                    <div>brutto</div>
                    <div>
                      {prettyNumber(
                        grossValueSum
                          ? Math.round(
                              (grossValueSum +
                                order.dispatch_price *
                                  percentToFactor(order.tax_rate)) *
                                100
                            ) / 100
                          : documents
                              .map((d) => Number(d.price))
                              .reduce((a, b) => a + b, 0)
                      )}{" "}
                      zł
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
