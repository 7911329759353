import React, { useEffect, useState } from "react";
//import OrderContext from "./contexts/order-context";
import { ordersPrepared } from "./functions";
import { URL } from "./global";

const getOrders = (params) => {
  return fetch(URL + "components/db-query-orders-opt.php", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((o) => {
      /* The data fetched from the database is a one-dimensional combination of order and document data
      - all document properties feature the 'doc_' prefix
      The following operations place all those properties inside a document object in the documents array
      */
      const orders = params.type === "search-all" ? o.rows : o;
      let documents = [];
      orders.forEach((item) => {
        let document = {};
        for (const property in item) {
          // if the property_name starts with "doc_"
          if (property.search(/^doc_/) > -1) {
            document[String(property).replace(/^doc_/, "")] = item[property];
            delete item[property];
          }
        }
        documents.push(document);
      });
      // Joins order/document objects into order objects with the 'document' property containing the array of all of that order's documents
      const uniqueOrders = Array.from(new Set(orders.map((a) => a.id)))
        .map((id) => orders.find((a) => a.id === id))
        .map((item) => ({
          documents: documents.filter((doc) => item.id === doc.id_orders),
          ...item,
        }));
      //      console.log(ordersPrepared(uniqueOrders))
      
      // APPLY PERSONAL DONES FROM LOCALSTORAGE
      let dones = localStorage.getItem("personal_done");
      dones = dones ? JSON.parse(dones) : Array();
      // Apply as 'personal_done' property
      const result = ordersPrepared(uniqueOrders).map((item) => {
        if (dones.some((doneItem) => item.id == doneItem.id)) {
          return {
            ...item,
            personal_done: true,
          };
        } else {
          return item;
        }
      });
      return result;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const useOrderDataFull = (params) => {
  if (!params) params = { type: null, language: null, disable: true };
  const [orders, setOrders] = useState({
    data: [],
    dataArchive: [],
    loading: true,
    error: false,
  });

  useEffect(() => {
    if (!params.disable) {
      getOrders(params).then((orders) => {
        if (params.type === "archive") {
          setOrders((prev) => ({
            data: prev.data,
            dataArchive: orders,
            loading: false,
            params: params,
            error: false,
          }));
        } else {
          setOrders((prev) => ({
            data: orders,
            dataArchive: prev.dataArchive,
            loading: false,
            params: params,
            error: false,
          }));
        }
      });
      return () => {
        setOrders((prev) => ({
          data: prev.data,
          dataArchive: prev.dataArchive,
          loading: false,
          params: params,
          error: false,
        }));
      };
    }
  }, [params.type, params.language, params.update]); //params?

  useEffect(() => {
    if (params.repeat) {
      const interval = setInterval(() => {
        getOrders(params).then((orders) => {
          if (params.type === "archive") {
            setOrders((prev) => ({
              data: prev.data,
              dataArchive: orders,
              loading: false,
              params: params,
              error: false,
            }));
          } else {
            setOrders((prev) => ({
              data: orders,
              dataArchive: prev.dataArchive,
              loading: false,
              params: params,
              error: false,
            }));
          }
        });
      }, 60000); // run every minute
      
      return () => {
        clearInterval(interval);
        setOrders((prev) => ({
          data: prev.data,
          dataArchive: prev.dataArchive,
          loading: true,
          error: false,
        }));
      };
    }
  }, [params.repeat, params.type, params.language]);

  return orders;
};
