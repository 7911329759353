import flags from "../../../../../flags";
import { languagesList } from "../../../../../global";

export const order_number = {
  label: "zlecenie",
  type: "search",
  dataSource: {
    name: [
      {
        title: "Pisemne",
        db: "order-table",
      },

      { title: "Ustne", db: "order-table-oral" },
    ],
    params: {
      orderBy: "year, month, number_in_month",
      orderDesc: "DESC, DESC, ASC",
      limit: 30,
    },
    value: "id",
    query: (value) => ({
      order_all: {
        value: value,
        // exactLeft: true
      },
    }),

    setters: (row) => {
      return [
        ["order_id", row.id],
        [
          "order_number",
          row.number.replaceAll("/", ".") + "." + row.language.toLowerCase(),
        ],
        ["language", row.id_language_main],
        ["language_from", row.id_language_from],
        ["language_to", row.id_language_to],
        ["order_customer", row.client],
        [
          "client_paid_rate",
          Math.floor(
            (100 * Number(row.price)) / Number(row.pages_to_translate_count)
          ) / 100,
        ],
      ];
    },
    display: (row) => (
      <div
        className={row.status === 1 ? "active-order" : ""}
        style={{
          display: "grid",
          gridTemplateColumns: "13px .7fr 1fr 30px 50px",
          gridGap: "5px",
          alignItems: "center",
          padding: "6px 9px",
          //background: row.status === 1 ? "yellow" : "unset"
        }}
      >
         <div>
          <img
            src={
              flags?.[
                languagesList.find((i) => i.code == row.language_main)?.flagCode
              ]
            }
            width={13}
          />
          
        </div>
        <div style={{ fontSize: "90%" }}>
          {row.number.replaceAll("/", ".")}.{row.language.toLowerCase()}
        </div>
        <div style={{ fontSize: "85%" }}>{row.client}</div>
        <div style={{ fontSize: "64%", color: "grey" }}>
          {row.pages_to_translate_count}
        </div>
        <div style={{ fontSize: "64%", color: "grey" }}>
          {"▸"}
          {new Date(row.finish_date).toLocaleDateString()}
        </div>
      </div>
    ),
    text: (row) =>
      row.number.replaceAll("/", ".") +
      row.language.toLowerCase() +
      " " +
      row.client,
  },
};
