//export const URL = process.env.NODE_ENV === 'production' ? 'https://sys2.tlumacz.tv/' : 'http://46.232.128.36/agitsys/';
export const URL = process.env.NODE_ENV === 'production' ? 'https://system2.tlumacz.tv/' : 'http://46.232.128.36/agitsys/';
export const URLdev = 'http://46.232.128.36/agitsys/';

export const URLdevS = 'https://data.agit.com.pl/agitsys/';

export const defaultEmailAccount = "tlumacze@tlumacz.tv";

export const languagesList = [
  { code: "Ang", name: "angielski", flagCode:"gb"},
  { code: "Pol", name: "polski", flagCode:"pl"},
  { code: "Niem", name: "niemiecki", flagCode: "de"},
  { code: "Wło", name: "włoski", flagCode: "it" },
  { code: "Franc", name: "francuski", flagCode:"fr" },
  { code: "Hiszp", name: "hiszpański", flagCode:"es" },
  { code: "Port", name: "portugalski", flagCode: "pt" },
  { code: "Ukra", name: "ukraiński", flagCode: "ua"},
  { code: "Ros", name: "rosyjski" , flagCode: "ru"},
  { code: "Biał", name: "białoruski", flagCode: "by" },
  { code: "Słowac", name: "słowacki", flagCode: "sk" },
  { code: "Czes", name: "czeski", flagCode: "cz" },
  { code: "Niderl", name: "niderlandzki", flagCode: "nl" },
  { code: "Norw", name: "norweski", flagCode: "no" },
  { code: "Arab", name: "arabski", flagCode: "sa" },
  { code: "Alba", name: "albański", flagCode: "al" },
  { code: "Bośn", name: "bośniacki", flagCode: "bih"},
  { code: "Bułg", name: "bułgarski", flagCode: "bg" },
  { code: "Rumu", name: "rumuński", flagCode: "ro" },
  { code: "Lit", name: "litewski", flagCode: "lt" },
  { code: "Łote", name: "łotewski", flagCode: "lv" },
  { code: "Chiński", name: "chiński", flagCode: "cn" },
  { code: "Kor", name: "koreański", flagCode: "ko" },
  { code: "Chorw", name: "chorwacki", flagCode: "hr" },
  { code: "Czecz", name: "czeczeński", flagCode: "chchnya" },
  { code: "Duń", name: "duński", flagCode: "dk" },
  { code: "Esto", name: "estoński", flagCode: "ee" },
  { code: "Fińs", name: "fiński", flagCode:"fi" },
  { code: "Grec", name: "grecki", flagCode: "gr" },
  { code: "Gruz", name: "gruziński", flagCode: "ge" },
  { code: "Hebr", name: "hebrajski", flagCode:"isr"},
  { code: "Islan", name: "islandzki", flagCode: "is" },
  { code: "Jap", name: "japoński", flagCode: "jp" },
  { code: "Jidysz", name: "jidysz", flagCode: "yd" },
  { code: "Maced", name: "macedoński", flagCode: "mk" },
  { code: "Mołd", name: "mołdawski", flagCode: "md" },
  { code: "Pers", name: "perski", flagCode: "ir" },
  { code: "Serb", name: "serbski", flagCode:"srb" },
  { code: "Słoweń", name: "słoweński", flagCode:"si" },
  { code: "Szwe", name: "szwedzki", flagCode: "se" },
  { code: "Tur", name: "turecki", flagCode:"tr" },
  { code: "Węg", name: "węgierski", flagCode: "hu" },
  { code: "Łac", name: "łaciński", flagCode: "question" },
  { code: "Mong", name: "mongolski", flagCode: "mngla"},
  { code: "kataloński", name: "kataloński", flagCode: "cat"},
  { code: "Indo", name: "indonezyjski", flagCode: "indonesian" },
  { code: "Hindi", name: "hindi", flagCode: "hindi" },
];

export const publicHolidays = [
  { month: 1, day: 1, description: "Nowy Rok" },
  { month: 1, day: 6, description: "Święto Trzech Króli" },
  { month: 5, day: 1, description: "Święto Pracy" },
  { month: 5, day: 3, description: "Święto Narodowe Trzeciego Maja" },
  { month: 8, day: 15, description: "Wniebowzięcie Najświętszej Marii Panny" },
  { month: 11, day: 1, description: "Wszystkich Świętych" },
  { month: 11, day: 11, description: "Święto Niepodległości" },
  { month: 12, day: 25, description: "1. dzień Świąt Bożego Narodzenia" },
  { month: 12, day: 26, description: "2. dzień Świąt Bożego Narodzenia" },
];


export const correctDocumentTypeName = (name) => {
  switch (name) {
    case "DVLA":
      return "dowód rejestracyjny";
    case "lek. zaśw.":
      return "zaświadczenie lekarskie";
    case "akt ur zup.":
      return "akt urodzenia zupełny";
    case "akt ur skr.":
      return "akt urodzenia skrócony";
    case "akt ur. ziel.":
      return "akt urodzenia";
    case "akt ur. czerw.":
      return "akt urodzenia";
    case "akt zgonu skr.":
      return "akt zgonu skrócony";
    case "św. bierzmowania":
      return "świadectwo bierzmowania";
    case "św. Komunii":
    case "św. szkolne":
    case "św. podstaw.":
    case "św. techn.":
    case "św. zawod.":
    case "św. gimnaz.":
      return "świadectwo";
    case "św. matura.":
      return "świadectwo dojrzałości";
    case "zaśw. ze szkoły/uczelni":
    case "US zaśw":
    case "ZUS zaśw":
      return "zaświadczenie";
    case "karta ze szpitala":
      return "karta informacyjna";
    case "zaśw. o niekaralności":
      return "zaświadczenie o niekaralności";
    case "akt małż zup.":
      return "akt małżeństwa zupełny";
    case "akt małż skr.":
      return "akt małżeństwa skrócony";
    case "zameld. zaśw.":
      return "zaświadczenie o zameldowaniu";
    case "świad. pracy":
      return "świadectwo pracy";
    case "niepełnosprawność":
      return "orzeczenie o stopniu niepełnosprawności";
    case "P60":
      return "roczne zaświadczenie o zarobkach P60";
    case "skier.  do szpitala":
    case "skier.  do lekarza":
      return "skierowanie";
    case "zwol.  ZUS":
      return "zwolnienie";
    case "KRS":
      return "Informacja odpowiadająca odpisowi aktualnemu z rejestru przedsiębiorców";
    case " pośw.  notarialne":
      return "poświadczenie notarialne";
    case "akt not.":
      return "akt notarialny";
    case "rozwód - wyrok ":
      return "wyrok rozwodowy";
    case "zakres obow.":
      return "zakres obowiązków";
    case "prawo wyk zawodu":
      return "prawo wykonywania zawodu";
    case "dowód osob.":
      return "dowód osobisty";
    case "strona intern.":
      return "strona internetowa";
    case "rozmowa tel.":
      return "rozmowa telefoniczna";
    case "cert. zgodności":
    case "cert zgodności sam.":
      return "certyfikat zgodności";
    case "inst. obsł.":
      return "instrukcja obsługi";
    case "um. handl.":
      return "umowa";
    case "um. współpr.":
      return "umowa o współpracy";
    case "um. najmu.":
      return "umowa najmu";
    case "um. dzierż.":
      return "umowa dzierżawy";
    case "um. inna.":
      return "umowa";
    case "aneks do um.":
      return "aneks do umowy";
    case "dok. techn.":
      return "";
    case "cert. złomow.":
      return "certyfikat złomowania";
    case "faktura sam.":
      return "faktura";
    case "dow. rej.":
    case "dow. rej. nieb.":
    case "dow. rej. ziel.":
      return "dowód rejestracyjny";
    case "karta przeb. stud.":
      return "karta przebiegu studiów";
    case "zaśw. o ukończ. kursu":
      return "zaświadczenie o ukończeniu kursu";
    case "cert of salvage":
      return "tytuł własności pojazdu z odzysku";
    case "cert. of perm. export":
      return "zaświadczenie wywozu na stałe";
    case "ref. ze szkoły":
      return "referencje";
    case "praktyki":
      return "zaświadczenie o odbyciu praktyki"
    default:
      return name;
  }
}