import React, { useState, useEffect } from "react";
import { URL } from "./global";

export const usePAP = (params, update) => {
  const [pap, setPAP] = useState({ data: [], loaded: false });

  const getPAP = (params) => {
    return fetch(URL + "components/db-order-listing.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => {
        console.log("Błąd pobierania: " + error);
        return error;
      });
  };

  useEffect(() => {
    setPAP({ data: [], loaded: false });
    getPAP(params).then((pap) => {
      setPAP({
        data: pap.filter((i) => i.remarks_top_secret !== "-"),
        loaded: true,
      });
    });
  }, [update]);

  return pap;
};
