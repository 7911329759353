import React, {useContext} from "react";
import driveIcon from "../../images/drive.png";
import OrderContext from "../contexts/order-context";
import OrderRow from "./OrderRow";
import Documents from "../Documents";
import GoogleDriveLink from "./GoogleDriveLink";
import OrdersOtherLink from "./OrdersOtherLink";
import { plDate } from "../functions";
//import {useDocuments} from '../useDocuments'

function FullOrderItem(props) {
  /*
  order={item["valid_order"]}
          client={item["customers_name"]}
          key={item["id"]}
          id={item["id"]}
          from={item["language_from"]}
          to={item["language_to"]}
          finishBy={item["finish_date"]}
          remarks={item["remarks_translators"]}
          acceptedDate={item["create_date"]}
          receiveMethods={item["receive_methods"]}
          employee={item["users_name"]}
          pages={item["pages_to_translate_count"]}
          grossPrice={item["price_brutto"]}
          status={item["status"]}
          active={item["active"]}
          documents={item["documents"]}
          rawData={item}
  */
  const context = useContext(OrderContext);
  const { view } = context;
  const {
    valid_order: order,
    customers_name: client,
    id: id,
    language_from: from,
    language_to: to,
    finish_date: finishBy,
    remarks_translators: remarksTranslators,
    remarks_general: remarksGeneral,
    create_date: acceptedDate,
    receive_methods: receiveMethods,
    users_name: employee,
    pages_to_translate_count: pages,
    price_brutto: grossPrice,
    status: statusNo,
    active: active,
    documents: documents,
    orderNoLang: orderNoLang
  } = props.data;
  let orderForSearch = order.split(".");
  orderForSearch.pop();
  orderForSearch = orderForSearch.join(".");
  //console.log(props)
  //const docData = useDocuments(id)
  const docData = documents;
  let plAcceptedDate = plDate(acceptedDate).slice(0, -3);
  let plFinishBy = plDate(finishBy).slice(0,-3);

  //console.log(docData)
  const defaultEmailAccount = "tlumacze@tlumacz.tv";

  function status() {
    switch (statusNo) {
      case "0":
        return <>Anulowane</>;
        break;
      case "1":
        return <>Do zrobienia</>;
      case "2":
        return <>Gotowe</>;
      case "3":
        return <>Archiwalne</>;
      case "4":
        return <>Wstrzymane</>;
      case "5":
        return <>???</>;
      default:
        return <>?</>;
    }
  }

  return (
    <div
      className="fullOrderItem"
      //style={{ display: hidden ? "none" : "block" }}
    >
      <div className="orderRow oOrderItem">
        <div title={JSON.stringify(props)}>Zlecenie</div>
        <div className="bigger">
          {order}
          <GoogleDriveLink orders={[props.data]}></GoogleDriveLink>
          {/* <OrdersOtherLink item={props.data} view={view} context={context} /> */}
        </div>
      </div>
      <OrderRow tag="Klient" content={client} className="oClient" />
      <OrderRow
        tag="Język z > na"
        content={from.toLowerCase() + " ➤ " + to.toLowerCase()}
        className="oLanguages"
      />
      <OrderRow
        tag="Data przyjęcia"
        content={plAcceptedDate}
        className="oAcceptDate"
      />
      <OrderRow
        tag="Termin realizacji"
        content={plFinishBy}
        className="oFinishDate"
      />
      <OrderRow
        tag="Liczba stron z wyceny"
        content={pages}
        className="oPages"
      />
      <OrderRow
        tag="Cena brutto"
        content={grossPrice + " zł"}
        className="oPrice"
      />
      {(!remarksTranslators && !remarksGeneral) ||
      (remarksGeneral === "Kary umowne za spóźnienia i błędy. " &&
        !remarksTranslators) ? (
        <></>
      ) : (
        <OrderRow
          tag="Uwagi dla tłumaczy"
          content={
            <>
              <div className="remarksTranslators">{remarksTranslators}</div>
              <div className="remarksGeneral">{remarksGeneral}</div>
            </>
          }
          contentClass="remarks"
          className="oRemarks"
        />
      )}
      <OrderRow
        tag="Odbiór"
        content={receiveMethods}
        className="oReceiveMethods"
      />
      <OrderRow tag="Przyjęte przez" content={employee} className="oEmployee" />
      <OrderRow tag="Status" content={status()} className="oStatus" />
      <Documents tag="Dokumenty" order={order} content={docData} className="oDocuments" />
    </div>
  );
}
/*
0 - anulowane
1 - niegotowe
2 - gotowe
3 - archiwalne
4 - wstrzymane
5 - ???*/

export default FullOrderItem;
