import flags from "../../../flags";
import { languagesList } from "../../../global";

export default function languageDisplay(v, fullName, style, reverse) {
  return (
    <div className={"language-display" + (reverse ? " reverse" : "")} style={style ? style : undefined}>
      <img
        className="flag"
        src={flags[languagesList.find((i) => i.code === v?.["name"])?.flagCode]}
        
      />{" "}
      <div>{fullName ? fullName : v?.["full_name"]}</div>
    </div>
  );
}
