import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Fields from "./Fields";
import { FieldsType } from "./interfaces";
import { createContext, useContext, useEffect, useState } from "react";
import { ModalContentContext } from "../ModalContent";
import { useAsyncError } from "react-router-dom";

export const MyFormContext = createContext<{
  fieldValueObjects: any;
  setFieldValueObjects: Object;
  defaultValues: any;
  disabledFields: string[];
  edit: Boolean;
  duplicate: Boolean;
  calculate: any[];
}>({
  fieldValueObjects: undefined,
  setFieldValueObjects: {},
  defaultValues: undefined,
  disabledFields: [],
  edit: false,
  duplicate: false,
  calculate: [],
});

export default function Form({
  fieldData,
  submitData,
  defaultFieldValues,
  disabledFields,
  edit,
  duplicate,
  error,
  setModal,
  calculate,
}: {
  fieldData: FieldsType | FieldsType[];
  submitData: Function;
  defaultFieldValues: any;
  disabledFields: string[];
  edit: Boolean;
  duplicate: Boolean;
  error?: string;
  setModal?: any;
  calculate?: any;
}) {
  const modalContext = useContext(ModalContentContext);

  const contentData = modalContext.contentData;

  const multipleGroups = Array.isArray(fieldData);

  const [errorMessage, setErrorMessage] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const defaultValues: any = {};
  if (defaultFieldValues) {
    Object.keys(defaultFieldValues).forEach((k: any) => {
      defaultValues[k] = defaultFieldValues[k];
    });
  }

  const [fieldValueObjects, setFieldValueObjects] = useState();

  const methods = useForm({
    defaultValues: defaultValues,
  });
  const { register, handleSubmit, setValue, getValues, watch } = methods;

  const submitForward = async (data: any, event: any) => {
    console.log(data, event);
    setErrorMessage("");
    setSubmitting(true);
    const result = await submitData(event, data);
    setSubmitting(false);
    if (result) {
      setErrorMessage((prev: string) =>
        result.error_message ? result.error_message : ""
      );
    }
  };
  const watchCalc = calculate && calculate?.[0] && watch(calculate[0]);

  // useEffect(() => {
  //   if (calculate && calculate[0] && calculate[1]) {
  //     const calc = calculate[1](watch());
  //     console.log(calc);
  //     Object.keys(calc).forEach((key: any) => {
  //       console.log(key, calc[key]);
  //       setValue(key, calc[key]);
  //     });
  //   }
  // }, [calculate && JSON.stringify(watch(calculate[0]))]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log("name", name);
      console.log(value, name, type);
      console.log(watchCalc, type, calculate);
      if (
        // type === "change" &&
        calculate &&
        calculate[0] &&
        calculate[0]?.includes(name) &&
        calculate[1]
      ) {
        const calc: any = calculate[1](getValues(), watchCalc);
        // console.log(calc);
        Object.keys(calc).forEach((key: any) => {
          console.log(key, calc[key]);
          setValue(key, calc[key]);
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watchCalc]);

  //console.log(fieldData);
  return fieldData ? (
    <FormProvider {...methods}>
      <MyFormContext.Provider
        value={{
          calculate: calculate,
          fieldValueObjects: fieldValueObjects,
          setFieldValueObjects: setFieldValueObjects,
          disabledFields: disabledFields,
          defaultValues: defaultValues,
          edit: edit,
          duplicate: duplicate,
        }}
      >
        <form
          className="universal-forms"
          onSubmit={
            handleSubmit(submitForward)
            //  () => console.log(getValues())))
          }
        >
          <div className="uf-field-sets">
            {multipleGroups ? (
              fieldData.map((fields) => <Fields fields={fields} />)
            ) : (
              <Fields fields={fieldData} />
            )}
          </div>
          <div
            style={{
              padding: "4px 8px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "11px",
                background: errorMessage ? "#cecece" : "",
                borderRadius: "15px",
                padding: "1px 12px",
                marginRight: "10px",
                //color: "white",
                fontStyle: "italic",
                color: errorMessage ? "brown" : "black",
                boxShadow: errorMessage ? "0 0 0px 0.5px" : "none",
              }}
            >
              {errorMessage ? (
                <>
                  <span>{errorMessage}</span>
                  <span
                    style={{
                      display: "inline-block",
                      cursor: "pointer",
                      padding: "3px",
                    }}
                    onClick={() => {
                      setErrorMessage("");
                    }}
                  >
                    ✕
                  </span>
                </>
              ) : submitting ? (
                <div>...</div>
              ) : (
                <></>
              )}
            </div>
            {submitting ? (
              <></>
            ) : (
              <button type="submit">
                {edit ? <>Aktualizuj</> : <>Dodaj wpis</>}
              </button>
            )}
          </div>
        </form>
      </MyFormContext.Provider>
    </FormProvider>
  ) : (
    <>brak danych</>
  );
}
