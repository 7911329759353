import React, { useState, useEffect } from "react";
import { URL } from "./global";

function probeOrders() {
  return fetch(URL + "components/db-system-query.php", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ type: "probe" }),
  }).then((response) => response.json());
}

function getOrders(queryType, params, limit) {
  return fetch(URL + "components/db-system-query.php", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ type: queryType, parameters: params, limit: limit }),
  }).then((response) => response.json());
}

function useOrderMulti(queryType, params, limit, refreshID) {
  const [orders, setOrders] = useState([]);
  const [lastID, setLastID] = useState(null);

  useEffect(() => {
    setOrders([]);
    getOrders(queryType, params, limit).then((result) => {
      setOrders(result);
    });
  }, [queryType, refreshID, lastID]);

  useEffect(() => {
    const repeat = window.setInterval(() => {
      probeOrders()
        .then((probe) => probe[0].id)
        .then((res) => {
          if (res != lastID) {
            setLastID(res);
          }
        });
    }, 10000);
    return () => clearInterval(repeat);
  }, []);

  return orders;
}

export default useOrderMulti;
