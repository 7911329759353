import React from 'react';
import { Link } from 'react-router-dom';
import backIcon from "../images/icons8-back-arrow-64.png";

function BackButton(props) {
    const { link } = props;
    return (
        <Link to={link}>
        <button className="back">
          <img src={backIcon} />
        </button>
      </Link>
    )
}

export default BackButton;