import { isSameDate } from "../../../functions";
import beautyDate from "../../../tables/functions/beautyDate";
import PercentDone from "./PercentDone";

export default function OrderListItem({
  order,
  documents,
  ind,
  all,
  selectedID,
  setSelectedID,
}) {
  const allDone = documents
    ?.filter((d) => d.name != "dodatkowy egzemplarz")
    ?.map((d) => (d.chars ? true : false))
    ?.reduce((a, b) => a & b, true);

  return (
    <>
      {!isSameDate(
        all[ind > 0 ? ind - 1 : 0].finish_date,
        order.finish_date
      ) ? (
        <div className="monitor-list-separate">
          {beautyDate(order.finish_date, { time: false })}
        </div>
      ) : (
        <></>
      )}
      <div
        key={order.id}
        onClick={() => setSelectedID(order.id)}
        className={
          "order-monitor-list-single" +
          (selectedID === order.id ? " selected" : "") +
          (allDone ? " allDone" : "") +
          (order.status == "2" ? " ready" : "")
        }
      >
        <div>
          <div>
            {order.number_order.replace(/\//g, ".")}.
            {order.language_main.toLowerCase()}
          </div>

          <div className="order-monitor-list-customer">
            {order.customers_name && order.customers_name.length < 35
              ? order.customers_name
              : order.customers_symbol.toUpperCase()}
          </div>
        </div>
        <div className="order-monitor-list-due">
          {beautyDate(order.finish_date, { multiRow: true })}
        </div>
        <PercentDone documents={documents} order={order} simple={true}/>
      </div>
    </>
  );
}
