import React from "react";

function Checkbox(props) {
  const { label, changeFunction, def } = props;
  return (
    <label className="switchWrap" style={{ opacity: def ? "1" : "0.6"}}>
      {label}
      <input type="checkbox" defaultChecked={def} onChange={changeFunction} />
      <span className="switch"></span>
    </label>
  );
}

export default Checkbox;
