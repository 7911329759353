import { useState } from "react";
import { useGeneric } from "../../../../useGeneric";
import HTMLReactParser from "html-react-parser";
import infoIcon from "../../../../../images/icons/buttons/info.svg";
import { Link } from "react-router-dom";

export default function CustomerDetails({ customer }) {
  const [view, setView] = useState(false);
  const fetchData = useGeneric({
    name: "customer-details",
    query: {
      limit: 1,
      offset: 0,
      search: {
        customer_id_db: {
          value: customer.customers_id,
          exact: true,
        },
      },
    },
    update: customer.customers_id,
  });

  const data =
    !fetchData.loading && fetchData.rows && fetchData.rows.length
      ? fetchData.rows[0]
      : undefined;
  const parse = HTMLReactParser;

  return data ? (
    <div className="sumField customer-details-head">
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => setView((prev) => !prev)}
      >
        {data.symbol ? data.symbol.toUpperCase() : customer.cutomers_name}
        <img src={infoIcon} width={20} />
      </div>
      {view ? (
        <div className="customer-details-wrap">
          <div
            onClick={() => setView((prev) => !prev)}
            className="close-button"
          >
            ✖
          </div>
          <div className="customer-details">
            <h3 style={{ marginTop: "0" }}>{customer.customers_name}</h3>
            {data.rates ? <div>{parse(data.rates)}</div> : <></>}
            {data.description ? <div>{parse(data.description)}</div> : <></>}
            {data.invoice ? <div>{parse(data.invoice)}</div> : <></>}
            <div>
              Aktualizacja:{" "}
              <span style={{ color: "brown" }}>{data.last_update}</span>
            </div>
            <div style={{ marginTop: "6px" }}>
              {!data.rates && !data.description && !data.invoice ? (
                <Link to={`../customer-details-table/create/${data.id}`}>
                  {" "}
                  <button>Dodaj informacje</button>
                </Link>
              ) : (
                <Link to={`../customer-details-table/edit/${data.id}`}>
                  {" "}
                  <button>Edytuj informacje</button>
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
