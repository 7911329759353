export const propMap = {
    "id": "",
"history": "czy wpis historyczny",
"modified": "czy wpis zmodyfikowany",
"number": "nr repertorium",
"date_added": "data dodania wpisu/modyfikacji",
"order_id": "id zlecenia z systemu w tabeli agit1_orders",
"order_number": "nr zlecenia w notacji kropkowej",
"date": "data dokonania wpisu",
"client": "nazwa osoby zlecającej",
"document_name": "nazwa dokumentu",
"document_date": "data dokumentu",
"document_reference": "oznaczenie dokumentu",
"document_reference_person_concerned": "czy oznaczenie w polu document_reference zmieniono na 'dot. imię i nazwisko'",
"source_language": "język dokumentu źródłowego",
"drawn_up_by": "osoba/podmiot, który sporządził dokument",
"form": "rodzaj dokumentu (oryginał, kopia, wydruk,elektroniczny,skan)",
"action_type": "rodzaj wykonanej czynności",
"copy_of_number": "dodatkowy egzemplarz dokumentu z podanym numerem repertorium",
"pages": "liczba stron tłumaczenia",
"copies": "liczba egzemplarzy tłumaczenia",
"document_return": "data zwrotu dokumentu - jeśli NULL lub błędna data: pobierane z tabeli agit1_orders",
"amount_received": "pobrane wynagrodzenie brutto",
"oral_translation": "czy tłumaczenie ustne",
"rejection": "",
"user_id": "id użytkownika dokonującego wpisu/modyfikacji",
"user_name": "",
"document_condition": "stan dokumentu",
"year": "rok wpisu",
"month": "miesiąc wpisu",
"gross_per_page": "kwota brutto za stronę",
};