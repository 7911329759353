import TableUniversal from "../../TableUniversal";
import customMenuItems from "../newExternals/customMenuItems";
import { propMap } from "./Dictionaries/CustomersPropMap";

export default function CustomersTable() {
  const dictionary = {
    id: {
      name: "id",
      order: "id",
    },
    name: {
      name: "Nazwa",
      order: "name",
      style: {
        width:"250px"
      }
    },
    symbol:{ 
      name: "Nazwa skrócona",
      style: {
        display:"none"
      }
    },
    email: {
      name: "E-mail",
    },
    phone: {
      name: "Telefon",
    },
    town: {
      name: "Miasto",
      style: {
        width:"120px"
      }
    },
    street: {
      name: "Ulica",
    },
    private_firm_name: {
      name: "Typ",
    },
    date_added: {
      name:"Dodano",
      transform: (v) => new Date(v).toLocaleString()
    }
  };
  return (
    <div className="fullScreen">
    <TableUniversal
      name="Kartoteka klientów"
      data="customers-table"
      classNames="big"
      searchPattern={{
        "name": "",
        "symbol":"",
        "town": "",
        "private_firm_name": {
          value: "",
          element: "select",
          exact:true,
          options: {
            "wszystkie": "",
            "osoba pryw.": "osoba prywatna",
            "firma": "firma"
          }
        }
      }}
      dictionary={dictionary}
      orderBy="id"
      orderDesc="DESC"
      propMap={propMap}
      customMenu={customMenuItems}
    />
    </div>
  );
}
