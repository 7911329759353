import React, { useState, useRef } from "react";
import homeOfficeIcon from "../../images/icons/home.svg";
import homeOfficeIconFilled from "../../images/icons/home_semi_filled.svg";

function DayButton(props) {
  //const weekend = (props.weekend === 0 || props.weekend === 6 || props.weekend === true) ? true : false;
  const {
    dayClass,
    day,
    index,
    active,
    person,
    setChange,
    off,
    groupIndex,
    type,
    prevType,
    nextType,
    prevDayType,
    nextDayType,
    dayOffTypes,
    setContextParams,
    setHoliday,
    db,
    approved,
    canControl,
    actualRemoteWork,
  } = props;

  const box = useRef(null);
  const thisType = dayOffTypes.find((t) => t.code === parseInt(type));

  return (
    <>
      <div
        className={
          dayClass +
          " dateButton" +
          (active ? " active" : "") +
          (active && thisType ? " " + thisType.className : "") +
          (db === "admin" && approved ? " ironclad" : "")
        }
        key={index}
        ref={box}
        title={day + " " + (active && thisType ? thisType.fullName : "")}
        // title={JSON.stringify(actualRemoteWork)}
        // title={day}
        data-day={day}
        onClick={() => {
          if (!canControl) return false;
          if (!off) {
            if (!active) {
              setHoliday("add", {
                person: person.id,
                employeeID: person.employeeID,
                day: day,
              });
              setChange((prev) => (prev = prev + 1));
            } else if (db !== "admin" && !approved) {
              setHoliday("remove", { person: person.id, day: day });
              setChange((prev) => (prev = prev + 1));
            }
          }
        }}
        onContextMenu={(e) => {
          if (!canControl) return false;
          if (!off) {
            const top =
              e.target.getBoundingClientRect().top +
              (e.target.getBoundingClientRect().height - 5);
            e.preventDefault();
            setContextParams(() => ({
              person: person.id,
              day: day,
              left:
                e.target.getBoundingClientRect().left +
                e.target.getBoundingClientRect().width / 2,
              top: window.innerHeight - top < 301 ? 300 : top,
              visible: true,
              group: false,
              new: active ? false : true,
              formattedDate:
                new Date(day).toLocaleDateString() +
                ", " +
                new Date(day).toLocaleDateString("pl-PL", {
                  weekday: "long",
                }),
            }));
          }
        }}
      >
        {actualRemoteWork ||
        (type === 11 && prevDayType === 11 && nextDayType === 11) ? (
          <img
            src={actualRemoteWork ? homeOfficeIconFilled : homeOfficeIcon}
            style={{
              width: "95%",
              maxWidth: actualRemoteWork && type !== 11 ? "30px" : "30px",
              opacity: actualRemoteWork && type !== 11 ? "1" : ".6",
              animationName:
                actualRemoteWork && type !== 11
                  ? "appear"
                  : actualRemoteWork
                  ? "none"
                  : "jump",
              animationDuration: actualRemoteWork && type !== 11 ? ".7s" : "2s",
              animationTimingFunction:
                actualRemoteWork && type !== 11 ? "ease-in-out" : "linear",
              animationIterationCount:
                actualRemoteWork && type !== 11 ? "1" : "infinite",
              filter:
                actualRemoteWork && type !== 11
                  ? "contrast(.01) brightness(1.62)"
                  : actualRemoteWork
                  ? "contrast(.5) brightness(1.6) sepia(2) saturate(2) hue-rotate(120deg)"
                  : "contrast(.5) sepia(2) saturate(5) hue-rotate(120deg)",
            }}
          />
        ) : type === 11 ? (
          <img
            src={homeOfficeIcon}
            style={{
              width: "95%",
              maxWidth: "32px",
              opacity: ".64",
              animationName: "jump",
              animationDuration: "2s",
              animationTimingFunction: "linear",
              animationIterationCount: "infinite",
              filter: "contrast(.5) sepia(2) saturate(5) hue-rotate(120deg)",
            }}
          />
        ) : // <span
        //   style={{
        //     fontSize: "8px",
        //     color: "rgb(90,90,90)",
        //     padding:"3px 0",
        //     fontWeight:"bold",
        //     boxShadow: "0 0 3px rgba(0,0,0,.41)",
        //   }}
        // >
        //   ZDALNE
        // </span>
        active ? (
          props.dayNumber
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default DayButton;
