import React, { useState, useEffect } from "react";
import "./App.scss";
import MainEditor from "./components/main/MainEditor";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Login";
import { URL, URLdev, URLdevS } from "./components/global";
import Register from "./components/main/Register";
import Attendance from "./components/main/Attendance";
import WorkTime from "./components/main/WorkTime";
import OrderQueue from "./components/main/OrderQueue/OrderQueue";
import { useGeneric } from "./components/useGeneric";
import Loading from "./components/main/Loading";

function App() {
  const [loggedIn, setLoggedIn] = useState(0);
  const [user, setUser] = useState({ name: false, id: false });
  const navigate = useNavigate();
  const location = useLocation();

  async function logOut() {
    const logout = await fetch(URL + "components/logout.php", {
      credentials: "include",
    });
    const response = await logout.text();
    if (response) {
      setLoggedIn(false);
      setUser(false);
      navigate("/login");
    }
  }

  async function checkLogin() {
    const response = await fetch(URL + "components/checkLogin.php", {
      credentials: "include",
    });
    const result = await response.json();
    if (result.accept) {
      setLoggedIn(result.accept);
      setUser({
        name: result.user,
        id: result.userID,
        type: result.type,
        employeeID: result.employeeID,
        fullname: result.fullname,
      });
    } else if (location.pathname === "/register") {
    } else {
      navigate("/login");
    }
  }

  async function fix() {
    const response = await fetch(
      URLdevS + "components/set/kill-problem-process.php",
      {
        credentials: "include",
        type: "POST",
      }
    );
    const result = await response.json();
    // window.alert(result[""])
    console.log(result);
    // window.location.reload();
  }

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Login
            className="Login"
            loggedIn={loggedIn}
            user={user.name}
            setLoggedIn={setLoggedIn}
            setUser={setUser}
            logOut={logOut}
          />
        }
      ></Route>
      <Route path="register/*" element={<Register />} />
      <Route
        path="/*"
        element={
          loggedIn ? (
            <div className="App">
              <MainEditor loggedIn={loggedIn} user={user} logOut={logOut} />
            </div>
          ) : (
            <div
              style={{
                width: "100vw",
                height: "100dvh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "radial-gradient(circle, rgb(253, 253, 253) 0%, rgb(171, 171, 171) 100%)",
              }}
            >
              <div>
                <Loading />
                <div className="appear-after-delay">
                  <div>Błąd dostępu do bazy danych</div>
                  <div onClick={fix}>
                    <button
                      className="problem-fix-button"
                    >
                      Spróbuj naprawić problem
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      ></Route>
      <Route path="orders-now/*" element={<OrderQueue />} />
    </Routes>
  );
}

export default App;
