import React from "react";
import SwornPages from "../tools/SwornPages";
import { Link } from "react-router-dom";

function SideMenu(props) {
  const { orders, setModal } = props;
  return (
    <div className="sideMenu">
      <Link to="/..">
        <button>Powrót</button>
      </Link>
      {/*<Link to="/../timesheet">
        <button>Timesheet</button>
      </Link>*/}
      <Link to="/../holidays">
        <button>Urlopy</button>
      </Link>
      <button
        onClick={() => {
          setModal({
            show: true,
            type: `Liczba potrzebnych kartek`,
            content: <SwornPages data={orders}></SwornPages>,
          });
        }}
      >
        Kartki
      </button>
    </div>
  );
}

export default SideMenu;
