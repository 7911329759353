import React from "react";

function Details(props) {
  const { order, addToTimesheet } = props;

  return (
    <div className="details">
      {order}
      <div
        className="acceptOrder"
        onClick={() => {
          addToTimesheet();
        }}
      >
        Rozpocznij zlecenie
      </div>
    </div>
  );
}

export default Details;
