import React, { useState, useEffect } from "react";
import { useOrderDataFull } from "../useOrderDataFull";
import OrderCardTableRow from "./OrderCardTableRow";
import GoogleDriveLink from "./GoogleDriveLink";
import moreIcon from "../../images/icons/icons8-view-more-100.png";

function OrdersOtherLink(props) {
  const { item, view, context } = props;
  const [customerOrderParams, setCustomerOrdersParams] = useState(null);
  // client orders
  const customerOrders = useOrderDataFull(customerOrderParams);

  // SHOW OTHER ORDERS FROM CUSTOMER
  useEffect(() => {
    if (customerOrders.loading) return;
    console.log("Pobrano", customerOrders, context);
    context.setModal((prev) => ({
      ...prev,
      content: (
        <div style={{ padding: "15px" }}>
          {/*customerOrders.data.length ? (
            <div className="flex driveMulti" style={{ fontSize: "14px" }}>
              <div>Wyszukaj łącznie trzy ostatnie zlecenia:</div>
              <GoogleDriveLink orders={customerOrders.data}></GoogleDriveLink>
            </div>
          ) : (
            ""
          )*/}
          {customerOrders?.data?.length
            ? customerOrders.data
                .reverse()
                .map((item, index) => (
                  <OrderCardTableRow
                    data={item}
                    view={view}
                    archive={"customer"}
                  ></OrderCardTableRow>
                ))
            : "brak"}
        </div>
      ),
      width: "full",
    }));
  }, [customerOrderParams, customerOrders.loading]);

  return (
    <div
      title="Inne zlecenia dla tego klienta"
      onClick={() => {
        //const shortCustomer = item.customers_name.length > 39 ? item.customers_name.slice(0,39) + "(...)" : item.customers_name;
        setCustomerOrdersParams(
          {
            type: "search-all",
            search: {
              language_main: {
                db: "o",
                value: item.language_main,
                standard: true,
                exact: true,
              },
              language_to: {
                db: "o",
                value: item.language_to,
                exact: true,
                standard: true
              },
              customers_name: {
                db: "o",
                standard: true,
                value: item.customers_name,
                exact: true,
              },
            },
          } /*{
          count: 100,
          type: "both",
          language: item.language_main,
          language_to: item.language_to,
          repeat: false,
          client: item.customers_name,
        }*/
        );
        context.setModal((prev) => ({
          ...prev,
          show: !prev.show,
          content: <div style={{ padding: "15px" }}>Pobieranie...</div>,
          type: `${item.customers_name} | Inne zlecenia > ${item.language_to}`,
          width: "wide",
        }));
      }}
    >
      <img src={moreIcon} alt="findOtherOrders"></img>
    </div>
  );
}

export default OrdersOtherLink;
