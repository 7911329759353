import { useGeneric } from "../../../useGeneric";
import { useSearchParams } from "react-router-dom";
import Loading from "../../../main/Loading";
import prettyDate from "../../functions/prettyDate";
import prettyNumber from "../../functions/prettyNumber";
import invoiceImage from "../../../../images/icons/buttons/invoice.svg";
import languageDisplay from "../displays/languageDisplay";
import GoogleDriveLink from "../../../orders/GoogleDriveLink";
import NumberOrder from "../../common/NumberOrder";
import { useState } from "react";

export default function InvoiceView({ id, number, inModal }) {
  const [query, setQuery] = useSearchParams();
  const invoiceID = query.get("id") ? query.get("id") : id;

  const [detailView, setDetailView] = useState("orders");

  const useData = useGeneric({
    name: invoiceID ? "invoice-view" : undefined,
    query: {
      search: {
        id: {
          value: invoiceID,
          exact: true,
          standard: true,
          db: "f",
        },
      },
    },
  });

  const invoice = !useData.loading ? useData.rows?.[0] : undefined;

  const items =
    invoice && invoice.items
      ? JSON.parse("[" + invoice.items + "]")
      : undefined;

  const orders =
    invoice && invoice.orders
      ? JSON.parse("[" + invoice.orders + "]")
      : undefined;

  return invoice ? (
    <div className={"invoice-view-new" + (inModal ? "" : " fullScreen")}>
      <div className="order-main">
        <div>
          <div className="invoice-number">
            <div>{invoice.number_facture}</div>
          </div>
          <div className="customer-name">{invoice.customers_name} </div>
          <div className="price-data">
            <div>
              <div>NETTO</div> <div>{prettyNumber(invoice.price)} zł</div>
            </div>
            <div>
              <div>BRUTTO</div>{" "}
              <div>{prettyNumber(invoice.price_brutto)} zł</div>
            </div>
          </div>
        </div>
        <div>
          <div className="minor-label">Utworzona</div>
          {invoice.users_name}, {prettyDate(invoice.create_date, "", true)}
        </div>

        <div>
          <div style={{ marginBottom: "15px" }}>
            <div className="minor-label">Status</div>
            {invoice.status === "payed" ? (
              <div style={{ color: "green" }}>✓ ZAPŁACONA</div>
            ) : (
              <div style={{ color: "brown" }}>✖ NIEZAPŁACONA</div>
            )}
          </div>
          <div className="minor-label">
            {invoice.payment_methods_payed_date !== "0000-00-00 00:00:00" ? (
              <>Data zapłaty</>
            ) : (
              <>Termin płatności</>
            )}
          </div>
          {invoice.payment_methods_payed_date !== "0000-00-00 00:00:00"
            ? prettyDate(invoice.payment_methods_payed_date, "", true)
            : prettyDate(invoice.payment_maturity_date, "", true)}
        </div>
      </div>
      <div className="buttons-small" style={{ display: "flex", padding: "0" }}>
       
        <button
          className={"button" + (detailView === "orders" ? " active" : "")}
          onClick={() => setDetailView("orders")}
        >
          Zlecenia
        </button>
        <button
          className={"button" + (detailView === "items" ? " active" : "")}
          onClick={() => setDetailView("items")}
        >
          Pozycje
        </button>
      </div>
      {items && detailView === "items" ? (
        <div className="invoice-items">
          <table className="universal-table big">
            <thead>
              <tr>
                <th>Lp.</th>
                <th>Nazwa</th>
                <th>Rodzaj</th>
                <th>Stawka</th>
                <th>Ilość</th>
                <th>Rabat</th>
                <th>Cena netto</th>
                <th>Cena brutto</th>
                <th>Oryginał</th>
                <th>Specjalist.</th>
                <th>Inne</th>
              </tr>
            </thead>
            <tbody>
              {items.map((i, ind) => (
                <tr key={i}>
                  <td>{ind + 1}</td>
                  <td>{i["name"]}</td>
                  <td>{i.kind}</td>
                  <td>{i.rate}</td>
                  <td>
                    {i.quantity} {i.unit}
                  </td>

                  <td>{i.discount}</td>
                  <td>{i.price}</td>
                  <td>{i.price_brutto}</td>

                  <td>{i.original}</td>
                  <td>{i.expert}</td>
                  <td>{i.special}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      {orders && detailView === "orders" ? (
        <div className="order-documents">
          <table className="universal-table big">
            <thead>
              <tr>
                <th>Nr zlecenia</th>
                <th>Z</th>
                <th>Na</th>
                <th>Termin</th>
                <th>Przyjęte przez</th>
                <th>Strony</th>
                <th>Cena netto</th>
                <th>Cena brutto</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orders).map((key) => {
                const i = orders[key];
                return (
                  <tr>
                    <td>
                      {
                        <NumberOrder
                          id={i.id}
                          number_order={i.number}
                          withLang={true}
                          lang={i.language}
                        />
                      }
                    </td>
                    <td style={{ width: "50px" }}>
                      {languageDisplay({ name: i.from }, i.from)}
                    </td>
                    <td style={{ width: "50px" }}>
                      {languageDisplay({ name: i.to }, i.to)}
                    </td>
                    <td>{prettyDate(i.dl)}</td>
                    <td>{i.usr}</td>
                    <td>{i.pages}</td>
                    <td>{prettyNumber(i.price)} zł</td>
                    <td>{prettyNumber(i.price_brutto)} zł</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : useData.loading ? (
    <Loading />
  ) : (
    <>Brak danych</>
  );
}
