import React, { useState, useEffect } from "react";
import { usePAP } from "../usePAP";
import { isToday } from "../functions";
import ListingTable from "./ListingTable";
import ListingExcelExport from "./ListingExcelExport";
import Loading from "./Loading";

function Listing(props) {
  const now = new Date();
  const [updates, setUpdates] = useState(0);
  const [checked, setChecked] = useState({});
  const [displayPrevious, setDisplayPrevious] = useState(false);
  const backDays = -3;
  const [params, setParams] = useState(() => {
    const d = new Date();
    const d1 = new Date();
    d1.setHours(15);
    d1.setDate(backDays);
    const d2 = new Date();
    d2.setHours(15);
    d2.setMonth(now.getMonth() + 1);
    d2.setDate(1);

    return {
      month: d.getMonth() + 1,
      startDate: d1.toISOString().slice(0, 10),
      endDate: d2.toISOString().slice(0, 10),
      year: d.getFullYear(),
      customer: "Polska Agencja Prasowa S.A.",
      symbol: "pap",
    };
  });

  const dataObj = usePAP(params, updates);
  const data = dataObj.data;
  const dataLoaded = dataObj.loaded;

  const sumWords = data
    .map((item) =>
      checked[item.id]
        ? parseInt(item.remarks_top_secret)
          ? parseInt(item.remarks_top_secret)
          : 0
        : 0
    )
    .reduce((a, b) => a + b, 0);

  const sumPages =
    Math.ceil(
      data
        .map((item) =>
          checked[item.id]
            ? parseFloat(item.price) && parseFloat(item.rate)
              ? parseFloat(item.price) / parseFloat(item.rate)
              : 0
            : 0
        )
        .reduce((a, b) => a + b, 0) * 100
    ) / 100;
  /*const sumPages = data
    .map((item) => parseFloat(item.pages_to_translate_count))
    .reduce((a, b) => a + b, 0);*/

  const sumPrice =
    Math.ceil(
      data
        .map((item) =>
          checked[item.id]
            ? parseFloat(item.price)
              ? parseFloat(item.price)
              : 0
            : 0
        )
        .reduce((a, b) => a + b, 0) * 100
    ) / 100;

  const sumPriceGross =
    Math.ceil(
      data
        .map((item) =>
          checked[item.id]
            ? parseFloat(item.price_brutto)
              ? parseFloat(item.price_brutto)
              : 0
            : 0
        )
        .reduce((a, b) => a + b, 0) * 100
    ) / 100;

  function changeTimeFrame(e, timeExp) {
    let date1, date2;
    if (timeExp === "month") {
      date1 = new Date(params.year, e.target.value - 1);
      date2 = new Date(params.year, e.target.value);
    } else if (timeExp === "year") {
      date1 = new Date(e.target.value, params.month - 1);
      date2 = new Date(e.target.value, params.month);
    }
    date1.setHours(15);
    date1.setDate(backDays);

    date2.setHours(15);
    date2.setDate(1);
    setParams((prev) => ({
      ...prev,
      [timeExp]: e.target.value,
      startDate: date1.toISOString().slice(0, 10),
      endDate: date2.toISOString().slice(0, 10),
    }));
  }

  useEffect(() => {
    const reload = window.setInterval(
      () => setUpdates((prev) => prev + 1),
      120000
    );

    return () => window.clearInterval(reload);
  }, []);

  useEffect(() => {
    setUpdates((prev) => prev + 1);
    setChecked({});
  }, [params.month, params.year, params.customer]);

  useEffect(() => {
    setChecked((prev) => {
      const add = {};
      data.forEach((item) => {
        if (new Date(item.create_date).getMonth() !== params.month - 1) {
          /*if (prev[item.id]) {
            delete prev[item.id];
          }*/
        } else {
          add[item.id] = true;
        }
      });
      return { ...prev, ...add };
    });
  }, [updates, params.month, params.year, data]);

  return (
    <div className="listing">
      <div className="listingHead top-bar">
        <div>{params.customer} | Zestawienie zleceń</div>
        <div>
          {new Date(params.year, params.month - 1).toLocaleDateString("pl-PL", {
            month: "long",
            year: "numeric",
          })}
          {": "}
          {
            Object.values(checked).filter((item) => item !== undefined).length
          }{" "}
          zlec. / {String(sumPages).replace(".", ",")} str. /{" "}
          {String(sumPrice).replace(".", ",")} zł
        </div>
        {
          <div>
            {dataLoaded && (
              <ListingExcelExport
                data={data.map((i) =>
                  checked[i.id]
                    ? { ...i, checked: true }
                    : { ...i, checked: false }
                )}
                params={params}
              />
            )}
          </div>
        }
        <form>
          <select
            value={params.customer}
            onChange={(e) =>
              setParams((prev) => ({
                ...prev,
                customer: e.target.value,
                symbol:
                  e.target.value === "Polska Agencja Prasowa S.A."
                    ? "pap"
                    : undefined,
              }))
            }
          >
            <option value="Polska Agencja Prasowa S.A.">PAP</option>
            <option value="Urząd Miasta Szczecin">Szczecin</option>
            <option value="Newseria Sp. z o.o.">Newseria</option>
            <option value="Województwo Lubelskie">Woj. Lubelskie</option>
            <option value="Zakład Ubezpieczeń Społecznych">ZUS</option>
            <option value="Gmina Lublin">Gmina Lublin</option>
            <option value="Kryf Sp. z o.o.">Kryf</option>
            <option value="*">Wszystkie</option>
          </select>
          <select
            value={params.month}
            onChange={(e) => changeTimeFrame(e, "month")}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
          <select
            value={params.year}
            onChange={(e) => changeTimeFrame(e, "year")}
          >
            {[
              2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
              2027,
            ].map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </form>
      </div>
      {data && data.length ? (
        <ListingTable
          data={data}
          checked={checked}
          setChecked={setChecked}
          displayPrevious={displayPrevious}
          setDisplayPrevious={setDisplayPrevious}
          sumPrice={sumPrice}
          sumWords={sumWords}
          sumPages={sumPages}
          sumPriceGross={sumPriceGross}
          isToday={isToday}
          params={params}
        />
      ) : (
        <Loading text="Pobieranie danych..." />
      )}
    </div>
  );
}

export default Listing;
