import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChooseTranslator from "./ChooseTranslator";
import OrderContext from "./contexts/order-context";

function DocumentRow(props) {
  const { item, order, index } = props;
  const context = useContext(OrderContext);

  //const [transMenu, setTransMenu] = useState(false);
  const [characters, setCharacters] = useState();
  const { showContextMenus, setShowContextMenus } = useContext(OrderContext);
  const [translator, setTranslator] = useState(
    item.translators
      ? { "Short Name": item.translators }
      : {
          "Short Name": item.translators_ts?.split(",")
            ? item.translators_ts?.split(",").join(", ")
            : item.translators_ts,
        }
  );
  const navigate = useNavigate();
  function createSwornRegistryEntry() {
    navigate("/repertorium", {
      state: {
        newData: {
          item: item,
          order: order,
        },
      },
    });
  }

  return (
    <tr className="doc" data-id={item.id} key={props.item.id}>
      <td className="docNumber" title={item.id}>
        {index + 1}
      </td>
      <td
        className={"docName" + (showContextMenus === item.id ? " context" : "")}
        onClick={() => {
          setShowContextMenus((prev) => (prev === item.id ? false : item.id));
        }}
        title={order.id + "/" + item.id}
      >
        {showContextMenus === item.id ? (
          <div className="contextMenu">
            <div className="contextOptions">
              <Link
                to="/work-time"
                state={{
                  new: true,
                  language: order.language_main,
                  order_id: order.number_order,
                  document_id: item.id,
                }}
              >
                <div>+ timesheet</div>
              </Link>
              <Link to={`edit-documents/${order.id}`}>
                <div>edytuj dokumenty</div>
              </Link>
            </div>
            <div>x</div>
          </div>
        ) : (
          <></>
        )}
        <div className="docNameWrap">
          {item.name ? item.name : item.document_name}
        </div>
      </td>
      <td
        className="docPages"
        title={
          "Netto: " +
          String(item.rate).replace(".", ",") +
          " zł/str.\r\nBrutto: " +
          String(
            Math.round(
              (parseFloat(item.price_brutto) /
                parseFloat(item.pages_count_translator)) *
                100
            ) / 100
          ).replace(".", ",") +
          " zł/str.\r\nSuma: " +
          item.price_brutto +
          " zł (brutto)"
        }
      >
        {order?.customers_name === "Polska Agencja Prasowa S.A." || order?.customers_name === "Polska Agencja Prasowa S.A. w likwidacji" &&
        order?.remarks_top_secret ? (
          <span style={{ color: "#2a4f50", fontSize: "12px" }}>
            {order.remarks_top_secret}
          </span>
        ) : (
          parseFloat(item.pages_count_translator)
        )}
      </td>
      <td className="docPrice">{item.price_brutto}</td>
      <td className="docRate">{item.rate}</td>
      <td className="docType">
        {item.type_of_translation === "przysięgłe" ? (
          <span
            onClick={createSwornRegistryEntry}
            style={{ cursor: "pointer" }}
            title="Kliknij, aby dodać ten dokument do repertorium"
          >
            uwierz.
          </span>
        ) : (
          String(item.type_of_translation)
            .replace(/zwykłe/, "zw.")
            .replace(/sprawdzenie/, "spr.")
        )}
        {item.special ? (
            <div
              title={"specjalizacja " + item.special + "%"}
              style={{
                display: "inline-block",
                padding: "1px 3px",
                background: "#d7bcb2",
                marginLeft: "3px",
                borderRadius: "3px",
                color: "black",
                fontSize: "8.5px",
                fontFamily: "Arial",
                fontWeight: "100",
              }}
            >
              +{item.special}%
            </div>
          ) : (
            <></>
          )}
          {item.express ? (
            <div
              title={"ekspres " + item.express + "%"}
              style={{
                display: "inline-block",
                padding: "1px 3px",
                background: "#e3b0d3",
                marginLeft: "3px",
                borderRadius: "3px 50% 50% 3px",
                color: "black",
                fontSize: "8.5px",
                fontFamily: "Arial",
                fontWeight: "100",
              }}
            >
              +{item.express}%
            </div>
          ) : (
            <></>
          )}
      </td>
      <td className="docTranslator">
        {/* <button
          onClick={() => {
            context.setModal((prev) => ({
              ...prev,
              show: !prev.show,
              type: "Wybierz tłumacza",
              content: (
                <ChooseTranslator
                  docId={item.id}
                  setTranslator={setTranslator}
                  translatorList={context.translators.filter(
                    (item) => item.Type !== 3
                  )}
                />
              ),
            }));
          }}
          className="selectTranslator"
        >
          {translator["Short Name"]}
        </button>*/}
        <button
          className="selectTranslator"
          onClick={(e) => e.preventDefault()}
          style={
            !translator["Short Name"] && order.ext_translators
              ? { fontSize: "8px", background: "#eaacd9" }
              : {}
          }
        >
          {translator["Short Name"] ? (
            translator["Short Name"]
          ) : order.ext_translators ? (
            <div>{order.ext_translators}</div>
          ) : (
            <></>
          )}
        </button>
      </td>
      {/*<td className="docProofreader">-</td>*/}
      <td className="docCharacters">
        {item.char_count && Number(item.char_count) ? (
          <div
            style={{ fontWeight: "bold", fontSize: "110%", color: "#3b7200" }}
          >
            {parseInt(item.char_count)}
          </div>
        ) : item.total_count && Number(item.total_count) ? (
          parseInt(item.total_count)
        ) : (
          "-"
        )}
        {/* <input
          onChange={(e) => {
            if (
              e.target.value.match(/[a-zA-Z*\/\\*!@#$%^&\(\)_\-\,\.]/g) === null
            ) {
              setCharacters(e.target.value);
            }
          }}
          className="docCharactersEdit"
          maxlength="7"
          value={characters}
        />*/}
      </td>
    </tr>
  );
}

/* <ChooseTranslator
            docId={item.id}
            transMenu={transMenu}
            setTransMenu={setTransMenu}
            setTranslator={setTranslator}
            translatorList={context.translators}
          /> */
export default DocumentRow;
