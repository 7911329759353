import { useContext } from "react";
import { TableContext, removeNumberKeys } from "../TableUniversal";
import Controls from "./Controls";
import MultiControls from "./MultiControls";

export default function Head() {
  const {
    data,
    dataRaw,
    headings,
    selectable,
    orderProp,
    setOrderProp,
    orderDir,
    setOrderDir,
    dictionary,
    setUpdate,
    selected,
    setSelected,
    disableSelect,
  } = useContext(TableContext);

  const overallSelection =
    selected && Object.keys(selected) && Object.keys(selected).length
      ? Object.keys(selected)
          .filter((key) => {
            //console.log(disableSelect[0], disableSelect[1], dataRaw[key][disableSelect[0]]);
            return disableSelect
              ? dataRaw?.[key]?.[disableSelect[0]] !== disableSelect[1]
              : true;
          })
          .map((key) => selected[key])
          ?.reduce((a, b) => a & b, true)
      : false;

  const anySelection =
    selected && Object.keys(selected).length
      ? Object.keys(selected).find((key) => selected[key] === true)
      : false;

  const realProps = Object.assign(
    ...Object.keys(dictionary).map((name) => ({
      [dictionary[name].name]: name,
    }))
  );

  const orderProps = Object.assign(
    ...Object.keys(dictionary).map((name) => ({
      [dictionary[name].name]: dictionary[name]?.order,
    }))
  );

  //console.log(orderProps, realProps);

  return (
    <thead>
      <Controls />
      <MultiControls />
      {headings?.length ? (
        <tr>
          {selectable ? (
            <th style={{ textAlign: "center", minWidth: "37px" }}>
              <input
                type="checkbox"
                checked={
                  selected && Object.keys(selected).length
                    ? Object.keys(selected)
                        .map((key) => selected[key])
                        ?.reduce((a, b) => a & b)
                    : false
                }
                onChange={(e) => {
                  setSelected((prev) => {
                    const newSelected = {};
                    if (selected && Object.keys(selected).length) {
                      data.forEach((item, index) => {
                        if (
                          disableSelect
                            ? dataRaw[index][disableSelect[0]] !==
                              disableSelect[1]
                            : true
                        ) {
                          newSelected[index] = !overallSelection;
                        }
                      });
                    } else {
                      data.forEach((item, index) => {
                        if (
                          disableSelect
                            ? dataRaw[index][disableSelect[0]] !==
                              disableSelect[1]
                            : true
                        ) {
                          newSelected[index] = true;
                        }
                      });
                    }
                    return newSelected;
                  });
                }}
              />
            </th>
          ) : (
            <></>
          )}
          <th style={{ minWidth: "0", padding: "0" }}></th>
          {headings.map((item, index) => (
            <th
              key={index}
              className={orderProp === orderProps[item] ? "active" : ""}
              style={{...dictionary[realProps[item]]?.style, fontSize:"12px"}}
              onClick={() => {
                if (!orderProps[item]) return;
                setOrderProp(orderProps[item]);
                setOrderDir((prev) => {
                  if (orderProp === orderProps[item]) {
                    setOrderDir((prev) => (prev !== "DESC" ? "DESC" : "ASC"));
                  }
                });
                setUpdate((prev) => prev + 1);
              }}
            >
              {item}{" "}
              {dictionary ? (
                orderProp === orderProps[item] && orderDir !== "DESC" ? (
                  <>▴</>
                ) : orderProp === orderProps[item] && orderDir === "DESC" ? (
                  <>▾</>
                ) : (
                  <span style={{ visibility: "hidden" }}>▾</span>
                )
              ) : (
                <></>
              )}
            </th>
          ))}
          <th></th>
        </tr>
      ) : (
        <></>
      )}
    </thead>
  );
}
