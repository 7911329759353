import DataToExcel from "../../../DataToExcel";
import xlsIcon from "../../../../../images/icons/xls.svg";
import { useGeneric } from "../../../../useGeneric";

export default function ExcelButton({
  caption,
  className,
  data,
  unsettled,
  multi,
}) {
  const rowsMap = (i) => {
    const initial = {
      Dodano: { v: new Date(i.time_added), t: "d" },
      "Numer zlecenia": { v: i.order_number, t: "s" },
      Klient: { v: i.order_customer, t: "s" },
      Opis: {
        v:
          i.description + (i.rate_distinctor ? "\r\n" + i.rate_distinctor : ""),
        t: "s",
      },
      Ilość: { v: i.quantity, t: "n" },
      "Jedn.": { v: i.unit_name, t: "n" },
      Stawka: { v: i.rate, t: "n" },
      Netto: { v: i.amount, t: "n" },
      Brutto: {
        v: i.amount_gross && i.amount_gross !== "0" ? i.amount_gross : i.amount,
        t: "n",
      },
    };
    let add = {};
    if (multi) {
      add = { Tłumacz: { v: i.translator_name, t: "s" }, ...rowsMap };
    }
    return { ...add, ...initial };
  };

  const sumLetter = multi ? "I" : "H";
  const sumLetter2 = multi ? "J" : "I";

  const colStyles = [
    { width: 20 },
    { width: 12 },
    { width: 20 },
    { width: 30 },
    { width: 30 },
    { width: 6 },
    { width: 6 },
    { width: 8 },
    { width: 8 },
    { width: 8 },
  ];
  if (!multi) {
    colStyles.shift();
  }

  return (
    <DataToExcel
      name="sent-orders-table"
      rowsMap={(i) => rowsMap(i)}
      workBook={multi ? "Różni tłumacze" : data?.[0]?.translator_name}
      pushRows={(rows) => ({
        Netto: {
          t: "n",
          f: `=SUM(${sumLetter}2:${sumLetter}` + (rows.length + 1) + ")",
        },
        Brutto: {
          t: "n",
          f: `=SUM(${sumLetter2}2:${sumLetter2}` + (rows.length + 1) + ")",
        },
      })}
      colStyles={colStyles}
      button={
        <div className={"excelButton" + (className ? " " + className : "")}>
          <div>{caption}</div>
          <img src={xlsIcon} />
        </div>
      } //"🖶"
      orderBy="id"
      orderDir="desc"
      search={{
        ...(unsettled
          ? {
              settled_id: {
                value: unsettled ? "0" : data.settlement_id,
                exact: true,
              },
            }
          : {
              settlement_id: {
                value: unsettled ? "0" : data.settlement_id,
                exact: true,
              },
            }),
        active: { value: 1, exact: true },
        ...(!multi
          ? {
              translator_id: {
                value: data.translator_id,
                exact: true,
              },
            }
          : undefined),
      }}
      filename={
        unsettled
          ? "AGIT " +
            (multi ? "" : data.translator_name + " ") +
            "nierozliczone " +
            new Date().toLocaleString()
          : "AGIT_" +
            data.translator_name +
            "_rozliczenie_nr_" +
            data.settled_id +
            "_" +
            new Date().toLocaleString()
      }
    />
  );
}
