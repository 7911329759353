import React, { useState } from "react";
import { useTranslators } from "../useTranslators";
import { URL } from "../global";
import { Link } from "react-router-dom";

function Register() {
  const getTranslators = useTranslators();
  const translators = !getTranslators.loading  ? getTranslators.active.filter(
    (person) => person.Type === 0 || person.Type === 3 || person.Type === 4
  ) : [];
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    user: "",
    password: "",
    id: 0,
    confirmPassword: "",
  });
  function handleChange(name, value) {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function register(e) {
    e.preventDefault();
    if (formData.user.length < 6) {
      setMessage({ text: "Nazwa użytkownika jest za krótka.", success: false });
      return;
    }
    if (formData.password.length < 8) {
      setMessage({
        text: "Hasło musi mieć co najmniej 8 znaków.",
        success: false,
      });
      return;
    } else if (formData.password.search(/[A-Z]/) === -1) {
      setMessage({
        text: "Hasło musi zawierać wielkie litery.",
        success: false,
      });
      return;
    } else if (formData.password.search(/[0-9]/) === -1) {
      setMessage({ text: "Hasło musi zawierać cyfry.", success: false });
      return;
    } else if (formData.password.search(/[!@#$%^&*(),.?":{}|<>]/) === -1) {
      setMessage({
        text: "Hasło musi zawierać znaki specjalne.",
        success: false,
      });
      return;
    } else if (formData.password !== formData.confirmPassword) {
      setMessage({ text: "Hasła nie są takie same.", success: false });
      return;
    }

    fetch(URL + "/components/registerDB.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        user: formData.user,
        password: formData.password,
        id: parseInt(formData.id),
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result[0] === "23000") {
          setMessage({
            text: "Nazwa użytkownika jest już zajęta lub istnieje użytkownik przypisany do tej osoby.",
            success: false,
          });
        } else if (result[0] === "00000") {
          setMessage({
            text: `Utworzono użytkownika ${formData.user}`,
            success: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <div className="body-wrap">
      <div className="register">
        <h1>Nowy użytkownik</h1>
        {!message.success ? (
          <form>
            <div>
              <div>
                <label for="id">Imię i nazwisko</label>
                <div className="micro-info">Wybierz z listy</div>
              </div>
              <select
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                name="id"
              >
                {translators.map((item) => (
                  <option value={item.id}>
                    {item["First Name"]} {item["Last Name"]}
                  </option>
                ))}
                {/*<option value={0}>- brak -</option>*/}
              </select>
            </div>
            <div>
              <div>
                <label for="user">Nazwa użytkownika</label>
                <div className="micro-info">Co najmniej 6 znaków</div>
              </div>
              <div>
                <input
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  name="user"
                  type="text"
                  minlength={8}
                  maxlength={16}
                  required={true}
                  value={formData.user}
                ></input>
              </div>
            </div>

            <div>
              <div>
                <label for="password">Hasło </label>
                <div className="micro-info">
                  Hasło musi mieć co najmniej 8 znaków, w tym:<br></br>
                  przynajmniej 1 wielką literę, 1 cyfrę i 1 znak specjalny
                </div>
              </div>
              <div>
                <input
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  minLength={8}
                  maxLength={35}
                  required={true}
                  value={formData.password}
                ></input>
              </div>
            </div>
            <div>
              <div>
                <label for="confirmPassword">Potwierdź hasło</label>
              </div>
              <div>
                <input
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  minLength={8}
                  maxLength={35}
                  required={true}
                  value={formData.confirmPassword}
                ></input>
              </div>
            </div>
            {!message.success ? (
              <div>
                <input
                  onClick={(e) => register(e)}
                  type="submit"
                  value="Dodaj użytkownika"
                ></input>
              </div>
            ) : (
              <></>
            )}
          </form>
        ) : (
          <></>
        )}
        <div className={"message" + (message.success ? " success" : " error")}>
          {message.text}
          {message.success ? (
            <div className="login-link">
              <Link to="/login">Przejdź do logowania</Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Register;
