import { useContext } from "react";

import { TableContext } from "../TableUniversal";
import BagItemOrder from "./BagItemOrder";

export default function BagItem({
  name,
  data,
  source,
  item,
  setContents,
  contents,
  disabled,
  createMap,
}) {
  return (
    <div className="bag-item">
      {createMap?.fields[source]?.display
        ? createMap.fields[source].display(item, data && data[0])
        : JSON.stringify(item)}

      { disabled ? <></> : <BagItemOrder name={name} item={item} setContents={setContents} />}
    </div>
  );
}
