import { useContext, useEffect, useRef, useState } from "react";
import { TableContext } from "./TableUniversal";
import Details from "./Details";
import Controls from "./components/Controls";
import { Field, Form, Formik } from "formik";
import FieldGroup from "./forms/FieldGroup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { URL } from "../global";
import { useGeneric } from "../useGeneric";
import removeNumberKeys from "./functions/removeNumberKeys";
import decodeSearch from "./instances/newExternals/decodeSearch";
import Loading from "../main/Loading";
import SectionGroup from "./SectionGroup";

export function objToArray(obj) {
  const arr = [];
  Object.keys(obj).forEach((key) => {
    arr.push({ ...obj[key], name: key });
  });
  return arr;
}

export function propToName(obj) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj[key] = obj[key];
      newObj[key].name = key;
    }
  });
  return newObj;
}

export function initials(obj) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[key] =
      obj[key] && obj[key].default !== undefined ? obj[key].default : "";
  });
  //console.log(newObj);
  return newObj;
}

export default function Create({
  edit,
  customMap,
  customBack,
  customButtonAction,
  initialSet = {},
}) {
  const {
    createMap,
    setUpdate,
    dataFetchName,
    update,
    offset,
    setInitialize,
    search,
    setSearch,
    prevSearch,
    setPrevSearch,
  } = useContext(TableContext);

  setPrevSearch(search);
  const structure = customMap ? customMap : createMap;
  const navigate = useNavigate();
  const location = useLocation();

  const [submitted, setSubmitted] = useState(false);
  const [hiddenFields, setHiddenFields] = useState(createMap && createMap.hiddenFields ? createMap.hiddenFields : []);

  const [bagContents, setBagContents] = useState(initialSet);

  const [initialValues, setInitialValues] = useState(
    initials(structure.fields)
  );

  const [allFormSourceData, setAllFormSourceData] = useState({});

  //const [proceduralValues, setProceduralValues] = useState({});

  const locationID = useLocation().pathname.split("/").at(-1);
  const pathID = !Number.isNaN(Number(locationID)) ? Number(locationID) : false;
  const locationState = location.state;

  const editDataFetch = useGeneric({
    name: edit ? dataFetchName : undefined,
    query: {
      search: { id: { value: edit ? pathID : undefined, exact: true } },
    },
  });
  const editData =
    editDataFetch && !editDataFetch.loading ? editDataFetch.rows : undefined;

  useEffect(() => {
    if (
      !editDataFetch.loading &&
      editDataFetch.rows &&
      editDataFetch.rows.length
    ) {
      setInitialValues(removeNumberKeys(editData[0]));
      console.log(editData[0]);
      // fill the bags

      const contents = {};
      const bags = Object.keys(structure.fields)
        .map((key) => structure.fields[key])
        .filter((f) => f.type === "bag");

      //console.log(bags)
      //console.log(structure.fields);
      bags.forEach((bag) => {
        //contents[bag.name] = editData[0][bag.name].split(",");
        contents[bag.source] = structure.fields?.[bag.name]?.revert(
          editData[0][bag.name],
          editData[0]
        );
        //console.log(structure.fields?.[bag.name])
      });

      //console.log(contents, );
      setBagContents(contents);
    }
  }, [editDataFetch.loading, editDataFetch.rows]);

  useEffect(() => {
    setInitialValues((prev) => ({ ...prev, ...initialSet }));
  }, [JSON.stringify(initialSet)]);

  useEffect(() => {
    setBagContents(initialSet);
  }, [JSON.stringify(initialSet)]);

  return (
    <div className="create-wrapper">
      {customBack ? (
        customBack
      ) : (
        <button
          onClick={() => {
            //setInitialize(true);

            setUpdate((prev) => prev + 1);
            setTimeout(
              () => {
                navigate(-1, { state: locationState });
                //navigate("." + location.search, { state: locationState });
              },
              100
            );
          }}
          className="return"
        >
          ◂
        </button>
      )}
      {structure.fields ? (
        <div
          className={
            "create-form" +
            (structure.className ? " " + structure.className : "")
          }
        >
          {edit && editDataFetch.loading ? (
            <Loading text={"Pobieranie..."} />
          ) : (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={async (values, actions) => {
                /*console.log(values);*/
                const response = await fetch(URL + structure.db, {
                  method: "POST",
                  body: JSON.stringify({
                    params: values,
                    editID: edit ? pathID : undefined,
                  }),
                  credentials: "include",
                });
                const result = await response.json();
                console.log(result);
                if (customButtonAction) {
                  customButtonAction();
                }
                setSubmitted(true);
                setUpdate((prev) => prev + 1);
                setInitialize(true);
//                setTimeout(() => navigate(".", { state: locationState }), 100);
                setTimeout(() => navigate(-1, { state: locationState }));
                return result;
              }}
            >
              {(props) => (
                <Form>
                  <SectionGroup
                    propToName={propToName}
                    structure={structure}
                    editData={editData}
                    locationState={locationState}
                    bagContents={bagContents}
                    setBagContents={setBagContents}
                    setAllFormSourceData={setAllFormSourceData}
                    allFormSourceData={allFormSourceData}
                    pathID={pathID}
                    all={editData}
                    hiddenFields={hiddenFields}
                    setHiddenFields={setHiddenFields}
                  />
                </Form>
              )}
            </Formik>
          )}
        </div>
      ) : (
        <div>Brak możliwości edycji tej tabeli</div>
      )}
    </div>
  );
}
