import React, { useEffect, useState } from "react";
import { URL } from "./global";

function getExternals(params) {
  return fetch(URL + "components/db-externals.php", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ queryType: "fetch", query: params }),
  }).then((response) => response.json());
}

function useExternals(params, updates, attrs) {
  const [externals, setExternals] = useState();
  function effect() {
    if (!params.translator_id) return;
    getExternals(params, attrs).then((result) => {
      result
        .sort((a, b) =>
          parseInt(
            new Date(b.accept_date) - new Date(a.accept_date) === 0
              ? parseInt(new Date(b.added) - new Date(a.added))
              : parseInt(new Date(b.accept_date) - new Date(a.accept_date))
          )
        )
        .sort((a, b) => parseInt(b.group_id) - parseInt(a.group_id));
      result = attrs.ascending ? result.reverse() : result;

      setExternals([
        ...result.filter((item) => !parseInt(item.settled)),
        ...result.filter((item) => parseInt(item.settled)),
      ]);
    });
  }
  useEffect(effect, [params.year, params.month, params.translator_id, updates, attrs.ascending]);

  return externals;
}

export default useExternals;
