import { useContext, useEffect, useRef, useState } from "react";
import ResultsBox from "./ResultsBox";
import FilledInput from "./FilledInput";
import { SearchBoxContext } from "./SearchBoxContext";

export default function SearchInput({
  inputValue,
  setInputValue,
  queryResults,
}) {
  const [active, setActive] = useState(false);
  const inputRef = useRef();
  const [escapePress, setEscapePress] = useState(false);
  const context = useContext(SearchBoxContext);

  useEffect(() => {
    if (active && inputRef) {
      inputRef.current.focus();
    }
    if (active && inputRef && context?.selectedItem?.number) {
      inputRef.current.placeholder =
        (context?.selectedItem?.number.replace(/\//g, ".") || "") +
        " " +
        (context?.selectedItem?.client || "");
    }
  }, [active]);


  return (
    <>
      {!active ? <FilledInput setActive={setActive} /> : <></>}
      <div className="input-divide">
        <input
          style={!active ? { display: "none" } : {}}
          className="search-input"
          value={inputValue}
          ref={inputRef}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              if (escapePress || inputValue === "") {
                setActive(false);
                setEscapePress(false);
              } else {
                setEscapePress(true);
              }
            }
          }}
          type="search"
        />
        {active ? (
          <button
            className="no-selection"
            onClick={() => {
              setActive(false);
              //context.setSelectedItem(undefined);
            }}
          >
            Powrót
          </button>
        ) : (
          <></>
        )}
        {active ? (
          <ResultsBox queryResults={queryResults} setActive={setActive} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
